import React from 'react';
import NotificationList from '../NotificationList/NotificationList';
import scss from './TopBar.module.scss';
import Profile from '../Profile/Profile';

export interface ITopBarProps {}

const TopBar: React.FC<ITopBarProps> = (props) => {
    return (
        <>
            <div className={`flexRow posRelative ${scss.rightContainer}`}>
                <NotificationList isInSidebar={true} />
                <Profile />
            </div>
        </>
    );
};

export default TopBar;
