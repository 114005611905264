import React, { useEffect, useContext, useState } from 'react';
import { InputLabel, FormControl, OutlinedInput, FormHelperText, CircularProgress, TextField } from '@material-ui/core';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { CreateLoginPasswordAction, PasswordAction } from '../../ServiceStack/ServiceStack.dtos';
import { IconButton } from '../IconButton/IconButton';
import { AppStateContext } from '../../contexts/AppState/AppState';

export interface IChangePasswordProps {
    cancelClick: () => void;
}

export const ChangePassword: React.FC<IChangePasswordProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);

    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [valid, setValid] = useState(false);
    const [pwdError, setPwdError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [processing, setProcessing] = useState(false);

    const handleChange = (event) => setFormData({ ...formData, [event.target.name]: event.target.value });

    const submit = () => {
        setProcessing(true);
        const request = new CreateLoginPasswordAction({
            email: appState.currentUser.email,
            passwordAction: PasswordAction.Change,
            currentPassword: formData.currentPassword,
            proposedPassword: formData.newPassword,
        });
        vegaApi
            .getApiClient()
            .post(request)
            .then(() => {
                setProcessing(false);
                setSuccess(true);
                setFormData({
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                });
                setTimeout(props.cancelClick, 2000);
            })
            .catch(() => {
                setProcessing(false);
                setError(true);
            });
    };

    useEffect(() => {
        setValid(!!(formData.currentPassword && formData.newPassword === formData.confirmPassword && formData.newPassword));
        if (formData.newPassword.length > 0 && formData.confirmPassword.length > 0) {
            setPwdError(formData.newPassword !== formData.confirmPassword);
        }
    }, [formData.currentPassword, formData.newPassword, formData.confirmPassword]);

    return (
        <>
            <div className='flexRow marginNormal-bottom'>
                <IconButton icon='back-arrow' className='c-white' onClick={props.cancelClick} />
                <h5>&nbsp;Edit Profile</h5>
            </div>
            <div className='topBottomMarginContainer'>
                <div>
                    <TextField name='currentPassword' label='Current Password' variant='outlined' type='password' value={formData.currentPassword} onChange={handleChange} />
                </div>
                <div>
                    <FormControl variant='outlined' error={pwdError}>
                        <InputLabel htmlFor='newPassword'>New Password</InputLabel>
                        <OutlinedInput labelWidth={100} type='password' id='newPassword' name='newPassword' value={formData.newPassword} onChange={handleChange} />
                        {pwdError && <FormHelperText>Passwords don't match</FormHelperText>}
                    </FormControl>
                </div>
                <div>
                    <FormControl variant='outlined' error={pwdError}>
                        <InputLabel htmlFor='confirmPassword'>Confirm Password</InputLabel>
                        <OutlinedInput labelWidth={100} type='password' id='confirmPassword' name='confirmPassword' value={formData.confirmPassword} onChange={handleChange} />
                        {pwdError && <FormHelperText>Passwords don't match</FormHelperText>}
                    </FormControl>
                </div>
                <div className='topBottomMarginContainer flexRow flexSeparate'>
                    <button className='text slate-light' type='button' onClick={props.cancelClick}>
                        Cancel
                    </button>
                    {!success && !processing && (
                        <button className='text primary' onClick={submit} disabled={!valid}>
                            {!error && 'Save Password'}
                            {error && 'Retry'}
                        </button>
                    )}
                    {processing && (
                        <div className='center'>
                            <CircularProgress />
                        </div>
                    )}
                </div>
                {success && <div className='c-success'>Password changed successfully!</div>}
                {error && <div className='c-danger'>Error changing password!</div>}
            </div>
        </>
    );
};
