import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { VegaProvider } from './contexts/Vega/Vega';
import { ActionsProvider } from './contexts/Actions/Actions';
import { ResourcesProvider } from './contexts/Resources/Resources';
import { WebSocketProvider } from './contexts/WebSocket/WebSocket';
import * as ServiceWorker from './serviceWorker';
import * as VegaTheme from './themes/VegaTheme';
import { SnackbarTheme } from './themes/SnackbarTheme';
import Routes from './routes/Routes';
import './css/main.scss';
import { SchedulesProvider } from './contexts/Schedules/Schedules';
import { VegaApiProvider } from './contexts/VegaApi/VegaApi';
import { AppStateProvider } from './contexts/AppState/AppState';
import { SnackbarProvider } from 'notistack';

require('dotenv').config();

const RootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter>
        <VegaProvider>
            <ActionsProvider>
                <SchedulesProvider>
                    <VegaApiProvider>
                        <MuiThemeProvider theme={VegaTheme.VegaTheme}>
                            <SnackbarProvider {...SnackbarTheme} preventDuplicate>
                                <WebSocketProvider>
                                    <AppStateProvider>
                                        <ResourcesProvider>
                                            <Routes />
                                        </ResourcesProvider>
                                    </AppStateProvider>
                                </WebSocketProvider>
                            </SnackbarProvider>
                        </MuiThemeProvider>
                    </VegaApiProvider>
                </SchedulesProvider>
            </ActionsProvider>
        </VegaProvider>
    </BrowserRouter>,
    RootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
ServiceWorker.unregister();
