import React from 'react';
import scss from './Chip.module.scss';

export interface ChipProps {
    label: any;
    icon?: JSX.Element;
    className?: string;
    onClick?: () => void;
}

const Chip = (props: ChipProps) => {
    let colorClass: string = '';

    switch (props.label) {
        case 'Needs Attention':
        case 'Pending':
        case 'Attention':
            colorClass = 'bg-warning';
            break;
        case 'Critical':
            colorClass = 'bg-danger';
            break;
        case 'Good':
        case 'Healthy':
            colorClass = 'bg-success';
            break;
        default:
    }

    return (
        <div className={`${scss.chip} ${colorClass ? colorClass : ''} ${props.className && props.className}`} onClick={props.onClick ? props.onClick : () => {}}>
            <span className={scss.icon}>{!!props.icon && props.icon}</span>
            <span className={scss.label}>{props.label}</span>
        </div>
    );
};

export default Chip;
