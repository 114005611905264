import React, { useContext } from 'react';
import scss from './WorkloadAddPcp.module.scss';
import Modal from '../Modal/Modal';
import { AppStateContext } from '../../contexts/AppState/AppState';

export interface WorkloadAddPcpTypeSelectModalProps {
    onClickImportWorkload: () => void;
    onClickSatelliteWorkload: () => void;
    onClickVegaWorkload: () => void;
    onClose: () => void;
}

const WorkloadAddPcpTypeSelectModal: React.FC<WorkloadAddPcpTypeSelectModalProps> = (props) => {
    const appState = useContext(AppStateContext);

    return (
        <>
            <Modal
                size='large'
                onClose={props.onClose}
                header={<h5>Create Workload Type</h5>}
                content={
                    <div className='flexRow flexCenter'>
                        <div className={`flexCol ${scss.workloadType}`}>
                            <div className={`flexCol ${scss.iconContainer}`}>
                                <span className='icon icon-import-resources large c-slate-lightest center' />
                            </div>
                            <button className='normal medium primary' onClick={props.onClickImportWorkload}>
                                Cloud Provider Workload
                            </button>
                            <div className='c-slate-lightest center padded'>Import an existing AWS account.</div>
                        </div>
                        <div className={`flexCol ${scss.workloadType}`}>
                            <div className={`flexCol ${scss.iconContainer}`}>
                                <span className='icon icon-satellite large c-slate-lightest center' />
                            </div>
                            <button disabled={appState.workloads.length === 0} className='normal medium primary' onClick={props.onClickSatelliteWorkload}>
                                Satellite Workload
                            </button>
                            <div className='c-slate-lightest center padded'>Move resources from one of your workloads.</div>
                        </div>
                        <div className={`flexCol ${scss.workloadType}`}>
                            <div className={`flexCol ${scss.iconContainer}`}>
                                <span className='icon icon-check large c-slate-lightest center' />
                            </div>
                            <button className='normal medium primary' disabled onClick={props.onClickVegaWorkload}>
                                Vega Complete Workload
                            </button>
                            <div className='c-slate-lightest center padded'>Vega will build and manage your workload.</div>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default WorkloadAddPcpTypeSelectModal;
