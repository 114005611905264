import React from 'react';
import axios from 'axios';
import * as Globals from '../../globals';

const SchedulesContext = React.createContext();

const stateObj = {};

class SchedulesProvider extends React.Component {
    constructor() {
        super();
        this.state = stateObj;
    }

    schedulesInit = () => this.setState(stateObj);

    get axiosConfig() {
        return {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('jwttoken') },
        };
    }

    getSchedule = async (entityId) => {
        var response = await axios.get(`${Globals.BASE_URL}schedules/exists/?EntityId=${entityId}`, this.axiosConfig);
        if (response.data.result.exists) {
            return axios.get(`${Globals.BASE_URL}schedules/${entityId}/recurring`, this.axiosConfig);
        }
    };

    postSchedule = (workloadId, model) => {
        return axios.post(`${Globals.BASE_URL}schedules/${workloadId}/recurring`, model, this.axiosConfig);
    };

    deleteSchedule = (workloadId) => axios.delete(`${Globals.BASE_URL}schedules/${workloadId}/recurring`, this.axiosConfig);

    render() {
        return (
            <SchedulesContext.Provider
                value={{
                    getSchedule: this.getSchedule,
                    postSchedule: this.postSchedule,
                    deleteSchedule: this.deleteSchedule,
                }}
            >
                {this.props.children}
            </SchedulesContext.Provider>
        );
    }
}

const SchedulesConsumer = SchedulesContext.Consumer;
export { SchedulesProvider, SchedulesConsumer, SchedulesContext };
