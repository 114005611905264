import React, { useContext, useState } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { UpdateUser } from '../../ServiceStack/ServiceStack.dtos';
import { IconButton } from '../IconButton/IconButton';

enum SaveState {
    None,
    Processing,
    Success,
    Error,
}

export interface IEditProfileProps {
    cancelClick: () => void;
}

export const EditProfile: React.FC<IEditProfileProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);
    const [model, setModel] = useState({
        fullName: appState.currentUser.firstName + ' ' + appState.currentUser.lastName,
    });
    const [processing, setProcessing] = useState(SaveState.None);

    const handleChange = (event) => {
        setModel({ ...model, [event.target.name]: event.target.value });
    };

    const saveProfile = () => {
        setProcessing(SaveState.Processing);
        const request = new UpdateUser({ email: appState.currentUser.email, ...model });
        vegaApi
            .getApiClient()
            .put(request)
            .then(() => {
                appState.loadUserData().then(() => {
                    setProcessing(SaveState.Success);
                    setTimeout(props.cancelClick, 2000);
                });
            })
            .catch(() => setProcessing(SaveState.Error));
    };

    return (
        <>
            <div className='flexRow marginNormal-bottom'>
                <IconButton icon='back-arrow' className='c-white' onClick={props.cancelClick} />
                <h5>&nbsp;Edit Profile</h5>
            </div>
            <div className='topBottomMarginContainer'>
                <div>
                    <TextField value={model.fullName} onChange={handleChange} name='fullName' label='Full Name' variant='outlined' type='text' />
                </div>
            </div>
            <div className='flexRow flexSeparate topBottomMarginContainer'>
                {processing !== SaveState.Success && (
                    <button className='text slate-light' type='button' onClick={props.cancelClick}>
                        Cancel
                    </button>
                )}
                {processing === SaveState.None && (
                    <button className='text primary' onClick={saveProfile}>
                        Save Profile
                    </button>
                )}
                {processing === SaveState.Processing && (
                    <div>
                        <CircularProgress />
                    </div>
                )}
                {processing === SaveState.Success && <div className='center c-success'>Profile Saved!</div>}
                {processing === SaveState.Error && (
                    <button className='text danger' onClick={saveProfile}>
                        Try again
                    </button>
                )}
            </div>
        </>
    );
};
