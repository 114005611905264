import React from 'react';
import { Grid, FormControl, InputLabel, MenuItem, Select, TextField, OutlinedInput, InputAdornment, FormHelperText } from '@material-ui/core';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { UpdateSpace } from '../../ServiceStack/ServiceStack.dtos';

export type UpdateSpaceForm = Omit<UpdateSpace, 'createResponse' | 'getTypeName' | 'spaceId'>;

export interface SpaceEditModelProps {
    errors: any;
    model: UpdateSpaceForm;
    handleChange: (event: any) => void;
}

const SpaceEditModel: React.FC<SpaceEditModelProps> = (props) => {
    const appState = React.useContext(AppStateContext);

    return (
        <Grid container spacing={4}>
            <Grid item container xs={6} spacing={4}>
                <Grid item xs={12}>
                    <FormControl variant='outlined' error={!!props.errors.name}>
                        <InputLabel htmlFor='name'>Space Name</InputLabel>
                        <OutlinedInput id='name' name='name' value={props.model.name} onChange={props.handleChange} required labelWidth={100} />
                        {props.errors.name && <FormHelperText>{props.errors.name}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' error={!!props.errors.description}>
                        <InputLabel htmlFor='description'>Space Description</InputLabel>
                        <OutlinedInput
                            id='description'
                            name='description'
                            multiline={true}
                            rows={3}
                            value={props.model.description}
                            onChange={props.handleChange}
                            required
                            labelWidth={140}
                        />
                        {props.errors.description && <FormHelperText>{props.errors.description}</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item container xs={6} spacing={4}>
                <Grid item xs={12}>
                    <FormControl variant='outlined'>
                        <InputLabel htmlFor='spaceTypeId'>Space Type</InputLabel>
                        <Select value={props.model.spaceTypeId} onChange={props.handleChange} input={<OutlinedInput labelWidth={90} name='spaceTypeId' id='spaceTypeId' />}>
                            {appState.spaceTypes.map((spaceType) => (
                                <MenuItem key={spaceType.spaceTypeId} value={spaceType.spaceTypeId}>
                                    {spaceType.spaceTypeName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item container xs={12} spacing={4}>
                    <Grid item xs={7}>
                        <TextField
                            id='budgetAmount'
                            name='budgetAmount'
                            label='Budget'
                            variant='outlined'
                            type='number'
                            value={props.model.budgetAmount}
                            InputProps={{
                                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                            }}
                            onChange={props.handleChange}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl variant='outlined'>
                            <InputLabel htmlFor='budgetPeriodType'>Period</InputLabel>
                            <Select
                                value={props.model.budgetPeriodType}
                                onChange={props.handleChange}
                                input={<OutlinedInput labelWidth={60} name='budgetPeriodType' id='budgetPeriodType' />}
                            >
                                <MenuItem value='Monthly'>Monthly</MenuItem>
                                <MenuItem value='Yearly'>Yearly</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SpaceEditModel;
