import React, { useContext, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, TextField, CircularProgress, FormControlLabel, Checkbox } from '@material-ui/core';
import { ReactComponent as AwsLogo } from '../../images/icons/logos/aws-logo.svg';
import scss from './WorkloadImportAws.module.scss';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import {
    CreateWorkload,
    CloudProvider,
    WorkloadCreationType,
    CreateWorkloadAws,
    GetWorkload,
    ListMasterWorkloadSummaries,
    ListMasterWorkloadSummariesModel,
} from '../../ServiceStack/ServiceStack.dtos';
import { AppStateContext } from '../../contexts/AppState/AppState';
import PageFooter from '../PageFooter/PageFooter';
import { IconButton } from '../IconButton/IconButton';
import Modal from '../Modal/Modal';
import { urlUpOneLevel } from '../../utils';
import WorkloadInfoForm from '../WorkloadInfoForm/WorkloadInfoForm';
import CombinedTopbar from '../CombinedTopbar/CombinedTopbar';

export type CreateWorkloadForm = Omit<CreateWorkload, 'createResponse' | 'getTypeName'>;

export interface WorkloadImportAwsProps extends RouteComponentProps<any> {}

const WorkloadImportAws: React.FC<WorkloadImportAwsProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);

    const [allowAddWorkload, setAllowAddWorkload] = useState(false);
    const [showMasterInfo, setShowMasterInfo] = useState(false);
    const [isMasterOrgSet, setIsMasterOrgSet] = useState(true);
    const [accountId, setAccountId] = useState('');
    const [formState, setFormState] = useState<CreateWorkloadForm>(
        new CreateWorkload({
            spaceId: appState.currentSpace.spaceId,
            masterWorkloadId: '',
            isMasterWorkload: false,
            name: '',
            description: '',
            workloadTypeId: 0,
            cloudProvider: CloudProvider.Aws,
            workloadCreationType: WorkloadCreationType.Import,
            budgetAmount: 0,
            budgetPeriodType: '',
            isLinkedWorkload: false,
        })
    );
    const [saving, setSaving] = useState(false);
    const [masterWorkloads, setMasterWorkloads] = useState<ListMasterWorkloadSummariesModel[]>([]);

    const handleChange = (event: any) => {
        let value: string = event.target.value;
        if (event.target.name === 'accountId') {
            value = value.replace(/-/gi, '');
            setAccountId(value);
            return;
        }
        setFormState({ ...formState, [event.target.name]: value });
    };

    const handleCheckChange = (event) => {
        let clearObj = {};
        if (formState.isLinkedWorkload && event.target.name === 'isMasterWorkload' && event.target.checked) {
            clearObj = { isLinkedWorkload: false };
        }
        setFormState({ ...formState, ...clearObj, [event.target.name]: event.target.checked });
    };

    const cancelClick = () => urlUpOneLevel(props);

    const addWorkload = async () => {
        try {
            setSaving(true);
            if (formState.isLinkedWorkload && masterWorkloads.length > 0) formState.masterWorkloadId = masterWorkloads[0].workloadId;
            const responseNewWl = await vegaApi.getApiClient().post(new CreateWorkload(formState));
            const requestAws = new CreateWorkloadAws({ workloadId: responseNewWl.result.workloadId, accountId: accountId });
            await vegaApi.getApiClient().post(requestAws);
            appState.setNewLoadId(responseNewWl.result.workloadId);
            appState.setCurrentWorkload((await vegaApi.getApiClient().get(new GetWorkload({ workloadId: responseNewWl.result.workloadId }))).result);
            await appState.loadWorkloads();
            props.history.push(`/spaces/${responseNewWl.result.spaceId}/${responseNewWl.result.workloadId}/configure`);
        } catch (error) {
            console.error(error);
            setSaving(false);
        }
    };

    useEffect(() => {
        // Check required fields are entered
        let allowNext = formState.name && formState.workloadTypeId && formState.description && accountId.length === 12;
        setAllowAddWorkload(allowNext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState, accountId]);

    const onAfterLoad = async () => {
        const masterWlResult = await vegaApi.getApiClient().get(new ListMasterWorkloadSummaries());
        setIsMasterOrgSet(masterWlResult.result.filter((x) => x.cloudProvider === CloudProvider.Aws).length > 0);
        setMasterWorkloads(masterWlResult.result);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        onAfterLoad();
    }, []);

    return (
        <>
            {showMasterInfo && (
                <Modal
                    size='small'
                    header={<h5>AWS Organizations</h5>}
                    content={
                        <>
                            Vega AWS Master Cloud Provider Workloads are required when you are using AWS Organizations so we can simplify and optimize your public cloud environment
                            correctly. When using AWS Organizations you have Master and Linked Accounts. AWS recommends a multi-account environment as a security and compliance
                            best practice. By isolating your workloads or applications into individual accounts, you can control access to specific workloads using accounts and
                            manage all of your accounts using AWS Organizations.
                        </>
                    }
                    onClose={() => setShowMasterInfo(false)}
                />
            )}
            <div className='scrollContainer flexGrow flexCol'>
                <header className='pageHeaderContainer'>
                    <CombinedTopbar />
                    <div className='flexRow flexSeparate alignItemsCenter'>
                        <h4>Create Vega Cloud Provider Workload</h4>
                        <div className='flexRow'>
                            <div className={scss.addSpaceType}></div>
                            <div className='svgIconNormal'>
                                <AwsLogo />
                            </div>
                        </div>
                    </div>
                </header>
                {!isMasterOrgSet && (
                    <div className='marginNormal'>
                        <FormControlLabel
                            control={<Checkbox checked={formState.isMasterWorkload} name='isMasterWorkload' onClick={handleCheckChange} />}
                            label={
                                <>
                                    This is an AWS Organization Master Account&nbsp;&nbsp;
                                    <IconButton icon='info' onClick={() => setShowMasterInfo(true)} />
                                </>
                            }
                        />
                        <p className={`body2 c-slate-light mui-checkbox-offset`}>
                            Select this if you would like to make this workload your master AWS Account workload for your Vega Organization.
                        </p>
                    </div>
                )}
                <div className='viewContainer flexRow flexGrow'>
                    <div className='marginNormal flex1 paper padded'>
                        <WorkloadInfoForm model={formState} handleChange={handleChange} />
                    </div>
                    <div className='marginNormal flex1 paper padded'>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <h5>Workload AWS Details</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField value={accountId} id='accountId' name='accountId' label='AWS Account' variant='outlined' onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} className='flexCol'>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={formState.isMasterWorkload}
                                                name='isLinkedWorkload'
                                                checked={formState.isLinkedWorkload}
                                                onChange={handleCheckChange}
                                            />
                                        }
                                        label='Part of an AWS Organization'
                                    />
                                </div>
                                <p className={`body2 c-slate-light mui-checkbox-offset`} style={{ maxWidth: '20rem' }}>
                                    Select this if you would like to add this workload to an AWS Organization Master Account.
                                </p>
                            </Grid>
                            {formState.isLinkedWorkload && !isMasterOrgSet && (
                                <Grid item xs={12}>
                                    <p className={`body2 c-danger mui-checkbox-offset`} style={{ maxWidth: '35rem' }}>
                                        A master account has not been created yet. You can still create this workload but will need to create your Master AWS Account Workload for
                                        some features to work properly.
                                    </p>
                                </Grid>
                            )}
                            {formState.isLinkedWorkload && isMasterOrgSet && (
                                <Grid item xs={12}>
                                    <p className={`body2 mui-checkbox-offset`} style={{ maxWidth: '35rem' }}>
                                        AWS Master Account Workload Information
                                        <br />
                                        <br />
                                        {masterWorkloads[0].name}
                                        <br />
                                        {masterWorkloads[0].accountId}
                                    </p>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </div>
            </div>
            <PageFooter
                buttons={
                    <>
                        <button className='accent medium primary' disabled={saving} onClick={cancelClick}>
                            Cancel
                        </button>
                        {!saving && (
                            <button className='normal medium primary' disabled={!allowAddWorkload} onClick={addWorkload}>
                                Add Workload
                            </button>
                        )}
                        {saving && <CircularProgress />}
                    </>
                }
            />
        </>
    );
};

export default WorkloadImportAws;
