import React from 'react';
import axios from 'axios';

import * as Globals from '../../globals';
import * as Utils from '../../utils';

const VegaContext = React.createContext();

const stateObj = {
    complianceTypes: Globals.EMPTY_ARRAY,
};
class VegaProvider extends React.Component {
    constructor() {
        super();
        this.state = stateObj;
    }

    vegaInit = () => {
        this.setState(stateObj);
    };

    loadComplianceTypes = async () => {
        try {
            let response = await axios.get(`${Globals.BASE_URL}organizations/lookups/types/compliance`);
            // Check response and update state
            if (response.errorCode !== undefined && response.errorCode.length > 0) {
                this.setState({
                    IsError: true,
                    IsAuthenticated: false,
                    ErrorCode: response.errorCode,
                    ErrorMsg: response.errorMsg,
                    IsProcessing: false,
                    IsEmailValid: false,
                });
            } else {
                this.setState({
                    complianceTypes: response.data.result.complianceTypes,
                });
            }
        } catch (e) {
            Utils.DoErrorStuff(e, this);
        }
    };

    render() {
        return (
            <VegaContext.Provider
                value={{
                    complianceTypes: this.state.complianceTypes,
                    loadComplianceTypes: this.loadComplianceTypes,
                    vegaInit: this.vegaInit,
                }}
            >
                {this.props.children}
            </VegaContext.Provider>
        );
    }
}

const VegaConsumer = VegaContext.Consumer;

export { VegaProvider, VegaConsumer, VegaContext };
