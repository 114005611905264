import React, { useContext, useState } from 'react';
import { Typography, Checkbox, Grid, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@material-ui/core';
import EmailInput from '../EmailInput/EmailInput';
import FullNameInput from '../FullName/FullName';
import TermsConditions from '../TermsConditions/TermsConditions';
import * as Globals from '../../globals';
import SignupLayout from '../SignupLayout/SignupLayout';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Redirect } from 'react-router-dom';
import { CreateOnBoardUserForm } from './SignUpLanding';
import { AppStateContext } from '../../contexts/AppState/AppState';

export interface SignUpProps {
    model: CreateOnBoardUserForm;
    modelChange: (newVal: CreateOnBoardUserForm) => void;
    onNext: () => void;
}

const SignUp: React.FC<SignUpProps> = (props) => {
    const appState = useContext(AppStateContext);
    const [thePwdConfirm, setThePwdConfirm] = useState('');

    const handlePasswordChange = (event) => {
        const temp = props.model;
        temp.password = event.target.value;
        props.modelChange(temp);
    };

    const handlePwConfirmChange = (event) => setThePwdConfirm(event.target.value);

    const setFullName = (myName) => {
        const temp = props.model;
        temp.userFullName = myName;
        props.modelChange(temp);
    };

    const setUserName = (email) => {
        const temp = props.model;
        temp.email = email;
        props.modelChange(temp);
    };

    const phoneChanged = (val, data) => {
        // this.setState({ cntryCd: data.dialCode });
        const temp = props.model;
        temp.mobilePhone = val;
        props.modelChange(temp);
    };

    const tcChanged = (event) => {
        const temp = props.model;
        temp.acceptTerms = event.target.checked;
        props.modelChange(temp);
    };

    const onFormSubmit = (event) => {
        event.preventDefault();
        props.onNext();
    };

    const allowNext =
        !!props.model.userFullName &&
        !!props.model.email &&
        !!props.model.password &&
        !!props.model.mobilePhone &&
        !!props.model.acceptTerms &&
        props.model.password === thePwdConfirm;

    const passwordsMatch = props.model.password === thePwdConfirm || (!props.model.password && !thePwdConfirm);

    return (
        <SignupLayout>
            {!appState.inviteCodeVerified && <Redirect to='/' />}
            <form onSubmit={onFormSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <FullNameInput setFN={setFullName} />
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <EmailInput id='yourEmail' setUN={setUserName} />
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <FormControl variant='outlined' error={!passwordsMatch}>
                            <InputLabel htmlFor='password'>Password</InputLabel>
                            <OutlinedInput id='password' name='password' value={props.model.password} onChange={handlePasswordChange} type='password' labelWidth={70} />
                            {!passwordsMatch && <FormHelperText>Passwords don't match</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <FormControl variant='outlined' error={!passwordsMatch}>
                            <InputLabel htmlFor='thePwdConfirm'>Confirm Password</InputLabel>
                            <OutlinedInput id='thePwdConfirm' name='thePwdConfirm' value={thePwdConfirm} onChange={handlePwConfirmChange} type='password' labelWidth={130} />
                            {!passwordsMatch && <FormHelperText>Passwords don't match</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <PhoneInput country={'us'} value={props.model.mobilePhone} autoFormat={false} onChange={phoneChanged} />
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={3} />
                    <Grid item xs={1}>
                        <Checkbox checked={props.model.acceptTerms} onChange={tcChanged} value='checkedTCs' color='primary' />
                    </Grid>
                    <Grid item xs={5}>
                        <Typography display='inline'>{Globals.TC_AGREE_STR}</Typography>
                        <TermsConditions LinkText={Globals.TC_STR} />
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={12}>
                        <button type='submit' className='normal medium primary block center' disabled={!allowNext}>
                            Create an Account
                        </button>
                    </Grid>
                </Grid>
            </form>
        </SignupLayout>
    );
};

export default SignUp;
