import React, { ChangeEvent } from 'react';
import Modal from '../Modal/Modal';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { GrantSecurityAccessType } from './GrantSecurityAccessController';
import { HoursInput } from '../HoursInput/HoursInput';

export interface IGrantSecurityAccessProps {
    onCancelClick: () => void;
    onClickGrantSecurityAccess: () => void;
    onClickGetMyIp: () => void;
    onChangeIpAddress: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeFromPort: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeToPort: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeSecurityAccessType: (event) => void;
    onChangeIpProtocol: (event) => void;
    onChangeIsTimedAccess: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeTimeForAccess: (event: { hours: string; isValid: boolean }) => void;
    fromPort: string;
    toPort: string;
    ipAddress: string;
    grantSecurityAccessTypes: GrantSecurityAccessType[];
    ipProtocols: string[];
    selectedIpProtocol: string;
    selectedSecurityAccessType: number;
    isTimedAccess: boolean;
    timeForAccess: string;
    isValidGrantSecurityInput: boolean;
    isValidIpAddress: boolean;
}

const GrantSecurityAccess: React.FC<IGrantSecurityAccessProps> = (props) => {
    const classes = useStyles(props);

    return (
        <>
            <Modal
                size='fullscreen'
                header={<h6>Grant Security Access</h6>}
                content={
                    <>
                        <Grid container spacing={4}>
                            <Grid container item xs={12} justify={'center'} className='c-slate-light'>
                                Grant time or untimed security access to the workload.
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant='outlined'>
                                    <InputLabel classes={{ outlined: classes.DropDownLabel }}>Select Type (Optional)</InputLabel>
                                    <Select value={props.selectedSecurityAccessType} onChange={props.onChangeSecurityAccessType}>
                                        {props.grantSecurityAccessTypes.map((grantSecurityAccessType, index) => (
                                            <MenuItem key={index} value={index}>
                                                {grantSecurityAccessType.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant='outlined'>
                                    <InputLabel classes={{ outlined: classes.DropDownLabel }}>Select Protocol</InputLabel>
                                    <Select value={props.selectedIpProtocol} onChange={props.onChangeIpProtocol}>
                                        {props.ipProtocols.map((ipProtocol) => (
                                            <MenuItem key={ipProtocol} value={ipProtocol}>
                                                {ipProtocol}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField type='Number' name='' label='From Port' variant='outlined' value={props.fromPort} onChange={props.onChangeFromPort} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Number(props.toPort) < Number(props.fromPort)}
                                    helperText={Number(props.toPort) < Number(props.fromPort) && 'To Port must be greater than or equal to From Port.'}
                                    type='Number'
                                    name=''
                                    label='To Port'
                                    variant='outlined'
                                    value={props.toPort}
                                    onChange={props.onChangeToPort}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    error={!props.isValidIpAddress}
                                    helperText={!props.isValidIpAddress && 'Must be an IPv4 CIDR address.'}
                                    name=''
                                    label='Ip Address'
                                    variant='outlined'
                                    value={props.ipAddress}
                                    onChange={props.onChangeIpAddress}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Button className={classes.GetMyIpButton} variant={'contained'} fullWidth={true} onClick={props.onClickGetMyIp}>
                                    Get My Ip
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    classes={{ label: classes.MuiFormControlLabel }}
                                    control={<Checkbox checked={props.isTimedAccess} onChange={props.onChangeIsTimedAccess} name='timedAccess' />}
                                    label='Control access time.'
                                />
                            </Grid>
                            {props.isTimedAccess && (
                                <HoursInput onChangeHours={props.onChangeTimeForAccess} hours={props.timeForAccess} maxHours={8760} label={'Security Access Grant Time'} />
                            )}
                            <Grid container justify={'center'} item xs={12} className={classes.GrantSecurityAccessWarning}>
                                {props.isTimedAccess
                                    ? `You are granting security access to this workload for ${props.timeForAccess ? props.timeForAccess : '_'} hours.`
                                    : 'You are granting security access to this workload indefinitely'}
                            </Grid>
                        </Grid>
                    </>
                }
                footer={
                    <>
                        <button className='text c-slate-light medium' onClick={props.onCancelClick} type='button'>
                            Cancel
                        </button>
                        <button className='primary normal medium' onClick={props.onClickGrantSecurityAccess} disabled={!props.isValidGrantSecurityInput} type='button'>
                            Grant Security Access
                        </button>
                    </>
                }
                onClose={props.onCancelClick}
            />
        </>
    );
};

type GrantSecurityAccessClassNames = 'MuiFormControlLabel' | 'GetMyIpButton' | 'GrantSecurityAccessWarning' | 'DropDownLabel';

const useStyles = makeStyles<Theme, IGrantSecurityAccessProps, GrantSecurityAccessClassNames>((theme: Theme) =>
    createStyles({
        MuiFormControlLabel: {
            '&.MuiFormControlLabel-label': {
                marginTop: 15,
            },
        },
        GetMyIpButton: {
            marginTop: 7,
        },
        GrantSecurityAccessWarning: {
            color: theme.palette.warning.main,
        },
        DropDownLabel: {
            '&.MuiInputLabel-outlined': {
                backgroundColor: 'white',
                paddingLeft: 5,
                paddingRight: 5,
            },
        },
    })
);

export { GrantSecurityAccess };
