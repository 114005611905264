import React from 'react';
import scss from './CostInfoTile.module.scss';

export interface CostInfoTileProps {
    icon?: JSX.Element;
    title: JSX.Element | string;
    graph?: JSX.Element;
    subtitle: JSX.Element | string;
    className?: string;
}

const CostInfoTile: React.FC<CostInfoTileProps> = (props) => {
    return (
        <>
            <div className={`flexRow ${!!props.className && props.className}`}>
                <div className={scss.icon}>{!!props.icon && props.icon}</div>
                <div className={scss.col}>
                    <h6 className='body1 c-secondary'>{props.title}</h6>
                    {!!props.graph && <div className={scss.graph}>{props.graph}</div>}
                    <p className='body2 c-slate-light'>{props.subtitle}</p>
                </div>
            </div>
        </>
    );
};

export default CostInfoTile;
