import React from 'react';

const ellipsePipe = (text) => {
    if (text !== undefined && text.length > 25) {
        const shortened = text.substring(0, 25);
        return <span title={text}>{shortened}...</span>;
    }
    return <span>{text}</span>;
};

export default ellipsePipe;
