import React, { useContext, useState, useEffect } from 'react';
import {
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText,
    TextField,
    Select,
    MenuItem,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    InputAdornment,
    TableSortLabel,
    Checkbox,
} from '@material-ui/core';
import DotIndicator from '../DotIndicator/DotIndicator';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { CloudResource, GetWorkload, WorkloadContentType } from '../../ServiceStack/ServiceStack.dtos';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { AppStateContext } from '../../contexts/AppState/AppState';

export interface WorkloadSatelliteAwsModelProps extends RouteComponentProps<any> {
    mode: 'Add' | 'Edit';
    model: any;
    handleChange: (event: any) => void;
    resources: CloudResource[];
    selectedResources: CloudResource[];
    selectedResourcesChange: (val: CloudResource[]) => void;
}

const WorkloadSatelliteAwsModel: React.FC<WorkloadSatelliteAwsModelProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);

    const [displayData, setDisplayData] = useState<CloudResource[]>([]);
    const [orderBy, setOrderBy] = useState('');
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState(true); // false = asc, true = desc
    const [parentWorkloadName, setParentWorkloadName] = useState('');

    const handleSearch = (event) => setSearch(event.target.value.toLowerCase());

    const clickedHeader = (colName) => {
        setOrder(!order);
        setOrderBy(colName);
    };

    const searchNsort = (): void => {
        let temp = props.resources;

        if (search) {
            temp = temp.filter((resource) => {
                let retval = false;
                Object.keys(resource).forEach((x) => {
                    if (resource[x].toString().toLowerCase().indexOf(search) !== -1) retval = true;
                });
                return retval;
            });
        }

        if (orderBy) {
            temp.sort((a, b) => {
                return order ? (a[orderBy] > b[orderBy] ? -1 : 1) : a[orderBy] < b[orderBy] ? 1 : -1;
            });
        }

        setDisplayData(temp);
    };

    const isCheckboxChecked = (id) => {
        return props.selectedResources.findIndex((x) => x.hostName === id) !== -1;
    };

    const handleSelectAll = (event) => {
        if (!event.target.checked) {
            props.selectedResourcesChange([]);
        } else {
            props.selectedResourcesChange(displayData);
        }
    };

    const handleCheck = (event) => {
        if (event.target.checked) {
            props.selectedResourcesChange([...props.selectedResources, props.resources.find((x) => x.hostName === event.target.value)]);
        } else {
            const tempSelectedResources = [...props.selectedResources];
            const idx = tempSelectedResources.map((x) => x.hostName).indexOf(event.target.value);
            if (idx !== -1) {
                tempSelectedResources.splice(idx, 1);
                props.selectedResourcesChange(tempSelectedResources);
            }
        }
    };

    const getSetParentWorkloadName = () => {
        vegaApi
            .getApiClient()
            .get(new GetWorkload({ workloadId: appState.currentWorkload.parentWorkloadId }))
            .then((response) => setParentWorkloadName(response.result.name));
    };

    useEffect(() => {
        searchNsort();
        // eslint-disable-next-line
    }, [orderBy, search, order, props.resources]);

    useEffect(() => {
        if (!appState.currentWorkload) {
            props.history.push(props.location.pathname.substring(0, props.location.pathname.lastIndexOf('/')));
            return;
        }

        if (appState.currentWorkload.workloadContentType === WorkloadContentType.Satellite) {
            getSetParentWorkloadName();
        } else {
            setParentWorkloadName(appState.currentWorkload.name);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className='flexRow'>
                <div className='marginNormal flex1 padded paper'>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <h5>Workload Information</h5>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='outlined'>
                                <InputLabel htmlFor='name'>Workload Name</InputLabel>
                                <OutlinedInput id='name' name='name' value={props.model.name} onChange={props.handleChange} labelWidth={110} />
                                <FormHelperText></FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='outlined'>
                                <InputLabel htmlFor='description'>Workload Description</InputLabel>
                                <OutlinedInput
                                    multiline={true}
                                    rows={3}
                                    value={props.model.description}
                                    id='description'
                                    name='description'
                                    onChange={props.handleChange}
                                    labelWidth={160}
                                />
                                <FormHelperText></FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant='outlined'>
                                <InputLabel htmlFor='workloadTypeId'>Workload Type</InputLabel>
                                <Select
                                    value={props.model.workloadTypeId}
                                    onChange={props.handleChange}
                                    input={<OutlinedInput labelWidth={110} name='workloadTypeId' id='workloadTypeId' />}
                                >
                                    {appState.workloadTypes.map((workloadType) => (
                                        <MenuItem key={workloadType.id} value={workloadType.id}>
                                            {workloadType.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div className='marginNormal flex1 padded paper'>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <h5>Parent Workload Information</h5>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name='parentWorkloadName' label='Parent Workload Name' variant='outlined' disabled={true} value={parentWorkloadName} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='awsAccount'
                                label='AWS Account'
                                variant='outlined'
                                disabled={true}
                                value={appState.currentWorkload.workloadConfigurationAws.accountId}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div className='marginNormal paper padded'>
                <h5>Resource List</h5>
                <div className='flexRow flexSeparate'>
                    <p className='c-slate-lightest'>
                        {props.mode === 'Add' && <>Please select the available resources to add to your satellite workload.</>}
                        {props.mode === 'Edit' && <>Add or remove resources from your satellite workload.</>}
                    </p>
                    <div>
                        <TextField
                            id='search'
                            name='search'
                            label='Search'
                            variant='outlined'
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <span className='icon icon-search' />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    // checked={isSelectAll}
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'resourceKind'} direction={order ? 'asc' : 'desc'} onClick={() => clickedHeader('resourceKind')}>
                                    Type
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'resourceType'} direction={order ? 'asc' : 'desc'} onClick={() => clickedHeader('resourceType')}>
                                    Size
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'resourceId'} direction={order ? 'asc' : 'desc'} onClick={() => clickedHeader('resourceId')}>
                                    Id
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'region'} direction={order ? 'asc' : 'desc'} onClick={() => clickedHeader('region')}>
                                    Region
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel active={orderBy === 'operatingState'} direction={order ? 'asc' : 'desc'} onClick={() => clickedHeader('operatingState')}>
                                    State
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayData.map((resource, index) => (
                            <TableRow key={resource.hostName + index}>
                                <TableCell>
                                    <Checkbox onChange={handleCheck} value={resource.hostName} checked={isCheckboxChecked(resource.hostName)} />
                                </TableCell>
                                <TableCell>{resource.resourceKind}</TableCell>
                                <TableCell>{resource.resourceType}</TableCell>
                                <TableCell>{resource.resourceId}</TableCell>
                                <TableCell>{resource.region}</TableCell>
                                <TableCell>
                                    <DotIndicator color={resource.operatingState} />
                                    {resource.operatingState}
                                </TableCell>
                            </TableRow>
                        ))}
                        {displayData.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={6}>No resources found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </>
    );
};

export default withRouter(WorkloadSatelliteAwsModel);
