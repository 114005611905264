import React from 'react';
import VegaBreadcrumbs from '../VegaBreadcrumbs/VegaBreadcrumbs';
import TopBar from '../TopBar/TopBar';
import scss from './CombinedTopbar.module.scss';

export interface ICombinedTopbarProps {
    hideBreadcrumbs?: boolean;
}

const CombinedTopbar: React.FC<ICombinedTopbarProps> = (props) => {
    return (
        <>
            <div className={`flexRow flexSeparate ${scss.combined}`}>
                {!props.hideBreadcrumbs ? <VegaBreadcrumbs /> : <div></div>}
                <TopBar />
            </div>
        </>
    );
};

export default CombinedTopbar;
