import moment from 'moment';
import React from 'react';

const dateTimePipe = (text) => {
    if (text !== undefined) {
        if (text.charAt(text.length - 1) !== 'Z') text += 'Z';
        const temp = moment(text).format('LLL');
        return <span>{temp}</span>;
    }

    return <span>{text}</span>;
};

export default dateTimePipe;
