import React from 'react';
import * as Globals from '../../globals';
import { RouteComponentProps } from 'react-router-dom';

export interface NotFoundProps extends RouteComponentProps<any> {}

const NotFound: React.FC<NotFoundProps> = (props) => (
    <div className='pageCenterContainer bg-mono'>
        <div className='center'>
            <h1>Page Not Found</h1>
            <p className='body1'>We are sorry, but the page you are looking for could not be found.</p>
            <button className='primary normal large' onClick={() => props.history.push('/dashboard')}>
                Home
            </button>
            <p className='body1'>Version: {Globals.VCCC_VERSION}</p>
        </div>
    </div>
);

export default NotFound;
