import React from 'react';
import scss from './Modal.module.scss';

export interface ModalProps {
    header: JSX.Element[] | JSX.Element;
    content: JSX.Element[] | JSX.Element;
    footer?: JSX.Element[] | JSX.Element;
    onClose: () => void;
    size?: 'auto' | 'small' | 'large' | 'fullscreen';
    disableAnimation?: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
    return (
        <div
            className={`
            ${scss.container} 
            ${props.size === 'large' && scss.large} 
            ${props.size === 'fullscreen' && scss.fullsize} 
            ${props.disableAnimation && scss.noAnimation} 
            ${props.size === 'small' && scss.small} 
        `}
        >
            <div className={`flexCol ${scss.modal}`}>
                <header className={`padded ${scss.header}`}>
                    {props.header}
                    <button className='iconButton closeButton' onClick={props.onClose}>
                        <span className='icon icon-close' />
                    </button>
                </header>
                <div className={`padded flexGrow ${scss.content}`}>{props.content}</div>
                {props.footer && <footer className={`padded flexRow flexSeparate alignItemsCenter ${scss.footer}`}>{props.footer}</footer>}
            </div>
        </div>
    );
};

export default Modal;
