import React, { useContext, useState, useMemo } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Badge, TableSortLabel } from '@material-ui/core';
import { ReactComponent as MySpaceIcon } from '../../images/icons/myspace-ico.svg';
import { ReactComponent as SpaceIcon } from '../../images/icons/space-ico.svg';
import * as Globals from '../../globals';
import scss from './SpacesLanding.module.scss';
import { SpaceCostInfo } from '../SpaceDetail/SpaceCostInfo';
import { MakeMoney } from '../../utils';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import Icon from '../Icon/Icon';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { ListSpacesSummary, ListSpacesSummaryModel } from '../../ServiceStack/ServiceStack.dtos';
import Skeleton from '@material-ui/lab/Skeleton';
import CombinedTopbar from '../CombinedTopbar/CombinedTopbar';

export interface SpacesLandingProps extends RouteComponentProps<any> {}

const SpacesLanding: React.FC<SpacesLandingProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const [viewType, setViewType] = useState(Globals.VIEW_TYPES.CARD);
    const [spaceSummaries, setSpaceSummaries] = useState<ListSpacesSummaryModel[]>([]);
    const [spacesLoading, setSpacesLoading] = useState(false);
    const [spacesLoaded, setSpacesLoaded] = useState(false);
    const [error, setError] = useState('');

    const handleView = (vType) => () => setViewType(vType);

    React.useEffect(() => {
        setSpacesLoading(true);
        vegaApi
            .getApiClient()
            .get(new ListSpacesSummary())
            .then((response) => {
                setSpacesLoading(false);
                setSpacesLoaded(true);
                setSpaceSummaries(response.result);
            })
            .catch((error) => {
                console.error(error);
                setSpacesLoading(false);
                setError('Unable to load space summaries');
            });
        // eslint-disable-next-line
    }, []);

    const CardView = (
        <div className={scss.cardContainer}>
            <NavLink className={`c-secondary simpleCard flexCol flexCenter addCard ${scss.spaceCard}`} to='/spaces/add'>
                <div className='c-primary'>
                    <Icon icon='plus' /> Add Space
                </div>
            </NavLink>
            {spaceSummaries.map((space) => (
                <NavLink key={space.spaceId} className={`c-secondary simpleCard selectable padded ${scss.spaceCard}`} to={`/spaces/${space.spaceId}`}>
                    <div className='flexCol'>
                        <div className='flexRow alignItemsCenter'>
                            <div className='cardIcon'>
                                {space.spaceContainerType === Globals.SPACE_TYPES.MySpace && <MySpaceIcon />}
                                {space.spaceContainerType === Globals.SPACE_TYPES.Standard && <SpaceIcon />}
                            </div>
                            <div className='flexCol'>
                                <div className='c-slate-light'>
                                    Spend&nbsp;&nbsp;&nbsp;&nbsp;
                                    {MakeMoney(space.totalCost)}
                                </div>
                                <div className='c-secondary'>{space.name}</div>
                            </div>
                        </div>
                        <div className={`${scss.spaceCardBody} flexRow`}>
                            <Badge badgeContent={space.notificationCount} color='error' className='center'>
                                <div className={`${scss.numCircle}`}>{space.workloadCount}</div>
                            </Badge>
                        </div>
                        {/* <div>
                                <div className='center'>
                                    <Chip label={space.vegaHealth} />
                                </div>
                            </div> */}
                    </div>
                </NavLink>
            ))}
            {spacesLoading && (
                <div className={`c-secondary simpleCard padded ${scss.spaceCard}`} role='loading'>
                    <div className='flexCol'>
                        <div className='flexRow alignItemsCenter'>
                            <div className='cardIcon'>
                                <Skeleton variant='circle' width={56} height={56} />
                            </div>
                            <div className='flexCol'>
                                <div className='c-slate-light'>
                                    <Skeleton variant='text' width={150} animation='wave' />
                                </div>
                                <div className='c-secondary'>
                                    <Skeleton variant='text' width={230} animation='wave' />
                                </div>
                            </div>
                        </div>
                        <div className={`${scss.spaceCardBody} flexRow`}>
                            <Skeleton className='center' variant='circle' width={56} height={56} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    const columns = useMemo(
        () => [
            { Header: 'Type', accessor: 'spaceContainerType' },
            { Header: 'Name', accessor: 'name' },
            { Header: 'Spend', accessor: 'totalCost' },
            { Header: 'Workloads', accessor: 'workloadCount' },
            { Header: 'Notifications', accessor: 'notificationCount' },
        ],
        []
    );

    const table = useTable(
        {
            columns,
            data: spaceSummaries,
        },
        useSortBy
    );
    const TableView = (
        <Table {...table.getTableProps()}>
            <TableHead>
                {table.headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'}>
                                    {column.render('Header')}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody>
                {table.rows.map(
                    (row) =>
                        table.prepareRow(row) || (
                            <TableRow {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <TableCell {...cell.getCellProps()}>
                                        {(() => {
                                            switch (cell.column.Header) {
                                                case 'Type':
                                                    return (
                                                        <div className='svgIconXSmall'>
                                                            {cell.value === Globals.SPACE_TYPES.MySpace && <MySpaceIcon />}
                                                            {cell.value === Globals.SPACE_TYPES.Standard && <SpaceIcon />}
                                                        </div>
                                                    );
                                                case 'Name':
                                                    return (
                                                        <NavLink to={`/spaces/${row.original.spaceId}`} className='c-primary'>
                                                            {cell.value}
                                                        </NavLink>
                                                    );
                                                case 'Spend':
                                                    return MakeMoney(cell.value);
                                                default:
                                                    return cell.render('Cell');
                                            }
                                        })()}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                )}
                {(!spaceSummaries || spaceSummaries.length < 1) && spacesLoaded && (
                    <TableRow>
                        <TableCell colSpan={5}>There are no spaces to show!</TableCell>
                    </TableRow>
                )}
                {spacesLoading && (
                    <TableRow>
                        <TableCell>
                            <Skeleton variant='circle' height={32} width={32} />
                        </TableCell>
                        <TableCell>
                            <Skeleton variant='text' width={125} />
                        </TableCell>
                        <TableCell>
                            <Skeleton variant='text' width={40} />
                        </TableCell>
                        <TableCell>
                            <Skeleton variant='text' width={25} />
                        </TableCell>
                        <TableCell>
                            <Skeleton variant='text' width={25} />
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );

    return (
        <>
            <header className='pageHeaderContainer light'>
                <CombinedTopbar />
                <h4>Spaces</h4>
                <SpaceCostInfo entityId='' />
            </header>
            <div className='marginNormal'>
                <div className='flexRow flexSeparate padded'>
                    <div>
                        {viewType === Globals.VIEW_TYPES.GRID && (
                            <NavLink className='c-primary bold' to={`/spaces/add`}>
                                <Icon icon='plus' /> Add Space
                            </NavLink>
                        )}
                    </div>
                    <div>
                        <button className={`iconButton big ${viewType === Globals.VIEW_TYPES.CARD ? 'active' : ''}`} onClick={handleView(Globals.VIEW_TYPES.CARD)}>
                            <Icon icon='dashboard' />
                        </button>
                        <button className={`iconButton big ${viewType === Globals.VIEW_TYPES.GRID ? 'active' : ''}`} onClick={handleView(Globals.VIEW_TYPES.GRID)}>
                            <Icon icon='list' />
                        </button>
                    </div>
                </div>
                {viewType === Globals.VIEW_TYPES.CARD && CardView}
                {viewType === Globals.VIEW_TYPES.GRID && TableView}
                {error}
            </div>
        </>
    );
};

export default SpacesLanding;
