import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RoutePrivate from './RoutePrivate/RoutePrivate';
import Login from '../components/Login/Login';
import Logout from '../components/Logout/Logout';
import Dashboard from '../components/Dashboard/Dashboard';
import SpacesLanding from '../components/SpacesLanding/SpacesLanding';
import SpaceDetail from '../components/SpaceDetail/SpaceDetail';
import SpaceAdd from '../components/SpaceAdd/SpaceAdd';
import SpaceEdit from '../components/SpaceEdit/SpaceEdit';
import SpaceCreateVega from '../components/SpaceCreateVega/SpaceCreateVega';
import WorkloadDetail from '../components/WorkloadDetail/WorkloadDetail';
import WorkloadEdit from '../components/WorkloadEdit/WorkloadEdit';
import WorkloadActions from '../components/WorkloadActions/WorkloadActions';
import WorkloadConfig from '../components/WorkloadConfig/WorkloadConfig';
import WorkloadAddPcp from '../components/WorkloadAddPcp/WorkloadAddPcp';
import WorkloadCreateAws from '../components/WorkloadCreateAws/WorkloadCreateAws';
import WorkloadCreateAzure from '../components/WorkloadCreateAzure/WorkloadCreateAzure';
import WorkloadCreateGcp from '../components/WorkloadCreateGcp/WorkloadCreateGcp';
import WorkloadImportAws from '../components/WorkloadImportAws/WorkloadImportAws';
import WorkloadImportAzure from '../components/WorkloadImportAzure/WorkloadImportAzure';
import WorkloadImportGcp from '../components/WorkloadImportGcp/WorkloadImportGcp';
import NotFound from '../components/NotFound/NotFound';
import Vega from '../components/Vega/Vega';
import SideBar from '../components/SideBar/SideBar';
import UiDemoLanding from '../components/UiDemo/UiDemoLanding';
import GA from '../components/GoogleAnalytics/GoogleAnalytics';
import HJ from '../components/HotJar/HotJar';
import OnboardLanding from '../components/OnboardLanding/OnboardLanding';
import TestComponent from '../components/TestComponent';
import WorkloadSatelliteAws from '../components/WorkloadSatelliteAws/WorkloadSatelliteAws';
import { AdminLanding } from '../components/AdminLanding/AdminLanding';
import { SignUpLanding } from '../components/SignUp/SignUpLanding';
import { ForgotPasswordLanding } from '../components/ForgotPassword/ForgotPasswordLanding';
import TermsConditions from '../components/TermsConditions/TermsConditions';
import AdminBudgetsLanding from '../components/AdminBudgetsLanding/AdminBudgetsLanding';
import AdminUsersLanding from '../components/AdminUsersLanding/AdminUsersLanding';
import { ReportsLanding } from '../components/ReportsLanding/ReportsLanding';

export default () => (
    <>
        {GA.init() && <GA.RouteTracker />}
        {HJ.init() && <HJ.HotJar />}
        <Switch>
            <Redirect exact={true} from='/' to='/login' />
            <Route exact={true} path='/login' component={Login} />
            <Route exact={true} path='/login/forgot' component={ForgotPasswordLanding} />
            <Route exact={true} path='/signup' component={SignUpLanding} />
            <Route exact={true} path='/uikitdemo' component={UiDemoLanding} />
            <Route exact={true} path='/ssa' component={TermsConditions} />
            <Route path='/logout' component={Logout} />
            <Route exact={true} path='/testcomponent' component={TestComponent} />
            <Route path='/notfound' component={NotFound} />
            <Route
                render={(props) => (
                    <div className='appContainer'>
                        <Vega />
                        <SideBar />
                        <div className='appContentContainer flexCol'>
                            <Switch>
                                <RoutePrivate exact={true} path='/dashboard' component={Dashboard} />
                                <RoutePrivate exact={true} path='/onboard' component={OnboardLanding} />
                                <RoutePrivate exact={true} path='/spaces' component={SpacesLanding} />
                                <RoutePrivate exact={true} path='/spaces/add' component={SpaceAdd} />
                                <RoutePrivate exact={true} path='/spaces/add/vega' component={SpaceCreateVega} />

                                <RoutePrivate exact={true} path='/spaces/:spaceId' component={SpaceDetail} />
                                <RoutePrivate exact={true} path='/spaces/:spaceId/edit' component={SpaceEdit} />

                                <RoutePrivate exact={true} path='/spaces/:spaceId/addpcp' component={WorkloadAddPcp} />
                                <RoutePrivate exact={true} path='/spaces/:spaceId/createaws' component={WorkloadCreateAws} />
                                <RoutePrivate exact={true} path='/spaces/:spaceId/createazure' component={WorkloadCreateAzure} />
                                <RoutePrivate exact={true} path='/spaces/:spaceId/creategcp' component={WorkloadCreateGcp} />
                                <RoutePrivate exact={true} path='/spaces/:spaceId/importaws' component={WorkloadImportAws} />
                                <RoutePrivate exact={true} path='/spaces/:spaceId/importazure' component={WorkloadImportAzure} />
                                <RoutePrivate exact={true} path='/spaces/:spaceId/importgcp' component={WorkloadImportGcp} />
                                <RoutePrivate exact={true} path='/spaces/:spaceId/satelliteaws' component={WorkloadSatelliteAws} />

                                <RoutePrivate exact={true} path='/spaces/:spaceId/:workloadId' component={WorkloadDetail} />
                                <RoutePrivate exact={true} path='/spaces/:spaceId/:workloadId/edit' component={WorkloadEdit} />
                                <RoutePrivate exact={true} path='/spaces/:spaceId/:workloadId/actions' component={WorkloadActions} />
                                <RoutePrivate exact={true} path='/spaces/:spaceId/:workloadId/configure' component={WorkloadConfig} />

                                <RoutePrivate exact={true} path='/admin' component={AdminLanding} />
                                <RoutePrivate exact={true} path='/admin/users' component={AdminUsersLanding} />
                                <RoutePrivate exact={true} path='/admin/budgets' component={AdminBudgetsLanding} />

                                <RoutePrivate exact={true} path='/reports' component={ReportsLanding} />

                                <Redirect to='/notfound' />
                            </Switch>
                        </div>
                    </div>
                )}
            />
            <Route component={NotFound} />
        </Switch>
    </>
);
