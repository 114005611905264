import React, { useState } from 'react';
import SignUp from './SignUp';
import SignUpAuth from './SignUpAuth';
import SignUpAuthVerify from './SignUpAuthVerify';
import { CreateOnBoardUser } from '../../ServiceStack/ServiceStack.dtos';

export interface SignUpLandingProps {}

export type CreateOnBoardUserForm = Omit<CreateOnBoardUser, 'createResponse' | 'getTypeName'>;

export const SignUpLanding: React.FC<SignUpLandingProps> = (props) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [model, setModel] = useState<CreateOnBoardUserForm>(new CreateOnBoardUser());

    const modelChange = (newVal: CreateOnBoardUserForm): void => setModel({ ...newVal });

    const next = () => setCurrentStep(currentStep + 1);

    const componentSteps = [
        <SignUp model={model} modelChange={modelChange} onNext={next} />,
        <SignUpAuth model={model} modelChange={modelChange} onNext={next} />,
        <SignUpAuthVerify model={model} />,
    ];

    return componentSteps[currentStep];
};
