import React, { useState } from 'react';
import { InfoSidePanelHeader } from '../InfoSidePanel/InfoSidePanelHeader';

export interface IFavoritesLanding {}

const FavoritesLanding: React.FC<IFavoritesLanding> = (props) => {
    const [favorites, setFavorites] = useState([]);

    return (
        <>
            <div className='padded'>
                <InfoSidePanelHeader showBottomMargin={true}>
                    <h5 className='c-slate-light'>Favorites</h5>
                </InfoSidePanelHeader>
                <div className='scrollContainer noScrollbar'>{/* this is where to map favorites */}</div>
                {!(favorites.length > 0) && <div>You don't have any favorites!</div>}
            </div>
        </>
    );
};

export default FavoritesLanding;
