import React, { Component } from 'react';
import TCs from '../../docs/terms-conditions.pdf';

class TermsConditions extends Component {
    componentDidMount() {
        if (!this.props.LinkText) {
            window.location.href = TCs;
        }
    }

    render() {
        return (
            <div className='App'>
                <a href={TCs} target='_blank' rel='noopener noreferrer'>
                    {this.props.LinkText}
                </a>
            </div>
        );
    }
}

export default TermsConditions;
