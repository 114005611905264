import { Component } from 'react';
import { hotjar } from 'react-hotjar';
import * as Globals from '../../globals';

class HotJar extends Component {
    componentDidMount() {}

    componentDidUpdate() {}

    render() {
        return null;
    }
}

const init = () => {
    const isHJEnabled = Globals.ENABLE_HOTJAR;

    if (isHJEnabled) {
        hotjar.initialize(Globals.REACT_APP_HJ_ID, Globals.REACT_APP_HJ_SNIPPET_VERSION);
    }

    return isHJEnabled;
};

export default {
    HotJar,
    init,
};
