import React, { useState, useContext } from 'react';
import { Grid, FormControl, InputLabel, FormHelperText, OutlinedInput, Select, MenuItem } from '@material-ui/core';
import scss from './Onboard.module.scss';
import Stepper from '../Stepper/Stepper';
import AdvanceButton from '../AdvanceButton/AdvanceButton';
import { Redirect } from 'react-router-dom';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { ListBusinessTypes, BusinessTypeModel } from '../../ServiceStack/ServiceStack.dtos';
import { useSnackbar } from 'notistack';

export interface OnboardStep1Props {
    model: any;
    modelChange: (newValues: any) => void;
    next: () => void;
}

const OnboardStep1: React.FC<OnboardStep1Props> = (props) => {
    const appState = React.useContext(AppStateContext);
    const vegaApi = useContext(VegaApiContext);
    const snackbar = useSnackbar();

    const [stepValid, setStepValid] = React.useState(false);
    const [errors, setErrors] = React.useState<any>({});
    const [businessTypes, setBusinessTypes] = useState<BusinessTypeModel[]>([]);

    const validate = (event) => {
        setErrors({
            ...errors,
            [event.target.name]: event.target.value ? '' : 'This field is required',
        });
    };

    const handleChange = (event) => {
        props.modelChange({ [event.target.name]: event.target.value });
        validate(event);
    };

    const nextStep = () => props.next();

    React.useEffect(() => {
        setStepValid(!!props.model.OrganizationName && !!props.model.businessTypeId);
    }, [props.model.OrganizationName, props.model.businessTypeId]);

    React.useEffect(() => {
        vegaApi
            .getApiClient()
            .get(new ListBusinessTypes())
            .then((response) => setBusinessTypes(response.result.businessTypes))
            .catch(() => snackbar.enqueueSnackbar('There was an error loading business types', { variant: 'error' }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className='marginNormal paper flexCol flexGrow'>
                {appState.currentUser.isOnBoarded && <Redirect to='/dashboard' />}
                <div className={`flexGrow ${scss.contentContainer}`}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Stepper numSteps={4} currentStep={1} />
                        </Grid>

                        <Grid item xs={3} />
                        <Grid item xs={6}>
                            <h6 className='center'>
                                The Vega Platform is designed to manage workloads that are based on accounts with Cloud Service Providers such as AWS, Azure and Google.
                            </h6>
                        </Grid>
                        <Grid item xs={3} />

                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <h6 className='center'>Let's take a few moments and set up your new organization and Vega dashboard.</h6>
                        </Grid>
                        <Grid item xs={4} />

                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <FormControl variant='outlined' error={!!errors.OrganizationName}>
                                <InputLabel htmlFor='OrganizationName'>Org Name</InputLabel>
                                <OutlinedInput id='OrganizationName' name='OrganizationName' value={props.model.OrganizationName} onChange={handleChange} labelWidth={80} />
                                {errors.OrganizationName && <FormHelperText>{errors.OrganizationName}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} />

                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <FormControl variant='outlined' error={!!errors.businessTypeId}>
                                <InputLabel htmlFor='businessTypeId'>Business Type</InputLabel>
                                <Select
                                    value={props.model.businessTypeId}
                                    onChange={handleChange}
                                    input={<OutlinedInput name='businessTypeId' id='businessTypeId' labelWidth={110} />}
                                >
                                    {businessTypes.map((sOption) => (
                                        <MenuItem value={sOption.businessTypeId} key={sOption.businessTypeId}>
                                            {sOption.businessTypeName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.businessTypeId && <FormHelperText>{errors.businessTypeId}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} />
                    </Grid>
                </div>
                <footer className={`padded flexRow flexSeparate ${scss.footer}`}>
                    <div />
                    <div>
                        <AdvanceButton onClick={nextStep} disabled={!stepValid} />
                    </div>
                </footer>
            </div>
        </>
    );
};

export default OnboardStep1;
