import React, { useContext, useState } from 'react';
import EmailInput from '../EmailInput/EmailInput';
import PasswordInput from '../PasswordInput/PasswordInput';
import { CircularProgress, Grid } from '@material-ui/core';
import LoginLayout from '../LoginLayout/LoginLayout';
import { RouteComponentProps } from 'react-router-dom';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { useSnackbar } from 'notistack';
import { ListSpacesSummary, ListSpacesSummaryModel } from '../../ServiceStack/ServiceStack.dtos';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';

export interface LoginProps extends RouteComponentProps<any> {}

const Login: React.FC<LoginProps> = (props) => {
    const appState = useContext(AppStateContext);
    const snackbar = useSnackbar();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [loggingIn, setLoggingIn] = useState(false);

    const vegaApi = React.useContext(VegaApiContext);

    const forgotPassword = () => props.history.push('/login/forgot');

    const submitLogin = (event) => {
        event.preventDefault();
        setLoggingIn(true);
        appState.login(userName, password).catch((error) => {
            console.error(error);
            snackbar.enqueueSnackbar(`Error logging in: ${error.responseStatus.message}`, { variant: 'error' });
            setLoggingIn(false);
        });
    };

    React.useEffect(() => {
        if (!appState.isAuthenticated) return;
        if (appState.currentUser.isOnBoarded) {
            if (appState.redirectUrl && appState.redirectUrl !== '/login') {
                props.history.push(appState.redirectUrl);
                appState.clearRedirectUrl();
            } else {
                props.history.push('/dashboard');
            }
        } else {
            appState.setPassword(password);
            props.history.push('/onboard');
        }
        // eslint-disable-next-line
    }, [appState.isAuthenticated]);

    React.useEffect(() => {
        if (appState.isAuthenticated) {
            if(userName.endsWith('@qumulo.com') && userName.startsWith('salesrep')) {
        // Get Spaces for Qumulo
            vegaApi
            .getApiClient()
            .get(new ListSpacesSummary())
            .then((response) => {
                // setSpaceSummaries(response.result);
                var salesRepSpace = response.result.filter(space => space.name.includes(appState.currentUser.email.split("@")[0]));
                if(salesRepSpace != null) {
                    props.history.push('/spaces/' + salesRepSpace[0].spaceId, 'FirstTime');
                }
            })
            .catch((error) => {
                console.error(error);
            });
            } else {
                props.history.push('/dashboard');
            }
            
        }
        // eslint-disable-next-line
    }, []);

    const formValid = userName.length > 0 && password.length > 0;

    return (
        <LoginLayout>
            <form onSubmit={submitLogin}>
                <Grid container spacing={4}>
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <EmailInput id='yourEmail' value={userName} setUN={setUserName} />
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <PasswordInput value={password} setPwd={setPassword} />
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={12}>
                        {!loggingIn && (
                            <button type='submit' className={`medium normal primary center`} disabled={!formValid}>
                                Sign In
                            </button>
                        )}
                        {loggingIn && (
                            <div className='center'>
                                <CircularProgress />
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <button className='primary accent small center' onClick={forgotPassword}>
                            Forgot Password?
                        </button>
                    </Grid>
                </Grid>
            </form>
        </LoginLayout>
    );
};

export default Login;
