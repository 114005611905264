import React, { useContext, useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core';
import { AppStateContext } from '../../../contexts/AppState/AppState';
import { CloudProvider, UpdateWorkload, GetWorkloadModel } from '../../../ServiceStack/ServiceStack.dtos';
import PageFooter from '../../PageFooter/PageFooter';
import { VegaApiContext } from '../../../contexts/VegaApi/VegaApi';
import { useSnackbar } from 'notistack';

export interface ILinkedWorkloadsTabProps {
    workload: GetWorkloadModel;
}

const LinkedWorkloadsTab: React.FC<ILinkedWorkloadsTabProps> = (props) => {
    const appState = useContext(AppStateContext);
    const vegaApi = useContext(VegaApiContext);
    const snackbar = useSnackbar();

    const [selectedWlIds, setSelectedWlIds] = useState<string[]>([]);
    const [saving, setSaving] = useState(false);

    const workloads = appState.workloads.filter((x) => x.cloudProvider === CloudProvider.Aws && x.workloadId !== props.workload.workloadId);

    const handleCheckChange = (event) => {
        if (event.target.checked) {
            selectedWlIds.push(event.target.value);
            setSelectedWlIds([...selectedWlIds]);
        } else {
            selectedWlIds.splice(selectedWlIds.indexOf(event.target.value), 1);
            setSelectedWlIds([...selectedWlIds]);
        }
    };

    const submitLinkedWorkloads = async () => {
        setSaving(true);
        try {
            for (const workloadId of selectedWlIds) {
                const request = new UpdateWorkload({
                    workloadId,
                    masterWorkloadId: props.workload.workloadId,
                    isLinkedWorkload: true,
                });
                await vegaApi.getApiClient().put(request);
            }
            await appState.loadWorkloads();
            setSaving(false);
            snackbar.enqueueSnackbar('Workloads have been linked successfully!', { variant: 'success' });
            setSelectedWlIds([]);
        } catch (error) {
            console.error(error);
            setSaving(false);
            snackbar.enqueueSnackbar('There was an error linking these workloads. If this issue persists, please contact support.', { variant: 'error' });
        }
    };

    return (
        <>
            <div className='flexCol flexGrow'>
                <div className='padded flexGrow'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Space</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {workloads.map((workload) => (
                                <TableRow key={workload.workloadId}>
                                    <TableCell>{workload.name}</TableCell>
                                    <TableCell>{workload.spaceId}</TableCell>
                                    <TableCell>
                                        {workload.isLinkedWorkload && !!workload.masterWorkloadId ? (
                                            <span className='c-success'>Connected</span>
                                        ) : (
                                            <>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={selectedWlIds.includes(workload.workloadId)} onChange={handleCheckChange} value={workload.workloadId} />
                                                    }
                                                    label='Connect'
                                                />
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                {selectedWlIds.length > 0 && (
                    <PageFooter
                        buttons={
                            <>
                                <button className='accent primary medium' onClick={() => setSelectedWlIds([])}>
                                    Cancel
                                </button>
                                {!saving && (
                                    <button className='normal primary medium' onClick={submitLinkedWorkloads}>
                                        Link Workload{selectedWlIds.length > 1 && 's'}
                                    </button>
                                )}
                                {saving && <CircularProgress />}
                            </>
                        }
                    />
                )}
            </div>
        </>
    );
};

export default LinkedWorkloadsTab;
