import React, { useState, useContext, useEffect } from 'react';
import { InfoSidePanelHeader } from '../InfoSidePanel/InfoSidePanelHeader';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { ListSpacesSummary, ListSpacesSummaryModel } from '../../ServiceStack/ServiceStack.dtos';
import { useSnackbar } from 'notistack';
import ExpandableMenu from '../ExpandableMenu/ExpandableMenu';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

export interface IBusinessUnitsListProps extends RouteComponentProps<any> {
    onHideSidebar: () => void;
}

const BusinessUnitsList: React.FC<IBusinessUnitsListProps> = (props) => {
    const appState = useContext(AppStateContext);
    const vegaApi = useContext(VegaApiContext);
    const snackbar = useSnackbar();

    const [spaceSummaries, setSpaceSummaries] = useState<ListSpacesSummaryModel[]>([]);

    const [businessUnits] = useState([appState.orgData.organizationName, 'Sample Business Unit']);

    useEffect(() => {
        vegaApi
            .getApiClient()
            .get(new ListSpacesSummary())
            .then((response) => {
                setSpaceSummaries(response.result);
            })
            .catch((error) => {
                console.error(error);
                snackbar.enqueueSnackbar('Unable to load spaces for this business unit', { variant: 'error' });
            });
    }, []);

    return (
        <>
            <div className='padded'>
                <InfoSidePanelHeader showBottomMargin={true}>
                    <h5 className='c-slate-light'>Business Units</h5>
                </InfoSidePanelHeader>
                <div className='scrollContainer noScrollbar topBottomMarginContainer'>
                    {businessUnits.map((businessUnit) => (
                        <ExpandableMenu
                            key={businessUnit}
                            title={businessUnit}
                            titleOnClick={() => props.history.push(`/spaces/`)}
                            menuItems={
                                <>
                                    {spaceSummaries.map((space) => (
                                        <NavLink key={space.spaceId} to={`/spaces/${space.spaceId}`} className='c-slate-light' activeClassName='active'>
                                            {space.name}
                                        </NavLink>
                                    ))}
                                </>
                            }
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default withRouter(BusinessUnitsList);
