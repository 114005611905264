import React, { useContext, useState, useEffect } from 'react';
import WorkloadHeader from '../../WorkloadHeader/WorkloadHeader';
import { AppStateContext } from '../../../contexts/AppState/AppState';
import DownloadLink from 'react-download-link';
import { Grid } from '@material-ui/core';
import { ReactComponent as QumuloLogo } from '../../../images/icons/logos/qumulo-logo.svg';
import { ReactComponent as TeradiciLogo } from '../../../images/icons/logos/teradici-logo.svg';

export interface VegaCompleteTelusLandingProps {}

const VegaCompleteTelusLanding: React.FC<VegaCompleteTelusLandingProps> = (props) => {
    const appState = useContext(AppStateContext);

    return (
        <>
            <WorkloadHeader title={appState.currentWorkload.name} isMainPage={true} showBorderBottom={true} />
            <Grid container spacing={4}>
                <Grid item xs={12} />
                <Grid item xs={6} className='center'>
                    <TeradiciLogo style={{ width: '50%' }} />
                </Grid>
                <Grid item xs={6} className='center'>
                    <QumuloLogo style={{ width: '55%', height: 'auto' }} />
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <div className='normal primary medium center'>
                                Clicking the link below will open a new web browser tab/window allowing you to download your Teradici PCoIP Client.
                                <br />
                                <br />
                                When the client has completed downloading, click it to launch your client.
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='normal primary medium center'>
                                <a href='https://docs.teradici.com/find/product/software-and-mobile-clients/2020.04/software-client-for-macos' target='_blank'>
                                    Client for MacOS
                                </a>
                                <br />
                                <a href='https://docs.teradici.com/find/product/software-and-mobile-clients/2020.04/software-client-for-windows' target='_blank'>
                                    Client for Windows
                                </a>
                                <br />
                                <a href='https://docs.teradici.com/find/product/software-and-mobile-clients/2020.04/software-client-for-linux' target='_blank'>
                                    Client for Linux
                                </a>
                                <br />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='normal primary medium center'>
                                <b>Client Login Information</b>
                                <hr />
                                <table>
                                    <tr>
                                        <td>Connection Name</td>
                                        <td>TelusPOC</td>
                                    </tr>
                                    <tr>
                                        <td>Host Address</td>
                                        <td>54.201.138.152</td>
                                    </tr>
                                    <tr>
                                        <td>Username</td>
                                        <td>teluspoc1@telus.com</td>
                                    </tr>
                                    <tr>
                                        <td>Password</td>
                                        <td>Cloud...</td>
                                    </tr>
                                </table>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <div className='normal primary medium center'>
                                <p>Clicking the link below will open your Qumulo Management Console.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12} />
                        <Grid item xs={12}>
                            <div className='normal primary medium center'>
                                <a href='https://qumulomanagement-cfeebddc0710451f.elb.us-west-2.amazonaws.com/' target='_blank'>
                                    Launch Qumulo Management Console
                                </a>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default VegaCompleteTelusLanding;
