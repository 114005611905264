import React from 'react';
import scss from './CostHeader.module.scss';
import VarianceText from '../VarianceText/VarianceText';
import { MakeMoney } from '../../utils';

export interface CostHeaderProps {
    totalCost: number;
    budget: number;
    variance: number;
}

const CostHeader = (props: CostHeaderProps) => {
    return (
        <div className={`${scss.stats} body2 c-slate-lightest`}>
            <div className='flexGrow padded'>
                <h6>{MakeMoney(props.totalCost)}</h6>
                <div className='body2'>Total Cost</div>
            </div>
            <div className='flexGrow padded'>
                <h6>{MakeMoney(props.budget)}</h6>
                <div className='body2'>
                    Total Budget&nbsp;&nbsp;&nbsp;&nbsp;
                    <VarianceText variance={props.variance} />
                </div>
            </div>
        </div>
    );
};

export default CostHeader;
