import React, { FC } from 'react';
import scss from './InfoSidePanel.module.scss';

export interface InfoSidePanelProps {}

const InfoSidePanel: FC<InfoSidePanelProps> = (props) => {
    return (
        <div className={scss.infoPanel}>
            <div className={`flexCol c-slate-lightest dark-bg ${scss.content}`}>{props.children}</div>
        </div>
    );
};

export default InfoSidePanel;
