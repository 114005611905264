import React from 'react';
import scss from './NotificationTile.module.scss';

export interface INotificationTileProps {
    title: string;
    description: string;
    timestamp?: string;
    actionIcon: JSX.Element;
    onClick?: () => void;
    isAlert?: boolean;
}

const NotificationTile: React.FC<INotificationTileProps> = (props) => {
    const handleOnClick = () => {
        if (props.onClick) props.onClick();
    };

    return (
        <>
            <div className={scss.message} onClick={handleOnClick}>
                <div className='flexRow flexSeparate'>
                    <h6 className='c-slate-light'>{props.title}</h6>
                    <div>{props.description}</div>
                    <div>{props.actionIcon}</div>
                </div>
                <div className='body2 c-slate-light'>{props.timestamp}</div>
            </div>
        </>
    );
};

export default NotificationTile;
