import React, { useEffect, useContext, useState } from 'react';
import { ListRecommendations, RecommendationDto, DeclineRecommendation, RecommendationStatus } from '../../ServiceStack/ServiceStack.dtos';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import scss from './WorkloadRecommendations.module.scss';
import { RecommendationAccept } from '../Recommendations/RecommendationAccept';
import ContentLoading from '../ContentLoading/ContentLoading';

enum ViewMode {
    None,
    RecommendationAccept,
}

export interface IWorkloadRecommendationsProps {
    workloadId: string;
}

const WorkloadRecommendations: React.FC<IWorkloadRecommendationsProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);

    const [recommendations, setRecommendations] = useState<RecommendationDto[]>([]);
    const [currentRecommendation, setCurrentRecommendation] = useState<RecommendationDto>();
    const [loaded, setLoaded] = useState(false);
    const [viewMode, setViewMode] = useState<ViewMode>();

    const loadRecommendations = () => {
        vegaApi
            .getApiClient()
            .get(new ListRecommendations())
            .then((response) => {
                setRecommendations(
                    response.results
                        .filter(
                            (x) =>
                                (x.status === RecommendationStatus.Scheduled ||
                                    x.status === RecommendationStatus.WaitingForApproval ||
                                    x.status === RecommendationStatus.Pending) &&
                                x.workloadId === props.workloadId
                        )
                        .sort((a, b) => +!!(a.status === RecommendationStatus.Scheduled) - +!!(b.status === RecommendationStatus.Scheduled))
                );
            })
            .catch((error) => console.error(error))
            .finally(() => setLoaded(true));
    };

    const cancelClick = () => {
        setViewMode(ViewMode.None);
        setCurrentRecommendation(undefined);
        loadRecommendations();
    };

    const acceptRecommendation = (rec: RecommendationDto) => {
        setCurrentRecommendation(rec);
        setViewMode(ViewMode.RecommendationAccept);
    };

    const onScheduleComplete = () => {
        cancelClick();
        loadRecommendations();
    };

    const doItMyself = (id: string) => {
        vegaApi
            .getApiClient()
            .delete(new DeclineRecommendation({ id }))
            .then((_) => setRecommendations(recommendations.filter((x) => x.id !== id)));
    };

    useEffect(() => {
        loadRecommendations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {viewMode === ViewMode.RecommendationAccept && (
                <RecommendationAccept recommendation={currentRecommendation} cancelClick={cancelClick} onComplete={onScheduleComplete} />
            )}
            <h5 className='marginNormal-bottom'>Recommendations</h5>
            {loaded && (
                <>
                    {recommendations.length > 0 && (
                        <div className={`flexGrow scrollContainer`}>
                            {recommendations.map((recommendation) => (
                                <div key={recommendation.id} className={scss.rec}>
                                    <p className='body1'>{recommendation.title}</p>
                                    <p className='body2 c-slate'>{recommendation.description}</p>
                                    <div className='flexRow flexSeparate'>
                                        <button className='text danger' onClick={() => doItMyself(recommendation.id)}>
                                            Do it Myself
                                        </button>
                                        <button className='accent primary small' onClick={() => acceptRecommendation(recommendation)}>
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {!(recommendations.length > 0) && <div className='flexGrow c-slate-light flexGrow'>No new recommendations</div>}
                </>
            )}
            {!loaded && <ContentLoading loadingMessage='Loading Recommendations...' />}
        </>
    );
};

export default WorkloadRecommendations;
