import React from 'react';
import scss from './Stepper.module.scss';

const Stepper = (props) => {
    let steps = [];

    for (let i = 1; i <= props.numSteps; i++) {
        let divClassName;
        if (i < props.currentStep) divClassName = scss.previous;
        if (i === props.currentStep) divClassName = scss.active;
        steps.push(
            <div key={i} className={`${scss.step} ${divClassName}`}>
                {/* <div className={scss.number}>{i}</div> */}
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className={scss.wizardStepper}>{steps}</div>
        </React.Fragment>
    );
};

export default Stepper;
