import React, { useState } from 'react';
import { ReactComponent as AwsLogo } from '../../images/icons/logos/aws-logo.svg';
import { ReactComponent as AzureLogo } from '../../images/icons/logos/azure-logo.svg';
import { ReactComponent as GcpLogo } from '../../images/icons/logos/google-logo.svg';
import scss from './WorkloadAddPcp.module.scss';
import BackButton from '../BackButton/BackButton';
import WorkloadAddPcpTypeSelectModal from './WorkloadAddPcpTypeSelectModal';
import { RouteComponentProps } from 'react-router-dom';
import WorkloadSatelliteAwsSelectModal from './WorkloadSatelliteAwsSelectModal';
import { CloudProvider } from '../../ServiceStack/ServiceStack.dtos';
import { urlUpOneLevel } from '../../utils';
import CombinedTopbar from '../CombinedTopbar/CombinedTopbar';

export interface WorkloadAddPcpProps extends RouteComponentProps<any> {}

const WorkloadAddPcp: React.FC<WorkloadAddPcpProps> = (props) => {
    const [showSelectionModal, setShowSelectionModal] = useState(false);
    const [showSatelliteModal, setShowSatelliteModal] = useState(false);
    const [selectedCloudProvider, setSelectedCloudProvider] = useState(CloudProvider.None);

    const cardClicked = (value: CloudProvider) => {
        setSelectedCloudProvider(value);
        setShowSelectionModal(true);
    };

    const doCancel = () => urlUpOneLevel(props);

    const onClickSatelliteWorkload = () => {
        setShowSelectionModal(false);
        setShowSatelliteModal(true);
    };

    const onClickImportWorkload = () => {
        switch (selectedCloudProvider) {
            case CloudProvider.Aws:
                props.history.push('importaws');
                break;
            case 'Azure':
                props.history.push('importazure');
                break;
            // case 'GCP':
            //     this.props.history.push('importgcp');
            //     break;
            default:
                console.error('Do not support importing:' + selectedCloudProvider);
        }
    };

    const onClickVegaWorkload = () => {
        switch (selectedCloudProvider) {
            // case 'AWS':
            //     this.props.history.push('createaws');
            //     break;
            // case 'Azure':
            //     this.props.history.push('createazure');
            //     break;
            // case 'GCP':
            //     this.props.history.push('creategcp');
            //     break;
            default:
                console.error('[WorkloadAddPcp] Do not support creating:' + selectedCloudProvider);
        }
    };

    return (
        <>
            {showSelectionModal && (
                <WorkloadAddPcpTypeSelectModal
                    onClickImportWorkload={onClickImportWorkload}
                    onClickSatelliteWorkload={onClickSatelliteWorkload}
                    onClickVegaWorkload={onClickVegaWorkload}
                    onClose={() => setShowSelectionModal(false)}
                />
            )}
            {showSatelliteModal && <WorkloadSatelliteAwsSelectModal onClose={() => setShowSatelliteModal(false)} />}
            <header className='pageHeaderContainer'>
                <CombinedTopbar />
                <div className='flexRow alignItemsCenter'>
                    <BackButton onClick={doCancel} />
                    <h4>Create Workload</h4>
                </div>
            </header>
            <div className='pageCenterContainer'>
                <div className={`center ${scss.addPCPContent}`}>
                    <div className='centeredCardContainer'>
                        <div className='cardWrapper'>
                            <button className='simpleCard selectable padded centerContent stdSize' onClick={() => cardClicked(CloudProvider.Aws)}>
                                <div className='svgIconNormal'>
                                    <AwsLogo />
                                </div>
                                <div className='body1 c-slate'>Amazon</div>
                            </button>
                        </div>

                        <div className='cardWrapper'>
                            <button className='simpleCard selectable padded centerContent stdSize' disabled={false} onClick={() => cardClicked(CloudProvider.Azure)}>
                                <div className='svgIconNormal'>
                                    <AzureLogo />
                                </div>
                                <div className='body1 c-slate'>Microsoft</div>
                            </button>
                        </div>

                        <div className='cardWrapper'>
                            <button className='simpleCard selectable padded centerContent stdSize' disabled={true}>
                                <div className='svgIconNormal'>
                                    <GcpLogo />
                                </div>
                                <div className='body1 c-slate'>Google</div>
                            </button>
                        </div>
                    </div>

                    <p className='center body1 c-slate-light'>
                        Workloads are logical groupings of cloud resources combined to run applications. Vega will allow you to import your existing workloads, or build and manage
                        one for you.
                    </p>
                </div>
            </div>
        </>
    );
};

export default WorkloadAddPcp;
