import React from 'react';
import axios from 'axios';
import * as Globals from '../../globals';

const ActionsContext = React.createContext();

const stateObj = {
    actions: [],
    historicalActions: Globals.EMPTY_ARRAY,
};

class ActionsProvider extends React.Component {
    constructor() {
        super();
        this.state = stateObj;
    }

    actionsInit = () => this.setState(stateObj);

    get axiosConfig() {
        return {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('jwttoken') },
        };
    }

    retrieveActions = async () => {
        // fetch new actions from api and add to collection
        /*
        try {
            // set state for processing indicator
            Utils.DoProcessingInit(this);
            console.log('OrgId:' + this.state.OrganizationId);

            var payload = {
                name: 'Default Vega Space',
                Description: 'This is the default space that Vega creates for your workloads.',
                OrganizationId: this.state.OrganizationId,
                SpaceTypeId: 1,
                SpaceContainerType: 'Standard',
                BudgetAmount: 0.00,
                budgetPeriodType: 'Yearly',
                IsDefaultSpace: true
            };
           // var response = await axios.post(Globals.SPACES_ENDPOINT, payload, this.axiosConfig);
            // Check response and update state
            if (response.errorCode !== undefined && response.errorCode.length > 0) {
                Utils.DoProcessingError(this, response);
            }
            else {
                this.setState({
                    DefaultSpaceId: response.data.result.Id,
                    IsCompleted: true,
                    IsProcessing: false
                });
            }
        }
        catch (e) {
            Utils.DoErrorStuff(e, this);
        }
        */
    };

    loadActions = (entityId) => axios.get(`${Globals.BASE_URL}actions/schedules/next?EntityId=${entityId}`, this.axiosConfig);

    loadHistoricalActions = (entityId) => {
        let config = this.axiosConfig;
        const d = new Date();
        const startOfYear = d.getUTCFullYear() + '-1-1';
        const today = d.getUTCFullYear() + '-' + (d.getUTCMonth() + 1) + '-' + d.getUTCDate();
        config.params = {
            entityId,
            dateFrom: startOfYear,
            dateTo: today,
        };
        return axios.get(`${Globals.BASE_URL}actions/schedules`, config);
    };

    addAction = (aObj) => {
        if (aObj !== undefined) {
            let aObjs = this.state.actions;
            aObjs.push(aObj);
            this.setState({ actions: aObjs });
        }
        // make a call with axios to add actions from our Vega API by VegaActionId
    };

    removeAction = (aId) => {
        let aObjs = this.state.actions;
        let aIdx = -1;
        aIdx = aObjs.findIndex((a) => a.Id === aId);
        if (aIdx !== undefined) {
            aObjs.splice(aIdx, 1);
            this.setState({ actions: aObjs });
        }
        // make a call with axios to remove actions from our Vega API by VegaActionId
    };

    render() {
        return (
            <ActionsContext.Provider
                value={{
                    actions: this.state.actions,
                    historicalActions: this.state.historicalActions,
                    addAction: this.addAction,
                    removeAction: this.removeAction,
                    loadActions: this.loadActions,
                    loadHistoricalActions: this.loadHistoricalActions,
                    actionsInit: this.actionsInit,
                    retrieveActions: this.retrieveActions,
                }}
            >
                {this.props.children}
            </ActionsContext.Provider>
        );
    }
}

const ActionsConsumer = ActionsContext.Consumer;

export { ActionsProvider, ActionsConsumer, ActionsContext };
