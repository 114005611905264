import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import scss from './AdminNavigation.module.scss';
import Icon from '../Icon/Icon';

export interface IAdminNavigationProps extends RouteComponentProps<any> {}

const AdminNavigation: React.FC<IAdminNavigationProps> = (props) => {
    return (
        <>
            <div className={`padded ${scss.container}`}>
                <section>
                    <NavLink to='/admin' className={scss.navLink}>
                        <Icon icon='home' />
                        &nbsp; Administration
                    </NavLink>
                </section>

                <section>
                    <h5>Organization</h5>
                    <NavLink to='/admin/users' className={scss.navLink} activeClassName='active'>
                        <Icon icon='account-multiple' />
                        &nbsp; Users
                    </NavLink>
                    <NavLink to='/admin/roles' className={scss.navLink} activeClassName='active'>
                        <Icon icon='account-key' />
                        &nbsp; Roles
                    </NavLink>
                    <NavLink to='/admin/infrastructure' className={scss.navLink} activeClassName='active'>
                        <Icon icon='domain' />
                        &nbsp; Infrastructure
                    </NavLink>
                </section>

                <section>
                    <h5>Cost</h5>
                    <NavLink to='/admin/budgets' className={scss.navLink} activeClassName='active'>
                        Budget
                    </NavLink>
                    <NavLink to='/admin/orders' className={scss.navLink} activeClassName='active'>
                        <Icon icon='cloud-print' />
                        &nbsp; Orders
                    </NavLink>
                </section>

                <section>
                    <h5>Messaging</h5>
                    <NavLink to='/admin/reporting' className={scss.navLink} activeClassName='active'>
                        <Icon icon='file-chart' />
                        &nbsp; Reporting
                    </NavLink>
                    <NavLink to='/admin/recommendations' className={scss.navLink} activeClassName='active'>
                        <Icon icon='cloud-search' />
                        &nbsp; Recommendations
                    </NavLink>
                    <NavLink to='/admin/notifications' className={scss.navLink} activeClassName='active'>
                        Notifications
                    </NavLink>
                </section>
            </div>
        </>
    );
};

export default withRouter(AdminNavigation);
