import React from 'react';

export interface PreviousButtonProps {
    onClick: () => void;
    label?: string;
}

const PreviousButton: React.FC<PreviousButtonProps> = (props) => {
    let label = props.label || 'Previous';

    return (
        <button className='accent medium primary' onClick={props.onClick}>
            <span className='icon icon-back-arrow' />
            &nbsp;&nbsp;
            {label}
        </button>
    );
};

export default PreviousButton;
