/* Options:
Date: 2020-07-17 08:07:51
Version: 5.60
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://192.168.86.33:5000

//GlobalNamespace: 
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export class SmartEnum_2<TEnum, TValue>
{
    public name: string;
    public value: TValue;

    public constructor(init?: Partial<SmartEnum_2<TEnum, TValue>>) { (Object as any).assign(this, init); }
}

export class SmartEnum_1<TEnum> extends SmartEnum_2<TEnum, number>
{

    public constructor(init?: Partial<SmartEnum_1<TEnum>>) { super(init); (Object as any).assign(this, init); }
}
export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export enum WorkloadCreationType
{
    VegaComplete = 'VegaComplete',
    Import = 'Import',
}

export enum WorkloadContentType
{
    CloudProvider = 'CloudProvider',
    Satellite = 'Satellite',
}

export enum CloudProvider
{
    None = 'None',
    Aws = 'Aws',
    Azure = 'Azure',
    Gcp = 'Gcp',
}

export class ValueObject
{

    public constructor(init?: Partial<ValueObject>) { (Object as any).assign(this, init); }
}

export class WorkloadConfigurationAws extends ValueObject
{
    public accountId: string;
    public awsEternalId: string;
    public roleArn: string;
    public s3BucketName: string;
    public s3BucketRegion: string;
    public curFileReportName: string;
    public curFileReportPrefix: string;

    public constructor(init?: Partial<WorkloadConfigurationAws>) { super(init); (Object as any).assign(this, init); }
}

export enum BudgetPeriodType
{
    None = 'None',
    Monthly = 'Monthly',
    Yearly = 'Yearly',
}

export class Budget extends ValueObject
{
    public budgetPeriodType: BudgetPeriodType;
    public amount: number;

    public constructor(init?: Partial<Budget>) { super(init); (Object as any).assign(this, init); }
}

export class WorkloadEntityState
{
    public organizationId: string;
    public workloadId: string;
    public parentWorkloadId: string;
    public satelliteId: number;
    public spaceId: string;
    public masterWorkloadId: string;
    public isMasterWorkload: boolean;
    public isLinkedWorkload: boolean;
    public name: string;
    public description: string;
    public workloadCreationType: WorkloadCreationType;
    public workloadContentType: WorkloadContentType;
    public workloadType: string;
    public complianceTypes: string[];
    public cloudProvider: CloudProvider;
    public workloadConfigurationAws: WorkloadConfigurationAws;
    public satelliteWorkloads: WorkloadEntityState[];
    public budget: Budget;

    public constructor(init?: Partial<WorkloadEntityState>) { (Object as any).assign(this, init); }
}

export class CreateWorkloadAwsModel extends WorkloadEntityState
{

    public constructor(init?: Partial<CreateWorkloadAwsModel>) { super(init); (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1, EmitDefaultValue=false)
    public errorCode: string;

    // @DataMember(Order=2, EmitDefaultValue=false)
    public fieldName: string;

    // @DataMember(Order=3, EmitDefaultValue=false)
    public message: string;

    // @DataMember(Order=4, EmitDefaultValue=false)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class VegaResponse<TModel>
{
    public result: TModel;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<VegaResponse<TModel>>) { (Object as any).assign(this, init); }
}

export class GetWorkloadTemplateAwsModel
{
    public securityPolicyJson: Object;
    public externalId: string;
    public vegaAccountId: string;

    public constructor(init?: Partial<GetWorkloadTemplateAwsModel>) { (Object as any).assign(this, init); }
}

export class CreateMessage implements IMessageRequest
{
    public organizationId: string;
    public workloadId: string;
    public spaceId: string;
    public userId: string;
    public name: string;
    public description: string;
    public linkTitle: string;
    public link: string;

    public constructor(init?: Partial<CreateMessage>) { (Object as any).assign(this, init); }
}

export class CreatePriorityMessage extends CreateMessage implements IMessageRequest, ICreatePriorityMessage
{
    public priorityLevel: number;

    public constructor(init?: Partial<CreatePriorityMessage>) { super(init); (Object as any).assign(this, init); }
}

export interface IMessageRequest
{
    organizationId: string;
    workloadId: string;
    spaceId: string;
    userId: string;
    name: string;
    description: string;
    linkTitle: string;
    link: string;
}

export interface ICreatePriorityMessage
{
    priorityLevel: number;
    organizationId: string;
    workloadId: string;
    spaceId: string;
    userId: string;
    name: string;
    description: string;
    link: string;
}

export class VegaMessage implements IVegaMessage
{
    public id: string;
    public source: string;
    public organizationId: string;
    public workloadId: string;
    public spaceId: string;
    public userId: string;
    public name: string;
    public description: string;
    public linkTitle: string;
    public link: string;
    public dateCreatedUtc: string;
    public closedByUserId: string;
    public dateClosedUtc: string;
    public isClosed: boolean;

    public constructor(init?: Partial<VegaMessage>) { (Object as any).assign(this, init); }
}

export class VegaPriorityMessage extends VegaMessage implements IVegaMessage, IVegaPriorityMessage
{
    public priorityLevel: number;

    public constructor(init?: Partial<VegaPriorityMessage>) { super(init); (Object as any).assign(this, init); }
}

export class CreateNotificationModel extends VegaPriorityMessage implements IVegaMessage, IVegaPriorityMessage
{

    public constructor(init?: Partial<CreateNotificationModel>) { super(init); (Object as any).assign(this, init); }
}

export class VegaListResponse<TModel>
{
    public result: TModel[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<VegaListResponse<TModel>>) { (Object as any).assign(this, init); }
}

export enum StatusFilter
{
    Open = 'Open',
    Closed = 'Closed',
}

export enum EntityFilterType
{
    None = 'None',
    Space = 'Space',
    Workload = 'Workload',
}

export class EntityFilterRequest
{
    /**
    * Cloud Provider
    */
    // @ApiMember(DataType="string", Description="Cloud Provider", Name="CloudProvider")
    public cloudProvider: CloudProvider;

    /**
    * Specifies the type of entity filter will be applied to
    */
    // @ApiMember(DataType="string", Description="Specifies the type of entity filter will be applied to", IsRequired=true, Name="EntityFilterType")
    public entityFilterType: EntityFilterType;

    /**
    * Specifies the ID of entity filter will be applied for
    */
    // @ApiMember(DataType="string", Description="Specifies the ID of entity filter will be applied for", Name="EntityId")
    public entityId: string;

    public constructor(init?: Partial<EntityFilterRequest>) { (Object as any).assign(this, init); }
}

export class EntityAndStatusFilterRequest extends EntityFilterRequest
{
    public statusFilter: StatusFilter;

    public constructor(init?: Partial<EntityAndStatusFilterRequest>) { super(init); (Object as any).assign(this, init); }
}

export class Notification extends VegaMessage implements IVegaMessage
{

    public constructor(init?: Partial<Notification>) { super(init); (Object as any).assign(this, init); }
}

export class ListNotificationsModel extends Notification implements IVegaMessage
{

    public constructor(init?: Partial<ListNotificationsModel>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteNotificationModel
{
    public isSuccess: boolean;

    public constructor(init?: Partial<DeleteNotificationModel>) { (Object as any).assign(this, init); }
}

export class ActionExecutionMessage
{
    public id: string;
    public source: string;
    public organizationId: string;
    public workloadId: string;
    public spaceId: string;
    public userId: string;
    public action: string;
    public actionType: string;
    public name: string;
    public dateStartUtc: string;
    public dateEndUtc: string;
    public percentageComplete: number;
    public closedByUserId: string;
    public isClosed: boolean;

    public constructor(init?: Partial<ActionExecutionMessage>) { (Object as any).assign(this, init); }
}

export class CreateExecutionMessageModel extends ActionExecutionMessage
{

    public constructor(init?: Partial<CreateExecutionMessageModel>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateExecutionMessageModel extends ActionExecutionMessage
{

    public constructor(init?: Partial<UpdateExecutionMessageModel>) { super(init); (Object as any).assign(this, init); }
}

export class ListExecutionMessagesModel extends ActionExecutionMessage
{

    public constructor(init?: Partial<ListExecutionMessagesModel>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteExecutionMessageModel
{
    public isSuccess: boolean;

    public constructor(init?: Partial<DeleteExecutionMessageModel>) { (Object as any).assign(this, init); }
}

export enum ActionType
{
    Discover = 'Discover',
    Park = 'Park',
    UnPark = 'UnPark',
    Backup = 'Backup',
    Restore = 'Restore',
    RecommendationImplementation = 'RecommendationImplementation',
    TimedInstance = 'TimedInstance',
}

export class BaseActionRequest
{
    // @ApiMember(DataType="string", IsRequired=true, Name="EntityId", ParameterType="path")
    public entityId: string;

    // @ApiMember(DataType="string", IsRequired=true, Name="ActionType", ParameterType="query")
    public actionType: ActionType;

    public constructor(init?: Partial<BaseActionRequest>) { (Object as any).assign(this, init); }
}

export class ResourceTag
{
    public name: string;
    public value: string;

    public constructor(init?: Partial<ResourceTag>) { (Object as any).assign(this, init); }
}

export enum ResourceActionType
{
    Unknown = 'Unknown',
    Park = 'Park',
    Backup = 'Backup',
    ParkImmediate = 'ParkImmediate',
    UnParkImmediate = 'UnParkImmediate',
    BackupImmediate = 'BackupImmediate',
    RestoreImmediate = 'RestoreImmediate',
}

export enum ResourceEcoType
{
    Unknown = 'Unknown',
    Sat = 'Sat',
}

export interface IResource
{
    assetId: string;
    tags: ResourceTag[];
    actions: ResourceActionType[];
    ecos: { [index in ResourceEcoType]: string; };
}

export class DatabaseResource implements IHasResource
{
    public region: string;
    public cloudProvider: CloudProvider;
    public databaseId: string;
    public databaseName: string;
    public databaseState: string;
    public databaseEngine: string;
    public resourceName: string;
    public databaseSize: string;
    public resource: IResource;

    public constructor(init?: Partial<DatabaseResource>) { (Object as any).assign(this, init); }
}

export class DatabaseModel extends DatabaseResource implements IHasResource
{

    public constructor(init?: Partial<DatabaseModel>) { super(init); (Object as any).assign(this, init); }
}

export class ServerResource implements IHasResource
{
    public region: string;
    public cloudProvider: CloudProvider;
    public serverId: string;
    public serverName: string;
    public serverState: string;
    public serverType: string;
    public resource: IResource;

    public constructor(init?: Partial<ServerResource>) { (Object as any).assign(this, init); }
}

export class ServerModel extends ServerResource implements IHasResource
{

    public constructor(init?: Partial<ServerModel>) { super(init); (Object as any).assign(this, init); }
}

export class ExecuteActionModel
{
    public databases: DatabaseModel[];
    public servers: ServerModel[];
    public nextToken: string;

    public constructor(init?: Partial<ExecuteActionModel>) { (Object as any).assign(this, init); }
}

export class DateFilterRequest
{

    public constructor(init?: Partial<DateFilterRequest>) { (Object as any).assign(this, init); }
}

export class WorkloadType extends SmartEnum_1<WorkloadType>
{

    public constructor(init?: Partial<WorkloadType>) { super(init); (Object as any).assign(this, init); }
}

export class GetWorkloadModel
{
    public workloadId: string;
    public parentWorkloadId: string;
    public satelliteId: number;
    public spaceId: string;
    public organizationId: string;
    public masterWorkloadId: string;
    public isMasterWorkload: boolean;
    public isLinkedWorkload: boolean;
    public name: string;
    public description: string;
    public workloadCreationType: WorkloadCreationType;
    public workloadType: WorkloadType;
    public workloadContentType: WorkloadContentType;
    public complianceTypes: string[];
    public cloudProvider: CloudProvider;
    public workloadConfigurationAws: WorkloadConfigurationAws;
    public budget: Budget;

    public constructor(init?: Partial<GetWorkloadModel>) { (Object as any).assign(this, init); }
}

export class CreateWorkloadModel extends WorkloadEntityState
{

    public constructor(init?: Partial<CreateWorkloadModel>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateWorkloadModel extends WorkloadEntityState
{

    public constructor(init?: Partial<UpdateWorkloadModel>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteWorkloadModel
{
    public isSuccess: boolean;

    public constructor(init?: Partial<DeleteWorkloadModel>) { (Object as any).assign(this, init); }
}

export class ListWorkloadTypesModel
{
    public id: number;
    public name: string;

    public constructor(init?: Partial<ListWorkloadTypesModel>) { (Object as any).assign(this, init); }
}

export enum VegaHealth
{
    Healthy = 'Healthy',
    NeedsAttention = 'NeedsAttention',
    Critical = 'Critical',
}

export class WorkloadSummaryModel
{
    public spaceId: string;
    public workloadId: string;
    public parentWorkloadId: string;
    public satelliteId: number;
    public masterWorkloadId: string;
    public isMasterWorkload: boolean;
    public isLinkedWorkload: boolean;
    public name: string;
    public cloudProvider: CloudProvider;
    public workloadCreationType: WorkloadCreationType;
    public workloadContentType: WorkloadContentType;
    public accountId: string;
    public totalCost: number;
    public notificationCount: number;
    public vegaHealth: VegaHealth;

    public constructor(init?: Partial<WorkloadSummaryModel>) { (Object as any).assign(this, init); }
}

export class ListWorkloadSummariesModel extends WorkloadSummaryModel
{

    public constructor(init?: Partial<ListWorkloadSummariesModel>) { super(init); (Object as any).assign(this, init); }
}

export class ListMasterWorkloadSummariesModel extends WorkloadSummaryModel
{

    public constructor(init?: Partial<ListMasterWorkloadSummariesModel>) { super(init); (Object as any).assign(this, init); }
}

export class CreateSatelliteWorkloadModel extends WorkloadEntityState
{

    public constructor(init?: Partial<CreateSatelliteWorkloadModel>) { super(init); (Object as any).assign(this, init); }
}

export class CloudResource
{
    public resourceType: string;
    public resourceId: string;
    public resourceEnvironment: string;
    public hostName: string;
    public size: string;
    public resourceKind: string;
    public region: string;
    public operatingState: string;

    public constructor(init?: Partial<CloudResource>) { (Object as any).assign(this, init); }
}

export class WorkspaceResource
{
    public id: string;
    public name: string;
    public type: string;
    public size: string;
    public status: string;
    public userName: string;
    public ipAddress: string;

    public constructor(init?: Partial<WorkspaceResource>) { (Object as any).assign(this, init); }
}

export class ListWorkloadResourcesModel
{
    public workloadId: string;
    public lastUpdate: string;
    public resources: CloudResource[];
    public servers: ServerModel[];
    public databases: DatabaseModel[];
    public workstations: WorkspaceResource[];

    public constructor(init?: Partial<ListWorkloadResourcesModel>) { (Object as any).assign(this, init); }
}

export enum ResourceAssetType
{
    ServerEc2 = 'ServerEc2',
    DatabaseRds = 'DatabaseRds',
}

export class BaseResource
{
    /**
    * The workload id
    */
    // @ApiMember(DataType="string", Description="The workload id", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    /**
    * Region
    */
    // @ApiMember(DataType="string", Description="Region", IsRequired=true, Name="Region", ParameterType="query")
    public region: string;

    /**
    * Resource Asset Type
    */
    // @ApiMember(DataType="string", Description="Resource Asset Type", IsRequired=true, Name="ResourceAssetType", ParameterType="query")
    public resourceAssetType: ResourceAssetType;

    public constructor(init?: Partial<BaseResource>) { (Object as any).assign(this, init); }
}

export class ResourceModel
{
    public assetId: string;
    public isSuccess: boolean;
    public errorMessage: string;

    public constructor(init?: Partial<ResourceModel>) { (Object as any).assign(this, init); }
}

export class CreateResourceActionModel extends ResourceModel
{

    public constructor(init?: Partial<CreateResourceActionModel>) { super(init); (Object as any).assign(this, init); }
}

export class CreateResourceEcoModel extends ResourceModel
{

    public constructor(init?: Partial<CreateResourceEcoModel>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteResourceActionModel extends ResourceModel
{

    public constructor(init?: Partial<DeleteResourceActionModel>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteResourceEcoModel extends ResourceModel
{

    public constructor(init?: Partial<DeleteResourceEcoModel>) { super(init); (Object as any).assign(this, init); }
}

export class GetResourceActionModel
{
    public actionTypes: ResourceActionType[];

    public constructor(init?: Partial<GetResourceActionModel>) { (Object as any).assign(this, init); }
}

export class GetResourceEcoModel
{
    public ecoTypes: { [index in ResourceEcoType]: string; };

    public constructor(init?: Partial<GetResourceEcoModel>) { (Object as any).assign(this, init); }
}

export class CreateUserModel
{
    public userId: string;
    public userLoginId: string;
    public lastName: string;
    public firstName: string;
    public email: string;
    public mobilePhone: string;

    public constructor(init?: Partial<CreateUserModel>) { (Object as any).assign(this, init); }
}

export class UserEntityState
{
    public userId: string;
    public userLoginId: string;
    public email: string;
    public lastName: string;
    public firstName: string;
    public mobilePhone: string;
    public organizationId: string;
    public isActive: boolean;
    public isFirstTimeLogin: boolean;
    public isOnBoarded: boolean;

    public constructor(init?: Partial<UserEntityState>) { (Object as any).assign(this, init); }
}

export class UserModel extends UserEntityState
{

    public constructor(init?: Partial<UserModel>) { super(init); (Object as any).assign(this, init); }
}

export class GetUserModel extends UserModel
{

    public constructor(init?: Partial<GetUserModel>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateUserModel extends UserModel
{

    public constructor(init?: Partial<UpdateUserModel>) { super(init); (Object as any).assign(this, init); }
}

export class CreateUserFeedbackModel
{
    public email: string;
    public feedback: string;
    public topicArea: string;

    public constructor(init?: Partial<CreateUserFeedbackModel>) { (Object as any).assign(this, init); }
}

export enum PassCodeDeliveryMedium
{
    Email = 'Email',
    MobilePhone = 'MobilePhone',
}

export class PassCodeDeliveryDetail
{
    public passCodeDeliveryMedium: PassCodeDeliveryMedium;
    public destination: string;

    public constructor(init?: Partial<PassCodeDeliveryDetail>) { (Object as any).assign(this, init); }
}

export class CreateLoginModel
{
    public passCodeDeliveryDetail: PassCodeDeliveryDetail;
    public loginUserId: string;

    public constructor(init?: Partial<CreateLoginModel>) { (Object as any).assign(this, init); }
}

export class ConfirmCreateLoginModel
{
    public confirmationSuccessful: boolean;

    public constructor(init?: Partial<ConfirmCreateLoginModel>) { (Object as any).assign(this, init); }
}

export enum PasswordAction
{
    Forgot = 'Forgot',
    ConfirmForgot = 'ConfirmForgot',
    Change = 'Change',
}

export class CreateLoginPasswordActionModel
{
    public passwordAction: PasswordAction;
    public passCodeDeliveryDetail: PassCodeDeliveryDetail;
    public message: string;

    public constructor(init?: Partial<CreateLoginPasswordActionModel>) { (Object as any).assign(this, init); }
}

export class ResendCreateLoginConfirmationModel
{
    public passCodeDeliveryDetail: PassCodeDeliveryDetail;

    public constructor(init?: Partial<ResendCreateLoginConfirmationModel>) { (Object as any).assign(this, init); }
}

export class AwsAuthenticationResult
{
    public accessToken: string;
    public expiresIn: number;
    public idToken: string;
    public refreshToken: string;
    public tokenType: string;
    public organizationId: string;
    public userId: string;

    public constructor(init?: Partial<AwsAuthenticationResult>) { (Object as any).assign(this, init); }
}

export class SignInModel
{
    public awsAuthenticationResult: AwsAuthenticationResult;

    public constructor(init?: Partial<SignInModel>) { (Object as any).assign(this, init); }
}

export class CreateOnBoardUserModel
{
    public userId: string;

    public constructor(init?: Partial<CreateOnBoardUserModel>) { (Object as any).assign(this, init); }
}

export class CreateOnBoardOrganizationModel
{
    public userId: string;
    public organizationId: string;
    public spaceId: string;

    public constructor(init?: Partial<CreateOnBoardOrganizationModel>) { (Object as any).assign(this, init); }
}

export class StartActionExecutionModel
{
    public executionIds: string[];

    public constructor(init?: Partial<StartActionExecutionModel>) { (Object as any).assign(this, init); }
}

export class StartActionExecutionResponse extends VegaResponse<StartActionExecutionModel>
{

    public constructor(init?: Partial<StartActionExecutionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class TimedInstanceParameters
{
    public publicKey: string;
    public userName: string;
    public password: string;
    public region: string;
    public roleArn: string;
    public externalId: string;
    public satelliteId: string;
    public securityGroupId: string;
    public amiImageId: string;
    public instanceName: string;
    public instanceType: string;
    public snapshotDescription: string;
    public isWindows: boolean;
    public waitInHours: number;

    public constructor(init?: Partial<TimedInstanceParameters>) { (Object as any).assign(this, init); }
}

/**
* StartActionExecution
*/
// @Route("/actions/executions/{entityId}", "POST")
// @Api(Description="StartActionExecution")
export class StartActionExecution extends BaseActionRequest implements IReturn<StartActionExecutionResponse>
{
    /**
    * Wait until to execute
    */
    // @ApiMember(DataType="string", Description="Wait until to execute", Format="date-time", Name="WaitUntil", ParameterType="query")
    public waitUntil: string;

    /**
    * Scheduled action id
    */
    // @ApiMember(DataType="string", Description="Scheduled action id", Name="ScheduledActionId", ParameterType="query")
    public scheduledActionId: string;

    /**
    * Parameters with Timed Instances
    */
    // @ApiMember(DataType="string", Description="Parameters with Timed Instances", Name="TimedInstanceParameters", ParameterType="body")
    public timedInstanceParameters: TimedInstanceParameters;

    public constructor(init?: Partial<StartActionExecution>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new StartActionExecutionResponse(); }
    public getTypeName() { return 'StartActionExecution'; }
}

export class StartActionExecutionConfiguration
{
    public token: string;
    public stepFunctionArn: string;
    public stepFunctionExecuteName: string;
    public workloadId: string;
    public startActionExecution: StartActionExecution;

    public constructor(init?: Partial<StartActionExecutionConfiguration>) { (Object as any).assign(this, init); }
}

export class ListTasksModel
{
    public taskId: string;
    public workloadId: string;
    public spaceId: string;
    public userId: string;
    public name: string;
    public description: string;
    public link: string;
    public dateCreatedUtc: string;
    public priorityLevel: number;

    public constructor(init?: Partial<ListTasksModel>) { (Object as any).assign(this, init); }
}

export class EntityAndDateFilterRequest extends EntityFilterRequest
{
    /**
    * Period filter:from date
    */
    // @ApiMember(DataType="string", Description="Period filter:from date", Format="date", Name="fromDate", ParameterType="query")
    public fromDate: string;

    /**
    * Period filter:to date
    */
    // @ApiMember(DataType="string", Description="Period filter:to date", Format="date", Name="toDate", ParameterType="query")
    public toDate: string;

    public constructor(init?: Partial<EntityAndDateFilterRequest>) { super(init); (Object as any).assign(this, init); }
}

export class GetStatisticBaseModel
{
    public entityDateFilterRequest: EntityAndDateFilterRequest;
    public amount: number;

    public constructor(init?: Partial<GetStatisticBaseModel>) { (Object as any).assign(this, init); }
}

/**
* ListSpaceSummary
*/
// @Route("/spaces/summaries", "GET")
// @Api(Description="ListSpaceSummary")
export class ListSpacesSummary extends DateFilterRequest implements IReturn<ListSpacesSummaryResponse>
{

    public constructor(init?: Partial<ListSpacesSummary>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ListSpacesSummaryResponse(); }
    public getTypeName() { return 'ListSpacesSummary'; }
}

export enum SpaceContainerType
{
    Standard = 'Standard',
    MySpace = 'MySpace',
}

/**
* ListSpaceSummary
*/
// @Route("/spaces/summaries", "GET")
// @Api(Description="ListSpaceSummary")
export class ListSpacesSummaryModel extends ListSpacesSummary
{
    public spaceId: string;
    public name: string;
    public spaceContainerType: SpaceContainerType;
    public totalCost: number;
    public workloadCount: number;
    public notificationCount: number;
    public vegaHealth: VegaHealth;

    public constructor(init?: Partial<ListSpacesSummaryModel>) { super(init); (Object as any).assign(this, init); }
}

export class ListSpacesSummaryResponse extends VegaListResponse<ListSpacesSummaryModel>
{

    public constructor(init?: Partial<ListSpacesSummaryResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SpaceType extends SmartEnum_1<SpaceType>
{

    public constructor(init?: Partial<SpaceType>) { super(init); (Object as any).assign(this, init); }
}

export class GetSpaceModel
{
    public spaceId: string;
    public organizationId: string;
    public name: string;
    public description: string;
    public workloads: string[];
    public users: string[];
    public complianceTypes: string[];
    public spaceContainerType: SpaceContainerType;
    public spaceType: SpaceType;
    public budget: Budget;
    public isDefaultSpace: boolean;

    public constructor(init?: Partial<GetSpaceModel>) { (Object as any).assign(this, init); }
}

export class SpaceEntityState
{
    public spaceId: string;
    public organizationId: string;
    public name: string;
    public description: string;
    public workloads: string[];
    public users: string[];
    public complianceTypes: string[];
    public spaceContainerType: SpaceContainerType;
    public spaceType: string;
    public budget: Budget;
    public isDefaultSpace: boolean;

    public constructor(init?: Partial<SpaceEntityState>) { (Object as any).assign(this, init); }
}

export class CreateSpaceModel extends SpaceEntityState
{

    public constructor(init?: Partial<CreateSpaceModel>) { super(init); (Object as any).assign(this, init); }
}

export class SpaceTypeModel
{
    public spaceTypeId: number;
    public spaceTypeName: string;

    public constructor(init?: Partial<SpaceTypeModel>) { (Object as any).assign(this, init); }
}

/**
* ListSpaceSummary
*/
// @Route("/spaces/summaries", "GET")
// @Api(Description="ListSpaceSummary")
export class ListSpaceTypesModel extends ListSpacesSummary
{
    public spaceTypes: SpaceTypeModel[];

    public constructor(init?: Partial<ListSpaceTypesModel>) { super(init); (Object as any).assign(this, init); }
}

export class GetSpaceStatusModel
{
    public spaceId: string;
    public spaceName: string;
    public spaceDescription: string;
    public spaceContainerType: SpaceContainerType;
    public spaceType: string;
    public vegaHealth: VegaHealth;
    public workloadIds: string[];
    public hasSchedule: boolean;
    public hasActionsRunning: boolean;
    public scheduleId: string;
    public runningActions: string[];

    public constructor(init?: Partial<GetSpaceStatusModel>) { (Object as any).assign(this, init); }
}

export class OperatingScheduleRecurringEntry
{
    public startMinuteUtc: number;
    public endMinuteUtc: number;

    public constructor(init?: Partial<OperatingScheduleRecurringEntry>) { (Object as any).assign(this, init); }
}

export class OperatingScheduleRecurring
{
    public id: string;
    public workloadId: string;
    public organizationId: string;
    public entriesUtc: OperatingScheduleRecurringEntry[];

    public constructor(init?: Partial<OperatingScheduleRecurring>) { (Object as any).assign(this, init); }
}

export enum EntityType
{
    None = 'None',
    Space = 'Space',
    Workload = 'Workload',
    Satellite = 'Satellite',
}

export enum DayOfWeek
{
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
}

export class ScheduleModel
{
    public id: string;
    public entityType: EntityType;
    public organizationId: string;
    public entityId: string;
    public daysOfWeek: DayOfWeek[];
    public startTimeInSeconds: number;
    public stopTimeInSeconds: number;
    public actionOnStart: ActionType;
    public actionOnStop: ActionType;

    public constructor(init?: Partial<ScheduleModel>) { (Object as any).assign(this, init); }
}

export class GetScheduleModel extends ScheduleModel
{

    public constructor(init?: Partial<GetScheduleModel>) { super(init); (Object as any).assign(this, init); }
}

export class ExistsScheduleModel
{
    public exists: boolean;

    public constructor(init?: Partial<ExistsScheduleModel>) { (Object as any).assign(this, init); }
}

export class CreateScheduleModel extends ScheduleModel
{

    public constructor(init?: Partial<CreateScheduleModel>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateScheduleModel extends ScheduleModel
{

    public constructor(init?: Partial<UpdateScheduleModel>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteScheduleModel
{
    public isSuccess: boolean;

    public constructor(init?: Partial<DeleteScheduleModel>) { (Object as any).assign(this, init); }
}

export enum ScheduledActionStatus
{
    Generated = 'Generated',
    Scheduled = 'Scheduled',
    Started = 'Started',
    Error = 'Error',
    Completed = 'Completed',
}

export class ScheduledAction extends ValueObject
{
    public id: string;
    public scheduleId: string;
    public organizationId: string;
    public spaceId: string;
    public workloadId: string;
    public entityId: string;
    public entityType: EntityType;
    public actionType: ActionType;
    public scheduledDateUtc: string;
    public status: ScheduledActionStatus;
    public isImmediate: boolean;
    public userId: string;

    public constructor(init?: Partial<ScheduledAction>) { super(init); (Object as any).assign(this, init); }
}

export class GenerateScheduledActionsModel extends Array<ScheduledAction>
{

    public constructor(init?: Partial<GenerateScheduledActionsModel>) { super(); (Object as any).assign(this, init); }
}

export class GetOrganizationModel
{
    public organizationId: string;
    public organizationName: string;
    public awsExternalId: string;
    public defaultSpaceId: string;

    public constructor(init?: Partial<GetOrganizationModel>) { (Object as any).assign(this, init); }
}

export class BusinessTypeModel
{
    public businessTypeId: number;
    public businessTypeName: string;

    public constructor(init?: Partial<BusinessTypeModel>) { (Object as any).assign(this, init); }
}

export class ItOrganizationTypeModel
{
    public itOrganizationTypeId: number;
    public itOrganizationTypeName: string;

    public constructor(init?: Partial<ItOrganizationTypeModel>) { (Object as any).assign(this, init); }
}

export class ComplianceTypeModel
{
    public complianceTypeId: number;
    public complianceTypeName: string;

    public constructor(init?: Partial<ComplianceTypeModel>) { (Object as any).assign(this, init); }
}

export class CreateOrganizationModel
{
    public organizationId: string;
    public organizationName: string;
    public businessType: BusinessTypeModel;
    public itOrganizationType: ItOrganizationTypeModel;
    public complianceTypes: ComplianceTypeModel[];
    public itOrganizationTypeOther: string;
    public subDomain: string;
    public userIds: string[];

    public constructor(init?: Partial<CreateOrganizationModel>) { (Object as any).assign(this, init); }
}

export class OrganizationEntityState
{
    public organizationId: string;
    public name: string;
    public businessTypeName: string;
    public itOrganizationTypeName: string;
    public itOrganizationTypeOther: string;
    public complianceTypes: string[];
    public subDomain: string;
    public awsExternalId: string;
    public stripeCustomerId: string;
    public isActive: boolean;

    public constructor(init?: Partial<OrganizationEntityState>) { (Object as any).assign(this, init); }
}

export class UpdateOrganizationModel extends OrganizationEntityState
{

    public constructor(init?: Partial<UpdateOrganizationModel>) { super(init); (Object as any).assign(this, init); }
}

export class ListComplianceTypesModel
{
    public complianceTypes: ComplianceTypeModel[];

    public constructor(init?: Partial<ListComplianceTypesModel>) { (Object as any).assign(this, init); }
}

export class ListBusinessTypesModel
{
    public businessTypes: BusinessTypeModel[];

    public constructor(init?: Partial<ListBusinessTypesModel>) { (Object as any).assign(this, init); }
}

export class ListItOrganizationTypesModel
{
    public itOrganizationTypes: ItOrganizationTypeModel[];

    public constructor(init?: Partial<ListItOrganizationTypesModel>) { (Object as any).assign(this, init); }
}

export class ValidateOrganizationDomainModel
{
    public isValid: boolean;

    public constructor(init?: Partial<ValidateOrganizationDomainModel>) { (Object as any).assign(this, init); }
}

export class ValidateOrganizationNameModel
{
    public isValid: boolean;

    public constructor(init?: Partial<ValidateOrganizationNameModel>) { (Object as any).assign(this, init); }
}

export class ListOrganizationsModel
{
    public position: number;
    public organizationId: string;

    public constructor(init?: Partial<ListOrganizationsModel>) { (Object as any).assign(this, init); }
}

export enum CloudProviderIntegrationType
{
    Backup = 'Backup',
    Billing = 'Billing',
    Discovery = 'Discovery',
    TimedInstances = 'TimedInstances',
    Recommendations = 'Recommendations',
    Park = 'Park',
    GrantSecurityAccess = 'GrantSecurityAccess',
}

export enum CloudProviderIntegrationDetailParameterType
{
    AwsS3BillingBucket = 'AwsS3BillingBucket',
    AwsS3BillingBucketRegion = 'AwsS3BillingBucketRegion',
    AwsCurFileName = 'AwsCurFileName',
    AwsCurFileNamePrefix = 'AwsCurFileNamePrefix',
}

export class CloudProviderIntegrationDetailParameter
{
    public type: CloudProviderIntegrationDetailParameterType;
    public value: string;

    public constructor(init?: Partial<CloudProviderIntegrationDetailParameter>) { (Object as any).assign(this, init); }
}

export class CloudProviderIntegrationDetail
{
    public cloudProviderIntegrationType: CloudProviderIntegrationType;
    public parameters: CloudProviderIntegrationDetailParameter[];

    public constructor(init?: Partial<CloudProviderIntegrationDetail>) { (Object as any).assign(this, init); }
}

export class CloudProviderIntegrationWorkflow
{
    public executionId: string;

    public constructor(init?: Partial<CloudProviderIntegrationWorkflow>) { (Object as any).assign(this, init); }
}

export class CloudProviderIntegration extends CloudProviderIntegrationDetail
{
    public id: string;
    public workloadId: string;
    public organizationId: string;
    public cloudProvider: CloudProvider;
    public isEnabled: boolean;
    public hasPolicyUpdates: boolean;
    public hasBeenCreated: boolean;

    public constructor(init?: Partial<CloudProviderIntegration>) { super(init); (Object as any).assign(this, init); }
}

export class CloudProviderIntegrationDefinition extends CloudProviderIntegration
{
    public title: string;
    public description: string;
    public policy: string;

    public constructor(init?: Partial<CloudProviderIntegrationDefinition>) { super(init); (Object as any).assign(this, init); }
}

/**
* ListWorkloadCostsByDay
*/
// @Route("/financial/workload/costsByDay", "GET")
// @Api(Description="ListWorkloadCostsByDay")
export class ListWorkloadCostsByDay extends EntityAndDateFilterRequest implements IReturn<ListWorkloadCostsByDayResponse>
{

    public constructor(init?: Partial<ListWorkloadCostsByDay>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ListWorkloadCostsByDayResponse(); }
    public getTypeName() { return 'ListWorkloadCostsByDay'; }
}

/**
* ListWorkloadCostsByDay
*/
// @Route("/financial/workload/costsByDay", "GET")
// @Api(Description="ListWorkloadCostsByDay")
export class ListWorkloadCostsByDayModel extends ListWorkloadCostsByDay
{
    public costDate: string;
    public totalCost: number;
    public minCost: number;
    public maxCost: number;

    public constructor(init?: Partial<ListWorkloadCostsByDayModel>) { super(init); (Object as any).assign(this, init); }
}

export class ListWorkloadCostsByDayResponse extends VegaListResponse<ListWorkloadCostsByDayModel>
{

    public constructor(init?: Partial<ListWorkloadCostsByDayResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetTotalBaseModel
{
    public entityAndDateFilterRequest: EntityAndDateFilterRequest;
    public amount: number;

    public constructor(init?: Partial<GetTotalBaseModel>) { (Object as any).assign(this, init); }
}

export class GetCostTotalModel extends GetTotalBaseModel
{

    public constructor(init?: Partial<GetCostTotalModel>) { super(init); (Object as any).assign(this, init); }
}

export class GetSavingsTotalModel extends GetTotalBaseModel
{

    public constructor(init?: Partial<GetSavingsTotalModel>) { super(init); (Object as any).assign(this, init); }
}

export class GetVarianceTotalModel extends GetTotalBaseModel
{

    public constructor(init?: Partial<GetVarianceTotalModel>) { super(init); (Object as any).assign(this, init); }
}

export enum FinancialPeriodType
{
    MTD = 'MTD',
    YTD = 'YTD',
}

export class GetFinancialTotalsModel
{
    public entityId: string;
    public financialPeriodType: FinancialPeriodType;
    public totalCost: number;
    public budgetAmount: number;
    public savingsAmount: number;
    public productCategoryCosts: { [index: string]: number; };

    public constructor(init?: Partial<GetFinancialTotalsModel>) { (Object as any).assign(this, init); }
}

export enum AwsResourceType
{
    EC2 = 'EC2',
    RDS = 'RDS',
}

export class BaseAwsTagRequest
{
    /**
    * WorkloadId
    */
    // @ApiMember(DataType="string", Description="WorkloadId", IsRequired=true, Name="WorkloadId", ParameterType="query")
    public workloadId: string;

    /**
    * Region
    */
    // @ApiMember(DataType="string", Description="Region", IsRequired=true, Name="Region", ParameterType="query")
    public region: string;

    /**
    * AwsResourceType
    */
    // @ApiMember(DataType="string", Description="AwsResourceType", IsRequired=true, Name="AwsResourceType", ParameterType="query")
    public awsResourceType: AwsResourceType;

    /**
    * ResourceId
    */
    // @ApiMember(DataType="string", Description="ResourceId", IsRequired=true, Name="ResourceId", ParameterType="query")
    public resourceId: string;

    /**
    * TagName
    */
    // @ApiMember(DataType="string", Description="TagName", IsRequired=true, Name="TagName", ParameterType="query")
    public tagName: string;

    /**
    * TagValue
    */
    // @ApiMember(DataType="string", Description="TagValue", IsRequired=true, Name="TagValue", ParameterType="query")
    public tagValue: string;

    public constructor(init?: Partial<BaseAwsTagRequest>) { (Object as any).assign(this, init); }
}

export class AwsTag
{
    public tagName: string;
    public tagValue: string;

    public constructor(init?: Partial<AwsTag>) { (Object as any).assign(this, init); }
}

export class CreateAwsTagModel extends AwsTag
{

    public constructor(init?: Partial<CreateAwsTagModel>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateAwsTagModel
{
    public isSuccess: boolean;

    public constructor(init?: Partial<UpdateAwsTagModel>) { (Object as any).assign(this, init); }
}

export class DeleteAwsTagModel extends AwsTag
{

    public constructor(init?: Partial<DeleteAwsTagModel>) { super(init); (Object as any).assign(this, init); }
}

export class GetAwsTagModel extends AwsTag
{

    public constructor(init?: Partial<GetAwsTagModel>) { super(init); (Object as any).assign(this, init); }
}

export class CreateAwsQueueModel
{
    public queueUrl: string;
    public queueArn: string;

    public constructor(init?: Partial<CreateAwsQueueModel>) { (Object as any).assign(this, init); }
}

export class AwsQueueMessageModel
{
    public messageId: string;
    public receiptHandle: string;
    public body: string;

    public constructor(init?: Partial<AwsQueueMessageModel>) { (Object as any).assign(this, init); }
}

export class GetAwsQueueMessageModel
{
    public messages: AwsQueueMessageModel[];

    public constructor(init?: Partial<GetAwsQueueMessageModel>) { (Object as any).assign(this, init); }
}

export class DeleteAwsQueueMessageModel
{
    public isSuccess: boolean;

    public constructor(init?: Partial<DeleteAwsQueueMessageModel>) { (Object as any).assign(this, init); }
}

export class ListAwsQueuesModel
{
    public queueUrls: string[];

    public constructor(init?: Partial<ListAwsQueuesModel>) { (Object as any).assign(this, init); }
}

export class DeleteAwsQueueModel
{
    public isSuccess: boolean;

    public constructor(init?: Partial<DeleteAwsQueueModel>) { (Object as any).assign(this, init); }
}

export class CreateAwsNotificationModel
{
    public notificationArn: string;

    public constructor(init?: Partial<CreateAwsNotificationModel>) { (Object as any).assign(this, init); }
}

export class CreateAwsSubscriptionModel
{
    public subscriptionArn: string;

    public constructor(init?: Partial<CreateAwsSubscriptionModel>) { (Object as any).assign(this, init); }
}

export class DeleteAwsNotificationModel
{
    public isSuccessful: boolean;

    public constructor(init?: Partial<DeleteAwsNotificationModel>) { (Object as any).assign(this, init); }
}

export class PublishAwsMessageModel
{
    public messageId: string;

    public constructor(init?: Partial<PublishAwsMessageModel>) { (Object as any).assign(this, init); }
}

export class ListAwsNotificationsModel
{
    public nextToken: string;
    public notificationArns: string[];

    public constructor(init?: Partial<ListAwsNotificationsModel>) { (Object as any).assign(this, init); }
}

export class GenerateNextScheduledActionsModel
{
    public isSuccess: boolean;

    public constructor(init?: Partial<GenerateNextScheduledActionsModel>) { (Object as any).assign(this, init); }
}

export class ScheduledActionHistory
{
    public id: string;
    public scheduledActionId: string;
    public organizationId: string;
    public spaceId: string;
    public workloadId: string;
    public name: string;
    public actionType: ActionType;
    public friendlyName: string;
    public scheduledDateUtc: string;
    public status: ScheduledActionStatus;

    public constructor(init?: Partial<ScheduledActionHistory>) { (Object as any).assign(this, init); }
}

export class ListNextScheduledActionsModel extends ScheduledActionHistory
{

    public constructor(init?: Partial<ListNextScheduledActionsModel>) { super(init); (Object as any).assign(this, init); }
}

export class CreateScheduledActionModel extends ScheduledAction
{

    public constructor(init?: Partial<CreateScheduledActionModel>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateScheduledActionModel extends ScheduledAction
{

    public constructor(init?: Partial<UpdateScheduledActionModel>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteScheduledActionModel
{
    public isSuccess: boolean;

    public constructor(init?: Partial<DeleteScheduledActionModel>) { (Object as any).assign(this, init); }
}

export class GetScheduledActionsModel extends ScheduledAction
{
    public position: number;

    public constructor(init?: Partial<GetScheduledActionsModel>) { super(init); (Object as any).assign(this, init); }
}

export class GetGeneratedActionsModel extends ScheduledAction
{
    public position: number;

    public constructor(init?: Partial<GetGeneratedActionsModel>) { super(init); (Object as any).assign(this, init); }
}

export class ListScheduledActionsModel extends ScheduledActionHistory
{

    public constructor(init?: Partial<ListScheduledActionsModel>) { super(init); (Object as any).assign(this, init); }
}

export enum DiscoverFilterType
{
    None = 'None',
    Park = 'Park',
    Backup = 'Backup',
    ParkImmediate = 'ParkImmediate',
    UnParkImmediate = 'UnParkImmediate',
    BackupImmediate = 'BackupImmediate',
    RestoreImmediate = 'RestoreImmediate',
    Satellite = 'Satellite',
}

export class BaseAwsActionRegionRequest
{
    // @ApiMember(DataType="string", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    // @ApiMember(DataType="string", IsRequired=true, Name="ActionType", ParameterType="query")
    public actionType: ActionType;

    // @ApiMember(DataType="string", Name="DiscoverFilterType", ParameterType="query")
    public discoverFilterType: DiscoverFilterType;

    // @ApiMember(DataType="string", IsRequired=true, Name="Region", ParameterType="query")
    public region: string;

    // @ApiMember(DataType="string", Name="NextToken", ParameterType="query")
    public nextToken: string;

    // @ApiMember(DataType="array", Name="AssetIds", ParameterType="query")
    public assetIds: string[];

    public constructor(init?: Partial<BaseAwsActionRegionRequest>) { (Object as any).assign(this, init); }
}

export class AwsActionResult
{
    public assetId: string;
    public hadError: boolean;
    public errorMessage: string;

    public constructor(init?: Partial<AwsActionResult>) { (Object as any).assign(this, init); }
}

export class ExecuteAwsDatabaseActionModel
{
    public databases: DatabaseModel[];
    public assetIds: string[];
    public assetArns: string[];
    public nextToken: string;
    public actionResults: AwsActionResult[];

    public constructor(init?: Partial<ExecuteAwsDatabaseActionModel>) { (Object as any).assign(this, init); }
}

export class ExecuteAwsServerActionModel
{
    public servers: ServerModel[];
    public assetIds: string[];
    public assetArns: string[];
    public nextToken: string;
    public actionResults: AwsActionResult[];

    public constructor(init?: Partial<ExecuteAwsServerActionModel>) { (Object as any).assign(this, init); }
}

export class ListAwsRegionsModel
{
    public regions: string[];

    public constructor(init?: Partial<ListAwsRegionsModel>) { (Object as any).assign(this, init); }
}

export class DescribeRegion
{
    public endpoint: string;
    public optInStatus: string;
    public regionName: string;

    public constructor(init?: Partial<DescribeRegion>) { (Object as any).assign(this, init); }
}

export class DescribeAmi
{
    public name: string;
    public imageId: string;
    public architecture: string;
    public platform: string;
    public imageLocation: string;
    public ownerId: string;
    public public: boolean;
    public imageType: string;
    public description: string;

    public constructor(init?: Partial<DescribeAmi>) { (Object as any).assign(this, init); }
}

export class DescribeInstanceType
{
    public instanceType: string;
    public currentGeneration: boolean;

    public constructor(init?: Partial<DescribeInstanceType>) { (Object as any).assign(this, init); }
}

export class DescribeVpc
{
    public vpcId: string;
    public isDefault: boolean;

    public constructor(init?: Partial<DescribeVpc>) { (Object as any).assign(this, init); }
}

export class DescribeSecurityGroup
{
    public groupName: string;
    public groupId: string;
    public description: string;
    public vpcId: string;

    public constructor(init?: Partial<DescribeSecurityGroup>) { (Object as any).assign(this, init); }
}

export class StartAwsActionModel
{
    public executionArn: string;
    public startDate: string;

    public constructor(init?: Partial<StartAwsActionModel>) { (Object as any).assign(this, init); }
}

export class CreateAwsActionLogModel
{
    public isSuccess: boolean;

    public constructor(init?: Partial<CreateAwsActionLogModel>) { (Object as any).assign(this, init); }
}

export enum RecommendationStatus
{
    WaitingForApproval = 'WaitingForApproval',
    Pending = 'Pending',
    Declined = 'Declined',
    Scheduled = 'Scheduled',
    InProcess = 'InProcess',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
}

export enum ScheduleType
{
    Schedule = 'Schedule',
    Window = 'Window',
}

export class ReviewTask
{
    public id: string;
    public title: string;
    public description: string;
    public output: string;

    public constructor(init?: Partial<ReviewTask>) { (Object as any).assign(this, init); }
}

export enum ImplementationTaskStatus
{
    Planned = 'Planned',
    Pending = 'Pending',
    InProcess = 'InProcess',
    Completed = 'Completed',
}

export class ImplementationTask
{
    public id: string;
    public title: string;
    public description: string;
    public output: string;
    public notes: string;
    public implementationTaskStatus: ImplementationTaskStatus;
    public statusDateUtc: string;

    public constructor(init?: Partial<ImplementationTask>) { (Object as any).assign(this, init); }
}

export class AcceptInviteModel
{
    public email: string;
    public inviteCode: string;

    public constructor(init?: Partial<AcceptInviteModel>) { (Object as any).assign(this, init); }
}

export class GetInviteModel
{
    public inviteMax: number;
    public inviteUsed: number;
    public inviteRemaining: number;

    public constructor(init?: Partial<GetInviteModel>) { (Object as any).assign(this, init); }
}

export class SendInviteModel
{
    public email: string;
    public inviteCode: string;

    public constructor(init?: Partial<SendInviteModel>) { (Object as any).assign(this, init); }
}

export enum ResourceType
{
    All = 'All',
    Ec2Instance = 'Ec2Instance',
    Function = 'Function',
    Snapshot = 'Snapshot',
    StateMachine = 'StateMachine',
    Volume = 'Volume',
    ElastiCacheCluster = 'ElastiCacheCluster',
    ElastiSnapshot = 'ElastiSnapshot',
    AuroraDbCluster = 'AuroraDbCluster',
    AutoScalingGroup = 'AutoScalingGroup',
    EcsCluster = 'EcsCluster',
    EksCluster = 'EksCluster',
    NatGateway = 'NatGateway',
    ClassicElasticLoadBlancer = 'ClassicElasticLoadBlancer',
    ApplicationAndNetworkElasticLoadBlancer = 'ApplicationAndNetworkElasticLoadBlancer',
    S3 = 'S3',
    DataSync = 'DataSync',
    Organization = 'Organization',
    ReservedEc2Instance = 'ReservedEc2Instance',
    ReservedDbInstance = 'ReservedDbInstance',
    VPC = 'VPC',
    Ec2Subnet = 'Ec2Subnet',
    Workspace = 'Workspace',
    IamPolicy = 'IamPolicy',
    RedshiftCluster = 'RedshiftCluster',
    CloudFrontDistribution = 'CloudFrontDistribution',
    ElasticBeanstalk = 'ElasticBeanstalk',
}

export enum InstanceUse
{
    Unknown = 'Unknown',
    AutoScale = 'AutoScale',
    ECS = 'ECS',
    ClassicLoadBalancer = 'ClassicLoadBalancer',
    StandAlone = 'StandAlone',
    Other = 'Other',
}

export class Tag
{
    public key: string;
    public value: string;

    public constructor(init?: Partial<Tag>) { (Object as any).assign(this, init); }
}

export class Ec2Instance
{
    public outpostArn: string;
    public placementGroupName: string;
    public platform: string;
    public privateDnsName: string;
    public privateIpAddress: string;
    public publicDnsName: string;
    public publicIpAddress: string;
    public ramdiskId: string;
    public rootDeviceName: string;
    public sourceDestCheck: boolean;
    public spotInstanceRequestId: string;
    public sriovNetSupport: string;
    public state: string;
    public stateReason: string;
    public stateTransitionReason: string;
    public subnetId: string;
    public amiLaunchIndex: number;
    public capacityReservationId: string;
    public clientToken: string;
    public ebsOptimized: boolean;
    public enaSupport: boolean;
    public imageId: string;
    public instanceId: string;
    public instanceLifecycle: string;
    public instanceType: string;
    public kernelId: string;
    public keyName: string;
    public launchTime: string;
    public vpcId: string;
    public isParkable: boolean;
    public isSpotInstance: boolean;
    public usedBy: InstanceUse;
    public classicLoadBalancerName: string;
    public autoScalingGroupName: string;
    public ecsClusterName: string;
    public tags: Tag[];

    public constructor(init?: Partial<Ec2Instance>) { (Object as any).assign(this, init); }
}

export class StateMachine
{
    public name: string;
    public arn: string;
    public creationDate: string;
    public tags: Tag[];

    public constructor(init?: Partial<StateMachine>) { (Object as any).assign(this, init); }
}

export class Function
{
    public name: string;
    public arn: string;
    public runtime: string;
    public role: string;
    public handler: string;
    public codeSize: number;
    public description: string;
    public timeout: number;
    public memorySize: number;
    public lastModified: string;
    public version: string;
    public revisionId: string;
    public tags: Tag[];

    public constructor(init?: Partial<Function>) { (Object as any).assign(this, init); }
}

export class VolumeAttachment
{
    public attachTime: string;
    public instanceId: string;
    public volumeId: string;
    public state: string;
    public deleteOnTermination: boolean;
    public device: string;

    public constructor(init?: Partial<VolumeAttachment>) { (Object as any).assign(this, init); }
}

export class Volume
{
    public availabilityZone: string;
    public attachments: VolumeAttachment[];
    public encrypted: boolean;
    public volumeType: string;
    public volumeId: string;
    public state: string;
    public snapshotId: string;
    public createTime: string;
    public size: number;
    public iops: number;
    public tags: Tag[];

    public constructor(init?: Partial<Volume>) { (Object as any).assign(this, init); }
}

export class Snapshot
{
    public dataEncryptionKeyId: string;
    public description: string;
    public encrypted: boolean;
    public kmsKeyId: string;
    public ownerAlias: string;
    public ownerId: string;
    public progress: string;
    public snapshotId: string;
    public startTime: string;
    public state: string;
    public stateMessage: string;
    public volumeId: string;
    public volumeSize: number;
    public tags: Tag[];

    public constructor(init?: Partial<Snapshot>) { (Object as any).assign(this, init); }
}

export class NotificationConfiguration
{
    public topicArn: string;
    public topicStatus: string;

    public constructor(init?: Partial<NotificationConfiguration>) { (Object as any).assign(this, init); }
}

export class Endpoint
{
    public address: string;
    public port: number;

    public constructor(init?: Partial<Endpoint>) { (Object as any).assign(this, init); }
}

export class CacheParameterGroupStatus
{
    public cacheNodeIdsToReboot: string[];
    public cacheParameterGroupName: string;
    public parameterApplyStatus: string;

    public constructor(init?: Partial<CacheParameterGroupStatus>) { (Object as any).assign(this, init); }
}

export class CacheNode
{
    public cacheNodeCreateTime: string;
    public cacheNodeId: string;
    public cacheNodeStatus: string;
    public customerAvailabilityZone: string;
    public endpoint: Endpoint;
    public parameterGroupStatus: string;
    public sourceCacheNodeId: string;

    public constructor(init?: Partial<CacheNode>) { (Object as any).assign(this, init); }
}

export class ElastiCacheCluster
{
    public snapshotRetentionLimit: number;
    public replicationGroupId: string;
    public preferredMaintenanceWindow: string;
    public preferredAvailabilityZone: string;
    public numCacheNodes: number;
    public notificationConfiguration: NotificationConfiguration;
    public engineVersion: string;
    public engine: string;
    public configurationEndpoint: Endpoint;
    public clientDownloadLandingPage: string;
    public cacheSubnetGroupName: string;
    public cacheParameterGroup: CacheParameterGroupStatus;
    public cacheNodeType: string;
    public cacheNodes: CacheNode[];
    public cacheClusterStatus: string;
    public cacheClusterId: string;
    public cacheClusterCreateTime: string;
    public autoMinorVersionUpgrade: boolean;
    public authTokenEnabled: boolean;
    public atRestEncryptionEnabled: boolean;
    public snapshotWindow: string;
    public transitEncryptionEnabled: boolean;

    public constructor(init?: Partial<ElastiCacheCluster>) { (Object as any).assign(this, init); }
}

export class ElastiNodeGroupConfiguration
{
    public nodeGroupId: string;
    public primaryAvailabilityZone: string;
    public replicaAvailabilityZones: string[];
    public replicaCount: number;
    public slots: string;

    public constructor(init?: Partial<ElastiNodeGroupConfiguration>) { (Object as any).assign(this, init); }
}

export class ElastiNodeSnapshot
{
    public cacheClusterId: string;
    public cacheNodeCreateTime: string;
    public cacheNodeId: string;
    public cacheSize: string;
    public nodeGroupConfiguration: ElastiNodeGroupConfiguration;
    public nodeGroupId: string;
    public snapshotCreateTime: string;

    public constructor(init?: Partial<ElastiNodeSnapshot>) { (Object as any).assign(this, init); }
}

export class ElastiSnapshot
{
    public snapshotWindow: string;
    public snapshotStatus: string;
    public snapshotSource: string;
    public snapshotRetentionLimit: number;
    public snapshotName: string;
    public replicationGroupId: string;
    public replicationGroupDescription: string;
    public preferredMaintenanceWindow: string;
    public preferredAvailabilityZone: string;
    public port: number;
    public numNodeGroups: number;
    public numCacheNodes: number;
    public nodeSnapshots: ElastiNodeSnapshot[];
    public kmsKeyId: string;
    public engineVersion: string;
    public engine: string;
    public cacheSubnetGroupName: string;
    public cacheParameterGroupName: string;
    public cacheNodeType: string;
    public cacheClusterId: string;
    public cacheClusterCreateTime: string;
    public autoMinorVersionUpgrade: boolean;
    public topicArn: string;
    public vpcId: string;
    public tags: Tag[];

    public constructor(init?: Partial<ElastiSnapshot>) { (Object as any).assign(this, init); }
}

export class ScalingConfigurationInfo
{
    public autoPause: boolean;
    public maxCapacity: number;
    public minCapacity: number;
    public secondsUntilAutoPause: number;
    public timeoutAction: string;

    public constructor(init?: Partial<ScalingConfigurationInfo>) { (Object as any).assign(this, init); }
}

export class DbClusterRole
{
    public featureName: string;
    public roleArn: string;
    public status: string;

    public constructor(init?: Partial<DbClusterRole>) { (Object as any).assign(this, init); }
}

export class DbClusterMember
{
    public dbClusterParameterGroupStatus: string;
    public dbInstanceIdentifier: string;
    public isClusterWriter: boolean;
    public promotionTier: number;

    public constructor(init?: Partial<DbClusterMember>) { (Object as any).assign(this, init); }
}

export class VpcSecurityGroupMembership
{
    public status: string;
    public vpcSecurityGroupId: string;

    public constructor(init?: Partial<VpcSecurityGroupMembership>) { (Object as any).assign(this, init); }
}

export class DbCluster
{
    public earliestRestorableTime: string;
    public enabledCloudwatchLogsExports: string[];
    public endpoint: string;
    public engine: string;
    public engineMode: string;
    public engineVersion: string;
    public hostedZoneId: string;
    public httpEndpointEnabled: boolean;
    public iamDatabaseAuthenticationEnabled: boolean;
    public kmsKeyId: string;
    public earliestBacktrackTime: string;
    public latestRestorableTime: string;
    public multiAz: boolean;
    public percentProgress: string;
    public port: number;
    public preferredBackupWindow: string;
    public preferredMaintenanceWindow: string;
    public readerEndpoint: string;
    public readReplicaIdentifiers: string[];
    public replicationSourceIdentifier: string;
    public scalingConfigurationInfo: ScalingConfigurationInfo;
    public status: string;
    public masterUsername: string;
    public deletionProtection: boolean;
    public dbSubnetGroup: string;
    public dbClusterResourceId: string;
    public activityStreamKinesisStreamName: string;
    public activityStreamKmsKeyId: string;
    public allocatedStorage: number;
    public associatedRoles: DbClusterRole[];
    public availabilityZones: string[];
    public backtrackConsumedChangeRecords: number;
    public backtrackWindow: number;
    public backupRetentionPeriod: number;
    public capacity: number;
    public characterSetName: string;
    public cloneGroupId: string;
    public clusterCreateTime: string;
    public copyTagsToSnapshot: boolean;
    public crossAccountClone: boolean;
    public customEndpoints: string[];
    public databaseName: string;
    public dbClusterArn: string;
    public dbClusterIdentifier: string;
    public dbClusterMembers: DbClusterMember[];
    public dbClusterParameterGroup: string;
    public storageEncrypted: boolean;
    public vpcSecurityGroups: VpcSecurityGroupMembership[];
    public tags: Tag[];

    public constructor(init?: Partial<DbCluster>) { (Object as any).assign(this, init); }
}

export class AutoScalingInstance
{
    public autoScalingGroupName: string;
    public availabilityZone: string;
    public healthStatus: string;
    public instanceId: string;
    public instanceType: string;
    public launchConfigurationName: string;
    public lifecycleState: string;
    public protectedFromScaleIn: boolean;
    public weightedCapacity: string;

    public constructor(init?: Partial<AutoScalingInstance>) { (Object as any).assign(this, init); }
}

export class AutoScalingGroup
{
    public targetGroupArns: string[];
    public status: string;
    public serviceLinkedRoleArn: string;
    public placementGroup: string;
    public newInstancesProtectedFromScaleIn: boolean;
    public minSize: number;
    public maxSize: number;
    public maxInstanceLifetime: number;
    public terminationPolicies: string[];
    public loadBalancerNames: string[];
    public launchConfigurationName: string;
    public instances: AutoScalingInstance[];
    public healthCheckType: string;
    public healthCheckGracePeriod: number;
    public desiredCapacity: number;
    public defaultCooldown: number;
    public createdTime: string;
    public availabilityZones: string[];
    public autoScalingGroupName: string;
    public autoScalingGroupArn: string;
    public vpcZoneIdentifier: string;
    public tags: Tag[];

    public constructor(init?: Partial<AutoScalingGroup>) { (Object as any).assign(this, init); }
}

export class EcsContainerInstance
{
    public statusReason: string;
    public status: string;
    public runningTasksCount: number;
    public version: number;
    public registeredAt: string;
    public ec2InstanceId: string;
    public containerInstanceArn: string;
    public agentConnected: boolean;
    public pendingTasksCount: number;
    public tags: Tag[];

    public constructor(init?: Partial<EcsContainerInstance>) { (Object as any).assign(this, init); }
}

export class EcsCluster
{
    public activeServicesCount: number;
    public clusterArn: string;
    public clusterName: string;
    public pendingTasksCount: number;
    public registeredContainerInstancesCount: number;
    public runningTasksCount: number;
    public status: string;
    public containerInstances: EcsContainerInstance[];
    public tags: Tag[];

    public constructor(init?: Partial<EcsCluster>) { (Object as any).assign(this, init); }
}

export class EksNodegroup
{
    public subnets: string[];
    public status: string;
    public autoScalingGroups: string[];
    public remoteAccessSecurityGroup: string;
    public releaseVersion: string;
    public nodeRole: string;
    public nodegroupName: string;
    public nodegroupArn: string;
    public modifiedAt: string;
    public labels: Tag[];
    public instanceTypes: string[];
    public diskSize: number;
    public createdAt: string;
    public clusterName: string;
    public version: string;
    public tags: Tag[];

    public constructor(init?: Partial<EksNodegroup>) { (Object as any).assign(this, init); }
}

export class EksCluster
{
    public arn: string;
    public clientRequestToken: string;
    public createdAt: string;
    public endpoint: string;
    public name: string;
    public platformVersion: string;
    public roleArn: string;
    public status: string;
    public version: string;
    public nodegroups: EksNodegroup[];
    public tags: Tag[];

    public constructor(init?: Partial<EksCluster>) { (Object as any).assign(this, init); }
}

export class NatGatewayAddress
{
    public allocationId: string;
    public networkInterfaceId: string;
    public privateIp: string;
    public publicIp: string;

    public constructor(init?: Partial<NatGatewayAddress>) { (Object as any).assign(this, init); }
}

export class ProvisionedBandwidth
{
    public provisioned: string;
    public provisionTime: string;
    public requested: string;
    public requestTime: string;
    public status: string;

    public constructor(init?: Partial<ProvisionedBandwidth>) { (Object as any).assign(this, init); }
}

export class NatGateway
{
    public createTime: string;
    public deleteTime: string;
    public failureCode: string;
    public failureMessage: string;
    public natGatewayAdresses: NatGatewayAddress[];
    public natGatewayId: string;
    public provisionedBandwidth: ProvisionedBandwidth;
    public state: string;
    public subnetId: string;
    public vpcId: string;
    public tags: Tag[];

    public constructor(init?: Partial<NatGateway>) { (Object as any).assign(this, init); }
}

export class BackendServerDescription
{
    public instancePort: number;
    public policyNames: string[];

    public constructor(init?: Partial<BackendServerDescription>) { (Object as any).assign(this, init); }
}

export enum LoadBalancerTypeEnum
{
    Unknown = 'Unknown',
    Classic = 'Classic',
    Application = 'Application',
    Network = 'Network',
}

export class ElasticLoadBalancerV1
{
    public securityGroups: string[];
    public scheme: string;
    public loadBalancerName: string;
    public subnets: string[];
    public instances: string[];
    public dnsName: string;
    public createdTime: string;
    public canonicalHostedZoneNameID: string;
    public canonicalHostedZoneName: string;
    public backendServerDescriptions: BackendServerDescription[];
    public availabilityZones: string[];
    public vpcId: string;
    public loadBalancerType: LoadBalancerTypeEnum;
    public tags: Tag[];

    public constructor(init?: Partial<ElasticLoadBalancerV1>) { (Object as any).assign(this, init); }
}

export class ElasticLoadBalancerV2
{
    public canonicalHostedZoneId: string;
    public createdTime: string;
    public dnsName: string;
    public loadBalancerArn: string;
    public loadBalancerName: string;
    public scheme: string;
    public securityGroups: string[];
    public vpcId: string;
    public loadBalancerType: LoadBalancerTypeEnum;
    public tags: Tag[];

    public constructor(init?: Partial<ElasticLoadBalancerV2>) { (Object as any).assign(this, init); }
}

export class S3Bucket
{
    public bucketName: string;
    public region: string;
    public creationDate: string;
    public encryptionAlgorithms: string[];
    public tags: Tag[];

    public constructor(init?: Partial<S3Bucket>) { (Object as any).assign(this, init); }
}

export class DataSync
{
    public locationArn: string;
    public locationUri: string;
    public creationTime: string;
    public s3StorageClass: string;
    public bucketAccessRoleArn: string;

    public constructor(init?: Partial<DataSync>) { (Object as any).assign(this, init); }
}

export class OrganizationPolicy
{
    public arn: string;
    public awsManaged: boolean;
    public description: string;
    public id: string;
    public name: string;
    public type: string;

    public constructor(init?: Partial<OrganizationPolicy>) { (Object as any).assign(this, init); }
}

export class OrganizationAccounts
{
    public arn: string;
    public email: string;
    public id: string;
    public joinedTimestamp: string;
    public name: string;
    public status: string;
    public tags: Tag[];

    public constructor(init?: Partial<OrganizationAccounts>) { (Object as any).assign(this, init); }
}

export class Organization
{
    public arn: string;
    public featureSet: string;
    public id: string;
    public masterAccountArn: string;
    public masterAccountEmail: string;
    public masterAccountId: string;
    public policies: OrganizationPolicy[];
    public accounts: OrganizationAccounts[];

    public constructor(init?: Partial<Organization>) { (Object as any).assign(this, init); }
}

export class ReservedRecurringCharge
{
    public amount: number;
    public frequency: string;

    public constructor(init?: Partial<ReservedRecurringCharge>) { (Object as any).assign(this, init); }
}

export class ReservedEc2Instance
{
    public state: string;
    public start: string;
    public reservedInstancesId: string;
    public recurringCharges: ReservedRecurringCharge[];
    public productDescription: string;
    public instanceCount: number;
    public fixedPrice: number;
    public end: string;
    public duration: number;
    public currencyCode: string;
    public availabilityZone: string;
    public instanceType: string;
    public usagePrice: number;
    public tags: Tag[];

    public constructor(init?: Partial<ReservedEc2Instance>) { (Object as any).assign(this, init); }
}

export class ReservedDbInstance
{
    public startTime: string;
    public reservedDBInstancesOfferingId: string;
    public reservedDBInstanceId: string;
    public reservedDBInstanceArn: string;
    public recurringCharges: ReservedRecurringCharge[];
    public productDescription: string;
    public state: string;
    public offeringType: string;
    public leaseId: string;
    public fixedPrice: number;
    public duration: number;
    public dbInstanceCount: number;
    public dbInstanceClass: string;
    public currencyCode: string;
    public multiAZ: boolean;
    public usagePrice: number;
    public tags: Tag[];

    public constructor(init?: Partial<ReservedDbInstance>) { (Object as any).assign(this, init); }
}

export class Ec2Subnet
{
    public assignIpv6AddressOnCreation: boolean;
    public availabilityZone: string;
    public availabilityZoneId: string;
    public availableIpAddressCount: number;
    public cidrBlock: string;
    public defaultForAz: boolean;
    public mapPublicIpOnLaunch: boolean;
    public outpostArn: string;
    public ownerId: string;
    public state: string;
    public subnetArn: string;
    public subnetId: string;
    public vpcId: string;
    public tags: Tag[];

    public constructor(init?: Partial<Ec2Subnet>) { (Object as any).assign(this, init); }
}

export class Vpc
{
    public cidrBlock: string;
    public dhcpOptionsId: string;
    public instanceTenancy: string;
    public isDefault: boolean;
    public ownerId: string;
    public state: string;
    public vpcId: string;
    public tags: Tag[];

    public constructor(init?: Partial<Vpc>) { (Object as any).assign(this, init); }
}

export class WorkspaceProperties
{
    public computeTypeName: string;
    public rootVolumeSizeGib: number;
    public runningMode: string;
    public runningModeAutoStopTimeoutInMinutes: number;
    public userVolumeSizeGib: number;

    public constructor(init?: Partial<WorkspaceProperties>) { (Object as any).assign(this, init); }
}

export class Workspace
{
    public bundleId: string;
    public computerName: string;
    public directoryId: string;
    public errorCode: string;
    public errorMessage: string;
    public ipAddress: string;
    public rootVolumeEncryptionEnabled: boolean;
    public state: string;
    public subnetId: string;
    public userName: string;
    public userVolumeEncryptionEnabled: boolean;
    public volumeEncryptionKey: string;
    public workspaceId: string;
    public workspaceProperties: WorkspaceProperties;
    public tags: Tag[];

    public constructor(init?: Partial<Workspace>) { (Object as any).assign(this, init); }
}

export class IamPolicy
{
    public arn: string;
    public attachmentCount: number;
    public createDate: string;
    public defaultVersionId: string;
    public description: string;
    public isAttachable: boolean;
    public path: string;
    public permissionsBoundaryUsageCount: number;
    public policyId: string;
    public policyName: string;
    public updateDate: string;

    public constructor(init?: Partial<IamPolicy>) { (Object as any).assign(this, init); }
}

export class RedshiftCluster
{
    public expectedNextSnapshotScheduleTimeStatus: string;
    public kmsKeyId: string;
    public maintenanceTrackName: string;
    public manualSnapshotRetentionPeriod: number;
    public masterUsername: string;
    public modifyStatus: string;
    public nextMaintenanceWindowStartTime: string;
    public expectedNextSnapshotScheduleTime: string;
    public nodeType: string;
    public pendingActions: string[];
    public preferredMaintenanceWindow: string;
    public publiclyAccessible: boolean;
    public snapshotScheduleIdentifier: string;
    public numberOfNodes: number;
    public vpcId: string;
    public enhancedVpcRouting: boolean;
    public encrypted: boolean;
    public allowVersionUpgrade: boolean;
    public automatedSnapshotRetentionPeriod: number;
    public availabilityZone: string;
    public clusterAvailabilityStatus: string;
    public clusterCreateTime: string;
    public clusterIdentifier: string;
    public clusterPublicKey: string;
    public clusterRevisionNumber: string;
    public clusterStatus: string;
    public clusterSubnetGroupName: string;
    public clusterVersion: string;
    public dbName: string;
    public elasticResizeNumberOfNodeOptions: string;
    public vpcSecurityGroups: VpcSecurityGroupMembership[];
    public tags: Tag[];

    public constructor(init?: Partial<RedshiftCluster>) { (Object as any).assign(this, init); }
}

export class CloudFrontDistribution
{
    public status: string;
    public priceClass: string;
    public lastModifiedTime: string;
    public isIPV6Enabled: boolean;
    public id: string;
    public httpVersion: string;
    public domainName: string;
    public comment: string;
    public arn: string;
    public aliases: string[];
    public enabled: boolean;
    public webACLId: string;
    public tags: Tag[];

    public constructor(init?: Partial<CloudFrontDistribution>) { (Object as any).assign(this, init); }
}

export class ElasticBeanstalkEnvironment
{
    public templateName: string;
    public status: string;
    public solutionStackName: string;
    public platformArn: string;
    public healthStatus: string;
    public health: string;
    public environmentName: string;
    public tierName: string;
    public tierType: string;
    public environmentArn: string;
    public endpointURL: string;
    public description: string;
    public dateUpdated: string;
    public dateCreated: string;
    public cname: string;
    public applicationName: string;
    public abortableOperationInProgress: boolean;
    public environmentId: string;
    public versionLabel: string;
    public tags: Tag[];

    public constructor(init?: Partial<ElasticBeanstalkEnvironment>) { (Object as any).assign(this, init); }
}

export class Resources
{
    public region: string;
    public ec2Instances: Ec2Instance[];
    public stateMachines: StateMachine[];
    public functions: Function[];
    public volumes: Volume[];
    public snapshots: Snapshot[];
    public elastiCacheClusters: ElastiCacheCluster[];
    public elastiSnapshots: ElastiSnapshot[];
    public auroraDbClusters: DbCluster[];
    public autoScalingGroups: AutoScalingGroup[];
    public ecsClusters: EcsCluster[];
    public eksClusters: EksCluster[];
    public natGateways: NatGateway[];
    public classicElasticLoadBlancers: ElasticLoadBalancerV1[];
    public applicationAndNetworkElasticLoadBlancers: ElasticLoadBalancerV2[];
    public s3Buckets: S3Bucket[];
    public dataSyncs: DataSync[];
    public organization: Organization;
    public reservedEc2Instances: ReservedEc2Instance[];
    public reservedDbInstances: ReservedDbInstance[];
    public ec2Subnets: Ec2Subnet[];
    public vpcs: Vpc[];
    public workspaces: Workspace[];
    public iamPolicies: IamPolicy[];
    public redshiftClusters: RedshiftCluster[];
    public cloudFrontDistributions: CloudFrontDistribution[];
    public elasticBeanstalkEnvironments: ElasticBeanstalkEnvironment[];

    public constructor(init?: Partial<Resources>) { (Object as any).assign(this, init); }
}

export interface IVegaMessage
{
    id: string;
    source: string;
    organizationId: string;
    workloadId: string;
    spaceId: string;
    userId: string;
    name: string;
    description: string;
    linkTitle: string;
    link: string;
    dateCreatedUtc: string;
    closedByUserId: string;
    dateClosedUtc: string;
}

export interface IVegaPriorityMessage
{
    priorityLevel: number;
    id: string;
    source: string;
    organizationId: string;
    workloadId: string;
    spaceId: string;
    userId: string;
    name: string;
    description: string;
    linkTitle: string;
    link: string;
    dateCreatedUtc: string;
    closedByUserId: string;
    dateClosedUtc: string;
}

export interface IHasResource
{
    resource: IResource;
}

export class CreateWorkloadAwsResponse extends VegaResponse<CreateWorkloadAwsModel>
{

    public constructor(init?: Partial<CreateWorkloadAwsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetWorkloadTemplateAwsResponse extends VegaResponse<GetWorkloadTemplateAwsModel>
{

    public constructor(init?: Partial<GetWorkloadTemplateAwsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateNotificationResponse extends VegaListResponse<CreateNotificationModel>
{

    public constructor(init?: Partial<CreateNotificationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListNotificationsResponse extends VegaListResponse<ListNotificationsModel>
{

    public constructor(init?: Partial<ListNotificationsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteNotificationResponse extends VegaResponse<DeleteNotificationModel>
{

    public constructor(init?: Partial<DeleteNotificationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateExecutionMessageResponse extends VegaListResponse<CreateExecutionMessageModel>
{

    public constructor(init?: Partial<CreateExecutionMessageResponse>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateExecutionMessageResponse extends VegaResponse<UpdateExecutionMessageModel>
{

    public constructor(init?: Partial<UpdateExecutionMessageResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListExecutionMessagesResponse extends VegaListResponse<ListExecutionMessagesModel>
{

    public constructor(init?: Partial<ListExecutionMessagesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteExecutionMessageResponse extends VegaResponse<DeleteExecutionMessageModel>
{

    public constructor(init?: Partial<DeleteExecutionMessageResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ExecuteActionResponse extends VegaResponse<ExecuteActionModel>
{

    public constructor(init?: Partial<ExecuteActionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetWorkloadResponse extends VegaResponse<GetWorkloadModel>
{

    public constructor(init?: Partial<GetWorkloadResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateWorkloadResponse extends VegaResponse<CreateWorkloadModel>
{

    public constructor(init?: Partial<CreateWorkloadResponse>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateWorkloadResponse extends VegaResponse<UpdateWorkloadModel>
{

    public constructor(init?: Partial<UpdateWorkloadResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteWorkloadResponse extends VegaResponse<DeleteWorkloadModel>
{

    public constructor(init?: Partial<DeleteWorkloadResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListWorkloadTypesResponse extends VegaListResponse<ListWorkloadTypesModel>
{

    public constructor(init?: Partial<ListWorkloadTypesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListWorkloadSummariesResponse extends VegaListResponse<ListWorkloadSummariesModel>
{

    public constructor(init?: Partial<ListWorkloadSummariesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListMasterWorkloadSummariesResponse extends VegaListResponse<ListMasterWorkloadSummariesModel>
{

    public constructor(init?: Partial<ListMasterWorkloadSummariesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateSatelliteWorkloadResponse extends VegaResponse<CreateSatelliteWorkloadModel>
{

    public constructor(init?: Partial<CreateSatelliteWorkloadResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListWorkloadResourcesResponse extends VegaResponse<ListWorkloadResourcesModel>
{

    public constructor(init?: Partial<ListWorkloadResourcesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateResourceActionResponse extends VegaListResponse<CreateResourceActionModel>
{

    public constructor(init?: Partial<CreateResourceActionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateResourceEcoResponse extends VegaListResponse<CreateResourceEcoModel>
{

    public constructor(init?: Partial<CreateResourceEcoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteResourceActionResponse extends VegaListResponse<DeleteResourceActionModel>
{

    public constructor(init?: Partial<DeleteResourceActionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteResourceEcoResponse extends VegaListResponse<DeleteResourceEcoModel>
{

    public constructor(init?: Partial<DeleteResourceEcoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetResourceActionResponse extends VegaResponse<GetResourceActionModel>
{

    public constructor(init?: Partial<GetResourceActionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetResourceEcoResponse extends VegaResponse<GetResourceEcoModel>
{

    public constructor(init?: Partial<GetResourceEcoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateUserResponse extends VegaResponse<CreateUserModel>
{

    public constructor(init?: Partial<CreateUserResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetUserResponse extends VegaResponse<GetUserModel>
{

    public constructor(init?: Partial<GetUserResponse>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateUserResponse extends VegaResponse<UpdateUserModel>
{

    public constructor(init?: Partial<UpdateUserResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateUserFeedbackResponse extends VegaResponse<CreateUserFeedbackModel>
{

    public constructor(init?: Partial<CreateUserFeedbackResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateLoginResponse extends VegaResponse<CreateLoginModel>
{

    public constructor(init?: Partial<CreateLoginResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ConfirmCreateLoginResponse extends VegaResponse<ConfirmCreateLoginModel>
{

    public constructor(init?: Partial<ConfirmCreateLoginResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateLoginPasswordActionResponse extends VegaResponse<CreateLoginPasswordActionModel>
{

    public constructor(init?: Partial<CreateLoginPasswordActionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ResendCreateLoginConfirmationResponse extends VegaResponse<ResendCreateLoginConfirmationModel>
{

    public constructor(init?: Partial<ResendCreateLoginConfirmationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SignInResponse extends VegaResponse<SignInModel>
{

    public constructor(init?: Partial<SignInResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateOnBoardUserResponse extends VegaResponse<CreateOnBoardUserModel>
{

    public constructor(init?: Partial<CreateOnBoardUserResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateOnBoardOrganizationResponse extends VegaResponse<CreateOnBoardOrganizationModel>
{

    public constructor(init?: Partial<CreateOnBoardOrganizationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetFromInternetResponse
{
    public testStuff: string;

    public constructor(init?: Partial<GetFromInternetResponse>) { (Object as any).assign(this, init); }
}

export class HelloResponse
{
    public result: string;

    public constructor(init?: Partial<HelloResponse>) { (Object as any).assign(this, init); }
}

export class ListTasksResponse extends VegaListResponse<ListTasksModel>
{

    public constructor(init?: Partial<ListTasksResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetWorkloadCountsResponse extends VegaResponse<GetStatisticBaseModel>
{

    public constructor(init?: Partial<GetWorkloadCountsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetResourceCountsResponse extends VegaResponse<GetStatisticBaseModel>
{

    public constructor(init?: Partial<GetResourceCountsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetNotificationCountsResponse extends VegaResponse<GetStatisticBaseModel>
{

    public constructor(init?: Partial<GetNotificationCountsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetTaskCountsResponse extends VegaResponse<GetStatisticBaseModel>
{

    public constructor(init?: Partial<GetTaskCountsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSpaceCountsResponse extends VegaResponse<GetStatisticBaseModel>
{

    public constructor(init?: Partial<GetSpaceCountsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSpaceResponse extends VegaResponse<GetSpaceModel>
{

    public constructor(init?: Partial<GetSpaceResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateSpaceResponse extends VegaResponse<CreateSpaceModel>
{

    public constructor(init?: Partial<CreateSpaceResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListSpaceTypesResponse extends VegaResponse<ListSpaceTypesModel>
{

    public constructor(init?: Partial<ListSpaceTypesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSpaceStatusResponse extends VegaResponse<GetSpaceStatusModel>
{

    public constructor(init?: Partial<GetSpaceStatusResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateRecurringScheduleResponse extends VegaResponse<OperatingScheduleRecurring>
{

    public constructor(init?: Partial<CreateRecurringScheduleResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetRecurringScheduleResponse extends VegaResponse<OperatingScheduleRecurring>
{

    public constructor(init?: Partial<GetRecurringScheduleResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteRecurringScheduleResponse extends VegaResponse<boolean>
{

    public constructor(init?: Partial<DeleteRecurringScheduleResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetScheduleResponse extends VegaResponse<GetScheduleModel>
{

    public constructor(init?: Partial<GetScheduleResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ExistsScheduleResponse extends VegaResponse<ExistsScheduleModel>
{

    public constructor(init?: Partial<ExistsScheduleResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateScheduleResponse extends VegaResponse<CreateScheduleModel>
{

    public constructor(init?: Partial<CreateScheduleResponse>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateScheduleResponse extends VegaResponse<UpdateScheduleModel>
{

    public constructor(init?: Partial<UpdateScheduleResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteScheduleResponse extends VegaResponse<DeleteScheduleModel>
{

    public constructor(init?: Partial<DeleteScheduleResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GenerateScheduledActionsResponse extends VegaResponse<GenerateScheduledActionsModel>
{

    public constructor(init?: Partial<GenerateScheduledActionsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetOrganizationResponse extends VegaResponse<GetOrganizationModel>
{

    public constructor(init?: Partial<GetOrganizationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateOrganizationResponse extends VegaResponse<CreateOrganizationModel>
{

    public constructor(init?: Partial<CreateOrganizationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateOrganizationResponse extends VegaResponse<UpdateOrganizationModel>
{

    public constructor(init?: Partial<UpdateOrganizationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListComplianceTypesResponse extends VegaResponse<ListComplianceTypesModel>
{

    public constructor(init?: Partial<ListComplianceTypesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListBusinessTypesResponse extends VegaResponse<ListBusinessTypesModel>
{

    public constructor(init?: Partial<ListBusinessTypesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListItOrganizationTypesResponse extends VegaResponse<ListItOrganizationTypesModel>
{

    public constructor(init?: Partial<ListItOrganizationTypesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ValidateOrganizationDomainResponse extends VegaResponse<ValidateOrganizationDomainModel>
{

    public constructor(init?: Partial<ValidateOrganizationDomainResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ValidateOrganizationNameResponse extends VegaResponse<ValidateOrganizationNameModel>
{

    public constructor(init?: Partial<ValidateOrganizationNameResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListOrganizationsResponse extends VegaListResponse<ListOrganizationsModel>
{

    public constructor(init?: Partial<ListOrganizationsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class StartCloudProviderIntegrationsResponse extends VegaResponse<CloudProviderIntegrationWorkflow>
{

    public constructor(init?: Partial<StartCloudProviderIntegrationsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateCloudProviderIntegrationsForEnabledResponse extends VegaResponse<boolean>
{

    public constructor(init?: Partial<UpdateCloudProviderIntegrationsForEnabledResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListCloudProviderIntegrationResponse extends VegaListResponse<CloudProviderIntegrationDefinition>
{

    public constructor(init?: Partial<ListCloudProviderIntegrationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetCostTotalResponse extends VegaResponse<GetCostTotalModel>
{

    public constructor(init?: Partial<GetCostTotalResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSavingsTotalResponse extends VegaResponse<GetSavingsTotalModel>
{

    public constructor(init?: Partial<GetSavingsTotalResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetVarianceTotalResponse extends VegaResponse<GetVarianceTotalModel>
{

    public constructor(init?: Partial<GetVarianceTotalResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetFinancialTotalsResponse extends VegaResponse<GetFinancialTotalsModel>
{

    public constructor(init?: Partial<GetFinancialTotalsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetDashboardUrlResult
{
    public embeddedUrl: string;

    public constructor(init?: Partial<GetDashboardUrlResult>) { (Object as any).assign(this, init); }
}

export class CreateAwsTagResponse extends VegaListResponse<CreateAwsTagModel>
{

    public constructor(init?: Partial<CreateAwsTagResponse>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateAwsTagResponse extends VegaResponse<UpdateAwsTagModel>
{

    public constructor(init?: Partial<UpdateAwsTagResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteAwsTagResponse extends VegaListResponse<DeleteAwsTagModel>
{

    public constructor(init?: Partial<DeleteAwsTagResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetAwsTagResponse extends VegaListResponse<GetAwsTagModel>
{

    public constructor(init?: Partial<GetAwsTagResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateAwsQueueResponse extends VegaResponse<CreateAwsQueueModel>
{

    public constructor(init?: Partial<CreateAwsQueueResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetAwsQueueMessageResponse extends VegaResponse<GetAwsQueueMessageModel>
{

    public constructor(init?: Partial<GetAwsQueueMessageResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteAwsQueueMessageResponse extends VegaResponse<DeleteAwsQueueMessageModel>
{

    public constructor(init?: Partial<DeleteAwsQueueMessageResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListAwsQueueResponse extends VegaResponse<ListAwsQueuesModel>
{

    public constructor(init?: Partial<ListAwsQueueResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteAwsQueueResponse extends VegaResponse<DeleteAwsQueueModel>
{

    public constructor(init?: Partial<DeleteAwsQueueResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateAwsNotificationResponse extends VegaResponse<CreateAwsNotificationModel>
{

    public constructor(init?: Partial<CreateAwsNotificationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateAwsSubscriptionResponse extends VegaResponse<CreateAwsSubscriptionModel>
{

    public constructor(init?: Partial<CreateAwsSubscriptionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteAwsNotificationResponse extends VegaResponse<DeleteAwsNotificationModel>
{

    public constructor(init?: Partial<DeleteAwsNotificationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class PublishAwsMessageResponse extends VegaResponse<PublishAwsMessageModel>
{

    public constructor(init?: Partial<PublishAwsMessageResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListAwsNotificationsResponse extends VegaResponse<ListAwsNotificationsModel>
{

    public constructor(init?: Partial<ListAwsNotificationsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GenerateNextScheduledActionsResponse extends VegaResponse<GenerateNextScheduledActionsModel>
{

    public constructor(init?: Partial<GenerateNextScheduledActionsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListNextScheduledActionsResponse extends VegaListResponse<ListNextScheduledActionsModel>
{

    public constructor(init?: Partial<ListNextScheduledActionsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateScheduledActionResponse extends VegaListResponse<CreateScheduledActionModel>
{

    public constructor(init?: Partial<CreateScheduledActionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateScheduledActionResponse extends VegaResponse<UpdateScheduledActionModel>
{

    public constructor(init?: Partial<UpdateScheduledActionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeleteScheduledActionResponse extends VegaResponse<DeleteScheduledActionModel>
{

    public constructor(init?: Partial<DeleteScheduledActionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetScheduledActionsResponse extends VegaListResponse<GetScheduledActionsModel>
{

    public constructor(init?: Partial<GetScheduledActionsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetGeneratedActionsResponse extends VegaListResponse<GetGeneratedActionsModel>
{

    public constructor(init?: Partial<GetGeneratedActionsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListScheduledActionsResponse extends VegaListResponse<ListScheduledActionsModel>
{

    public constructor(init?: Partial<ListScheduledActionsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ExecuteAwsDatabaseActionResponse extends VegaResponse<ExecuteAwsDatabaseActionModel>
{

    public constructor(init?: Partial<ExecuteAwsDatabaseActionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ExecuteAwsServerActionResponse extends VegaResponse<ExecuteAwsServerActionModel>
{

    public constructor(init?: Partial<ExecuteAwsServerActionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListAwsRegionsResponse extends VegaResponse<ListAwsRegionsModel>
{

    public constructor(init?: Partial<ListAwsRegionsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ListAwsDescribeRegionsResponse
{
    public result: DescribeRegion[];

    public constructor(init?: Partial<ListAwsDescribeRegionsResponse>) { (Object as any).assign(this, init); }
}

export class UpdateQuickStartAmisResponse
{
    public result: string[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UpdateQuickStartAmisResponse>) { (Object as any).assign(this, init); }
}

export class ListAwsDescribeAmisResponse
{
    public result: DescribeAmi[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListAwsDescribeAmisResponse>) { (Object as any).assign(this, init); }
}

export class ListAwsDescribeInstanceTypesResponse
{
    public result: DescribeInstanceType[];

    public constructor(init?: Partial<ListAwsDescribeInstanceTypesResponse>) { (Object as any).assign(this, init); }
}

export class ListAwsDescribeVpcsResponse
{
    public result: DescribeVpc[];

    public constructor(init?: Partial<ListAwsDescribeVpcsResponse>) { (Object as any).assign(this, init); }
}

export class ListAwsDescribeSecurityGroupsResponse
{
    public result: DescribeSecurityGroup[];

    public constructor(init?: Partial<ListAwsDescribeSecurityGroupsResponse>) { (Object as any).assign(this, init); }
}

export class StartAwsActionResponse extends VegaResponse<StartAwsActionModel>
{

    public constructor(init?: Partial<StartAwsActionResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetAwsExecutionModel
{
    public executionArn: string;
    public name: string;
    public startDate: string;
    public stopDate: string;
    public status: string;
    public percentComplete: number;

    public constructor(init?: Partial<GetAwsExecutionModel>) { (Object as any).assign(this, init); }
}

export class CreateAwsActionLogResponse extends VegaResponse<CreateAwsActionLogModel>
{

    public constructor(init?: Partial<CreateAwsActionLogResponse>) { super(init); (Object as any).assign(this, init); }
}

export class RecommendationDto
{
    public id: string;
    public friendlyId: number;
    public workloadId: string;
    public category: string;
    public title: string;
    public description: string;
    public cost: number;
    public estimatedTimeRequired: number;
    public accepted: boolean;
    public status: RecommendationStatus;
    public scheduledType: ScheduleType;
    public scheduledStartDateUtc: string;
    public scheduledEndDateUtc: string;
    public reviewTasks: ReviewTask[];

    public constructor(init?: Partial<RecommendationDto>) { (Object as any).assign(this, init); }
}

export class ListRecommendationsResponse
{
    public results: RecommendationDto[];

    public constructor(init?: Partial<ListRecommendationsResponse>) { (Object as any).assign(this, init); }
}

export class BoolResponse
{
    public result: boolean;

    public constructor(init?: Partial<BoolResponse>) { (Object as any).assign(this, init); }
}

export class ListImplementationTasksResponse
{
    public results: ImplementationTask[];

    public constructor(init?: Partial<ListImplementationTasksResponse>) { (Object as any).assign(this, init); }
}

export class AcceptInviteResponse extends VegaResponse<AcceptInviteModel>
{

    public constructor(init?: Partial<AcceptInviteResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetInviteResponse extends VegaResponse<GetInviteModel>
{

    public constructor(init?: Partial<GetInviteResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SendInviteResponse extends VegaResponse<SendInviteModel>
{

    public constructor(init?: Partial<SendInviteResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetResourcesResponse
{
    public result: Resources[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetResourcesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

/**
* CreateWorkloadAws
*/
// @Route("/workloads/{workloadId}/aws", "POST")
// @Api(Description="CreateWorkloadAws")
export class CreateWorkloadAws implements IReturn<CreateWorkloadAwsResponse>
{
    /**
    * Workload ID
    */
    // @ApiMember(DataType="string", Description="Workload ID", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    /**
    * Master Workload ID
    */
    // @ApiMember(DataType="string", Description="Master Workload ID", Name="MasterWorkloadId", ParameterType="path")
    public masterWorkloadId: string;

    /**
    * Is A Master Workload
    */
    // @ApiMember(DataType="bool", Description="Is A Master Workload", IsRequired=true, Name="IsMasterWorkload", ParameterType="query")
    public isMasterWorkload: boolean;

    /**
    * Is A Linked Workload
    */
    // @ApiMember(DataType="bool", Description="Is A Linked Workload", IsRequired=true, Name="IsLinkedWorkload", ParameterType="query")
    public isLinkedWorkload: boolean;

    /**
    * Aws Account
    */
    // @ApiMember(DataType="string", Description="Aws Account", IsRequired=true, Name="AccountId", ParameterType="query")
    public accountId: string;

    /**
    * The ARN of the IAM security role to access account
    */
    // @ApiMember(DataType="string", Description="The ARN of the IAM security role to access account", Name="AwsRoleArn", ParameterType="query")
    public awsRoleArn: string;

    public constructor(init?: Partial<CreateWorkloadAws>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateWorkloadAwsResponse(); }
    public getTypeName() { return 'CreateWorkloadAws'; }
}

/**
* GetWorkloadTemplateAws
*/
// @Route("/workloads/templates/aws", "GET")
// @Api(Description="GetWorkloadTemplateAws")
export class GetWorkloadTemplateAws implements IReturn<GetWorkloadTemplateAwsResponse>
{
    /**
    * Aws Account
    */
    // @ApiMember(DataType="string", Description="Aws Account", IsRequired=true, Name="AccountId", ParameterType="query")
    public accountId: string;

    public constructor(init?: Partial<GetWorkloadTemplateAws>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetWorkloadTemplateAwsResponse(); }
    public getTypeName() { return 'GetWorkloadTemplateAws'; }
}

/**
* CreateNotifications
*/
// @Route("/notifications/", "POST")
// @Api(Description="CreateNotifications")
export class CreateNotification extends CreatePriorityMessage implements IReturn<CreateNotificationResponse>, IMessageRequest, ICreatePriorityMessage
{

    public constructor(init?: Partial<CreateNotification>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new CreateNotificationResponse(); }
    public getTypeName() { return 'CreateNotification'; }
}

/**
* ListNotifications
*/
// @Route("/notifications", "GET")
// @Api(Description="ListNotifications")
export class ListNotifications extends EntityAndStatusFilterRequest implements IReturn<ListNotificationsResponse>
{

    public constructor(init?: Partial<ListNotifications>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ListNotificationsResponse(); }
    public getTypeName() { return 'ListNotifications'; }
}

/**
* DeleteNotification
*/
// @Route("/notifications/{id}", "DELETE")
// @Api(Description="DeleteNotification")
export class DeleteNotification implements IReturn<DeleteNotificationResponse>
{
    public id: string;

    public constructor(init?: Partial<DeleteNotification>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteNotificationResponse(); }
    public getTypeName() { return 'DeleteNotification'; }
}

/**
* CreateExecutionMessage
*/
// @Route("/actions/executions/messages", "POST")
// @Api(Description="CreateExecutionMessage")
export class CreateExecutionMessage implements IReturn<CreateExecutionMessageResponse>
{
    public organizationId: string;
    public workloadId: string;
    public spaceId: string;
    public userId: string;
    public action: string;
    public actionType: string;
    public name: string;
    public percentageComplete: number;

    public constructor(init?: Partial<CreateExecutionMessage>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateExecutionMessageResponse(); }
    public getTypeName() { return 'CreateExecutionMessage'; }
}

/**
* UpdateExecutionMessage
*/
// @Route("/actions/executions/messages/{id}/", "PUT")
// @Api(Description="UpdateExecutionMessage")
export class UpdateExecutionMessage implements IReturn<UpdateExecutionMessageResponse>
{
    public id: string;
    public percentageComplete: number;

    public constructor(init?: Partial<UpdateExecutionMessage>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateExecutionMessageResponse(); }
    public getTypeName() { return 'UpdateExecutionMessage'; }
}

/**
* ListExecutionMessages
*/
// @Route("/actions/executions/messages/", "GET")
// @Api(Description="ListExecutionMessages")
export class ListExecutionMessages extends EntityAndStatusFilterRequest implements IReturn<ListExecutionMessagesResponse>
{

    public constructor(init?: Partial<ListExecutionMessages>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ListExecutionMessagesResponse(); }
    public getTypeName() { return 'ListExecutionMessages'; }
}

/**
* DeleteExecutionMessage
*/
// @Route("/actions/executions/messages/{id}/", "DELETE")
// @Api(Description="DeleteExecutionMessage")
export class DeleteExecutionMessage implements IReturn<DeleteExecutionMessageResponse>
{
    public id: string;

    public constructor(init?: Partial<DeleteExecutionMessage>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteExecutionMessageResponse(); }
    public getTypeName() { return 'DeleteExecutionMessage'; }
}

/**
* ExecuteAction
*/
// @Route("/actions/executions/{entityId}/demo", "POST")
// @Api(Description="ExecuteAction")
export class ExecuteAction extends BaseActionRequest implements IReturn<ExecuteActionResponse>
{

    public constructor(init?: Partial<ExecuteAction>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ExecuteActionResponse(); }
    public getTypeName() { return 'ExecuteAction'; }
}

/**
* GetWorkload
*/
// @Route("/workloads/{workloadId}", "GET")
// @Api(Description="GetWorkload")
export class GetWorkload extends DateFilterRequest implements IReturn<GetWorkloadResponse>
{
    /**
    * The workload id
    */
    // @ApiMember(DataType="string", Description="The workload id", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    public constructor(init?: Partial<GetWorkload>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetWorkloadResponse(); }
    public getTypeName() { return 'GetWorkload'; }
}

/**
* CreateWorkload
*/
// @Route("/workloads", "POST")
// @Api(Description="CreateWorkload")
export class CreateWorkload implements IReturn<CreateWorkloadResponse>
{
    /**
    * Space SpaceId
    */
    // @ApiMember(DataType="string", Description="Space SpaceId", IsRequired=true, Name="SpaceId", ParameterType="query")
    public spaceId: string;

    /**
    * Master Workload Id
    */
    // @ApiMember(DataType="string", Description="Master Workload Id", Name="MasterWorkloadId", ParameterType="query")
    public masterWorkloadId: string;

    /**
    * Is A Master Workload
    */
    // @ApiMember(DataType="bool", Description="Is A Master Workload", IsRequired=true, Name="IsMasterWorkload", ParameterType="query")
    public isMasterWorkload: boolean;

    /**
    * Is A Linked Workload
    */
    // @ApiMember(DataType="bool", Description="Is A Linked Workload", IsRequired=true, Name="IsLinkedWorkload", ParameterType="query")
    public isLinkedWorkload: boolean;

    /**
    * Name of workload
    */
    // @ApiMember(DataType="string", Description="Name of workload", IsRequired=true, Name="Name", ParameterType="query")
    public name: string;

    /**
    * Description of workload
    */
    // @ApiMember(DataType="string", Description="Description of workload", IsRequired=true, Name="Description", ParameterType="query")
    public description: string;

    /**
    * Type of workload
    */
    // @ApiMember(DataType="number", Description="Type of workload", IsRequired=true, Name="WorkloadTypeId", ParameterType="query")
    public workloadTypeId: number;

    /**
    * Cloud Provider
    */
    // @ApiMember(DataType="string", Description="Cloud Provider", IsRequired=true, Name="CloudProvider", ParameterType="query")
    public cloudProvider: CloudProvider;

    /**
    * Describes how workload was created
    */
    // @ApiMember(DataType="string", Description="Describes how workload was created", IsRequired=true, Name="WorkloadCreationType", ParameterType="query")
    public workloadCreationType: WorkloadCreationType;

    /**
    * Amount of budget
    */
    // @ApiMember(DataType="number", Description="Amount of budget", IsRequired=true, Name="BudgetAmount", ParameterType="query")
    public budgetAmount: number;

    /**
    * Describes the type of periodType the BudgetAmount applies too
    */
    // @ApiMember(DataType="string", Description="Describes the type of periodType the BudgetAmount applies too", IsRequired=true, Name="BudgetPeriodType", ParameterType="query")
    public budgetPeriodType: string;

    public constructor(init?: Partial<CreateWorkload>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateWorkloadResponse(); }
    public getTypeName() { return 'CreateWorkload'; }
}

/**
* UpdateWorkload
*/
// @Route("/workloads/{workloadId}", "PUT")
// @Api(Description="UpdateWorkload")
export class UpdateWorkload implements IReturn<UpdateWorkloadResponse>
{
    /**
    * The workload id
    */
    // @ApiMember(DataType="string", Description="The workload id", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    /**
    * The master workload id
    */
    // @ApiMember(DataType="string", Description="The master workload id", Name="MasterWorkloadId", ParameterType="query")
    public masterWorkloadId: string;

    /**
    * Is A Master Workload
    */
    // @ApiMember(DataType="bool", Description="Is A Master Workload", Name="IsMasterWorkload", ParameterType="query")
    public isMasterWorkload: boolean;

    /**
    * Is A Linked Workload
    */
    // @ApiMember(DataType="bool", Description="Is A Linked Workload", Name="IsLinkedWorkload", ParameterType="query")
    public isLinkedWorkload: boolean;

    /**
    * Name of the workload
    */
    // @ApiMember(DataType="string", Description="Name of the workload", Name="Name", ParameterType="query")
    public name: string;

    /**
    * Description of the workload
    */
    // @ApiMember(DataType="string", Description="Description of the workload", Name="Description", ParameterType="query")
    public description: string;

    /**
    * SpaceId of workload type
    */
    // @ApiMember(DataType="number", Description="SpaceId of workload type", Name="WorkloadTypeId", ParameterType="query")
    public workloadTypeId: number;

    /**
    * Amount of budget
    */
    // @ApiMember(DataType="number", Description="Amount of budget", Name="BudgetAmount", ParameterType="query")
    public budgetAmount: number;

    /**
    * Describes the type of periodType the BudgetAmount applies too
    */
    // @ApiMember(DataType="string", Description="Describes the type of periodType the BudgetAmount applies too", Name="BudgetPeriodType", ParameterType="query")
    public budgetPeriodType: string;

    /**
    * The ARN of the IAM security role to access account
    */
    // @ApiMember(DataType="string", Description="The ARN of the IAM security role to access account", Name="AwsRoleArn", ParameterType="query")
    public awsRoleArn: string;

    public constructor(init?: Partial<UpdateWorkload>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateWorkloadResponse(); }
    public getTypeName() { return 'UpdateWorkload'; }
}

/**
* DeleteWorkload
*/
// @Route("/workloads/{id}", "DELETE")
// @Api(Description="DeleteWorkload")
export class DeleteWorkload implements IReturn<DeleteWorkloadResponse>
{
    /**
    * Workload Id
    */
    // @ApiMember(DataType="string", Description="Workload Id", IsRequired=true, Name="id", ParameterType="path")
    public id: string;

    public constructor(init?: Partial<DeleteWorkload>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteWorkloadResponse(); }
    public getTypeName() { return 'DeleteWorkload'; }
}

/**
* ListWorkloadTypes
*/
// @Route("/workloads/lookups/types/workload", "GET")
// @Api(Description="ListWorkloadTypes")
export class ListWorkloadTypes implements IReturn<ListWorkloadTypesResponse>
{

    public constructor(init?: Partial<ListWorkloadTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListWorkloadTypesResponse(); }
    public getTypeName() { return 'ListWorkloadTypes'; }
}

/**
* ListWorkloadSummaries
*/
// @Route("/workloads/summaries", "GET")
// @Api(Description="ListWorkloadSummaries")
export class ListWorkloadSummaries extends DateFilterRequest implements IReturn<ListWorkloadSummariesResponse>
{

    public constructor(init?: Partial<ListWorkloadSummaries>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ListWorkloadSummariesResponse(); }
    public getTypeName() { return 'ListWorkloadSummaries'; }
}

/**
* ListMasterWorkloadSummaries
*/
// @Route("/workloads/master/summaries", "GET")
// @Api(Description="ListMasterWorkloadSummaries")
export class ListMasterWorkloadSummaries extends DateFilterRequest implements IReturn<ListMasterWorkloadSummariesResponse>
{

    public constructor(init?: Partial<ListMasterWorkloadSummaries>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ListMasterWorkloadSummariesResponse(); }
    public getTypeName() { return 'ListMasterWorkloadSummaries'; }
}

/**
* CreateSatelliteWorkload
*/
// @Route("/workloads/{workloadId}/satellites", "POST")
// @Api(Description="CreateSatelliteWorkload")
export class CreateSatelliteWorkload implements IReturn<CreateSatelliteWorkloadResponse>
{
    /**
    * Parent Workload
    */
    // @ApiMember(DataType="string", Description="Parent Workload", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    /**
    * Name of workload
    */
    // @ApiMember(DataType="string", Description="Name of workload", IsRequired=true, Name="Name", ParameterType="query")
    public name: string;

    /**
    * Description of workload
    */
    // @ApiMember(DataType="string", Description="Description of workload", IsRequired=true, Name="Description", ParameterType="query")
    public description: string;

    /**
    * Type of workload
    */
    // @ApiMember(DataType="number", Description="Type of workload", IsRequired=true, Name="WorkloadTypeId", ParameterType="query")
    public workloadTypeId: number;

    public constructor(init?: Partial<CreateSatelliteWorkload>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateSatelliteWorkloadResponse(); }
    public getTypeName() { return 'CreateSatelliteWorkload'; }
}

/**
* ListWorkloadResources
*/
// @Route("/workloads/{workloadId}/resources", "GET")
// @Api(Description="ListWorkloadResources")
export class ListWorkloadResources implements IReturn<ListWorkloadResourcesResponse>
{
    /**
    * The workload id
    */
    // @ApiMember(DataType="string", Description="The workload id", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    public constructor(init?: Partial<ListWorkloadResources>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListWorkloadResourcesResponse(); }
    public getTypeName() { return 'ListWorkloadResources'; }
}

/**
* CreateResourceAction
*/
// @Route("/workloads/{workloadId}/resources/actions", "POST")
// @Api(Description="CreateResourceAction")
export class CreateResourceAction extends BaseResource implements IReturn<CreateResourceActionResponse>
{
    /**
    * Resource Action Type
    */
    // @ApiMember(DataType="string", Description="Resource Action Type", IsRequired=true, Name="ResourceActionType", ParameterType="query")
    public resourceActionType: ResourceActionType;

    /**
    * AssetId
    */
    // @ApiMember(DataType="array", Description="AssetId", IsRequired=true, Name="AssetIds", ParameterType="query")
    public assetIds: string[];

    public constructor(init?: Partial<CreateResourceAction>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new CreateResourceActionResponse(); }
    public getTypeName() { return 'CreateResourceAction'; }
}

/**
* CreateResourceEco
*/
// @Route("/workloads/{workloadId}/resources/ecos", "POST")
// @Api(Description="CreateResourceEco")
export class CreateResourceEco extends BaseResource implements IReturn<CreateResourceEcoResponse>
{
    /**
    * Resource Eco Type
    */
    // @ApiMember(DataType="string", Description="Resource Eco Type", IsRequired=true, Name="ResourceEcoType", ParameterType="query")
    public resourceEcoType: ResourceEcoType;

    /**
    * AssetId
    */
    // @ApiMember(DataType="array", Description="AssetId", IsRequired=true, Name="AssetIds", ParameterType="query")
    public assetIds: string[];

    /**
    * SatelliteId
    */
    // @ApiMember(DataType="int", Description="SatelliteId", IsRequired=true, Name="SatelliteId", ParameterType="query")
    public satelliteId: number;

    public constructor(init?: Partial<CreateResourceEco>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new CreateResourceEcoResponse(); }
    public getTypeName() { return 'CreateResourceEco'; }
}

/**
* DeleteResourceAction
*/
// @Route("/workloads/{workloadId}/resources/actions", "DELETE")
// @Api(Description="DeleteResourceAction")
export class DeleteResourceAction extends BaseResource implements IReturn<DeleteResourceActionResponse>
{
    /**
    * Resource Action Type
    */
    // @ApiMember(DataType="string", Description="Resource Action Type", IsRequired=true, Name="ResourceActionType", ParameterType="query")
    public resourceActionType: ResourceActionType;

    /**
    * AssetId
    */
    // @ApiMember(DataType="array", Description="AssetId", IsRequired=true, Name="AssetIds", ParameterType="query")
    public assetIds: string[];

    public constructor(init?: Partial<DeleteResourceAction>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new DeleteResourceActionResponse(); }
    public getTypeName() { return 'DeleteResourceAction'; }
}

/**
* DeleteResourceAction
*/
// @Route("/workloads/{workloadId}/resources/ecos", "DELETE")
// @Api(Description="DeleteResourceAction")
export class DeleteResourceEco extends BaseResource implements IReturn<DeleteResourceEcoResponse>
{
    /**
    * Resource Eco Type
    */
    // @ApiMember(DataType="string", Description="Resource Eco Type", IsRequired=true, Name="ResourceEcoType", ParameterType="query")
    public resourceEcoType: ResourceEcoType;

    /**
    * AssetId
    */
    // @ApiMember(DataType="array", Description="AssetId", IsRequired=true, Name="AssetIds", ParameterType="query")
    public assetIds: string[];

    public constructor(init?: Partial<DeleteResourceEco>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new DeleteResourceEcoResponse(); }
    public getTypeName() { return 'DeleteResourceEco'; }
}

/**
* GetResourceAction
*/
// @Route("/workloads/{workloadId}/resources/actions", "GET")
// @Api(Description="GetResourceAction")
export class GetResourceAction extends BaseResource implements IReturn<GetResourceActionResponse>
{
    /**
    * AssetId
    */
    // @ApiMember(DataType="string", Description="AssetId", IsRequired=true, Name="AssetId", ParameterType="query")
    public assetId: string;

    public constructor(init?: Partial<GetResourceAction>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetResourceActionResponse(); }
    public getTypeName() { return 'GetResourceAction'; }
}

/**
* GetResourceEco
*/
// @Route("/workloads/{workloadId}/resources/ecos", "GET")
// @Api(Description="GetResourceEco")
export class GetResourceEco extends BaseResource implements IReturn<GetResourceEcoResponse>
{
    /**
    * AssetId
    */
    // @ApiMember(DataType="string", Description="AssetId", IsRequired=true, Name="AssetId", ParameterType="query")
    public assetId: string;

    public constructor(init?: Partial<GetResourceEco>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetResourceEcoResponse(); }
    public getTypeName() { return 'GetResourceEco'; }
}

/**
* CreateUser
*/
// @Route("/users", "POST")
// @Api(Description="CreateUser")
export class CreateUser implements IReturn<CreateUserResponse>
{
    /**
    * Email
    */
    // @ApiMember(DataType="string", Description="Email", IsRequired=true, Name="Email", ParameterType="query")
    public email: string;

    /**
    * Login assigned by authentication system (AWS Cognito)
    */
    // @ApiMember(DataType="string", Description="Login assigned by authentication system (AWS Cognito)", IsRequired=true, Name="LoginUserId", ParameterType="query")
    public loginUserId: string;

    /**
    * User's Full Name
    */
    // @ApiMember(DataType="string", Description="User\'s Full Name", IsRequired=true, Name="FullName", ParameterType="query")
    public fullName: string;

    /**
    * MobilePhone
    */
    // @ApiMember(DataType="string", Description="MobilePhone", IsRequired=true, Name="MobilePhone", ParameterType="query")
    public mobilePhone: string;

    public constructor(init?: Partial<CreateUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateUserResponse(); }
    public getTypeName() { return 'CreateUser'; }
}

/**
* GetUser
*/
// @Route("/users/{email}", "GET")
// @Api(Description="GetUser")
export class GetUser implements IReturn<GetUserResponse>
{
    /**
    * Email
    */
    // @ApiMember(DataType="path", Description="Email", IsRequired=true, Name="Email", ParameterType="query")
    public email: string;

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUserResponse(); }
    public getTypeName() { return 'GetUser'; }
}

/**
* UpdateUser
*/
// @Route("/users/{email}", "PUT")
// @Api(Description="UpdateUser")
export class UpdateUser implements IReturn<UpdateUserResponse>
{
    /**
    * Email
    */
    // @ApiMember(DataType="path", Description="Email", IsRequired=true, Name="Email", ParameterType="path")
    public email: string;

    /**
    * User's Full Name
    */
    // @ApiMember(DataType="string", Description="User\'s Full Name", Name="FullName", ParameterType="query")
    public fullName: string;

    /**
    * Is First Time Login
    */
    // @ApiMember(DataType="boolean", Description="Is First Time Login", Name="IsFirstTimeLogin", ParameterType="query")
    public isFirstTimeLogin: boolean;

    /**
    * Is On Boarded
    */
    // @ApiMember(DataType="boolean", Description="Is On Boarded", Name="IsOnBoarded", ParameterType="query")
    public isOnBoarded: boolean;

    /**
    * Is On Active
    */
    // @ApiMember(DataType="boolean", Description="Is On Active", Name="IsActive", ParameterType="query")
    public isActive: boolean;

    public constructor(init?: Partial<UpdateUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateUserResponse(); }
    public getTypeName() { return 'UpdateUser'; }
}

/**
* CreateUserFeedback
*/
// @Route("/users/feedback", "POST")
// @Api(Description="CreateUserFeedback")
export class CreateUserFeedback implements IReturn<CreateUserFeedbackResponse>
{
    /**
    * Email
    */
    // @ApiMember(DataType="string", Description="Email", IsRequired=true, Name="Email")
    public email: string;

    /**
    * User's Feedback
    */
    // @ApiMember(DataType="string", Description="User\'s Feedback", IsRequired=true, Name="Feedback")
    public feedback: string;

    /**
    * Subject
    */
    // @ApiMember(DataType="string", Description="Subject", IsRequired=true, Name="Subject")
    public subject: string;

    public constructor(init?: Partial<CreateUserFeedback>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateUserFeedbackResponse(); }
    public getTypeName() { return 'CreateUserFeedback'; }
}

/**
* CreateLogin
*/
// @Route("/users/logins", "POST")
// @Api(Description="CreateLogin")
export class CreateLogin implements IReturn<CreateLoginResponse>
{
    /**
    * Users email
    */
    // @ApiMember(DataType="string", Description="Users email", IsRequired=true, Name="Email", ParameterType="query")
    public email: string;

    /**
    * Preferred method to receive confirmation code
    */
    // @ApiMember(DataType="string", Description="Preferred method to receive confirmation code", IsRequired=true, Name="PassCodeDeliveryMedium", ParameterType="query")
    public passCodeDeliveryMedium: PassCodeDeliveryMedium;

    /**
    * Users email
    */
    // @ApiMember(DataType="string", Description="Users email", IsRequired=true, Name="Password", ParameterType="query")
    public password: string;

    /**
    * Users mobile phone
    */
    // @ApiMember(DataType="string", Description="Users mobile phone", IsRequired=true, Name="MobilePhone", ParameterType="query")
    public mobilePhone: string;

    /**
    * With Auto Confirm
    */
    // @ApiMember(DataType="boolean", Description="With Auto Confirm", IsRequired=true, Name="WithAuthConfirm", ParameterType="query")
    public withAuthConfirm: boolean;

    public constructor(init?: Partial<CreateLogin>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateLoginResponse(); }
    public getTypeName() { return 'CreateLogin'; }
}

/**
* ConfirmCreateLogin
*/
// @Route("/users/logins/{email}/confirm", "POST")
// @Api(Description="ConfirmCreateLogin")
export class ConfirmCreateLogin implements IReturn<ConfirmCreateLoginResponse>
{
    /**
    * Email
    */
    // @ApiMember(DataType="string", Description="Email", IsRequired=true, Name="Email", ParameterType="path")
    public email: string;

    /**
    * Passcode
    */
    // @ApiMember(DataType="string", Description="Passcode", IsRequired=true, Name="Passcode", ParameterType="query")
    public passCode: string;

    public constructor(init?: Partial<ConfirmCreateLogin>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConfirmCreateLoginResponse(); }
    public getTypeName() { return 'ConfirmCreateLogin'; }
}

/**
* CreateLoginPasswordAction
*/
// @Route("/users/logins/{email}/passwords", "POST")
// @Api(Description="CreateLoginPasswordAction")
export class CreateLoginPasswordAction implements IReturn<CreateLoginPasswordActionResponse>
{
    /**
    * Email
    */
    // @ApiMember(DataType="string", Description="Email", IsRequired=true, Name="Email", ParameterType="path")
    public email: string;

    /**
    * Password actions.  Valid values Forgot,ConfirmForgot,Change
    */
    // @ApiMember(DataType="string", Description="Password actions.  Valid values Forgot,ConfirmForgot,Change", IsRequired=true, Name="PasswordAction", ParameterType="query")
    public passwordAction: PasswordAction;

    /**
    * Required for PasswordAction.ConfirmForgot
    */
    // @ApiMember(DataType="string", Description="Required for PasswordAction.ConfirmForgot", Name="PassCode", ParameterType="query")
    public passCode: string;

    /**
    * Required for PasswordAction.Change
    */
    // @ApiMember(DataType="string", Description="Required for PasswordAction.Change", Name="CurrentPassword", ParameterType="query")
    public currentPassword: string;

    /**
    * Required for PasswordAction.Change and PasswordAction.ConfirmForgot
    */
    // @ApiMember(DataType="string", Description="Required for PasswordAction.Change and PasswordAction.ConfirmForgot", Name="ProposedPassword", ParameterType="query")
    public proposedPassword: string;

    public constructor(init?: Partial<CreateLoginPasswordAction>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateLoginPasswordActionResponse(); }
    public getTypeName() { return 'CreateLoginPasswordAction'; }
}

/**
* ResendCreateLoginConfirmation
*/
// @Route("/users/logins/{email}/resend", "POST")
// @Api(Description="ResendCreateLoginConfirmation")
export class ResendCreateLoginConfirmation implements IReturn<ResendCreateLoginConfirmationResponse>
{
    /**
    * Users email
    */
    // @ApiMember(DataType="string", Description="Users email", IsRequired=true, Name="Email", ParameterType="path")
    public email: string;

    public constructor(init?: Partial<ResendCreateLoginConfirmation>) { (Object as any).assign(this, init); }
    public createResponse() { return new ResendCreateLoginConfirmationResponse(); }
    public getTypeName() { return 'ResendCreateLoginConfirmation'; }
}

// @Route("/users/{email}/signin", "POST")
export class SignIn implements IReturn<SignInResponse>
{
    /**
    * Email
    */
    // @ApiMember(DataType="string", Description="Email", IsRequired=true, Name="Email", ParameterType="path")
    public email: string;

    /**
    * Email
    */
    // @ApiMember(DataType="string", Description="Email", IsRequired=true, Name="Password", ParameterType="query")
    public password: string;

    public constructor(init?: Partial<SignIn>) { (Object as any).assign(this, init); }
    public createResponse() { return new SignInResponse(); }
    public getTypeName() { return 'SignIn'; }
}

/**
* CreateOnBoardUser
*/
// @Route("/users/onboard/users", "POST")
// @Api(Description="CreateOnBoardUser")
export class CreateOnBoardUser implements IReturn<CreateOnBoardUserResponse>
{
    /**
    * Email
    */
    // @ApiMember(DataType="string", Description="Email", IsRequired=true, Name="Email", ParameterType="query")
    public email: string;

    /**
    * User's Full Name
    */
    // @ApiMember(DataType="string", Description="User\'s Full Name", IsRequired=true, Name="UserFullName", ParameterType="query")
    public userFullName: string;

    /**
    * MobilePhone
    */
    // @ApiMember(DataType="string", Description="MobilePhone", IsRequired=true, Name="MobilePhone", ParameterType="query")
    public mobilePhone: string;

    /**
    * Preferred method to receive confirmation code
    */
    // @ApiMember(DataType="string", Description="Preferred method to receive confirmation code", IsRequired=true, Name="PassCodeDeliveryMedium", ParameterType="query")
    public passCodeDeliveryMedium: PassCodeDeliveryMedium;

    /**
    * Users email
    */
    // @ApiMember(DataType="string", Description="Users email", IsRequired=true, Name="Password", ParameterType="query")
    public password: string;

    /**
    * Accept Terms
    */
    // @ApiMember(DataType="boolean", Description="Accept Terms", IsRequired=true, Name="AcceptTerms", ParameterType="query")
    public acceptTerms: boolean;

    public constructor(init?: Partial<CreateOnBoardUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateOnBoardUserResponse(); }
    public getTypeName() { return 'CreateOnBoardUser'; }
}

/**
* CreateOnBoardOrganization
*/
// @Route("/users/onboard/organizations", "POST")
// @Api(Description="CreateOnBoardOrganization")
export class CreateOnBoardOrganization implements IReturn<CreateOnBoardOrganizationResponse>
{
    /**
    * BusinessTypeName of the organization
    */
    // @ApiMember(DataType="string", Description="BusinessTypeName of the organization", IsRequired=true, Name="OrganizationName", ParameterType="query")
    public organizationName: string;

    /**
    * BusinessTypeId
    */
    // @ApiMember(DataType="integer", Description="BusinessTypeId", IsRequired=true, Name="BusinessTypeId", ParameterType="query")
    public businessTypeId: number;

    /**
    * ItOrganizationTypeId
    */
    // @ApiMember(DataType="integer", Description="ItOrganizationTypeId", IsRequired=true, Name="ItOrganizationTypeId", ParameterType="query")
    public itOrganizationTypeId: number;

    /**
    * Other organization type
    */
    // @ApiMember(DataType="string", Description="Other organization type", Name="ItOrganizationTypeOther", ParameterType="query")
    public itOrganizationTypeOther: string;

    /**
    * Compliance type ids
    */
    // @ApiMember(DataType="array", Description="Compliance type ids", Name="ComplianceTypes", ParameterType="query")
    public complianceTypes: number[];

    /**
    * SubDomain
    */
    // @ApiMember(DataType="string", Description="SubDomain", Name="SubDomain", ParameterType="query")
    public subDomain: string;

    /**
    * CreditCardNumber
    */
    // @ApiMember(DataType="string", Description="CreditCardNumber", IsRequired=true, Name="CreditCardNumber", ParameterType="query")
    public creditCardNumber: string;

    /**
    * CreditCardExpMonth
    */
    // @ApiMember(DataType="integer", Description="CreditCardExpMonth", IsRequired=true, Name="CreditCardExpMonth", ParameterType="query")
    public creditCardExpMonth: number;

    /**
    * CreditCardExpYear
    */
    // @ApiMember(DataType="integer", Description="CreditCardExpYear", IsRequired=true, Name="CreditCardExpYear", ParameterType="query")
    public creditCardExpYear: number;

    /**
    * CreditCardCvc
    */
    // @ApiMember(DataType="string", Description="CreditCardCvc", IsRequired=true, Name="CreditCardCvc", ParameterType="query")
    public creditCardCvc: string;

    /**
    * Name
    */
    // @ApiMember(DataType="string", Description="Name", IsRequired=true, Name="CreditCardName", ParameterType="query")
    public creditCardName: string;

    /**
    * CreditCardAddressCity
    */
    // @ApiMember(DataType="string", Description="CreditCardAddressCity", IsRequired=true, Name="CreditCardAddressCity", ParameterType="query")
    public creditCardAddressCity: string;

    /**
    * AddressCountry
    */
    // @ApiMember(DataType="string", Description="AddressCountry", Name="AddressCountry", ParameterType="query")
    public addressCountry: string;

    /**
    * CreditCardAddressLine1
    */
    // @ApiMember(DataType="string", Description="CreditCardAddressLine1", IsRequired=true, Name="CreditCardAddressLine1", ParameterType="query")
    public creditCardAddressLine1: string;

    /**
    * CreditCardAddressLine2
    */
    // @ApiMember(DataType="string", Description="CreditCardAddressLine2", Name="CreditCardAddressLine2", ParameterType="query")
    public creditCardAddressLine2: string;

    /**
    * CreditCardAddressState
    */
    // @ApiMember(DataType="string", Description="CreditCardAddressState", IsRequired=true, Name="CreditCardAddressState", ParameterType="query")
    public creditCardAddressState: string;

    /**
    * CreditCardAddressZip
    */
    // @ApiMember(DataType="string", Description="CreditCardAddressZip", IsRequired=true, Name="CreditCardAddressZip", ParameterType="query")
    public creditCardAddressZip: string;

    public constructor(init?: Partial<CreateOnBoardOrganization>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateOnBoardOrganizationResponse(); }
    public getTypeName() { return 'CreateOnBoardOrganization'; }
}

/**
* GetFromInternet
*/
// @Route("/tests/internet", "GET")
// @Api(Description="GetFromInternet")
export class GetFromInternet implements IReturn<GetFromInternetResponse>
{

    public constructor(init?: Partial<GetFromInternet>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFromInternetResponse(); }
    public getTypeName() { return 'GetFromInternet'; }
}

/**
* Hello
*/
// @Route("/tests/hello/{name}", "GET")
// @Api(Description="Hello")
export class Hello implements IReturn<HelloResponse>
{
    public name: string;

    public constructor(init?: Partial<Hello>) { (Object as any).assign(this, init); }
    public createResponse() { return new HelloResponse(); }
    public getTypeName() { return 'Hello'; }
}

/**
* DtoGen
*/
// @Route("/tests/dto", "GET")
// @Api(Description="DtoGen")
export class DtoExporter
{
    public startActionExecutionConfiguration: StartActionExecutionConfiguration;

    public constructor(init?: Partial<DtoExporter>) { (Object as any).assign(this, init); }
}

// @Route("/tasks", "GET")
export class ListTasks extends EntityAndStatusFilterRequest implements IReturn<ListTasksResponse>
{

    public constructor(init?: Partial<ListTasks>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ListTasksResponse(); }
    public getTypeName() { return 'ListTasks'; }
}

/**
* GetWorkloadCounts
*/
// @Route("/statistics/counts/workloads", "GET")
// @Api(Description="GetWorkloadCounts")
export class GetWorkloadCounts extends EntityAndDateFilterRequest implements IReturn<GetWorkloadCountsResponse>
{

    public constructor(init?: Partial<GetWorkloadCounts>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetWorkloadCountsResponse(); }
    public getTypeName() { return 'GetWorkloadCounts'; }
}

/**
* GetResourceCounts
*/
// @Route("/statistics/counts/resources", "GET")
// @Api(Description="GetResourceCounts")
export class GetResourceCounts extends EntityAndDateFilterRequest implements IReturn<GetResourceCountsResponse>
{

    public constructor(init?: Partial<GetResourceCounts>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetResourceCountsResponse(); }
    public getTypeName() { return 'GetResourceCounts'; }
}

/**
* GetNotificationCounts
*/
// @Route("/statistics/counts/notifications", "GET")
// @Api(Description="GetNotificationCounts")
export class GetNotificationCounts extends EntityAndDateFilterRequest implements IReturn<GetNotificationCountsResponse>
{

    public constructor(init?: Partial<GetNotificationCounts>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetNotificationCountsResponse(); }
    public getTypeName() { return 'GetNotificationCounts'; }
}

/**
* GetTaskCounts
*/
// @Route("/statistics/counts/tasks", "GET")
// @Api(Description="GetTaskCounts")
export class GetTaskCounts extends EntityAndDateFilterRequest implements IReturn<GetTaskCountsResponse>
{

    public constructor(init?: Partial<GetTaskCounts>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetTaskCountsResponse(); }
    public getTypeName() { return 'GetTaskCounts'; }
}

/**
* GetSpaceCounts
*/
// @Route("/statistics/counts/tasks", "GET")
// @Api(Description="GetSpaceCounts")
export class GetSpaceCounts extends EntityAndDateFilterRequest implements IReturn<GetSpaceCountsResponse>
{

    public constructor(init?: Partial<GetSpaceCounts>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSpaceCountsResponse(); }
    public getTypeName() { return 'GetSpaceCounts'; }
}

/**
* GetSpace
*/
// @Route("/spaces/{spaceId}", "GET")
// @Api(Description="GetSpace")
export class GetSpace implements IReturn<GetSpaceResponse>
{
    /**
    * Guid of space
    */
    // @ApiMember(DataType="string", Description="Guid of space", IsRequired=true, Name="SpaceId", ParameterType="path")
    public spaceId: string;

    public constructor(init?: Partial<GetSpace>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSpaceResponse(); }
    public getTypeName() { return 'GetSpace'; }
}

/**
* UpdateSpace
*/
// @Route("/spaces/{spaceId}", "PUT")
// @Api(Description="UpdateSpace")
export class UpdateSpace implements IReturn<GetSpaceResponse>
{
    /**
    * UserGuid of space
    */
    // @ApiMember(DataType="string", Description="UserGuid of space", IsRequired=true, Name="SpaceId", ParameterType="path")
    public spaceId: string;

    /**
    * Name of the space
    */
    // @ApiMember(DataType="string", Description="Name of the space", Name="Name", ParameterType="query")
    public name: string;

    /**
    * Description of space
    */
    // @ApiMember(DataType="string", Description="Description of space", Name="Description", ParameterType="query")
    public description: string;

    /**
    * ID of space type
    */
    // @ApiMember(DataType="string", Description="ID of space type", IsRequired=true, Name="SpaceTypeId", ParameterType="query")
    public spaceTypeId: number;

    /**
    * Amount of budget
    */
    // @ApiMember(DataType="number", Description="Amount of budget", Name="BudgetAmount", ParameterType="query")
    public budgetAmount: number;

    /**
    * Describes the type of periodType the BudgetAmount applies too
    */
    // @ApiMember(DataType="string", Description="Describes the type of periodType the BudgetAmount applies too", Name="BudgetPeriodType", ParameterType="query")
    public budgetPeriodType: string;

    public constructor(init?: Partial<UpdateSpace>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSpaceResponse(); }
    public getTypeName() { return 'UpdateSpace'; }
}

/**
* CreateSpace
*/
// @Route("/spaces", "POST")
// @Api(Description="CreateSpace")
export class CreateSpace implements IReturn<CreateSpaceResponse>
{
    /**
    * Name of the space
    */
    // @ApiMember(DataType="string", Description="Name of the space", IsRequired=true, Name="Name", ParameterType="query")
    public name: string;

    /**
    * Description of space
    */
    // @ApiMember(DataType="string", Description="Description of space", IsRequired=true, Name="Description", ParameterType="query")
    public description: string;

    /**
    * OrganizationId
    */
    // @ApiMember(DataType="string", Description="OrganizationId", Name="OrganizationId", ParameterType="query")
    public organizationId: string;

    /**
    * ID of space type
    */
    // @ApiMember(DataType="string", Description="ID of space type", IsRequired=true, Name="SpaceTypeId", ParameterType="query")
    public spaceTypeId: number;

    /**
    * Type of space container
    */
    // @ApiMember(DataType="string", Description="Type of space container", IsRequired=true, Name="SpaceContainerType", ParameterType="query")
    public spaceContainerType: SpaceContainerType;

    /**
    * Amount of budget
    */
    // @ApiMember(DataType="number", Description="Amount of budget", IsRequired=true, Name="BudgetAmount", ParameterType="query")
    public budgetAmount: number;

    /**
    * Describes the type of periodType the BudgetAmount applies too
    */
    // @ApiMember(DataType="string", Description="Describes the type of periodType the BudgetAmount applies too", IsRequired=true, Name="BudgetPeriodType", ParameterType="query")
    public budgetPeriodType: BudgetPeriodType;

    /**
    * Is default space
    */
    // @ApiMember(DataType="boolean", Description="Is default space", IsRequired=true, Name="IsDefaultSpace", ParameterType="query")
    public isDefaultSpace: boolean;

    public constructor(init?: Partial<CreateSpace>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateSpaceResponse(); }
    public getTypeName() { return 'CreateSpace'; }
}

/**
* ListSpaceTypes
*/
// @Route("/spaces/lookups/types/space", "GET")
// @Api(Description="ListSpaceTypes")
export class ListSpaceTypes implements IReturn<ListSpaceTypesResponse>
{

    public constructor(init?: Partial<ListSpaceTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListSpaceTypesResponse(); }
    public getTypeName() { return 'ListSpaceTypes'; }
}

/**
* GetSpaceStatus
*/
// @Route("/spaces/{spaceId}/status", "GET")
// @Api(Description="GetSpaceStatus")
export class GetSpaceStatus implements IReturn<GetSpaceStatusResponse>
{
    /**
    * Guid of space
    */
    // @ApiMember(DataType="string", Description="Guid of space", IsRequired=true, Name="SpaceId", ParameterType="path")
    public spaceId: string;

    public constructor(init?: Partial<GetSpaceStatus>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSpaceStatusResponse(); }
    public getTypeName() { return 'GetSpaceStatus'; }
}

/**
* CreateRecurringSchedule
*/
// @Route("/schedules/{workloadId}/recurring", "POST")
// @Api(Description="CreateRecurringSchedule")
export class CreateRecurringSchedule implements IReturn<CreateRecurringScheduleResponse>
{
    /**
    * WorkloadId
    */
    // @ApiMember(DataType="string", Description="WorkloadId", Name="WorkloadId", ParameterType="query")
    public workloadId: string;

    /**
    * EntriesUtc
    */
    // @ApiMember(DataType="string", Description="EntriesUtc", Name="EntriesUtc", ParameterType="query")
    public entriesUtc: OperatingScheduleRecurringEntry[];

    public constructor(init?: Partial<CreateRecurringSchedule>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateRecurringScheduleResponse(); }
    public getTypeName() { return 'CreateRecurringSchedule'; }
}

/**
* GetRecurringSchedule
*/
// @Route("/schedules/{workloadId}/recurring", "GET")
// @Api(Description="GetRecurringSchedule")
export class GetRecurringSchedule implements IReturn<GetRecurringScheduleResponse>
{
    /**
    * WorkloadId
    */
    // @ApiMember(DataType="string", Description="WorkloadId", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    public constructor(init?: Partial<GetRecurringSchedule>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetRecurringScheduleResponse(); }
    public getTypeName() { return 'GetRecurringSchedule'; }
}

/**
* DeleteRecurringSchedule
*/
// @Route("/schedules/{workloadId}/recurring", "DELETE")
// @Api(Description="DeleteRecurringSchedule")
export class DeleteRecurringSchedule implements IReturn<DeleteRecurringScheduleResponse>
{
    /**
    * WorkloadId
    */
    // @ApiMember(DataType="string", Description="WorkloadId", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    public constructor(init?: Partial<DeleteRecurringSchedule>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteRecurringScheduleResponse(); }
    public getTypeName() { return 'DeleteRecurringSchedule'; }
}

/**
* GetSchedule
*/
// @Route("/schedules/", "GET")
// @Route("/workloads/{entityId}/schedules/", "GET")
// @Route("/spaces/{entityId}/schedules/", "GET")
// @Api(Description="GetSchedule")
export class GetSchedule implements IReturn<GetScheduleResponse>
{
    /**
    * Get Schedule by schedule id
    */
    // @ApiMember(DataType="string", Description="Get Schedule by schedule id", Name="ScheduleId", ParameterType="query")
    public scheduleId: string;

    /**
    * Get schedule for workload
    */
    // @ApiMember(DataType="string", Description="Get schedule for workload", Name="EntityId", ParameterType="query")
    public entityId: string;

    public constructor(init?: Partial<GetSchedule>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetScheduleResponse(); }
    public getTypeName() { return 'GetSchedule'; }
}

/**
* ExistsSchedule
*/
// @Route("/schedules/exists", "GET")
// @Route("/workloads/{entityId}/schedules/exists", "GET")
// @Route("/spaces/{entityId}/schedules/exists", "GET")
// @Api(Description="ExistsSchedule")
export class ExistsSchedule implements IReturn<ExistsScheduleResponse>
{
    /**
    * Get schedule for entityId
    */
    // @ApiMember(DataType="string", Description="Get schedule for entityId", Name="EntityId", ParameterType="query")
    public entityId: string;

    public constructor(init?: Partial<ExistsSchedule>) { (Object as any).assign(this, init); }
    public createResponse() { return new ExistsScheduleResponse(); }
    public getTypeName() { return 'ExistsSchedule'; }
}

/**
* CreateSchedule
*/
// @Route("/schedules", "POST")
// @Api(Description="CreateSchedule")
export class CreateSchedule implements IReturn<CreateScheduleResponse>
{
    /**
    * Entity (Workload or Space) to create schedule for
    */
    // @ApiMember(DataType="string", Description="Entity (Workload or Space) to create schedule for", IsRequired=true, Name="EntityId", ParameterType="query")
    public entityId: string;

    /**
    * Entity (Workload or Space) to create schedule for
    */
    // @ApiMember(DataType="array", Description="Entity (Workload or Space) to create schedule for", IsRequired=true, Name="DaysOfWeek", ParameterType="query")
    public daysOfWeek: DayOfWeek[];

    /**
    * Time schedule should start
    */
    // @ApiMember(DataType="number", Description="Time schedule should start", IsRequired=true, Name="StartTimeInSeconds", ParameterType="query")
    public startTimeInSeconds: number;

    /**
    * Time schedule should stop
    */
    // @ApiMember(DataType="number", Description="Time schedule should stop", IsRequired=true, Name="StopTimeInSeconds", ParameterType="query")
    public stopTimeInSeconds: number;

    /**
    * Action type to perform when schedule starts
    */
    // @ApiMember(DataType="string", Description="Action type to perform when schedule starts", IsRequired=true, Name="ActionOnStart", ParameterType="query")
    public actionOnStart: ActionType;

    /**
    * Action type to perform when schedule stops
    */
    // @ApiMember(DataType="string", Description="Action type to perform when schedule stops", IsRequired=true, Name="ActionOnStop", ParameterType="query")
    public actionOnStop: ActionType;

    public constructor(init?: Partial<CreateSchedule>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateScheduleResponse(); }
    public getTypeName() { return 'CreateSchedule'; }
}

/**
* UpdateSchedule
*/
// @Route("/schedules/{scheduleId}", "PUT")
// @Api(Description="UpdateSchedule")
export class UpdateSchedule implements IReturn<UpdateScheduleResponse>
{
    /**
    * Schedule id
    */
    // @ApiMember(DataType="string", Description="Schedule id", IsRequired=true, Name="ScheduleId", ParameterType="path")
    public scheduleId: string;

    /**
    * Entity (Workload or Space) to create schedule for
    */
    // @ApiMember(DataType="array", Description="Entity (Workload or Space) to create schedule for", IsRequired=true, Name="DaysOfWeek", ParameterType="query")
    public daysOfWeek: DayOfWeek[];

    /**
    * Time schedule should start
    */
    // @ApiMember(DataType="number", Description="Time schedule should start", IsRequired=true, Name="StartTimeInSeconds", ParameterType="query")
    public startTimeInSeconds: number;

    /**
    * Time schedule should stop
    */
    // @ApiMember(DataType="number", Description="Time schedule should stop", IsRequired=true, Name="StopTimeInSeconds", ParameterType="query")
    public stopTimeInSeconds: number;

    /**
    * Action type to perform when schedule starts
    */
    // @ApiMember(DataType="string", Description="Action type to perform when schedule starts", IsRequired=true, Name="ActionOnStart", ParameterType="query")
    public actionOnStart: ActionType;

    /**
    * Action type to perform when schedule stops
    */
    // @ApiMember(DataType="string", Description="Action type to perform when schedule stops", IsRequired=true, Name="ActionOnStop", ParameterType="query")
    public actionOnStop: ActionType;

    public constructor(init?: Partial<UpdateSchedule>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateScheduleResponse(); }
    public getTypeName() { return 'UpdateSchedule'; }
}

/**
* DeleteSchedule
*/
// @Route("/schedules/{scheduleId}/", "DELETE")
// @Api(Description="DeleteSchedule")
export class DeleteSchedule implements IReturn<DeleteScheduleResponse>
{
    /**
    * Schedule Id
    */
    // @ApiMember(DataType="string", Description="Schedule Id", IsRequired=true, Name="ScheduleId", ParameterType="path")
    public scheduleId: string;

    public constructor(init?: Partial<DeleteSchedule>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteScheduleResponse(); }
    public getTypeName() { return 'DeleteSchedule'; }
}

/**
* GenerateActionSchedules
*/
// @Route("/schedules/generators/", "GET")
// @Api(Description="GenerateActionSchedules")
export class GenerateScheduledActions implements IReturn<GenerateScheduledActionsResponse>
{
    public entityId: string;
    public startDateTimeUtc: string;
    public endDateTimeUtc: string;

    public constructor(init?: Partial<GenerateScheduledActions>) { (Object as any).assign(this, init); }
    public createResponse() { return new GenerateScheduledActionsResponse(); }
    public getTypeName() { return 'GenerateScheduledActions'; }
}

/**
* GetOrganization
*/
// @Route("/organizations", "GET")
// @Api(Description="GetOrganization")
export class GetOrganization implements IReturn<GetOrganizationResponse>
{

    public constructor(init?: Partial<GetOrganization>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetOrganizationResponse(); }
    public getTypeName() { return 'GetOrganization'; }
}

/**
* CreateOrganization
*/
// @Route("/organizations", "POST")
// @Api(Description="CreateOrganization")
export class CreateOrganization implements IReturn<CreateOrganizationResponse>
{
    /**
    * BusinessTypeName of the organization
    */
    // @ApiMember(DataType="string", Description="BusinessTypeName of the organization", IsRequired=true, Name="OrganizationName", ParameterType="query")
    public organizationName: string;

    /**
    * BusinessTypeId
    */
    // @ApiMember(DataType="integer", Description="BusinessTypeId", IsRequired=true, Name="BusinessTypeId", ParameterType="query")
    public businessTypeId: number;

    /**
    * ItOrganizationTypeId
    */
    // @ApiMember(DataType="integer", Description="ItOrganizationTypeId", IsRequired=true, Name="ItOrganizationTypeId", ParameterType="query")
    public itOrganizationTypeId: number;

    /**
    * Other organization type
    */
    // @ApiMember(DataType="string", Description="Other organization type", Name="ItOrganizationTypeOther", ParameterType="query")
    public itOrganizationTypeOther: string;

    /**
    * Compliance type ids
    */
    // @ApiMember(DataType="array", Description="Compliance type ids", Name="ComplianceTypes", ParameterType="query")
    public complianceTypes: number[];

    /**
    * SubDomain
    */
    // @ApiMember(DataType="string", Description="SubDomain", IsRequired=true, Name="SubDomain", ParameterType="query")
    public subDomain: string;

    /**
    * User UserGuid Guid
    */
    // @ApiMember(DataType="string", Description="User UserGuid Guid", IsRequired=true, Name="UserId", ParameterType="query")
    public userId: string;

    public constructor(init?: Partial<CreateOrganization>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateOrganizationResponse(); }
    public getTypeName() { return 'CreateOrganization'; }
}

/**
* UpdateOrganization
*/
// @Route("/organizations", "PUT")
// @Api(Description="UpdateOrganization")
export class UpdateOrganization implements IReturn<UpdateOrganizationResponse>
{
    /**
    * BusinessTypeName of the organization
    */
    // @ApiMember(DataType="string", Description="BusinessTypeName of the organization", IsRequired=true, Name="OrganizationName", ParameterType="query")
    public organizationName: string;

    public constructor(init?: Partial<UpdateOrganization>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateOrganizationResponse(); }
    public getTypeName() { return 'UpdateOrganization'; }
}

/**
* ListComplianceTypes
*/
// @Route("/organizations/lookups/types/compliance", "GET")
// @Api(Description="ListComplianceTypes")
export class ListComplianceTypes implements IReturn<ListComplianceTypesResponse>
{

    public constructor(init?: Partial<ListComplianceTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListComplianceTypesResponse(); }
    public getTypeName() { return 'ListComplianceTypes'; }
}

/**
* ListBusinessTypes
*/
// @Route("/organizations/lookups/types/business", "GET")
// @Api(Description="ListBusinessTypes")
export class ListBusinessTypes implements IReturn<ListBusinessTypesResponse>
{

    public constructor(init?: Partial<ListBusinessTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListBusinessTypesResponse(); }
    public getTypeName() { return 'ListBusinessTypes'; }
}

/**
* ListItOrganizationTypes
*/
// @Route("/organizations/lookups/types/itorganization", "GET")
// @Api(Description="ListItOrganizationTypes")
export class ListItOrganizationTypes implements IReturn<ListItOrganizationTypesResponse>
{

    public constructor(init?: Partial<ListItOrganizationTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListItOrganizationTypesResponse(); }
    public getTypeName() { return 'ListItOrganizationTypes'; }
}

/**
*  ValidateOrganizationDomain
*/
// @Route("/organizations/validations/domain/name/{name}", "GET")
// @Api(Description=" ValidateOrganizationDomain")
export class ValidateOrganizationDomain implements IReturn<ValidateOrganizationDomainResponse>
{
    /**
    * The domain name to validate
    */
    // @ApiMember(DataType="string", Description="The domain name to validate", IsRequired=true, Name="DomainName", ParameterType="path")
    public name: string;

    public constructor(init?: Partial<ValidateOrganizationDomain>) { (Object as any).assign(this, init); }
    public createResponse() { return new ValidateOrganizationDomainResponse(); }
    public getTypeName() { return 'ValidateOrganizationDomain'; }
}

/**
* ValidateOrganizationName
*/
// @Route("/organizations/validations/name/{name}", "GET")
// @Api(Description="ValidateOrganizationName")
export class ValidateOrganizationName implements IReturn<ValidateOrganizationNameResponse>
{
    /**
    * The name to validate
    */
    // @ApiMember(DataType="string", Description="The name to validate", IsRequired=true, Name="BusinessTypeName", ParameterType="path")
    public name: string;

    public constructor(init?: Partial<ValidateOrganizationName>) { (Object as any).assign(this, init); }
    public createResponse() { return new ValidateOrganizationNameResponse(); }
    public getTypeName() { return 'ValidateOrganizationName'; }
}

/**
* ListOrganizations
*/
// @Route("/organizations/list", "GET")
// @Api(Description="ListOrganizations")
export class ListOrganizations implements IReturn<ListOrganizationsResponse>
{

    public constructor(init?: Partial<ListOrganizations>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListOrganizationsResponse(); }
    public getTypeName() { return 'ListOrganizations'; }
}

/**
* StartCloudProviderIntegrations
*/
// @Route("/integrations/cloudproviders/worfklows", "POST")
// @Api(Description="StartCloudProviderIntegrations")
export class StartCloudProviderIntegrations implements IReturn<StartCloudProviderIntegrationsResponse>
{
    public workloadId: string;
    public cloudProvider: CloudProvider;
    public token: string;
    public integrationDetails: CloudProviderIntegrationDetail[];

    public constructor(init?: Partial<StartCloudProviderIntegrations>) { (Object as any).assign(this, init); }
    public createResponse() { return new StartCloudProviderIntegrationsResponse(); }
    public getTypeName() { return 'StartCloudProviderIntegrations'; }
}

/**
* UpdateCloudProviderIntegrationsForEnabled
*/
// @Route("/integrations/cloudproviders/worfklows", "PUT")
// @Api(Description="UpdateCloudProviderIntegrationsForEnabled")
export class UpdateCloudProviderIntegrationsForEnabled implements IReturn<UpdateCloudProviderIntegrationsForEnabledResponse>
{
    public workloadId: string;

    public constructor(init?: Partial<UpdateCloudProviderIntegrationsForEnabled>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateCloudProviderIntegrationsForEnabledResponse(); }
    public getTypeName() { return 'UpdateCloudProviderIntegrationsForEnabled'; }
}

/**
* ListCloudProviderIntegration
*/
// @Route("/integrations/cloudproviders/", "GET")
// @Route("/workloads/integrations/cloudproviders/{id}", "GET")
// @Api(Description="ListCloudProviderIntegration")
export class ListCloudProviderIntegration implements IReturn<ListCloudProviderIntegrationResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="WorkloadId", ParameterType="query")
    public workloadId: string;

    public constructor(init?: Partial<ListCloudProviderIntegration>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListCloudProviderIntegrationResponse(); }
    public getTypeName() { return 'ListCloudProviderIntegration'; }
}

/**
* GetCostTotal
*/
// @Route("/financial/costs/totals", "GET")
// @Api(Description="GetCostTotal")
export class GetCostTotal extends EntityAndDateFilterRequest implements IReturn<GetCostTotalResponse>
{

    public constructor(init?: Partial<GetCostTotal>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetCostTotalResponse(); }
    public getTypeName() { return 'GetCostTotal'; }
}

/**
* GetBudgetTotal
*/
// @Route("/financial/budgets/totals", "GET")
// @Api(Description="GetBudgetTotal")
export class GetBudgetTotal extends EntityAndDateFilterRequest implements IReturn<GetCostTotalResponse>
{

    public constructor(init?: Partial<GetBudgetTotal>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetCostTotalResponse(); }
    public getTypeName() { return 'GetBudgetTotal'; }
}

/**
* GetSavingsTotal
*/
// @Route("/financial/savings/totals", "GET")
// @Api(Description="GetSavingsTotal")
export class GetSavingsTotal extends EntityAndDateFilterRequest implements IReturn<GetSavingsTotalResponse>
{

    public constructor(init?: Partial<GetSavingsTotal>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSavingsTotalResponse(); }
    public getTypeName() { return 'GetSavingsTotal'; }
}

/**
* GetVarianceTotal
*/
// @Route("/financial/variances/totals", "GET")
// @Api(Description="GetVarianceTotal")
export class GetVarianceTotal extends EntityAndDateFilterRequest implements IReturn<GetVarianceTotalResponse>
{

    public constructor(init?: Partial<GetVarianceTotal>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVarianceTotalResponse(); }
    public getTypeName() { return 'GetVarianceTotal'; }
}

/**
* GetFinancialTotals
*/
// @Route("/financial/totals", "GET")
// @Api(Description="GetFinancialTotals")
export class GetFinancialTotals implements IReturn<GetFinancialTotalsResponse>
{
    /**
    * Financial period type
    */
    // @ApiMember(DataType="string", Description="Financial period type", IsRequired=true, Name="FinancialPeriodType")
    public financialPeriodType: FinancialPeriodType;

    /**
    * Specifies the ID of entity filter will be applied for
    */
    // @ApiMember(DataType="string", Description="Specifies the ID of entity filter will be applied for", Name="EntityId")
    public entityId: string;

    public constructor(init?: Partial<GetFinancialTotals>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFinancialTotalsResponse(); }
    public getTypeName() { return 'GetFinancialTotals'; }
}

// @Route("/dashboard", "GET")
export class GetDashboardUrl implements IReturn<GetDashboardUrlResult>
{

    public constructor(init?: Partial<GetDashboardUrl>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDashboardUrlResult(); }
    public getTypeName() { return 'GetDashboardUrl'; }
}

/**
* CreateAwsTag
*/
// @Route("/aws/tags", "POST")
// @Api(Description="CreateAwsTag")
export class CreateAwsTag extends BaseAwsTagRequest implements IReturn<CreateAwsTagResponse>
{

    public constructor(init?: Partial<CreateAwsTag>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new CreateAwsTagResponse(); }
    public getTypeName() { return 'CreateAwsTag'; }
}

/**
* UpdateAwsTag
*/
// @Route("/aws/tags", "PUT")
// @Api(Description="UpdateAwsTag")
export class UpdateAwsTag extends BaseAwsTagRequest implements IReturn<UpdateAwsTagResponse>
{

    public constructor(init?: Partial<UpdateAwsTag>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new UpdateAwsTagResponse(); }
    public getTypeName() { return 'UpdateAwsTag'; }
}

/**
* DeleteAwsTag
*/
// @Route("/aws/tags", "DELETE")
// @Api(Description="DeleteAwsTag")
export class DeleteAwsTag extends BaseAwsTagRequest implements IReturn<DeleteAwsTagResponse>
{

    public constructor(init?: Partial<DeleteAwsTag>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new DeleteAwsTagResponse(); }
    public getTypeName() { return 'DeleteAwsTag'; }
}

/**
* GetAwsTag
*/
// @Route("/aws/tags", "GET")
// @Api(Description="GetAwsTag")
export class GetAwsTags implements IReturn<GetAwsTagResponse>
{
    /**
    * WorkloadId
    */
    // @ApiMember(DataType="string", Description="WorkloadId", IsRequired=true, Name="WorkloadId", ParameterType="query")
    public workloadId: string;

    /**
    * Region
    */
    // @ApiMember(DataType="string", Description="Region", IsRequired=true, Name="Region", ParameterType="query")
    public region: string;

    /**
    * AwsResourceType
    */
    // @ApiMember(DataType="string", Description="AwsResourceType", IsRequired=true, Name="AwsResourceType", ParameterType="query")
    public awsResourceType: AwsResourceType;

    /**
    * ResourceId
    */
    // @ApiMember(DataType="string", Description="ResourceId", IsRequired=true, Name="ResourceId", ParameterType="query")
    public resourceId: string;

    public constructor(init?: Partial<GetAwsTags>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAwsTagResponse(); }
    public getTypeName() { return 'GetAwsTags'; }
}

/**
* CreateAwsQueue
*/
// @Route("/aws/queues", "POST")
// @Api(Description="CreateAwsQueue")
export class CreateAwsQueue implements IReturn<CreateAwsQueueResponse>
{
    public name: string;

    public constructor(init?: Partial<CreateAwsQueue>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateAwsQueueResponse(); }
    public getTypeName() { return 'CreateAwsQueue'; }
}

/**
* GetAwsQueueMessage
*/
// @Route("/aws/queues/{queueUrl}/messages", "GET")
// @Api(Description="GetAwsQueueMessage")
export class GetAwsQueueMessage implements IReturn<GetAwsQueueMessageResponse>
{
    public queueUrl: string;

    public constructor(init?: Partial<GetAwsQueueMessage>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAwsQueueMessageResponse(); }
    public getTypeName() { return 'GetAwsQueueMessage'; }
}

/**
* DeleteAwsQueueMessage
*/
// @Route("/aws/queues/{queueUrl}/messages", "DELETE")
// @Api(Description="DeleteAwsQueueMessage")
export class DeleteAwsQueueMessage implements IReturn<DeleteAwsQueueMessageResponse>
{
    public queueUrl: string;
    public receiptHandler: string;

    public constructor(init?: Partial<DeleteAwsQueueMessage>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteAwsQueueMessageResponse(); }
    public getTypeName() { return 'DeleteAwsQueueMessage'; }
}

/**
* ListAwsQueues
*/
// @Route("/aws/queues", "GET")
// @Api(Description="ListAwsQueues")
export class ListAwsQueues implements IReturn<ListAwsQueueResponse>
{

    public constructor(init?: Partial<ListAwsQueues>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListAwsQueueResponse(); }
    public getTypeName() { return 'ListAwsQueues'; }
}

/**
* DeleteAwsQueue
*/
// @Route("/aws/queues", "DELETE")
// @Api(Description="DeleteAwsQueue")
export class DeleteAwsQueue implements IReturn<DeleteAwsQueueResponse>
{
    // @ApiMember(DataType="array", Name="QueueUrls", ParameterType="query")
    public queueUrls: string[];

    public constructor(init?: Partial<DeleteAwsQueue>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteAwsQueueResponse(); }
    public getTypeName() { return 'DeleteAwsQueue'; }
}

/**
* CreateAwsNotifications
*/
// @Route("/aws/notifications", "POST")
// @Api(Description="CreateAwsNotifications")
export class CreateAwsNotification implements IReturn<CreateAwsNotificationResponse>
{
    public region: string;
    public name: string;

    public constructor(init?: Partial<CreateAwsNotification>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateAwsNotificationResponse(); }
    public getTypeName() { return 'CreateAwsNotification'; }
}

/**
* CreateAwsSubscription
*/
// @Route("/aws/notifications/{topicArn}/subscriptions", "POST")
// @Api(Description="CreateAwsSubscription")
export class CreateAwsSubscription implements IReturn<CreateAwsSubscriptionResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="TopicArn", ParameterType="path")
    public topicArn: string;

    // @ApiMember(DataType="string", IsRequired=true, Name="Protocol", ParameterType="query")
    public protocol: string;

    // @ApiMember(DataType="string", IsRequired=true, Name="Endpoint", ParameterType="query")
    public endpoint: string;

    public constructor(init?: Partial<CreateAwsSubscription>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateAwsSubscriptionResponse(); }
    public getTypeName() { return 'CreateAwsSubscription'; }
}

/**
* DeleteAwsSubscription
*/
// @Route("/aws/subscriptions/{subscriptionArn}", "DELETE")
// @Api(Description="DeleteAwsSubscription")
export class DeleteAwsSubscription implements IReturn<CreateAwsSubscriptionResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="SubscriptionArn", ParameterType="path")
    public subscriptionArn: string;

    public constructor(init?: Partial<DeleteAwsSubscription>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateAwsSubscriptionResponse(); }
    public getTypeName() { return 'DeleteAwsSubscription'; }
}

/**
* DeleteAwsNotifications
*/
// @Route("/aws/notifications/", "DELETE")
// @Api(Description="DeleteAwsNotifications")
export class DeleteAwsNotification implements IReturn<DeleteAwsNotificationResponse>
{
    // @ApiMember(DataType="array", Name="TopicArns", ParameterType="query")
    public topicArns: string[];

    public constructor(init?: Partial<DeleteAwsNotification>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteAwsNotificationResponse(); }
    public getTypeName() { return 'DeleteAwsNotification'; }
}

/**
* PublishAwsMessage
*/
// @Route("/aws/notifications/{topicArn}/publish", "POST")
// @Api(Description="PublishAwsMessage")
export class PublishAwsMessage implements IReturn<PublishAwsMessageResponse>
{
    public topicArn: string;
    public message: string;
    public subject: string;

    public constructor(init?: Partial<PublishAwsMessage>) { (Object as any).assign(this, init); }
    public createResponse() { return new PublishAwsMessageResponse(); }
    public getTypeName() { return 'PublishAwsMessage'; }
}

/**
* ListAwsNotifications
*/
// @Route("/aws/notifications", "GET")
// @Api(Description="ListAwsNotifications")
export class ListAwsNotifications implements IReturn<ListAwsNotificationsResponse>
{
    public region: string;
    public nextToken: string;

    public constructor(init?: Partial<ListAwsNotifications>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListAwsNotificationsResponse(); }
    public getTypeName() { return 'ListAwsNotifications'; }
}

/**
* GenerateNextScheduledActions
*/
// @Route("/actions/schedules/generate", "POST")
// @Api(Description="GenerateNextScheduledActions")
export class GenerateNextScheduledActions implements IReturn<GenerateNextScheduledActionsResponse>
{

    public constructor(init?: Partial<GenerateNextScheduledActions>) { (Object as any).assign(this, init); }
    public createResponse() { return new GenerateNextScheduledActionsResponse(); }
    public getTypeName() { return 'GenerateNextScheduledActions'; }
}

/**
* ListNextScheduledAction
*/
// @Route("/actions/schedules/next", "GET")
// @Api(Description="ListNextScheduledAction")
export class ListNextScheduledActions implements IReturn<ListNextScheduledActionsResponse>
{
    public entityId: string;

    public constructor(init?: Partial<ListNextScheduledActions>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListNextScheduledActionsResponse(); }
    public getTypeName() { return 'ListNextScheduledActions'; }
}

/**
* CreateScheduledAction
*/
// @Route("/actions/schedules/{entityId}", "POST")
// @Api(Description="CreateScheduledAction")
export class CreateScheduledAction extends BaseActionRequest implements IReturn<CreateScheduledActionResponse>
{
    /**
    * Wait until to execute
    */
    // @ApiMember(DataType="string", Description="Wait until to execute", Format="date-time", Name="WaitUntil", ParameterType="query")
    public waitUntil: string;

    /**
    * Wait until to execute
    */
    // @ApiMember(DataType="boolean", Description="Wait until to execute", IsRequired=true, Name="IsImmediate", ParameterType="query")
    public isImmediate: boolean;

    /**
    * Recommendation Id
    */
    // @ApiMember(DataType="string", Description="Recommendation Id", Name="RecommendationId", ParameterType="query")
    public recommendationId: string;

    public constructor(init?: Partial<CreateScheduledAction>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new CreateScheduledActionResponse(); }
    public getTypeName() { return 'CreateScheduledAction'; }
}

/**
* UpdateScheduledAction
*/
// @Route("/actions/schedules/{id}", "PUT")
// @Api(Description="UpdateScheduledAction")
export class UpdateScheduledAction implements IReturn<UpdateScheduledActionResponse>
{
    public id: string;
    public status: ScheduledActionStatus;

    public constructor(init?: Partial<UpdateScheduledAction>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateScheduledActionResponse(); }
    public getTypeName() { return 'UpdateScheduledAction'; }
}

/**
* DeleteScheduledAction
*/
// @Route("/actions/schedules/{id}", "DELETE")
// @Api(Description="DeleteScheduledAction")
export class DeleteScheduledAction implements IReturn<DeleteScheduledActionResponse>
{
    public id: string;

    public constructor(init?: Partial<DeleteScheduledAction>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteScheduledActionResponse(); }
    public getTypeName() { return 'DeleteScheduledAction'; }
}

/**
* GetScheduledActions
*/
// @Route("/actions/vega/scheduled", "GET")
// @Api(Description="GetScheduledActions")
export class GetScheduledActions implements IReturn<GetScheduledActionsResponse>
{
    /**
    * Scheduled through data
    */
    // @ApiMember(DataType="string", Description="Scheduled through data", IsRequired=true, Name="ScheduledThroughDateUtc", ParameterType="query")
    public scheduledThroughDateUtc: string;

    public constructor(init?: Partial<GetScheduledActions>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetScheduledActionsResponse(); }
    public getTypeName() { return 'GetScheduledActions'; }
}

/**
* GetGeneratedActions
*/
// @Route("/actions/vega/generated", "GET")
// @Api(Description="GetGeneratedActions")
export class GetGeneratedActions implements IReturn<GetGeneratedActionsResponse>
{
    /**
    * Scheduled through data
    */
    // @ApiMember(DataType="string", Description="Scheduled through data", IsRequired=true, Name="ScheduledThroughDateUtc", ParameterType="query")
    public scheduledThroughDateUtc: string;

    public constructor(init?: Partial<GetGeneratedActions>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGeneratedActionsResponse(); }
    public getTypeName() { return 'GetGeneratedActions'; }
}

/**
* ListScheduledAction
*/
// @Route("/actions/schedules", "GET")
// @Api(Description="ListScheduledAction")
export class ListScheduledActions implements IReturn<ListScheduledActionsResponse>
{
    public entityId: string;
    public dateFrom: string;
    public dateTo: string;

    public constructor(init?: Partial<ListScheduledActions>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListScheduledActionsResponse(); }
    public getTypeName() { return 'ListScheduledActions'; }
}

/**
* ExecuteAwsDatabaseAction
*/
// @Route("/actions/aws/databases/{workloadId}", "POST")
// @Api(Description="ExecuteAwsDatabaseAction")
export class ExecuteAwsDatabaseAction extends BaseAwsActionRegionRequest implements IReturn<ExecuteAwsDatabaseActionResponse>
{

    public constructor(init?: Partial<ExecuteAwsDatabaseAction>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ExecuteAwsDatabaseActionResponse(); }
    public getTypeName() { return 'ExecuteAwsDatabaseAction'; }
}

/**
* ExecuteAwsServerAction
*/
// @Route("/actions/aws/servers/{workloadId}", "POST")
// @Api(Description="ExecuteAwsServerAction")
export class ExecuteAwsServerAction extends BaseAwsActionRegionRequest implements IReturn<ExecuteAwsServerActionResponse>
{

    public constructor(init?: Partial<ExecuteAwsServerAction>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ExecuteAwsServerActionResponse(); }
    public getTypeName() { return 'ExecuteAwsServerAction'; }
}

/**
* ListAwsRegions
*/
// @Route("/actions/aws/regions", "GET")
// @Api(Description="ListAwsRegions")
export class ListAwsRegions implements IReturn<ListAwsRegionsResponse>
{

    public constructor(init?: Partial<ListAwsRegions>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListAwsRegionsResponse(); }
    public getTypeName() { return 'ListAwsRegions'; }
}

/**
* ListAwsDescribeRegions
*/
// @Route("/actions/aws/describeregions/{workloadId}", "GET")
// @Api(Description="ListAwsDescribeRegions")
export class ListAwsDescribeRegions implements IReturn<ListAwsDescribeRegionsResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    public constructor(init?: Partial<ListAwsDescribeRegions>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListAwsDescribeRegionsResponse(); }
    public getTypeName() { return 'ListAwsDescribeRegions'; }
}

/**
* UpdateQuickStartAmis
*/
// @Route("/actions/aws/updatequickstartamis/{workloadId}", "PUT")
// @Api(Description="UpdateQuickStartAmis")
export class UpdateQuickStartAmis implements IReturn<UpdateQuickStartAmisResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    // @ApiMember(DataType="string", IsRequired=true, Name="InputText", ParameterType="form")
    public inputText: string;

    public constructor(init?: Partial<UpdateQuickStartAmis>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateQuickStartAmisResponse(); }
    public getTypeName() { return 'UpdateQuickStartAmis'; }
}

/**
* ListAwsDescribeAmis
*/
// @Route("/actions/aws/describeamis/{workloadId}", "GET")
// @Api(Description="ListAwsDescribeAmis")
export class ListAwsDescribeAmis implements IReturn<ListAwsDescribeAmisResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    // @ApiMember(DataType="string", IsRequired=true, Name="Region", ParameterType="query")
    public region: string;

    // @ApiMember(DataType="string", Name="OperatingSystem", ParameterType="query")
    public operatingSystem: string;

    public constructor(init?: Partial<ListAwsDescribeAmis>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListAwsDescribeAmisResponse(); }
    public getTypeName() { return 'ListAwsDescribeAmis'; }
}

/**
* ListAwsDescribeInstanceTypes
*/
// @Route("/actions/aws/describeinstancetypes/{workloadId}", "GET")
// @Api(Description="ListAwsDescribeInstanceTypes")
export class ListAwsDescribeInstanceTypes implements IReturn<ListAwsDescribeInstanceTypesResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    // @ApiMember(DataType="string", IsRequired=true, Name="Region", ParameterType="query")
    public region: string;

    public constructor(init?: Partial<ListAwsDescribeInstanceTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListAwsDescribeInstanceTypesResponse(); }
    public getTypeName() { return 'ListAwsDescribeInstanceTypes'; }
}

/**
* ListAwsDescribeVpcs
*/
// @Route("/actions/aws/describevpcs/{workloadId}", "GET")
// @Api(Description="ListAwsDescribeVpcs")
export class ListAwsDescribeVpcs implements IReturn<ListAwsDescribeVpcsResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    // @ApiMember(DataType="string", IsRequired=true, Name="Region", ParameterType="query")
    public region: string;

    public constructor(init?: Partial<ListAwsDescribeVpcs>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListAwsDescribeVpcsResponse(); }
    public getTypeName() { return 'ListAwsDescribeVpcs'; }
}

/**
* ListAwsDescribeSecurityGroups
*/
// @Route("/actions/aws/describesecuritygroups/{workloadId}", "GET")
// @Api(Description="ListAwsDescribeSecurityGroups")
export class ListAwsDescribeSecurityGroups implements IReturn<ListAwsDescribeSecurityGroupsResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    // @ApiMember(DataType="string", IsRequired=true, Name="Region", ParameterType="query")
    public region: string;

    // @ApiMember(DataType="string", Name="Vpc", ParameterType="query")
    public vpc: string;

    public constructor(init?: Partial<ListAwsDescribeSecurityGroups>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListAwsDescribeSecurityGroupsResponse(); }
    public getTypeName() { return 'ListAwsDescribeSecurityGroups'; }
}

/**
* StartAwsAction
*/
// @Route("/actions/aws/{workloadId}", "POST")
// @Api(Description="StartAwsAction")
export class StartAwsAction implements IReturn<StartAwsActionResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    // @ApiMember(DataType="string", IsRequired=true, Name="StepFunctionArn", ParameterType="query")
    public stepFunctionArn: string;

    /**
    * Wait until to execute
    */
    // @ApiMember(DataType="string", Description="Wait until to execute", Format="date-time", Name="WaitUntil", ParameterType="query")
    public waitUntil: string;

    /**
    * Security Token
    */
    // @ApiMember(DataType="string", Description="Security Token", Format="date-time", Name="Token", ParameterType="query")
    public token: string;

    /**
    * Scheduled action id
    */
    // @ApiMember(DataType="string", Description="Scheduled action id", IsRequired=true, Name="ScheduledActionId", ParameterType="query")
    public scheduledActionId: string;

    public constructor(init?: Partial<StartAwsAction>) { (Object as any).assign(this, init); }
    public createResponse() { return new StartAwsActionResponse(); }
    public getTypeName() { return 'StartAwsAction'; }
}

/**
* GetAwsExecution
*/
// @Route("/actions/aws/executions/{executionArn}", "GET")
// @Api(Description="GetAwsExecution")
export class GetAwsExecution implements IReturn<GetAwsExecutionModel>
{
    public executionArn: string;

    public constructor(init?: Partial<GetAwsExecution>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAwsExecutionModel(); }
    public getTypeName() { return 'GetAwsExecution'; }
}

/**
* CreateAwsActionLog
*/
// @Route("/actions/aws/logs/{workloadId}", "POST")
// @Api(Description="CreateAwsActionLog")
export class CreateAwsActionLog implements IReturn<CreateAwsActionLogResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    // @ApiMember(DataType="string", IsRequired=true, Name="ExecutionArn", ParameterType="query")
    public executionArn: string;

    // @ApiMember(DataType="string", IsRequired=true, Name="ActionType", ParameterType="query")
    public actionType: ActionType;

    // @ApiMember(DataType="string", IsRequired=true, Name="ResourceType", ParameterType="query")
    public resourceType: string;

    // @ApiMember(DataType="string", Name="Servers", ParameterType="query")
    public servers: ServerModel[];

    // @ApiMember(DataType="string", Name="Databases", ParameterType="query")
    public databases: DatabaseModel[];

    public constructor(init?: Partial<CreateAwsActionLog>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateAwsActionLogResponse(); }
    public getTypeName() { return 'CreateAwsActionLog'; }
}

/**
* ListRecommendations
*/
// @Route("/recommendations", "GET")
// @Api(Description="ListRecommendations")
export class ListRecommendations implements IReturn<ListRecommendationsResponse>
{

    public constructor(init?: Partial<ListRecommendations>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListRecommendationsResponse(); }
    public getTypeName() { return 'ListRecommendations'; }
}

/**
* GetRecommendation
*/
// @Route("/recommendations/{id}", "GET")
// @Api(Description="GetRecommendation")
export class GetRecommendation implements IReturn<RecommendationDto>
{
    public id: string;

    public constructor(init?: Partial<GetRecommendation>) { (Object as any).assign(this, init); }
    public createResponse() { return new RecommendationDto(); }
    public getTypeName() { return 'GetRecommendation'; }
}

/**
* DeclineRecommendation
*/
// @Route("/recommendations/{id}", "DELETE")
// @Api(Description="DeclineRecommendation")
export class DeclineRecommendation implements IReturn<BoolResponse>
{
    public id: string;

    public constructor(init?: Partial<DeclineRecommendation>) { (Object as any).assign(this, init); }
    public createResponse() { return new BoolResponse(); }
    public getTypeName() { return 'DeclineRecommendation'; }
}

/**
* UpdateRecommendationSchedule
*/
// @Route("/recommendations/{id}/schedule", "PUT")
// @Api(Description="UpdateRecommendationSchedule")
export class UpdateRecommendationSchedule implements IReturn<BoolResponse>
{
    public id: string;
    public scheduledType: ScheduleType;
    public scheduledStartDateUtc: string;
    public scheduledEndDateUtc: string;

    public constructor(init?: Partial<UpdateRecommendationSchedule>) { (Object as any).assign(this, init); }
    public createResponse() { return new BoolResponse(); }
    public getTypeName() { return 'UpdateRecommendationSchedule'; }
}

/**
* CancelRecommendationSchedule
*/
// @Route("/recommendations/{id}/schedule", "DELETE")
// @Api(Description="CancelRecommendationSchedule")
export class CancelRecommendationSchedule implements IReturn<BoolResponse>
{
    public id: string;

    public constructor(init?: Partial<CancelRecommendationSchedule>) { (Object as any).assign(this, init); }
    public createResponse() { return new BoolResponse(); }
    public getTypeName() { return 'CancelRecommendationSchedule'; }
}

/**
* ListImplementationTasks
*/
// @Route("/recommendations/{id}/implementationtasks", "GET")
// @Api(Description="ListImplementationTasks")
export class ListImplementationTasks implements IReturn<ListImplementationTasksResponse>
{
    public id: string;

    public constructor(init?: Partial<ListImplementationTasks>) { (Object as any).assign(this, init); }
    public createResponse() { return new ListImplementationTasksResponse(); }
    public getTypeName() { return 'ListImplementationTasks'; }
}

/**
* UpdateRecommendationStatus
*/
// @Route("/recommendations/{id}/status", "PUT")
// @Api(Description="UpdateRecommendationStatus")
export class UpdateRecommendationStatus implements IReturn<BoolResponse>
{
    public id: string;
    public status: RecommendationStatus;

    public constructor(init?: Partial<UpdateRecommendationStatus>) { (Object as any).assign(this, init); }
    public createResponse() { return new BoolResponse(); }
    public getTypeName() { return 'UpdateRecommendationStatus'; }
}

/**
* StartRecommendationAccept
*/
// @Route("/recommendations/{id}/workflows/startaccept", "POST")
// @Api(Description="StartRecommendationAccept")
export class StartRecommendationAccept implements IReturn<BoolResponse>
{
    public id: string;

    public constructor(init?: Partial<StartRecommendationAccept>) { (Object as any).assign(this, init); }
    public createResponse() { return new BoolResponse(); }
    public getTypeName() { return 'StartRecommendationAccept'; }
}

/**
* StartRecommendationAcceptWithSchedule
*/
// @Route("/recommendations/{id}/workflows/startacceptwithschedule", "POST")
// @Api(Description="StartRecommendationAcceptWithSchedule")
export class StartRecommendationAcceptWithSchedule implements IReturn<BoolResponse>
{
    public id: string;
    public scheduledType: ScheduleType;
    public scheduledStartDateUtc: string;
    public scheduledEndDateUtc: string;

    public constructor(init?: Partial<StartRecommendationAcceptWithSchedule>) { (Object as any).assign(this, init); }
    public createResponse() { return new BoolResponse(); }
    public getTypeName() { return 'StartRecommendationAcceptWithSchedule'; }
}

/**
* StartRecommendationCancel
*/
// @Route("/recommendations/{id}/workflows/startcancel", "POST")
// @Api(Description="StartRecommendationCancel")
export class StartRecommendationCancel implements IReturn<BoolResponse>
{
    public id: string;

    public constructor(init?: Partial<StartRecommendationCancel>) { (Object as any).assign(this, init); }
    public createResponse() { return new BoolResponse(); }
    public getTypeName() { return 'StartRecommendationCancel'; }
}

/**
* AcceptInvite
*/
// @Route("/invites/{email}", "PUT")
// @Api(Description="AcceptInvite")
export class AcceptInvite implements IReturn<AcceptInviteResponse>
{
    /**
    * Email
    */
    // @ApiMember(DataType="string", Description="Email", IsRequired=true, Name="Email", ParameterType="query")
    public email: string;

    /**
    * InviteCode
    */
    // @ApiMember(DataType="string", Description="InviteCode", IsRequired=true, Name="InviteCode")
    public inviteCode: string;

    public constructor(init?: Partial<AcceptInvite>) { (Object as any).assign(this, init); }
    public createResponse() { return new AcceptInviteResponse(); }
    public getTypeName() { return 'AcceptInvite'; }
}

/**
* GetInvite
*/
// @Route("/invites", "GET")
// @Api(Description="GetInvite")
export class GetInvite implements IReturn<GetInviteResponse>
{

    public constructor(init?: Partial<GetInvite>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInviteResponse(); }
    public getTypeName() { return 'GetInvite'; }
}

/**
* SendInvite
*/
// @Route("/invites/{email}", "POST")
// @Api(Description="SendInvite")
export class SendInvite implements IReturn<SendInviteResponse>
{
    /**
    * Email
    */
    // @ApiMember(DataType="string", Description="Email", IsRequired=true, Name="Email", ParameterType="query")
    public email: string;

    public constructor(init?: Partial<SendInvite>) { (Object as any).assign(this, init); }
    public createResponse() { return new SendInviteResponse(); }
    public getTypeName() { return 'SendInvite'; }
}

/**
* GetResources
*/
// @Route("/discovery/{workloadId}", "GET")
// @Api(Description="GetResources")
export class GetResources implements IReturn<GetResourcesResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="WorkloadId", ParameterType="path")
    public workloadId: string;

    // @ApiMember(DataType="string", Name="Region", ParameterType="query")
    public region: string;

    // @ApiMember(DataType="string", Name="ResourceType", ParameterType="query")
    public resourceType: ResourceType;

    public constructor(init?: Partial<GetResources>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetResourcesResponse(); }
    public getTypeName() { return 'GetResources'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @Route("/authenticate")
// @Route("/authenticate/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe: boolean;

    // @DataMember(Order=8)
    public continue: string;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

