import React, { FC } from 'react';
import scss from './InlineBadge.module.scss';

export interface InlineBadgeProps {
    value: number;
    className?: string;
    danger?: boolean;
}

export const InlineBadge: FC<InlineBadgeProps> = (props) => (
    <>{props.value > 0 && <span className={`${scss.badge} ${props.danger && scss.danger} ${!!props.className && props.className}`}>{props.value}</span>}</>
);
