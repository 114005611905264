import React from 'react';
import OnboardStep1 from './OnboardStep1';
import OnboardStep2 from './OnboardStep2';
import OnboardStep3 from './OnboardStep3';

export interface OnboardLandingProps {}

const OnboardLanding: React.FC<OnboardLandingProps> = (props) => {
    const [currentStep, setCurrentStep] = React.useState(1);
    const [onboardModel, setOnboardModel] = React.useState({
        OrganizationName: '',
        businessTypeId: '',
        ItOrganizationTypeId: '',
        itOrganizationTypeOther: '',
        complianceTypes: [],
        CreditCardNumber: '',
        CreditCardExpMonth: '',
        CreditCardExpYear: '',
        CreditCardCvc: '',
        CreditCardName: '',
        CreditCardAddressCity: '',
        CreditCardAddressLine1: '',
        CreditCardAddressLine2: '',
        CreditCardAddressState: '',
        CreditCardAddressZip: '',
    });

    const backClicked = () => setCurrentStep(currentStep - 1);

    const nextClicked = () => setCurrentStep(currentStep + 1);

    const updateOnboardModel = (newValues) => {
        setOnboardModel({ ...onboardModel, ...newValues });
    };

    const components = [
        <OnboardStep1 model={onboardModel} modelChange={updateOnboardModel} next={nextClicked} />,
        <OnboardStep2 model={onboardModel} modelChange={updateOnboardModel} back={backClicked} next={nextClicked} />,
        <OnboardStep3 model={onboardModel} modelChange={updateOnboardModel} back={backClicked} next={nextClicked} />,
        // <OnboardStep4 model={onboardModel} modelChange={updateOnboardModel} back={backClicked} />
    ];

    const currentComponent = components[currentStep - 1];

    return currentComponent;
};

export default OnboardLanding;
