import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export interface IAdminUsersLanding extends RouteComponentProps<any> {}

const AdminUsersLanding: React.FC<IAdminUsersLanding> = (props) => {
    return (
        <>
            <p>This administration feature is coming soon.</p>
        </>
    );
};

export default AdminUsersLanding;
