import React from 'react';
import './Scheduler.scss';
import AvailableTimes from 'react-available-times';
import AutoSizer from 'react-virtualized-auto-sizer';

export type Times = {
    start: number;
    end: number;
};

export interface ISchedulerProps {
    height?: number;
    disabled?: boolean;
    initialSelections?: Array<Times>;
    onChangeSchedule?: (selectedTimes: Array<Times>) => void;
}

const Scheduler: React.FC<ISchedulerProps> = (props: ISchedulerProps) => {
    return (
        <AutoSizer>
            {({ height, width }) => {
                return (
                    <div style={{ height: height - 50, width: width - 25 }} className={props.disabled ? 'schedulerDisabled' : 'scheduler'}>
                        <div className={'legend'}>
                            <span className={'runningDot'}></span>
                            Running
                            <span className={'stoppedDot'}></span>
                            Stopped
                        </div>
                        <AvailableTimes
                            timeConvention='12h'
                            weekStartsOn={'sunday'}
                            calendars={[
                                {
                                    id: 'ParkSchedule',
                                    title: 'Park Schedule',
                                    selected: true,
                                },
                            ]}
                            onChange={props.onChangeSchedule}
                            initialSelections={props.initialSelections}
                            onEventsRequested={({ calendarId, start, end, callback }) => {}}
                            height={props.height ? props.height : height - 80}
                            width={width - 25}
                            recurring={true}
                            availableDays={['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']}
                            availableHourRange={{ start: 0, end: 24 }}
                        />
                    </div>
                );
            }}
        </AutoSizer>
    );
};

export { Scheduler };
