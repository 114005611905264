import React from 'react';
import { ReactComponent as VegaLogo } from '../../images/vega-logo.svg';
import { LinearProgress } from '@material-ui/core';
import scss from './VegaAppLoading.module.scss';

export interface IVegaAppLoadingProps {
    progress?: number;
}

const VegaAppLoading: React.FC<IVegaAppLoadingProps> = (props) => {
    return (
        <>
            <div className={scss.container}>
                <div className={`center flexCol padded ${scss.content}`}>
                    <div className={scss.logoContainer}>
                        <VegaLogo />
                    </div>
                    <p className='body1 c-slate-light'>Loading VegaCloud Command Center</p>
                    <div style={{ width: '18rem' }}>
                        <LinearProgress variant='indeterminate' />
                    </div>
                </div>
            </div>
        </>
    );
};

export default VegaAppLoading;
