import React from 'react';
import { ReactComponent as AWSIcon } from '../../images/icons/logos/aws-logo.svg';
import { ReactComponent as AzureIcon } from '../../images/icons/logos/azure-logo.svg';
import { ReactComponent as GoogleIcon } from '../../images/icons/logos/google-logo.svg';
import scss from './WorkloadTypeIndicator.module.scss';
import Icon from '../Icon/Icon';
import { GetWorkloadModel, CloudProvider, WorkloadContentType, WorkloadCreationType } from '../../ServiceStack/ServiceStack.dtos';

export interface WorkloadTypeIndicatorProps {
    workload: GetWorkloadModel;
}

const WorkloadTypeIndicator: React.FC<WorkloadTypeIndicatorProps> = (props) => {
    return (
        <div className={`flexCol justifyCenter ${scss.container}`}>
            <div className={`flexRow alignItemsCenter flexRight`}>
                <div className={`flexCol ta-right ${scss.workloadTypeYo}`}>
                    <div className={`body2 c-slate`}>
                        {props.workload.workloadCreationType === WorkloadCreationType.Import && (
                            <>
                                {props.workload.workloadContentType === WorkloadContentType.CloudProvider && 'Cloud Provider'}
                                {props.workload.workloadContentType === WorkloadContentType.Satellite && (
                                    <>
                                        <Icon icon='satellite' />
                                        &nbsp; Satellite
                                    </>
                                )}
                            </>
                        )}
                        {props.workload.workloadCreationType === WorkloadCreationType.VegaComplete && 'Vega Complete'}
                    </div>
                    <div className='caption c-slate-lightest'>{props.workload.workloadConfigurationAws.accountId}</div>
                </div>
                <div className='svgIconSmall'>
                    {props.workload.cloudProvider === CloudProvider.Aws && <AWSIcon />}
                    {props.workload.cloudProvider === CloudProvider.Azure && <AzureIcon />}
                    {props.workload.cloudProvider === CloudProvider.Gcp && <GoogleIcon />}
                </div>
            </div>
            <div className='caption c-slate-lightest ta-right'>{!!props.workload && !!props.workload.satelliteId && <>Tag: VegaEco - SAT@{props.workload.satelliteId}</>}</div>
        </div>
    );
};

export default WorkloadTypeIndicator;
