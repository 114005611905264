import { createMuiTheme, createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { BaseCSSProperties, CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { CSSProperties } from 'react';
import getClasses from '@material-ui/core/test-utils/getClasses';

export const VegaTheme = createMuiTheme({
    props: {
        MuiButtonBase: {
            disableRipple: true,
            disableTouchRipple: true,
        },
    },
    overrides: {
        MuiCard: {
            root: {
                textAlign: 'center',
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
        },
        MuiLinearProgress: {
            root: {
                height: '8px',
                borderRadius: '2px',
            },
        },
    },
    palette: {
        text: {
            primary: '#273754',
            secondary: '#7F8FA4',
            disabled: '#DFE2E5',
            hint: '#FFFFFF',
        },
        primary: {
            main: '#0F71E2',
            light: '#87B8F0',
            dark: '#0C5EBB',
        },
        secondary: {
            main: '#273754',
            dark: '#1E232D',
        },
        success: {
            main: '#00D166',
            light: '#02EA73',
            dark: '#00B056',
        },
        warning: {
            main: '#FFBD00',
            light: '#FFD252',
            dark: '#F3B400',
        },
    },
    typography: {
        fontFamily: ['"Rubik"', 'sans-serif'].join(','),
        fontSize: 16,
        h1: {
            fontSize: '3rem',
            lineHeigth: '4.5rem',
            fontWeight: 400,
            margin: '0px',
            padding: '0px',
        },
        h2: {
            fontSize: '2.25rem',
            lineHeigth: '3.5rem',
            fontWeight: 400,
            margin: '0px',
            padding: '0px',
        },
        h3: {
            fontSize: '1.75rem',
            lineHeigth: '2.5rem',
            fontWeight: 400,
            margin: '0px',
            padding: '0px',
        },
        h4: {
            fontSize: '1.5rem',
            lineHeigth: '2.5rem',
            fontWeight: 400,
            margin: '0px',
            padding: '0px',
        },
        h5: {
            fontSize: '1.125rem',
            lineHeigth: '2rem',
            fontWeight: 500,
            margin: '0px',
            padding: '0px',
        },
        h6: {
            fontSize: '1rem',
            lineHeigth: '1.5rem',
            fontWeight: 500,
            margin: '0px',
            padding: '0px',
        },
        body1: {
            fontSize: '1rem',
            lineHeigth: '1.5rem',
            marginBottom: '1rem',
        },
        body2: {
            fontSize: '0.875rem',
            lineHeigth: '1.5rem',
            marginBottom: '1rem',
        },
        subtitle1: {
            fontSize: '1rem',
            lineHeigth: '2rem',
            margin: '0px',
            padding: '0px',
        },
        subtitle2: {
            fontSize: '0.875rem',
            lineHeigth: '1.5rem',
            fontWeight: 500,
            margin: '0px',
            padding: '0px',
        },
    },
});

// create styled mui compomnent
const StyledButton = withStyles({
    root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

// create styles for using in a mui component and can pass in props
interface ISomeCoolComponentProps {
    color: string;
    params?: any;
}

type SomeCoolComponentClassNames = 'MuiTypography' | 'UnProppedClass';

const useStyles = makeStyles<Theme, ISomeCoolComponentProps, SomeCoolComponentClassNames>((theme: Theme) =>
    createStyles({
        MuiTypography: (props) => ({
            '&.MuiTypography-root': {
                color: props.color,
                fontSize: theme.typography.h1.fontSize,
                fontWeight: theme.typography.fontWeightRegular,
            },
            anotherClass: {
                //can add more styles
                borderWidth: 12,
            },
        }),
        UnProppedClass: {
            //another class that doesnt take props
            flex: `1`,
        },
    })
);

// const classes = useStyles({ color: 'red' });
//
// var t = classes.MuiTypography;
