import React from 'react';
import WorkloadActionsTable from '../../WorkloadActions/WorkloadActionsTable';
import { GetWorkloadModel } from '../../../ServiceStack/ServiceStack.dtos';

export interface HistoryTabProps {
    workload: GetWorkloadModel;
}

const HistoryTab: React.FC<HistoryTabProps> = (props) => {
    return (
        <div className='marginNormal'>
            <WorkloadActionsTable entityId={props.workload.workloadId} type='history' />
        </div>
    );
};

export default HistoryTab;
