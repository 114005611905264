import React from 'react';
import Icon from '../Icon/Icon';

export interface IconButtonProps {
    icon: string;
    onClick: () => void;
    className?: string;
}

export const IconButton: React.FC<IconButtonProps> = (props) => {
    return (
        <button className={`iconButton iconLabel iconButtonSize ${!!props.className && props.className}`} type='button' onClick={props.onClick}>
            <Icon icon={props.icon} />
        </button>
    );
};
