import React from 'react';
import * as Utils from '../../utils';

export interface VarianceTextProps {
    variance: number;
}

const VarianceText = (props: VarianceTextProps) => {
    return (
        <React.Fragment>
            {props.variance < 0 ? <span className='icon icon-arrow-up body2 c-danger'></span> : <span className='icon icon-arrow-down body2 c-success'></span>}
            <span className={props.variance < 0 ? 'c-danger' : 'c-success'}>&nbsp; {Utils.MakeMoney(Math.abs(props.variance))}</span>
        </React.Fragment>
    );
};

export default VarianceText;
