import React, { useState } from 'react';
import * as Globals from '../../globals';
import { WebSocketMessage, MessageAction } from './WebSocketTypes';

export interface IWebSocketContext {
    initializeWebSocket: () => void;
    disconnectWebSocket: () => void;
    startDiscovery: () => void;
    resetParkingMessages: () => void;
    webSocket: WebSocket | null;
    rdsDiscoveryFinished: boolean;
    ec2DiscoveryFinished: boolean;
    ec2ParkingEnded: boolean;
    rdsParkingEnded: boolean;
    ec2UnparkEnded: boolean;
    rdsUnparkEnded: boolean;
    addListener: (listener: (msg: WebSocketMessage) => void) => number;
    removeListener: (id: number) => void;
}

const WebSocketContext = React.createContext({} as IWebSocketContext);

const WebSocketProvider: React.FC = ({ children }) => {
    const [webSocket, setWebSocket] = useState(null);
    const [rdsDiscoveryFinished, setRdsDiscoveryFinished] = useState(false);
    const [ec2DiscoveryFinished, setEc2DiscoveryFinished] = useState(false);
    const [ec2ParkingEnded, setEc2ParkingEnded] = useState(false);
    const [rdsParkingEnded, setRdsParkingEnded] = useState(false);
    const [ec2UnparkEnded, setEc2UnparkEnded] = useState(false);
    const [rdsUnparkEnded, setRdsUnparkEnded] = useState(false);
    const [listeners, setListeners] = useState<((msg: WebSocketMessage) => void)[]>([]);

    let autoReconnectInterval = 250; // in ms

    const initializeWebSocket = () => {
        const webSocket = new WebSocket(`${Globals.WEBSOCKET_URL}?token=${localStorage.getItem('jwttoken')}`);

        webSocket.onopen = (event: Event) => {
            autoReconnectInterval = 250; //reset the interval for reconnecting
            setWebSocket(webSocket);
        };

        webSocket.onmessage = (message: MessageEvent) => {
            const decodedMessage = JSON.parse(message.data);
            handleMessage(decodedMessage);
            //add message handler
        };

        webSocket.onclose = (event: CloseEvent) => {
            switch (event.code) {
                case 1000: //closed normally
                    console.log('WebSocket closed normally');
                    break;
                default:
                    autoReconnectInterval = autoReconnectInterval += autoReconnectInterval; //increment the interval for reconnecting
                    reconnectToWebSocket(autoReconnectInterval);
                    break;
            }
        };

        webSocket.onerror = (error: any) => {
            switch (error.code) {
                case 'ECONNREFUSED':
                    autoReconnectInterval = autoReconnectInterval += autoReconnectInterval; //increment the interval for reconnecting
                    reconnectToWebSocket(autoReconnectInterval);
                    break;
                default:
                    console.log(`WebSocket encountered error: ${error}`);
                    break;
            }
        };
    };

    const startDiscovery = () => {
        setRdsDiscoveryFinished(false);
        setEc2DiscoveryFinished(false);
    };

    const resetParkingMessages = () => {
        setEc2ParkingEnded(false);
        setRdsParkingEnded(false);
        setEc2UnparkEnded(false);
        setRdsUnparkEnded(false);
    };

    const handleMessage = (message: WebSocketMessage) => {
        console.debug(message);
        listeners.forEach((listen) => listen(message));

        switch (message.actionType) {
            case MessageAction.RDS_DISCOVERY_ENDED:
                setRdsDiscoveryFinished(true);
                break;
            case MessageAction.EC2_DISCOVERY_ENDED:
                setEc2DiscoveryFinished(true);
                break;
            case MessageAction.EC2_PARKING_ENDED:
                setEc2ParkingEnded(true);
                break;
            case MessageAction.RDS_PARK_ENDED:
                setRdsParkingEnded(true);
                break;
            case MessageAction.EC2_UNPARK_ENDED:
                setEc2UnparkEnded(true);
                break;
            case MessageAction.RDS_UNPARK_ENDED:
                setRdsUnparkEnded(true);
                break;
            default:
                break;
        }
    };

    const reconnectToWebSocket = (autoReconnectInterval: number) => {
        const maximumConnectionTimeout = 10000;
        setTimeout(initializeWebSocket, Math.min(maximumConnectionTimeout, autoReconnectInterval));
    };

    const disconnectWebSocket = () => {
        if (webSocket !== null) {
            (webSocket as WebSocket).close(1000);
        }
    };

    const addListener = (listener: (msg: WebSocketMessage) => void): number => {
        const id = listeners.push(listener) - 1;
        setListeners(listeners);
        return id;
    };

    const removeListener = (id: number) => {
        const temp = [...listeners];
        temp.splice(id, 1);
        setListeners(temp);
    };

    return (
        <WebSocketContext.Provider
            value={{
                webSocket: webSocket,
                initializeWebSocket: initializeWebSocket,
                disconnectWebSocket: disconnectWebSocket,
                startDiscovery: startDiscovery,
                resetParkingMessages: resetParkingMessages,
                rdsDiscoveryFinished: rdsDiscoveryFinished,
                ec2DiscoveryFinished: ec2DiscoveryFinished,
                ec2ParkingEnded: ec2ParkingEnded,
                rdsParkingEnded: rdsParkingEnded,
                ec2UnparkEnded: ec2UnparkEnded,
                rdsUnparkEnded: rdsUnparkEnded,
                addListener,
                removeListener,
            }}
        >
            {children}
        </WebSocketContext.Provider>
    );
};

const WebSocketConsumer = WebSocketContext.Consumer;
export { WebSocketProvider, WebSocketConsumer, WebSocketContext };
