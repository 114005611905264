export enum MessageAction {
    RDS_DISCOVERY_ENDED = 'RDS_DISCOVERY_ENDED',
    EC2_DISCOVERY_ENDED = 'EC2_DISCOVERY_ENDED',
    EC2_PARKING_ENDED = 'EC2_PARKING_ENDED',
    RDS_PARK_ENDED = 'RDS_PARK_ENDED',
    EC2_UNPARK_ENDED = 'EC2_UNPARK_ENDED',
    RDS_UNPARK_ENDED = 'RDS_UNPARK_ENDED',
    CLOUDPROVIDERAUTHORIZATIONURL = 'CLOUDPROVIDERAUTHORIZATIONURL',
    CLOUDPROVIDERAUTHORIZATIONFINISHED = 'CLOUDPROVIDERAUTHORIZATIONFINISHED',
}

export type WebSocketMessage = {
    actionType: MessageAction;
    status: boolean;
    error: string;
    url: string;
};
