import React, { useContext, useEffect, useState } from 'react';
import scss from './WorkloadDetail.module.scss';
import WorkloadCostInfo from './WorkloadCostInfo';
import { RouteComponentProps } from 'react-router';
import WorkloadTypeIndicator from '../WorkloadTypeIndicator/WorkloadTypeIndicator';
import ContentLoading from '../ContentLoading/ContentLoading';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { GetWorkload, WorkloadCreationType } from '../../ServiceStack/ServiceStack.dtos';
import TopBar from '../TopBar/TopBar';
import WorkloadDetailImportLanding from './WorkloadDetailsViews/WorkloadDetailImportLanding';
import VegaCompleteWorkRemoteLanding from './WorkloadDetailsViews/VegaCompleteWorkRemoteLanding';
import VegaCompleteQumuloLanding from './WorkloadDetailsViews/VegaCompleteQumuloLanding';
import { ReactComponent as TelusLogo } from '../../images/icons/logos/telus-logo.svg';
import { ReactComponent as QumuloLogo } from '../../images/icons/logos/qumulo-logo.svg';
import VegaCompleteTelusLanding from './WorkloadDetailsViews/VegaCompleteTelusLanding';

export interface WorkloadDetailProps extends RouteComponentProps<any> {}

const WorkloadDetail: React.FC<WorkloadDetailProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const workloadId = props.location.pathname.split('/')[3];
        if (appState.currentWorkload.workloadId !== workloadId)
            vegaApi
                .getApiClient()
                .get(new GetWorkload({ workloadId }))
                .then((response) => {
                    appState.setCurrentWorkload(response.result);
                    setLoaded(true);
                });
        else setLoaded(true);
        // eslint-disable-next-line
    }, []);

    const isQumuloSalesRep = appState.currentUser.email.endsWith('@qumulo.com') && appState.currentUser.email.startsWith('salesrep');
    const isTelusPOC = appState.currentUser.email.endsWith('@telus.com') && appState.currentUser.email.startsWith('teluspoc');

    const view = (
        <>
            <div className='flexRow flexGrow scrollContainer'>
                <div className={`${scss.left} flexCol scrollContainer`}>
                    {appState.currentWorkload.workloadCreationType === WorkloadCreationType.Import && <WorkloadDetailImportLanding />}
                    {appState.currentWorkload.workloadCreationType === WorkloadCreationType.VegaComplete && !isQumuloSalesRep && !isTelusPOC && (
                        <>
                            {/* eventually we will load appropriate component based on what api tells us the type of vegacomplete WL this is */}
                            <VegaCompleteWorkRemoteLanding />
                        </>
                    )}
                    {appState.currentWorkload.workloadCreationType === WorkloadCreationType.VegaComplete && isQumuloSalesRep && (
                        <>
                            <VegaCompleteQumuloLanding />
                        </>
                    )}
                    {appState.currentWorkload.workloadCreationType === WorkloadCreationType.VegaComplete && isTelusPOC && (
                        <>
                            <VegaCompleteTelusLanding />
                        </>
                    )}
                </div>
                <div className={`${scss.right} flexCol`}>
                    <div className='padded'>
                        <TopBar />
                    </div>
                    <div className='padded scrollContainer'>
                        {isQumuloSalesRep && <QumuloLogo style={{ width: '55%', height: 50 }} />}
                        {isTelusPOC && <TelusLogo style={{ width: '55%' }} />}
                        <WorkloadTypeIndicator workload={appState.currentWorkload} />
                        <WorkloadCostInfo workload={appState.currentWorkload} />
                    </div>
                </div>
            </div>
        </>
    );

    return loaded ? view : <ContentLoading />;
};

export default WorkloadDetail;
