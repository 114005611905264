import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import ForgotPassword from './ForgotPassword';
import ForgotPasscodeVerify from './ForgotPasscodeVerify';
import ForgotPasswordReset from './ForgotPasswordReset';
import LoginLayout from '../LoginLayout/LoginLayout';
import { CreateLoginPasswordActionResponse, PassCodeDeliveryDetail } from '../../ServiceStack/ServiceStack.dtos';

export interface ForgotPasswordLandingProps extends RouteComponentProps<any> {}

export const ForgotPasswordLanding: React.FC<ForgotPasswordLandingProps> = (props) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [passCodeDeliveryMedium, setPassCodeDeliveryMedium] = useState<PassCodeDeliveryDetail>();
    const [email, setEmail] = useState('');

    const handleEmailEntered = (data: CreateLoginPasswordActionResponse, email: string) => {
        setPassCodeDeliveryMedium(data.result.passCodeDeliveryDetail);
        setEmail(email);
        setCurrentStep(currentStep + 1);
    };

    const handlePasscodeVerified = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleOnComplete = () => {
        props.history.push('/login');
    };

    const componentSteps = [
        <ForgotPassword next={(data, email) => handleEmailEntered(data, email)} />,
        <ForgotPasscodeVerify passCodeDeliveryMedium={passCodeDeliveryMedium} email={email} next={handlePasscodeVerified} />,
        <ForgotPasswordReset email={email} onComplete={handleOnComplete} />,
    ];

    return (
        <>
            <LoginLayout
                onClose={() => {
                    props.history.push('/login');
                }}
            >
                {componentSteps[currentStep]}
            </LoginLayout>
        </>
    );
};
