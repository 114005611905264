import React from 'react';

export interface UiDemoLayoutCompsTabProps {}

const UiDemoLayoutCompsTab: React.FC<UiDemoLayoutCompsTabProps> = (props) => {
    return (
        <>
            <div className='topBottomMarginContainer'>
                <h1>Paper</h1>
                <div className='paper'>This is paper. Use className='paper'. Designed to be used on dark backgrounds (it's white)</div>
                <div className='paper dark'>This is dark paper. Add 'dark' to className</div>
                <div className='paper dark padded'>
                    This is dark paper with padding. Add 'padded' to className.
                    <br />
                    <br />
                    Note that "padded" works on any element to add the global padding
                </div>
                <div className='paper dark padded'>
                    All elements in this section are inside a div with className "topBottomMarginContainer". That adds the top/bottom margins to every direct child element inside
                    it.
                </div>

                <h1>Cards</h1>
                <div className='simpleCard padded'>
                    This is a basic card.
                    <br />
                    To use it, use className="simpleCard"
                </div>
            </div>
        </>
    );
};

export default UiDemoLayoutCompsTab;
