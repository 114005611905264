import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { SaveAlt, FileCopy } from '@material-ui/icons';
import clipCopy from 'clipboard-copy';
import FileSaver from 'file-saver';

export interface WorkloadPolicyProps {
    cft: string;
}

const WorkloadPolicy = (props: WorkloadPolicyProps) => {
    const [showPolicy, setShowPolicy] = React.useState(false);
    const [copyClipboard, setCopyClipboard] = React.useState(false);

    const viewPolicy = (event) => setShowPolicy(!showPolicy);

    const copyJson = (event) => {
        clipCopy(JSON.stringify(props.cft, null, 2))
            .then(() => {
                setCopyClipboard(true);
            })
            .catch(() => {
                setCopyClipboard(false);
            });
        setCopyClipboard(true);
    };

    const saveJson = (event) => {
        var blob = new Blob([JSON.stringify(props.cft, null, 2)], { type: 'json/applicaiton;charset=utf-8' });
        FileSaver.saveAs(blob, 'workload-policy.json');
    };

    return (
        <>
            <div className='flexRow flexSeparate'>
                <div>
                    <Tooltip disableHoverListener open={copyClipboard} title='Copied to Clipboard'>
                        <IconButton onClick={copyJson}>
                            <FileCopy />
                        </IconButton>
                    </Tooltip>
                    <IconButton onClick={saveJson}>
                        <SaveAlt />
                    </IconButton>
                </div>
                <div>
                    <button className='accent primary small' onClick={viewPolicy}>
                        {showPolicy ? 'Hide' : 'Show'} Policy
                    </button>
                </div>
            </div>
            {showPolicy && (
                <div className='paper padded'>
                    <code>{props.cft}</code>
                </div>
            )}
        </>
    );
};

export default WorkloadPolicy;
