import React from 'react';
import { DarkTextField } from './DarkTextField/DarkTextField';

const TestComponent = (props) => {
    const handleChange = (event) => {
        console.log(event);
    };

    return (
        <div className='padded bg-secondary'>
            <DarkTextField onChange={handleChange} name='fullName' label='Full Name' variant='outlined' type='text' />
        </div>
    );
};
export default TestComponent;
