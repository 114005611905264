import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import * as Globals from '../../globals';

class EmailInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddr: Globals.EMPTY_STR,
            errorMsg: Globals.EMPTY_STR,
        };
        this.helperTextProps = {
            error: true,
        };
    }

    updateEmail = () => {
        if (this.props.setUN instanceof Function) {
            this.props.setUN(this.state.emailAddr);
        }
        if (this.props.onBlur instanceof Function) {
            this.props.onBlur(this.state.emailAddr);
        }
    };

    onValueChange = (evt) => {
        this.setState(
            {
                emailAddr: evt.target.value,
            },
            () => {
                this.updateEmail();
            }
        );

        this.validateEmail(evt.target.value);
    };

    validateEmail = (theEmail) => {
        // Check the email that it is the correct format
        // Email has to have a local part and a domain part.
        // The parts are tokenized on '@'
        let msg = Globals.EMPTY_STR;

        if (theEmail != null) {
            if (theEmail.indexOf(Globals.AT_SIGN) === -1) {
                msg = Globals.INVALID_EMAIL_MSG;
            } else {
                // Check the lengths of the local and domain parts
                // local 64 characters or less is valid
                // domain 255 characters or less is valid
                var tokens = theEmail.split('@');
                if (tokens[0].length > 0 && tokens[0].length <= 64 && tokens[1].length > 0 && tokens[1].length <= 255) {
                    // Check the domain has at least a single dot and has no less than 2 following last dot
                    let lastDotIdx = tokens[1].lastIndexOf(Globals.DOT);
                    if (lastDotIdx !== -1 && tokens[1].length - lastDotIdx > 2) {
                        msg = Globals.EMPTY_STR;
                    } else {
                        msg = Globals.INVALID_EMAIL_MSG;
                    }
                } else {
                    msg = Globals.INVALID_EMAIL_MSG;
                }
            }
        }
        this.setState({
            errorMsg: msg,
        });
    };

    render() {
        return (
            <React.Fragment>
                <FormControl>
                    <TextField
                        value={this.state.emailAddr}
                        error={this.state.errorMsg.length > 0 ? true : false}
                        label='Your Email'
                        helperText={this.state.errorMsg}
                        FormHelperTextProps={this.helperTextProps}
                        type='email'
                        name='email'
                        autoComplete='email'
                        variant='outlined'
                        onChange={this.onValueChange}
                    />
                </FormControl>
            </React.Fragment>
        );
    }
}

export default EmailInput;
