import React, { useContext, useState, useEffect } from 'react';
import { CircularProgress, Grid, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@material-ui/core';
import SignupLayout from '../SignupLayout/SignupLayout';
import { CreateOnBoardUserForm } from './SignUpLanding';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { ConfirmCreateLogin, ResendCreateLoginConfirmation } from '../../ServiceStack/ServiceStack.dtos';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { useSnackbar } from 'notistack';

export interface SignUpAuthVerifyProps extends RouteComponentProps {
    model: CreateOnBoardUserForm;
}

const SignUpAuthVerify: React.FC<SignUpAuthVerifyProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);
    const snackbar = useSnackbar();

    const [passCode, setPassCode] = useState('');
    const [invalidCodeError, setInvalidCodeError] = useState(undefined);
    const [processing, setProcessing] = useState(false);

    const handleChange = (event) => setPassCode(event.target.value);

    const completeSignUp = async (event) => {
        event.preventDefault();
        try {
            setProcessing(true);
            const confirmRequest = new ConfirmCreateLogin({ email: props.model.email, passCode });
            await vegaApi.getApiClient().post(confirmRequest);
            await appState.login(props.model.email, props.model.password);
            appState.setPassword(props.model.password);
            props.history.push('/onboard');
        } catch (error) {
            setInvalidCodeError(error.responseStatus.message);
            setProcessing(false);
        }
    };

    const resendPasscode = () => {
        vegaApi
            .getApiClient()
            .post(new ResendCreateLoginConfirmation({ email: props.model.email }))
            .then(() => {
                snackbar.enqueueSnackbar(`Passcode has been resent! Check your ${props.model.passCodeDeliveryMedium}.`, { variant: 'info' });
            });
    };

    useEffect(() => {
        snackbar.enqueueSnackbar(`Please check your ${props.model.passCodeDeliveryMedium} for your passcode.  Code is good for 60 minutes.`, { variant: 'info' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const allowNext = passCode.length === 6;

    return (
        <>
            <SignupLayout>
                <form onSubmit={completeSignUp}>
                    <Grid container spacing={4}>
                        <Grid item xs={3} />
                        <Grid item xs={6}>
                            <h5>Authentication</h5>
                        </Grid>
                        <Grid item xs={3} />

                        <Grid item xs={3} />
                        <Grid item xs={6}>
                            <span className='body1 c-slate-light'>Please check your {props.model.passCodeDeliveryMedium} for your verification code.</span>
                        </Grid>
                        <Grid item xs={3} />

                        <Grid item xs={3} />
                        <Grid item xs={6}>
                            <FormControl variant='outlined' error={!!invalidCodeError}>
                                <InputLabel htmlFor='code'>Verification Code</InputLabel>
                                <OutlinedInput id='code' name='code' type='text' onChange={handleChange} labelWidth={130} />
                                {!!invalidCodeError && <FormHelperText>{invalidCodeError}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} />

                        <Grid item xs={12}>
                            {!processing && (
                                <button className='medium primary normal center' type='submit' disabled={!allowNext}>
                                    Verify
                                </button>
                            )}
                            {processing && (
                                <div className='center'>
                                    <CircularProgress />
                                </div>
                            )}
                        </Grid>

                        <Grid item xs={3} />
                        <Grid item xs={6}>
                            <span className='body1 c-slate-light'>Didn't recieve your code? Pleace check your spam filter or, resend your code.</span>
                        </Grid>
                        <Grid item xs={3} />

                        <Grid item xs={12}>
                            <button type='button' className='accent small primary center' onClick={resendPasscode}>
                                Resend code
                            </button>
                        </Grid>
                    </Grid>
                </form>
            </SignupLayout>
        </>
    );
};

export default withRouter(SignUpAuthVerify);
