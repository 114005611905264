import React, { useEffect, useState, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ListWorkloadCostsByDay } from '../../ServiceStack/ServiceStack.dtos';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import ContentLoading from '../ContentLoading/ContentLoading';

export interface RuntimeGraphProps {
    workloadId: string;
}

const RuntimeGraph: React.FC<RuntimeGraphProps> = (props) => {
    const todayDate = new Date();
    const numDaysInMonth = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0).getDate();
    let daysInMonth = [];
    for (let i = 1; i <= numDaysInMonth; i++) daysInMonth.push(i);

    const vegaApi = useContext(VegaApiContext);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([
        {
            name: 'Cost',
            data: [],
        },
    ]);
    const [maxCost, setMaxCost] = useState(0.00);
    const [options, setOptions] = useState({
        chart: {
            id: 'basic-bar',
            type: 'area',
            toolbar: { tools: { download: false } },
        },
        grid: { show: false },
        yaxis: { show: true, min: 0, max: 0, tickAmount: 10 },
        xaxis: {
            categories: daysInMonth,
            axisTicks: { show: false },
        },
        dataLabels: {
            enabled: false,
        },
    });

    const loadSetData = (vals: number[]) => {
        let newData = [];
        for (let i = 0; i < numDaysInMonth; i++) {
            newData.push(vals[i] >= 0 ? vals[i] : null);
        }
        setData([{ name: 'Cost', data: newData }]);
        setLoading(false);
    };

    const initMockedData = () => {
        setLoading(true);
        const generateNumber = () => Math.floor(Math.random() * (100 - 0 + 1) + 0);
        const currentDay = new Date().getDate();
        let mockData = [];
        for (let i = 0; i <= currentDay; i++) mockData.push(generateNumber());
        loadSetData(mockData);
    };

    const initRealData = () => {
        setLoading(true);
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();

        var date = new Date(year, month, 1, 0, 0, 0, 0);
        var fromDateStr = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString();
        date = new Date(year, month, day, 0, 0, 0, 0);
        var toDateStr = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString();

        const request = new ListWorkloadCostsByDay({ entityId: props.workloadId });
        request.fromDate =  fromDateStr;
        request.toDate = toDateStr;
        vegaApi
            .getApiClient()
            .get(request)
            .then((response) => {
                let newData = [];
                for (let i = 0; i < numDaysInMonth; i++) {
                    if(i < response.result.length) {
                        if(i == 0) {
                            setMaxCost(response.result[i].maxCost);
                        }
                        newData.push(response.result[i].totalCost.toFixed(2));
                    } else {
                        newData.push(null);
                    }
                  }
                
                setData([{ name: 'Cost', data: newData }]);
                setLoading(false);
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        initRealData();
        // initMockedData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        var maxCostNum = new Number(maxCost.toFixed(0));

        setOptions(
            {
                chart: {
                    id: 'basic-bar',
                    type: 'area',
                    toolbar: { tools: { download: false } },
                },
                grid: { show: false },
                yaxis: { show: true, min: 0, max: maxCostNum.valueOf(), tickAmount: 10 },
                xaxis: {
                    categories: daysInMonth,
                    axisTicks: { show: false },
                },
                dataLabels: {
                    enabled: false,
                },
            }
        );
    }, [maxCost]);

    return (
        <>
            <div className='padded posRelative'>
                <h5>Workload Cost By Day ({todayDate.toLocaleString('default', { month: 'long' })})</h5>
                {loading && (
                    <div className='fullWidth' style={{ height: '200px', position: 'absolute' }}>
                        <ContentLoading loadingMessage='Fetching runtime totals...' />
                    </div>
                )}
                <ReactApexChart options={options} series={data} type='area' height={200} />
            </div>
        </>
    );
};

export default RuntimeGraph;
