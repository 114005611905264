import React, { useContext, useState } from 'react';
import { CircularProgress, Grid, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@material-ui/core';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { CreateLoginPasswordAction, PasswordAction, PassCodeDeliveryDetail } from '../../ServiceStack/ServiceStack.dtos';
import * as Globals from '../../globals';

export interface ForgotPasscodeVerifyProps {
    passCodeDeliveryMedium: PassCodeDeliveryDetail;
    email: string;
    next: () => void;
}

const ForgotPasscodeVerify: React.FC<ForgotPasscodeVerifyProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [showResentPw, setShowResentPw] = useState(false);
    const [error, setError] = useState();
    const [isProcessing, setIsProcessing] = useState(false);
    const [passCode, setPassCode] = useState('');

    const handleChange = (event) => setPassCode(event.target.value);

    const resendCode = () => {
        const request = new CreateLoginPasswordAction({ email: props.email, passwordAction: PasswordAction.Forgot });
        vegaApi
            .getApiClient()
            .post(request)
            .then(() => {
                setResendDisabled(true);
                setShowResentPw(true);
                setTimeout(() => {
                    setShowResentPw(false);
                }, 3500);
            });
    };

    const submitForm = (event) => {
        event.preventDefault();
        setIsProcessing(true);
        const request = new CreateLoginPasswordAction({
            email: props.email,
            passwordAction: PasswordAction.ConfirmForgot,
            passCode,
            proposedPassword: Globals.NEW_USER_PWD,
        });
        vegaApi
            .getApiClient()
            .post(request)
            .then(() => {
                props.next();
            })
            .catch((error) => {
                setError(error.responseStatus.message);
                setIsProcessing(false);
            });
    };

    return (
        <>
            <div className='snackbarContainer top'>
                <div className='snackbar simpleCard padded primary'>Passcode has been sent! Code is good for 60 minutes.</div>
            </div>
            <form onSubmit={submitForm}>
                <Grid container spacing={4}>
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <h5>Authentication</h5>
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <span className='body1 c-slate-lightest'>Please check your {props.passCodeDeliveryMedium.passCodeDeliveryMedium} for your verification code.</span>
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <FormControl variant='outlined' error={!!error}>
                            <InputLabel htmlFor='code'>Verification Code</InputLabel>
                            <OutlinedInput id='code' name='code' onChange={handleChange} labelWidth={130} />
                            {!!error && <FormHelperText>{error}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={12}>
                        {!isProcessing && (
                            <button type='submit' className='normal primary medium center' disabled={passCode.length !== 6}>
                                Verify
                            </button>
                        )}
                        {isProcessing && (
                            <div className='center'>
                                <CircularProgress />
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <span className='body1 c-slate-lightest'>Didn't recieve your code? Pleace check your spam filter, or resend your code.</span>
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={12}>
                        <button type='button' className='accent primary small center' onClick={resendCode} disabled={resendDisabled}>
                            Resend code
                        </button>
                    </Grid>
                </Grid>
            </form>
            {showResentPw && (
                <div className='snackbarContainer bottom'>
                    <div className='snackbar paper padded'>Passcode has been resent!</div>
                </div>
            )}
        </>
    );
};

export default ForgotPasscodeVerify;
