import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Tooltip, TableSortLabel } from '@material-ui/core';
import { ReactComponent as AwsLogoWhite } from '../../images/icons/logos/aws-logo-white.svg';
import { ReactComponent as AzureLogoWhite } from '../../images/icons/logos/microsoft-logo-white.svg';
import { ReactComponent as GcpLogoWhite } from '../../images/icons/logos/google-logo-white.svg';
import { ReactComponent as AwsLogo } from '../../images/icons/logos/aws-logo.svg';
import { ReactComponent as AzureLogo } from '../../images/icons/logos/azure-logo.svg';
import { ReactComponent as GcpLogo } from '../../images/icons/logos/google-logo.svg';
import SpaceActionMenu from '../SpaceActionMenu/SpaceActionMenu';
import * as Globals from '../../globals';
import scss from './SpaceDetail.module.scss';
import Chip from '../Chip/Chip';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import BackButton from '../BackButton/BackButton';
import Icon from '../Icon/Icon';
import { SpaceCostInfo } from './SpaceCostInfo';
import { MakeMoney } from '../../utils';
import { useTable, useSortBy } from 'react-table';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { ListWorkloadSummariesModel, WorkloadContentType, CloudProvider, GetSpace } from '../../ServiceStack/ServiceStack.dtos';
import { AppStateContext } from '../../contexts/AppState/AppState';
import Skeleton from '@material-ui/lab/Skeleton';
import CombinedTopbar from '../CombinedTopbar/CombinedTopbar';
import DotIndicator from '../DotIndicator/DotIndicator';
import { ReactComponent as TelusLogo } from '../../images/icons/logos/telus-logo.svg';
import { ReactComponent as QumuloLogo } from '../../images/icons/logos/qumulo-logo.svg';

export interface SpaceDetailProps extends RouteComponentProps {}

const SpaceDetail: React.FC<SpaceDetailProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);

    const [viewType, setViewType] = useState(Globals.VIEW_TYPES.CARD);
    const [loaded, setLoaded] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [workloads, setWorkloads] = useState<ListWorkloadSummariesModel[]>([]);

    const handleView = (vType) => () => setViewType(vType);

    const goBack = () => props.history.push('/spaces');

    const reloadData = () => {
        setLoaded(false);
        const spaceId = props.location.pathname.split('/')[2];
        if (appState.currentSpace.spaceId !== spaceId)
            vegaApi
                .getApiClient()
                .get(new GetSpace({ spaceId }))
                .then((response) => {
                    appState.setCurrentSpace(response.result);
                });
        else setLoaded(true);
    };

    const isQumuloSalesRep = appState.currentUser.email.endsWith('@qumulo.com') && appState.currentUser.email.startsWith('salesrep');
    const isTelusPOC = appState.currentUser.email.endsWith('@telus.com') && appState.currentUser.email.startsWith('teluspoc');

    useEffect(() => {
        reloadData();
    }, [props.location.pathname]);

    useEffect(() => {
        if (appState.workloads && appState.currentSpace.spaceId) {
            setWorkloads(appState.workloads.filter((x) => x.spaceId === appState.currentSpace.spaceId));
            setLoaded(true);
        }
    }, [appState.workloads, appState.currentSpace]);

    useEffect(() => {
        reloadData();
        // eslint-disable-next-line
    }, []);

    const doActionsClick = () => props.history.push(`${props.history.location.pathname}/actions`);

    let cardView = (
        <>
            <div className={scss.spaceDetail_cardContainer}>
                {!isQumuloSalesRep && !isTelusPOC && (
                    <NavLink to={`/spaces/${appState.currentSpace.spaceId}/addpcp`} className={`simpleCard flexCol flexCenter addCard ${scss.workloadCard}`}>
                        <div className='c-primary'>
                            <Icon icon='plus' /> Add Workload
                        </div>
                    </NavLink>
                )}
                {loaded &&
                    workloads.map((workload) => (
                        <NavLink to={`${props.location.pathname}/${workload.workloadId}`} key={workload.workloadId} className={`simpleCard selectable ${scss.workloadCard}`}>
                            <div className={`flexRow alignItemsCenter ${scss.cardBody} ${scss['cardBody' + workload.cloudProvider]}`}>
                                <div className='cardIcon'>
                                    {workload.cloudProvider === CloudProvider.Aws && <AwsLogoWhite />}
                                    {workload.cloudProvider === CloudProvider.Azure && <AzureLogoWhite />}
                                    {workload.cloudProvider === CloudProvider.Gcp && <GcpLogoWhite />}
                                </div>
                                <div className='flexCol'>
                                    <div className={`body1 ${scss.workloadCardTitle}`}>{workload.name}</div>
                                    {workload.workloadContentType === WorkloadContentType.CloudProvider && <div className='body2 c-mono'>{workload.workloadCreationType}</div>}
                                </div>
                                {/* <Chip label={workload.vegaHealth} className={scss.statusIndicator} /> */}
                            </div>
                            <div className={`c-slate-light ${scss.cardBottom}`}>
                                {workload.workloadContentType === WorkloadContentType.CloudProvider && !isQumuloSalesRep && !isTelusPOC && (
                                    <>Spend&nbsp;&nbsp;&nbsp;&nbsp;{MakeMoney(workload.totalCost)}</>
                                )}
                                {workload.workloadContentType === WorkloadContentType.Satellite && (
                                    <>
                                        <Icon icon='satellite' />
                                        &nbsp;&nbsp;&nbsp;&nbsp;Satellite Workload
                                    </>
                                )}
                                {(isQumuloSalesRep || isTelusPOC) && (
                                    <>
                                        <DotIndicator color='running' /> Running...
                                    </>
                                )}
                            </div>
                        </NavLink>
                    ))}
                {!loaded && (
                    <>
                        <div className={`simpleCard ${scss.workloadCard}`}>
                            <div className={`flexRow alignItemsCenter ${scss.cardBody} ${scss.cardBodyLoading}`}>
                                <div className='cardIcon'>
                                    <Skeleton variant='circle' height={56} width={56} />
                                </div>
                                <div className='flexCol'>
                                    <div className={`body1 ${scss.workloadCardTitle}`}>
                                        <Skeleton variant='text' width={100} animation='wave' />
                                    </div>
                                    <Skeleton variant='text' width={50} animation='wave' />
                                </div>
                                {/* <Chip label={workload.vegaHealth} className={scss.statusIndicator} /> */}
                            </div>
                            <div className={`c-slate-light ${scss.cardBottom}`}>
                                <Skeleton variant='text' width={150} animation='wave' />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );

    const columns = useMemo(
        () => [
            { Header: 'Provider', accessor: 'cloudProvider' },
            { Header: 'Name', accessor: 'name' },
            { Header: 'Spend', accessor: 'totalCost' },
            { Header: 'Notifications', accessor: 'notificationCount' },
        ],
        []
    );

    const table = useTable(
        {
            columns,
            data: workloads,
        },
        useSortBy
    );
    const tableView = (
        <Table {...table.getTableProps()}>
            <TableHead>
                {table.headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'}>
                                    {column.render('Header')}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>

            <TableBody>
                {table.rows.map(
                    (row) =>
                        table.prepareRow(row) || (
                            <TableRow {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <TableCell {...cell.getCellProps()}>
                                        {(() => {
                                            switch (cell.column.Header) {
                                                case 'Provider':
                                                    return (
                                                        <div className='svgIconXSmall'>
                                                            {cell.value === CloudProvider.Azure && <AzureLogo />}
                                                            {cell.value === CloudProvider.Aws && <AwsLogo />}
                                                            {cell.value === CloudProvider.Gcp && <GcpLogo />}
                                                        </div>
                                                    );
                                                case 'Name':
                                                    return (
                                                        <NavLink to={`${props.location.pathname}/${row.original.workloadId}`} className='c-primary'>
                                                            {cell.value}
                                                        </NavLink>
                                                    );
                                                case 'Spend':
                                                    return MakeMoney(cell.value);
                                                default:
                                                    return cell.render('Cell');
                                            }
                                        })()}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                )}
                {!workloads.length && (
                    <TableRow>
                        <TableCell colSpan={5}>You don't have any workloads under this space yet!</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );

    let view = (
        <>
            <header className='pageHeaderContainer light'>
                <CombinedTopbar />
                <div className='flexRow flexSeparate alignItemsCenter'>
                    <div className='flexRow'>
                        {!isQumuloSalesRep && !isTelusPOC && <BackButton onClick={goBack} />}
                        {isQumuloSalesRep && <QumuloLogo style={{ width: '10%', height: 38, marginRight: 5 }} />}
                        {isTelusPOC && <TelusLogo style={{ width: '25%', marginRight: 15 }} />}
                        {!loaded && (
                            <h4>
                                <Skeleton variant='text' width={150} />
                            </h4>
                        )}
                        {loaded && <h4>{appState.currentSpace.name}</h4>}
                    </div>
                    {!isQumuloSalesRep && !isTelusPOC && (
                        <div className='flexRow chipContainer'>
                            <button onClick={doActionsClick} disabled={true} className='accent small primary'>
                                Actions
                            </button>
                            <SpaceActionMenu />
                        </div>
                    )}
                </div>
                <div className={`flexRow padded chipContainer alignItemsCenter ${scss.chipContainer}`}>
                    {!loaded && <Skeleton variant='text' width={70} animation='wave' />}
                    {loaded && <Chip label={appState.currentSpace.spaceType.name} className='bg-slate-lightest' />}
                    <Tooltip
                        arrow
                        open={showDescription}
                        onClose={() => setShowDescription(false)}
                        title={appState.currentSpace.description ? appState.currentSpace.description : ''}
                        disableHoverListener
                    >
                        <button className='iconButton'>
                            <span className='icon icon-info' onClick={() => setShowDescription(!showDescription)} />
                        </button>
                    </Tooltip>
                    {loaded && appState.currentSpace.complianceTypes.map((compliance) => <Chip key={compliance} label={compliance} className='bg-slate-lightest' />)}
                    {!loaded && <Skeleton variant='text' width={50} animation='wave' />}
                </div>
                <SpaceCostInfo entityId={appState.currentSpace.spaceId} />
            </header>
            <div className='marginNormal posRelative'>
                {!isQumuloSalesRep && !isTelusPOC && (
                    <div className='flexRow flexSeparate padded'>
                        <div>
                            {viewType === Globals.VIEW_TYPES.GRID && (
                                <NavLink className='c-primary bold' to={`/spaces/${appState.currentSpace.spaceId}/addpcp`}>
                                    <Icon icon='plus' /> Add Workload
                                </NavLink>
                            )}
                        </div>
                        <div>
                            <button className={`iconButton big ${viewType === Globals.VIEW_TYPES.CARD ? 'active' : ''}`} onClick={handleView(Globals.VIEW_TYPES.CARD)}>
                                <Icon icon='dashboard' />
                            </button>
                            <button className={`iconButton big ${viewType === Globals.VIEW_TYPES.GRID ? 'active' : ''}`} onClick={handleView(Globals.VIEW_TYPES.GRID)}>
                                <Icon icon='list' />
                            </button>
                        </div>
                    </div>
                )}
                {viewType === Globals.VIEW_TYPES.CARD && cardView}
                {viewType === Globals.VIEW_TYPES.GRID && tableView}
            </div>
        </>
    );

    return view;
};

export default SpaceDetail;
