import React from 'react';
import scss from './SignupLayout.module.scss';
import { withRouter } from 'react-router-dom';
import { ReactComponent as VegaLogo } from '../../images/vega-logo.svg';
import { ReactComponent as SignUpImage } from '../../images/sign-up-image.svg';

const SignupLayout = withRouter((props) => {
    const goToLogin = () => props.history.push('/login');

    return (
        <div className={scss.SignupContainer}>
            <div className={scss.leftSide}>
                <div className={scss.leftContent}>
                    <div className={scss.image}>
                        <VegaLogo className='vegaLogoLarge center' />
                    </div>
                    {props.children}
                </div>
            </div>
            <div className={`${scss.rightSide} flexCol`}>
                <div className={scss.graphicContainer}>
                    <SignUpImage className={scss.graphic} />
                </div>
                <div className='padded'>
                    <h2 className='bold'>Welcome to Vega!</h2>
                    <h6>Tools to help you understand and manage your cloud environments.</h6>
                    <p className='body1'>
                        Already have an account?&nbsp;
                        <button onClick={goToLogin} className='accent small primary'>
                            Sign In!
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
});

export default SignupLayout;
