import React, { useState, useContext } from 'react';
import { RecommendationDto, RecommendationStatus, ScheduleType, StartRecommendationCancel } from '../../ServiceStack/ServiceStack.dtos';
import { InfoSidePanelHeader } from '../InfoSidePanel/InfoSidePanelHeader';
import BackButton from '../BackButton/BackButton';
import { parseFormatMomentDate, parseFormatMomentTime } from '../../utils';
import Chip from '../Chip/Chip';
import Icon from '../Icon/Icon';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import Modal from '../Modal/Modal';
import { CircularProgress } from '@material-ui/core';
import { RecommendationSchedule } from './RecommendationSchedule';

export interface RecommendDetailsProps {
    recommendation: RecommendationDto;
    cancelClick: () => void;
    onComplete: () => void;
    refreshRecommendation: () => void;
    onClickDoItMyself?: (event: any) => void;
    onClickContinue?: () => void;
    onDeleteSchedule?: (recommendationId: string) => void;
}

export const RecommendDetails: React.FC<RecommendDetailsProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const deleteSchedule = () => {
        setDeleting(true);
        const request = new StartRecommendationCancel();
        request.id = props.recommendation.id;
        vegaApi
            .getApiClient()
            .post(request)
            .then(() => {
                props.onDeleteSchedule(props.recommendation.id);
            });
    };

    return (
        <>
            {showConfirmDelete && (
                <Modal
                    header={<h6>Confirm schedule deletion</h6>}
                    content={
                        <>
                            Are you sure you want to delete this scheduled work?
                            <br />
                            <br />
                            You may still reschedule the work at a future date
                        </>
                    }
                    footer={
                        <>
                            <button className='text slate-light small' onClick={() => setShowConfirmDelete(false)}>
                                Cancel
                            </button>
                            {!deleting && (
                                <button className='accent danger medium' onClick={deleteSchedule}>
                                    Delete Schedule
                                </button>
                            )}
                            {deleting && <CircularProgress />}
                        </>
                    }
                    onClose={() => setShowConfirmDelete(false)}
                />
            )}
            {showEdit && <RecommendationSchedule isEditSchedule cancelClick={() => setShowEdit(false)} onComplete={props.onComplete} recommendation={props.recommendation} />}

            <div className='padded'>
                <InfoSidePanelHeader showBottomMargin={true}>
                    <BackButton onClick={props.cancelClick} lightMode={true} />
                    <h5 className='c-slate-light'>&nbsp;Recommendations</h5>
                </InfoSidePanelHeader>
                {(props.recommendation.status === RecommendationStatus.Scheduled || props.recommendation.status === RecommendationStatus.Pending) && (
                    <Chip
                        label={props.recommendation.status}
                        icon={props.recommendation.status === RecommendationStatus.Scheduled && <Icon icon='clock' />}
                        className='bg-success'
                    />
                )}
                <h6 className='white'>{props.recommendation.title}</h6>
                <div className='topBottomMarginContainer'>
                    <p className='body1 c-slate-lightest'>{props.recommendation.description}</p>
                    {props.recommendation.status !== RecommendationStatus.Scheduled && (
                        <ul className='body1'>
                            {props.recommendation.reviewTasks.map((task, index) => (
                                <li key={index}>
                                    <div>{task.title}</div>
                                    <span>{task.description}</span>
                                </li>
                            ))}
                        </ul>
                    )}
                    {props.recommendation.status === RecommendationStatus.Scheduled && (
                        <>
                            {props.recommendation.scheduledType === ScheduleType.Schedule && (
                                <>
                                    <div className='body2'>Scheduled for:</div>
                                    <div>
                                        {parseFormatMomentDate(props.recommendation.scheduledStartDateUtc)}
                                        <br />
                                        {parseFormatMomentTime(props.recommendation.scheduledStartDateUtc)}
                                    </div>
                                </>
                            )}
                            {props.recommendation.scheduledType === ScheduleType.Window && (
                                <>
                                    <div className='body2'>Maintenance Window scheduled for:</div>
                                    <div>
                                        <div className='body2 c-slate-light'>Start</div>
                                        <div className='c-white'>
                                            {parseFormatMomentDate(props.recommendation.scheduledStartDateUtc)}
                                            <br />
                                            {parseFormatMomentTime(props.recommendation.scheduledStartDateUtc)}
                                        </div>
                                    </div>
                                    <div>
                                        <div className='body2 c-slate-light'>End</div>
                                        <div className='c-white'>
                                            {parseFormatMomentDate(props.recommendation.scheduledEndDateUtc)}
                                            <br />
                                            {parseFormatMomentTime(props.recommendation.scheduledEndDateUtc)}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className='flexRow flexSeparate alignItemsCenter'>
                                <button className='text danger small' onClick={() => setShowConfirmDelete(true)}>
                                    Delete
                                </button>
                                <button className='text primary small' onClick={() => setShowEdit(true)}>
                                    Edit
                                </button>
                            </div>
                        </>
                    )}
                    {props.recommendation.status === RecommendationStatus.WaitingForApproval && (
                        <div className='flexRow alignItemsCenter flexSeparate'>
                            <button className='text danger' type='button' onClick={props.onClickDoItMyself}>
                                Do it Myself
                            </button>
                            <button className='flat small primary' type='button' onClick={props.onClickContinue}>
                                Continue
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
