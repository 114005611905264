import React from 'react';

export interface AdmiralSnackbarProps {
    children;
}

const AdmiralSnackbar = (props: AdmiralSnackbarProps) => {
    return (
        <div className='snackbarContainer'>
            <div className='snackbar simpleCard padded flexRow alignItemsCenter flexSeparate'>{props.children}</div>
        </div>
    );
};

export default AdmiralSnackbar;
