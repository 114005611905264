import { useContext, useEffect } from 'react';
import { VegaContext } from '../../contexts/Vega/Vega';

export default function Vega() {
    const vegaContext = useContext(VegaContext);

    // load contexts with initial data at login
    useEffect(() => {
        vegaContext.loadComplianceTypes();
        // eslint-disable-next-line
    }, []);

    return null;
}
