import React, { useState, useEffect } from 'react';
import { JsonServiceClient } from '@servicestack/client';
import * as Globals from '../../globals';

export interface IVegaApiContextMethods {
    getApiClient: () => JsonServiceClient;
    setClient: React.Dispatch<React.SetStateAction<JsonServiceClient>>;
}

const VegaApiContext = React.createContext({} as IVegaApiContextMethods);

const VegaApiProvider = ({ children }) => {
    const [client, setClient] = useState<JsonServiceClient>(undefined);

    const generateNewClient = (): JsonServiceClient => {
        let tempClient = new JsonServiceClient(Globals.BASE_URL);
        tempClient.credentials = 'omit';
        if (localStorage.getItem('jwttoken')) tempClient.bearerToken = localStorage.getItem('jwttoken');
        return tempClient;
    };

    const getApiClient = () => client;

    useEffect(() => {
        setClient(generateNewClient());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <VegaApiContext.Provider value={{ getApiClient, setClient }}>{!!client && children}</VegaApiContext.Provider>;
};

const VegaApiConsumer = VegaApiContext.Consumer;

export { VegaApiProvider, VegaApiConsumer, VegaApiContext };
