import React, { useState } from 'react';
import { IconButton } from '../IconButton/IconButton';

export interface IExpandableMenuProps {
    title: JSX.Element[] | JSX.Element | string;
    menuItems: JSX.Element[] | JSX.Element;
    titleOnClick?: () => void;
}

const ExpandableMenu: React.FC<IExpandableMenuProps> = (props) => {
    const [expanded, setExpanded] = useState(false);

    const titleOnClick = () => {
        if (!!props.titleOnClick) props.titleOnClick();
    };

    return (
        <>
            <div className='flexRow flexSeparate alignItemsCenter'>
                <div className={`flexGrow ${!!props.titleOnClick && 'selectable'}`} onClick={titleOnClick}>
                    {props.title}
                </div>
                <div>
                    <IconButton icon={expanded ? 'chevron-up' : 'chevron-down'} onClick={() => setExpanded(!expanded)} />
                </div>
            </div>
            {expanded && <div className='topBottomMarginContainer'>{props.menuItems}</div>}
        </>
    );
};

export default ExpandableMenu;
