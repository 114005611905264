import React, { useContext, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemIcon, ListItemText } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import * as Globals from '../../globals';
import { ResourcesContext } from '../../contexts/Resources/Resources';
import Icon from '../Icon/Icon';
import { WorkloadContentType, DeleteWorkload, ListWorkloadSummariesModel } from '../../ServiceStack/ServiceStack.dtos';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';

export interface WorkloadActionMenuProps extends RouteComponentProps<any> {}

const WorkloadActionMenu: React.FC<WorkloadActionMenuProps> = (props) => {
    const appState = useContext(AppStateContext);
    const vegaApi = useContext(VegaApiContext);
    const resourcesContext = useContext<any>(ResourcesContext);

    const { currentWorkload } = appState;

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [numberOfSatellites, setNumberOfSatellites] = useState(0);

    const calcNumberOfSatellites = (workloadSummaries: ListWorkloadSummariesModel[]) => {
        const satellites = workloadSummaries.filter(
            (workload) => workload.workloadContentType === WorkloadContentType.Satellite && workload.parentWorkloadId === currentWorkload.workloadId
        );
        setNumberOfSatellites(satellites.length);
    };

    useEffect(() => {
        if (appState.currentWorkload) calcNumberOfSatellites(appState.workloads);
        // eslint-disable-next-line
    }, [appState.currentWorkload]);

    useEffect(() => {
        if (appState.workloads.length > 0 && currentWorkload.workloadContentType === WorkloadContentType.CloudProvider) {
            calcNumberOfSatellites(appState.workloads);
        } else {
            setNumberOfSatellites(0);
        }
        // eslint-disable-next-line
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        doAction(event.currentTarget.id);
    };

    const doOpen = () => {
        setOpen(true);
    };

    const doDelete = () => {
        const deleteCallback = (workloadId: string, redirectStr: string) => {
            vegaApi
                .getApiClient()
                .delete(new DeleteWorkload({ id: workloadId }))
                .then(() => {
                    appState.loadWorkloads().then(() => props.history.push(redirectStr));
                });
        };

        switch (currentWorkload.workloadContentType) {
            case WorkloadContentType.CloudProvider: {
                const redirectStr = props.location.pathname.substring(0, props.location.pathname.lastIndexOf('/'));
                deleteCallback(currentWorkload.workloadId, redirectStr);
                break;
            }
            case WorkloadContentType.Satellite: {
                const redirectStr = props.location.pathname.substring(0, props.location.pathname.lastIndexOf('/'));
                resourcesContext
                    .tagSatellites('untag', appState.awsRegions, resourcesContext.resources, currentWorkload.workloadId, currentWorkload.workloadId)
                    .then((response) => {
                        resourcesContext.discoverResources(currentWorkload.parentWorkloadId);
                        deleteCallback(currentWorkload.workloadId, redirectStr);
                    });
                break;
            }
            default:
                console.warn('Delete not supported for this action type:' + currentWorkload.workloadContentType);
                break;
        }
        setOpen(false);
        setAnchorEl(null);
    };

    const doCancel = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const doFavorite = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const doAction = (actionId) => {
        let pStr = props.location.pathname;

        switch (actionId) {
            case Globals.ACTION_MENU_ACTIONS.MAKE_FAVORITE:
                doFavorite();
                break;
            case Globals.ACTION_MENU_ACTIONS.EDIT:
                props.history.push(pStr + '/edit');
                break;
            case Globals.ACTION_MENU_ACTIONS.CONFIGURE:
                props.history.push(pStr + '/configure');
                break;
            default:
                console.log('Unknown ActionId');
        }
    };

    return (
        <>
            <button className='accent primary small' onClick={handleClick}>
                <Icon icon='settings' />
            </button>
            <Menu id='action-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem id={Globals.ACTION_MENU_ACTIONS.EDIT} onClick={handleClose}>
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText primary={Globals.ACTION_MENU_ACTIONS.EDIT} />
                </MenuItem>
                <MenuItem onClick={() => doAction(Globals.ACTION_MENU_ACTIONS.CONFIGURE)} disabled={currentWorkload.workloadContentType === WorkloadContentType.Satellite}>
                    <ListItemIcon>
                        <Icon icon='settings' />
                    </ListItemIcon>
                    <ListItemText primary={Globals.ACTION_MENU_ACTIONS.CONFIGURE} />
                </MenuItem>
                <MenuItem id={Globals.ACTION_MENU_ACTIONS.DELETE} onClick={doOpen}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText primary={Globals.ACTION_MENU_ACTIONS.DELETE} />
                </MenuItem>
            </Menu>
            <Dialog open={open} onClose={doCancel} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>Warning</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {currentWorkload.workloadContentType === WorkloadContentType.Satellite &&
                            'You are about to delete a Vega Satellite Workload. This is to confirm your deletion.'}
                        {currentWorkload.workloadContentType === WorkloadContentType.CloudProvider &&
                            !numberOfSatellites &&
                            'You are about to delete a Vega Cloud Provider Workload. This is to confirm your deletion.'}
                        {currentWorkload.workloadContentType === WorkloadContentType.CloudProvider &&
                            !!numberOfSatellites &&
                            `There are currently ${numberOfSatellites} Satellite Workloads attached to this parent Workload. All Satellite Workloads need to be deleted first.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className='primary accent medium' onClick={doCancel}>
                        Cancel
                    </button>
                    {!numberOfSatellites && (
                        <button className='danger normal medium' onClick={doDelete}>
                            Delete Workload
                        </button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withRouter(WorkloadActionMenu);
