// Vega Command Console Globals

export const VCCC_VERSION = '1.6.0.0';
export const CLOUD_PROVIDERS = { AWS: 'Aws', AZURE: 'Azure', GCP: 'Gcp' };
export const ACTION_MENU_ACTIONS = { EDIT: 'Edit', ACTIONS: 'Actions', DELETE: 'Delete', MAKE_FAVORITE: 'Make Favorite', CONFIGURE: 'Configure' };
export const SPACE_TYPES = { Standard: 'Standard', MySpace: 'MySpace' };
export const SPACE_ACTIONS = { PARK: 'Park', UNPARK: 'UnPark', BACKUP: 'Backup', RESTORE: 'Restore' };
export const WORKLOAD_ACTIONS = { PARK: 'Park', UNPARK: 'UnPark', BACKUP: 'Backup', RESTORE: 'Restore' };
export const HEALTH_LEVELS = { GOOD: 'Good', ATTENTION: 'Attention', CRITICAL: 'Critical' };
export const VIEW_TYPES = { CARD: 'Card', GRID: 'Grid', ORBIT: 'Orbit' };
export const BUDGET_PERIODS = { YEAR: 'Yearly', MONTH: 'Monthly', NONE: 'None' };
export const GROUP_TYPES = { SPACE: 'Spaces', WORKLOAD: 'Workloads', RESOURCE: 'Resources', SATELLITE: 'Satellite' };
export const WORKLOAD_TYPES = { IMPORT: 'Import', VEGA: 'VegaComplete' };
export const SCHEDULE_MODE = { NOW: 'now', SCHEDULE: 'schedule' };
export const CC_COUNTRY = 'US';
export const INIT_NUM = -1;
export const INIT_BOOL = false;
export const INIT_DATE = new Date(0);
export const INIT_TIME = new Date(0);
export const EMPTY_STR = '';
export const AT_SIGN = '@';
export const DOT = '.';
export const SPACE = ' ';
export const COLON = ':';
export const QMARK = '?';
export const EMPTY_ARRAY = [];
export const EMPTY_OBJ = {};
export const HALF_MINUTE_MS = 30000;
export const MINUTE_MS = 60000;
export const FIVE_MINUTE_MS = 300000;
export const SLOGAN_STR = 'Better visibility in the cloud';
export const INVALID_EMAIL_MSG = 'Please provide a valid email';
export const INVALID_FULLNAME_MSG = 'Please provide a name';
export const SIGN_IN_FAILURE_MSG = 'Username or password are incorrect.  Please try again.';
export const SIGN_IN_TROUBLE_MSG = 'Having trouble signing in?';
export const BY_EMAIL = 'By Email';
export const BY_PHONE = 'By Phone';
export const TC_AGREE_STR = 'By checking this box, you agree to our';
export const TC_STR = 'terms and conditons';
export const ALREADY_HAVE_ACCT = 'Already have an account?';
export const PASSCODE_SENT_STR = 'Your passcode had been sent.';
export const PASSCODE_ENTER_STR = 'Please enter it below.';
export const HOW_SEND_PWD_RECOVERY = 'How would you like us to send your password recovery?';
export const SIGN_UP_STR = 'Sign up!';
export const HAVE_ACCOUNT_STR = 'Do not have an account?';
export const RECAPTCHA_SITE_KEY = '6LfqupgUAAAAAGPp-5fQbARh-6oqAGwon0ZN5W07';
export const RECAPTCHA_SITE_KEY_V3 = '6LdAs5gUAAAAACGBz1STNnNaW_9yIZhjiNvLlNtw';
export const RECAPTCHA_STOKEN = '';
export const SEARCH_LBL = 'Search';
export const STAT_FILT_TITLE = 'Status Filters';
export const WORKLOAD_LBL = 'Workloads';
export const STAT_FILT_VEGAAUTOTASK_LBL = 'Vega Automated Tasks';
export const STAT_FILT_TEAMMEMBACTS_LBL = 'Team Member Activities';
export const TASK_LIST_TITLE = 'Tasks';
export const PROFILE_STR = 'Profile';
export const LOGOUT_STR = 'Logout';
export const MY_DASHBOARD = 'My Dashboard';
export const DETAILS = 'Details';
export const WORKLOAD_CONFIG_DETAILS =
    'Template will install AWS infratructure for a stand alone new 3 tier web site with load balancing, high availability and a secure/compliant framework setup.';
export const WORKLOAD_CONFIG_TYPE = 'Workload Configuration Type';
export const AVAILABILITY = 'Availability';
export const NETWORK = 'Network';
export const INSTANCES = 'Instances';
export const SELECT_WORKLOAD = 'Select Workload';
export const PARK = 'Park';
export const UNPARK = 'UnPark';
export const BACKUP_SPACE = 'Backup Space';
export const PARK_SPACE = 'Park Space';
export const DELETE_SPACE = 'Delete Space';
export const DELETE_SPACE_DESC = 'You are about to delete a Vega Space.  This is to confirm your deletion.';
export const PARK_WORKLOAD = 'Park Workload';
export const CANCEL = 'Cancel';
export const AVAILABLE_ACTIONS = 'Available Actions';
export const SCHEDULED = 'Scheduled';
// Vega API
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
export const ENABLE_REPORTING = process.env.REACT_APP_ENABLE_REPORTING === 'true';
export const ENABLE_RECOMMENDATIONS = process.env.REACT_APP_ENABLE_RECOMMENDATIONS === 'true';
export const ENABLE_HOTJAR = process.env.REACT_APP_ENABLE_HOTJAR === 'true';
export const WORKLOADS_ENDPOINT = BASE_URL + 'workloads';
export const WORKLOAD_TYPES_ENDPOINT = WORKLOADS_ENDPOINT + '/lookups/types/workload';
export const WORKLOADS_TEMPLATES_AWS_ENDPOINT = WORKLOADS_ENDPOINT + '/templates/aws';
export const ORG_VAL_DOMAIN_NAME_ENDPOINT = BASE_URL + 'organizations/validations/domain/name/';
export const SPACES_ENDPOINT = BASE_URL + 'spaces';
export const NEW_USER_PWD = 'VegaR0cksTh3Cl0ud~23';
export const ACTIONS_ENDPOINT = BASE_URL + 'actions';
export const AWS_REGIONS_ENDPOINT = ACTIONS_ENDPOINT + '/aws/regions';
export const FINANCIAL_BUDGETS_ENDPOINT = BASE_URL + 'financial/budgets/totals';
export const FINANCIAL_SAVINGS_ENDPOINT = BASE_URL + 'financial/savings/totals';
export const STATISTICS_COUNTS_WORKLOADS_ENDPOINT = BASE_URL + 'statistics/counts/workloads';
export const AWS_NOTIFICATIONS_ENDPOINT = BASE_URL + 'aws/notifications';
export const AWS_QUEUES_ENDPOINT = BASE_URL + 'aws/queues';
export const ALERTS_ENDPOINT = BASE_URL + 'alerts';
export const TASKS_ENDPOINT = BASE_URL + 'tasks';
// Google Analytics
export const REACT_APP_GA_TRACKING_ID = 'UA-141040705-1';
export const REACT_APP_GA_DEBUG = true;
// HotJar
export const REACT_APP_HJ_ID = 1343665;
export const REACT_APP_HJ_SNIPPET_VERSION = 6;
