import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Snackbar, Button, TextField, Select, MenuItem, OutlinedInput, InputLabel, FormControl, Checkbox, ListItemText, Radio } from '@material-ui/core';
import clipCopy from 'clipboard-copy';

import WorkloadCreateAwsConfigDetails from '../WorkloadCreateAwsConfigDetails/WorkloadCreateAwsConfigDetails';

import { ReactComponent as AwsLogo } from '../../images/icons/logos/aws-logo.svg';
import { ReactComponent as WinWebLogo } from '../../images/icons/logos/aws-logo.svg';
import { ReactComponent as LinuxWebLogo } from '../../images/icons/logos/azure-logo.svg';
import { ReactComponent as RedshiftDWLogo } from '../../images/icons/logos/google-logo.svg';

import * as Globals from '../../globals';

import './WorkloadCreateAws.css';

const styles = (theme) => ({
    root: {
        left: '4rem',
        right: 0,
        top: '4rem',
        bottom: 0,
        display: 'block',
        position: 'absolute',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
});

const compliances = ['HIPPA', 'PCI', 'SOC2', 'FedRAMP'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
class WorkloadCreateAws extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workloadName: Globals.EMPTY_STR,
            workloadDesc: Globals.EMPTY_STR,
            workloadType: Globals.EMPTY_STR,
            workloadCompliance: Globals.EMPTY_ARRAY,
            budgetPeriod: Globals.EMPTY_STR,
            selectedConfig: Globals.EMPTY_STR,
            copiedClipboard: false,
            addDisabled: true,
        };
    }
    // React Component Lifecycle Methods
    componentDidMount() {}
    componentDidUpdate() {}
    componentWillUnmount() {}

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value }, () => this.onChange());
    };

    copyExternalId = (event) => {
        clipCopy(this.state.externalId)
            .then(() => {
                this.setState({ copiedClipboard: true });
            })
            .catch(() => {
                this.setState({ copiedClipboard: false });
            })
            .finally(() => {
                console.log('Copied to Clipboard');
            });
    };

    onChange = () => {
        // Check required fields are entered
        if (this.state.workloadName.length > 0 && this.state.workloadType.length > 0 && this.state.workloadCompliance.length > 0 && this.state.workloadDesc.length > 0) {
            this.setState({ addDisabled: false });
        } else {
            this.setState({ addDisabled: true });
        }
    };

    cancelClick = (event) => {
        this.props.history.push('/spaces/vs-000000000');
    };

    createWorkload = (event) => {
        this.props.history.push('/spaces/' + this.state.space.Id + '/vw-68458493');
    };

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <div className={classes.root}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div className='createWorkloadHeader'>
                                <div className='createWorkloadTitle'>
                                    Create Workload
                                    <AwsLogo width={100} />
                                </div>
                                <div className='createWorkloadType'>Vega Complete</div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='createWorkloadCard'>
                                <div className='createWorkloadCardTitle'>Workload Information</div>
                                <div className='createWorkloadCardDesc'>Whith Vega Complete, the platform will build and manage your workload for you.</div>
                                <div className='createWorkloadInfo'>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <TextField
                                                id='workloadName'
                                                name='workloadName'
                                                label='Workload name'
                                                className={classes.textField}
                                                margin='normal'
                                                variant='outlined'
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant='outlined' className={classes.formControl}>
                                                <InputLabel
                                                    ref={(ref) => {
                                                        this.InputLabelRef = ref;
                                                    }}
                                                    htmlFor='workloadType'
                                                >
                                                    Workload Type
                                                </InputLabel>
                                                <Select
                                                    value={this.state.workloadType}
                                                    onChange={this.handleChange}
                                                    input={<OutlinedInput labelWidth={80} name='workloadType' id='workloadType' />}
                                                >
                                                    <MenuItem value='winApp'>Application on Windows</MenuItem>
                                                    <MenuItem value='linuxApp'>Application on Linux</MenuItem>
                                                    <MenuItem value='containerApp'>Containerized Application</MenuItem>
                                                    <MenuItem value='webApp'>Web Site</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor='workloadCompliance'>Workload Compliance</InputLabel>
                                                <Select
                                                    multiple
                                                    value={this.state.workloadCompliance}
                                                    onChange={this.handleChange}
                                                    input={<OutlinedInput labelWidth={80} name='workloadCompliance' id='workloadCompliance' />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    {compliances.map((comp) => (
                                                        <MenuItem key={comp} value={comp}>
                                                            <Checkbox checked={this.state.workloadCompliance.indexOf(comp) > -1} />
                                                            <ListItemText primary={comp} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id='workoadDesc'
                                                name='workloadDesc'
                                                label='Workload Description'
                                                className={classes.textField}
                                                margin='normal'
                                                variant='outlined'
                                                multiline
                                                rows='4'
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <div className='budgetDollar'>$</div>
                                                    <TextField
                                                        id='awsBudget'
                                                        name='awsBudget'
                                                        label='budget (Optional)'
                                                        className={classes.textField}
                                                        margin='normal'
                                                        variant='outlined'
                                                        onChange={this.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl variant='outlined' className={classes.formControl}>
                                                        <InputLabel
                                                            ref={(ref) => {
                                                                this.InputLabelRef = ref;
                                                            }}
                                                            htmlFor='budgetPeriod'
                                                        >
                                                            Period
                                                        </InputLabel>
                                                        <Select
                                                            value={this.state.budgetPeriod}
                                                            onChange={this.handleChange}
                                                            input={<OutlinedInput labelWidth={80} name='budgetPeriod' id='budgetPeriod' />}
                                                        >
                                                            <MenuItem value='monthly'>Monthly</MenuItem>
                                                            <MenuItem value='yearly'>Yearly</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <div>
                        <div className='workloadConfigTitle'>Workload Configuration</div>
                        <Grid container spacing={1}>
                            <Grid item xs={2} key='WinWeb'>
                                <div className='createWorkloadCard'>
                                    <div>
                                        <Radio
                                            checked={this.state.selectedConfig === 'WinWeb'}
                                            onChange={this.handleChange}
                                            value='WinWeb'
                                            name='selectedConfig'
                                            aria-label='WinWeb'
                                        />
                                    </div>
                                    <div className='configLogo'>
                                        <WinWebLogo />
                                    </div>
                                    <div>Windows 3 Tier Web</div>
                                </div>
                                <div className='detailsButton'>
                                    <WorkloadCreateAwsConfigDetails id='WinWebBut' name='WinWeb' />
                                </div>
                            </Grid>
                            <Grid item xs={2} key='LinuxWeb'>
                                <div className='createWorkloadCard'>
                                    <div>
                                        <Radio
                                            checked={this.state.selectedConfig === 'LinuxWeb'}
                                            onChange={this.handleChange}
                                            value='LinuxWeb'
                                            name='selectedConfig'
                                            aria-label='LinuxWeb'
                                        />
                                    </div>
                                    <div className='configLogo'>
                                        <LinuxWebLogo />
                                    </div>
                                    <div>LAMP 3 Tier Web</div>
                                </div>
                                <div className='detailsButton'>
                                    <WorkloadCreateAwsConfigDetails id='LinuxWebBut' name='LinuxWeb' />
                                </div>
                            </Grid>
                            <Grid item xs={2} key='RedshiftDW'>
                                <div className='createWorkloadCard'>
                                    <div>
                                        <Radio
                                            checked={this.state.selectedConfig === 'RedshiftDW'}
                                            onChange={this.handleChange}
                                            value='RedshiftDW'
                                            name='selectedConfig'
                                            aria-label='RedshiftDW'
                                        />
                                    </div>
                                    <div className='configLogo'>
                                        <RedshiftDWLogo />
                                    </div>
                                    <div>Redshift DW w/ Quicksite Front End</div>
                                </div>
                                <div className='detailsButton'>
                                    <WorkloadCreateAwsConfigDetails id='RedshiftDWBut' name='RedshiftDW' />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={true}
                        action={[
                            <Button className='cancelButton' key='cancel' color='primary' size='small' onClick={this.cancelClick}>
                                Cancel
                            </Button>,
                            <Button
                                className='createButton'
                                disabled={this.state.addDisabled}
                                variant='contained'
                                key='create'
                                color='primary'
                                size='small'
                                onClick={this.createWorkload}
                            >
                                Create Workload
                            </Button>,
                        ]}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(WorkloadCreateAws);
