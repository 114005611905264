import React, { useContext, useState, useEffect } from 'react';
import WorkloadHeader from '../../WorkloadHeader/WorkloadHeader';
import { AppStateContext } from '../../../contexts/AppState/AppState';
import { Paper } from '@material-ui/core';
import Grid from '../../Grid/Grid';
import { VegaApiContext } from '../../../contexts/VegaApi/VegaApi';
import { ListWorkloadResources, WorkspaceResource } from '../../../ServiceStack/ServiceStack.dtos';
import { useSnackbar } from 'notistack';

export interface VegaCompleteWorkRemoteLandingProps {}

const VegaCompleteWorkRemoteLanding: React.FC<VegaCompleteWorkRemoteLandingProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);
    const snackbar = useSnackbar();

    const [workstations, setWorkstations] = useState<WorkspaceResource[]>([]);

    useEffect(() => {
        vegaApi
            .getApiClient()
            .get(new ListWorkloadResources({ workloadId: appState.currentWorkload.workloadId }))
            .then((response) => {
                setWorkstations(response.result.workstations);
            })
            .catch((error) => {
                snackbar.enqueueSnackbar('There was an error loading your workstations', { variant: 'error' });
            });
    }, []);

    return (
        <>
            <WorkloadHeader title={appState.currentWorkload.name} isMainPage={true} showBorderBottom={true} />
            <Grid container spacing={4} className='padded'>
                {workstations.map((workstation) => (
                    <Grid item xs={3}>
                        <Paper key={workstation.id} variant='outlined' className='padded'>
                            <h5>{workstation.name}</h5>
                            <p className='body1'>
                                Type: {workstation.type}
                                <br />
                                Size: {workstation.size}
                                <br />
                                Status: {workstation.status}
                            </p>
                            <p className='body1'>
                                Username: {workstation.userName}
                                <br />
                                IP: {workstation.ipAddress}
                            </p>
                            <p>
                                <button className='accent primary medium center'>Reset Password</button>
                                <br />
                                <button className='accent primary medium center'>Request Changes</button>
                            </p>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default VegaCompleteWorkRemoteLanding;
