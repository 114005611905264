import React, { useState, useContext } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import PasswordInput from '../PasswordInput/PasswordInput';
import * as Globals from '../../globals';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { CreateLoginPasswordAction, PasswordAction } from '../../ServiceStack/ServiceStack.dtos';

export interface ForgotPasswordResetProps {
    email: string;
    onComplete: () => void;
}

const ForgotPasswordReset: React.FC<ForgotPasswordResetProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState();
    const [isProcessing, setIsProcessing] = useState(false);

    const handleNewPw = (myPwd) => setNewPassword(myPwd);

    const handleNewPwConfirm = (myPwd) => setConfirmPassword(myPwd);

    const formSubmit = (event) => {
        event.preventDefault();
        setIsProcessing(true);
        const request = new CreateLoginPasswordAction({
            email: props.email,
            passwordAction: PasswordAction.Change,
            currentPassword: Globals.NEW_USER_PWD,
            proposedPassword: newPassword,
        });
        vegaApi
            .getApiClient()
            .post(request)
            .then(() => {
                props.onComplete();
            })
            .catch((error) => {
                setError(error.responseStatus.message);
                setIsProcessing(false);
            });
    };

    const allowNext = newPassword === confirmPassword && newPassword.length > 0;

    return (
        <>
            {!!error && (
                <div className='snackbarContainer top'>
                    <div className='snackbar simpleCard padded primary'>{error}</div>
                </div>
            )}
            <form onSubmit={formSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <h5>Reset Password</h5>
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <PasswordInput placeholder='New Password' setPwd={handleNewPw} />
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <PasswordInput placeholder='Confirm Password' setPwd={handleNewPwConfirm} />
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={12}>
                        {!isProcessing && (
                            <button className='normal primary medium center' disabled={!allowNext}>
                                Reset Password
                            </button>
                        )}
                        {isProcessing && (
                            <div className='center'>
                                <CircularProgress />
                            </div>
                        )}
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default ForgotPasswordReset;
