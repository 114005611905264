import React from 'react';
import { Grid, Switch, TextField } from '@material-ui/core';
import { CloudProviderIntegrationDefinition, CloudProviderIntegrationDetail } from '../../ServiceStack/ServiceStack.dtos';
import Icon from '../Icon/Icon';
import WorkloadPolicy from '../WorkloadPolicy/WorkloadPolicy';

export interface VegaConnectAwsProps {
    integration: CloudProviderIntegrationDefinition;
    onEnabled: (val: CloudProviderIntegrationDetail) => void;
    onDisabled: (val: CloudProviderIntegrationDetail) => void;
    enableOverride?: boolean;
}

const VegaConnectAws = (props: VegaConnectAwsProps) => {
    const [enabled, setEnabled] = React.useState(false);
    const [showDetails, setShowDetails] = React.useState(false);
    const [parameters, setParameters] = React.useState([]);
    const updateParamValue = (event) => {
        const params = [...parameters];
        params[params.findIndex((p) => p.type === event.target.name)].value = event.target.value;
        setParameters(params);
    };

    const toggleShowDetails = () => setShowDetails(!showDetails);

    const switchClicked = (event) => setEnabled(!enabled);

    React.useEffect(() => {
        const details = new CloudProviderIntegrationDetail();
        details.cloudProviderIntegrationType = props.integration.cloudProviderIntegrationType;

        if (enabled) {
            details.parameters = parameters;
            props.onEnabled(details);
        } else props.onDisabled(details);
        // eslint-disable-next-line
    }, [enabled]);

    React.useEffect(() => {
        if (props.enableOverride) setEnabled(true);
        else setEnabled(props.integration.isEnabled);
        setParameters(props.integration.parameters);
        // eslint-disable-next-line
    }, []);

    return (
        <section className={`marginNormal paper padded`}>
            <div className='flexRow flexSeparate'>
                <div className='flexRow alignItemsCenter'>
                    <div>
                        <h5>{props.integration.title}</h5>
                    </div>
                    <div>
                        <Switch checked={enabled} onChange={switchClicked} />
                    </div>
                </div>
                <div>
                    {enabled && (
                        <button onClick={toggleShowDetails} className='accent small primary'>
                            {showDetails && (
                                <>
                                    Less Details <Icon icon='chevron-up' />
                                </>
                            )}
                            {!showDetails && (
                                <>
                                    Show Details <Icon icon='chevron-down' />
                                </>
                            )}
                        </button>
                    )}
                </div>
            </div>
            <p className='c-slate-light'>{props.integration.description}</p>
            {showDetails && (
                <div className='paper dark padded'>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <h6>Details</h6>
                            This will connect your AWS account [number] to Vega. Once you click the Add Workload button, you will need to log into the account you are connecting to
                            and run our Cloud Formation Template.
                            <br />
                            <br />
                            Here is what Vega will be doing to your account:
                            <br />
                            <br />
                            Creating a new policy which can be viewed with the link above.
                            <br />
                            Adding a new cross account Role to that account. Once that role is created, it delivers the ARN to Vega so it may gather data and perform actions on
                            your behalf.
                        </Grid>
                        <Grid item xs={6}>
                            <h6>AWS Policy</h6>
                            <WorkloadPolicy cft={props.integration.policy} />
                            <hr />
                            {parameters.length > 0 && (
                                <Grid container spacing={4}>
                                    {parameters.map((parameter, index) => (
                                        <Grid item xs={12} key={index}>
                                            <TextField
                                                key={index}
                                                type='text'
                                                name={parameter.type}
                                                label={parameter.type}
                                                value={parameter.value}
                                                onChange={updateParamValue}
                                                variant='outlined'
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </div>
            )}
        </section>
    );
};

export default VegaConnectAws;
