import React, { useContext } from 'react';
import { Grid, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import Modal from '../Modal/Modal';
import Stepper from '../Stepper/Stepper';
import WorkloadPark from '../WorkloadPark/WorkloadPark';
import WorkloadParkSummary from '../WorkloadParkSummary/WorkloadParkSummary';
import { ResourcesContext } from '../../contexts/Resources/Resources';
import AdvanceButton from '../AdvanceButton/AdvanceButton';
import { SchedulesContext } from '../../contexts/Schedules/Schedules';
import { convertSecToHourMin } from '../../utils';
import dateTimePipe from '../../utils/DateTimePipe';
import { withRouter } from 'react-router-dom';
import { ActionSchedule } from '../ActionSchedule/ActionSchedule';
import moment from 'moment';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { useSnackbar } from 'notistack';
import { Times } from '../Scheduler/Scheduler';
import Typography from '@material-ui/core/Typography';

const WorkloadParkWizard = withRouter((props) => {
    const [currentStep, setCurrentStep] = React.useState(0);
    const [isProcessing] = React.useState(false);
    const [scheduleMode, setScheduleMode] = React.useState('now');
    const [schedule, setSchedule] = React.useState([]);
    const [scheduleAlreadyExists, setScheduleAlreadyExists] = React.useState(false);
    const [showConfirmDeleteSchedule, setShowConfirmDeleteSchedule] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);

    const appState = useContext(AppStateContext);
    const snackbar = useSnackbar();
    const resourcesContext = useContext(ResourcesContext);
    const schedulesContext = useContext(SchedulesContext);

    const backClicked = () => setCurrentStep(currentStep - 1);

    const mapScheduleToModel = (schedule) => {
        return schedule.map((scheduledTime) => ({
            startMinuteUtc: scheduledTime.start - moment().utcOffset(),
            endMinuteUtc: scheduledTime.end - moment().utcOffset(),
        }));
    };

    const mapModelToSchedule = (model) => {
        return model.map((modelTime) => ({
            start: modelTime.startMinuteUtc + moment().utcOffset(),
            end: modelTime.endMinuteUtc + moment().utcOffset(),
        }));
    };

    const confirmPark = async (workloadId) => {
        setProcessing(true);
        try {
            if (scheduleMode === 'schedule') {
                if (scheduleAlreadyExists) {
                    await schedulesContext.deleteSchedule(props.workload.workloadId);
                }
                let model = {};
                model.entriesUtc = mapScheduleToModel(schedule);
                await schedulesContext.postSchedule(props.workload.workloadId, model);
                await resourcesContext.parkAllResources(appState.awsRegions, workloadId, scheduleMode);
                snackbar.enqueueSnackbar('The selected workload is now scheduled for parking', { variant: 'info' });
            } else if (scheduleMode === 'now') {
                resourcesContext.parkAllResources(appState.awsRegions, workloadId, scheduleMode);
                snackbar.enqueueSnackbar('The selected workload is now queued for parking', { variant: 'info' });
            }
            props.onClose();
        } catch (error) {
            console.error(error);
            setProcessing(false);
            snackbar.enqueueSnackbar('There was an error parking your workload', { variant: 'error' });
        }
    };

    const deleteSchedule = () => {
        schedulesContext.deleteSchedule(props.workload.workloadId).then((response) => {
            // remove all the action tags
            resourcesContext.clearVegaActionTag(appState.awsRegions, props.workload.workloadId);
            props.onClose();
        });
    };

    const onChangeSchedule = (selectedTimes) => {
        const fixedSelectedTimes = selectedTimes.map((selectedTime) => (selectedTime.end === 0 ? { ...selectedTime, end: 10080 } : selectedTime));

        setSchedule(fixedSelectedTimes);
    };

    React.useEffect(() => {
        schedulesContext
            .getSchedule(props.workload.workloadId)
            .then((newSchedule) => {
                let data = newSchedule.data.result;
                data.startTime = convertSecToHourMin(data.startTimeInSeconds);
                data.endTime = convertSecToHourMin(data.stopTimeInSeconds);
                setScheduleAlreadyExists(true);
                console.log(data);
                setSchedule(mapModelToSchedule(data.entriesUtc));
            })
            .catch((error) => {
                console.log(error);
                // it's all good, no schedule exists already! :)
            });
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        resourcesContext.loadResources(props.workload.workloadId);
    }, []);

    const confirmDeleteScheduleDialog = (
        <Dialog open={showConfirmDeleteSchedule} onClick={() => setShowConfirmDeleteSchedule(false)}>
            <DialogTitle>Confirm Dangerous Action</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete this schedule?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className='outline primary normal' onClick={() => setShowConfirmDeleteSchedule(false)}>
                    Cancel
                </button>
                <button className='flat danger normal' onClick={deleteSchedule}>
                    Delete Workload Schedule
                </button>
            </DialogActions>
        </Dialog>
    );

    const header = (
        <div style={{ display: 'block' }}>
            <h5>
                <span className='icon icon-park' />
                &nbsp;&nbsp;Park&nbsp;{props.workload.name}
            </h5>
            {scheduleMode === 'schedule' && (
                <Typography variant={'body2'} className='c-slate-light' style={{ marginLeft: '2rem' }}>
                    Set a weekly Operating Schedule for your workload.
                </Typography>
            )}
        </div>
    );

    const footer = (
        <>
            <button className={`accent primary medium ${currentStep > 0 ? '' : 'invisible'}`} onClick={backClicked}>
                <span className='icon icon-back-arrow' /> Previous
            </button>
            <div className='flexRow alignItemsCenter'>
                <div>
                    <button style={{ marginRight: '25px' }} className='flat c-slate-light small' onClick={props.onClose}>
                        Cancel
                    </button>
                    {!processing &&
                        (scheduleMode === 'now' ? (
                            <button className='flat primary small' onClick={() => confirmPark(props.workload.workloadId)}>
                                Park Workload
                            </button>
                        ) : (
                            <>
                                {scheduleAlreadyExists && (
                                    <>
                                        <button style={{ marginRight: '25px' }} className='danger accent small' onClick={() => setShowConfirmDeleteSchedule(true)}>
                                            Delete Schedule
                                        </button>
                                        {confirmDeleteScheduleDialog}
                                    </>
                                )}
                                <button className='flat primary small' onClick={() => confirmPark(props.workload.workloadId)} disabled={schedule.length < 1}>
                                    Set Schedule
                                </button>
                            </>
                        ))}
                </div>
                {processing && (
                    <div className='center'>
                        <CircularProgress />
                    </div>
                )}
            </div>
        </>
    );

    const content = () => {
        return (
            <ActionSchedule
                initialSelections={schedule}
                onChangeSchedule={onChangeSchedule}
                scheduleMode={scheduleMode}
                onSetScheduleMode={(event, scheduleMode) => setScheduleMode(scheduleMode)}
            />
        );
    };

    return <Modal size='large' onClose={props.onClose} header={header} content={content()} footer={footer} />;
});

export default WorkloadParkWizard;
