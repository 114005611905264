import React, { useState, useContext, useEffect } from 'react';
import VegaConnectAws from '../VegaConnectAws/VegaConnectAws';
import WorkloadHeader from '../WorkloadHeader/WorkloadHeader';
import { RouteComponentProps } from 'react-router-dom';
import ContentLoading from '../ContentLoading/ContentLoading';
import {
    CloudProviderIntegrationDefinition,
    CloudProviderIntegrationDetail,
    CloudProviderIntegrationType,
    StartCloudProviderIntegrations,
    ListCloudProviderIntegration,
} from '../../ServiceStack/ServiceStack.dtos';
import { CircularProgress } from '@material-ui/core';
import { WebSocketContext } from '../../contexts/WebSocket/WebSocket';
import { ResourcesContext } from '../../contexts/Resources/Resources';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { AppStateContext } from '../../contexts/AppState/AppState';
import Modal from '../Modal/Modal';
import PageFooter from '../PageFooter/PageFooter';
import { urlUpOneLevel } from '../../utils';
import { useSnackbar } from 'notistack';
import { WebSocketMessage, MessageAction } from '../../contexts/WebSocket/WebSocketTypes';

export interface WorkloadConfigProps extends RouteComponentProps<any> {}

const WorkloadConfig: React.FC<WorkloadConfigProps> = (props) => {
    const websocketContext = useContext(WebSocketContext);
    const resourcesContext = useContext<any>(ResourcesContext);
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);
    const snackbar = useSnackbar();

    const [loaded, setLoaded] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [awsUrlReady, setAwsUrlReady] = useState(false);
    const [integrations, setIntegrations] = useState<CloudProviderIntegrationDefinition[]>([]);
    const [integrationDetails, setIntegrationDetails] = useState<CloudProviderIntegrationDetail[]>([]);
    const [isNewWorkload, setIsNewWorkload] = useState<boolean>();
    const [showConfirmSkip, setShowConfirmSkip] = useState(false);
    const [awsUrl, setAwsUrl] = useState('');
    const [websocketId, setWebsocketId] = useState<number>();

    const setEnabled = (val: CloudProviderIntegrationDetail) =>
        setIntegrationDetails((old) => {
            return [...old, val];
        });

    const setDisabled = (val: CloudProviderIntegrationDetail) => {
        const integrationDetailsTemp = [...integrationDetails];
        integrationDetailsTemp.splice(
            integrationDetailsTemp.findIndex((x) => x.cloudProviderIntegrationType === val.cloudProviderIntegrationType),
            1
        );
        setIntegrationDetails(integrationDetailsTemp);
    };

    const goUpLevel = () => {
        appState.setNewLoadId('');
        urlUpOneLevel(props);
    };

    const skipConfig = () => setShowConfirmSkip(true);

    const onAfterLoaded = async () => {
        try {
            const cpRequest = new ListCloudProviderIntegration({ workloadId: appState.currentWorkload.workloadId });
            const cpResult = (await vegaApi.getApiClient().get(cpRequest)).result;

            // TODO this logic should live in the API, this is a temporary patch
            const row = cpResult.findIndex((x) => x.cloudProviderIntegrationType === 'Billing');
            const parameter = cpResult[row].parameters.findIndex((y) => y.type === 'AwsS3BillingBucket');
            cpResult[row].parameters[parameter].value =
                // eslint-disable-next-line
                cpResult[row].parameters[parameter].value.replace('${organizationName}', appState.orgData.organizationName.toLowerCase().replace(/[^\w]/gi, ''));
            setIntegrations(cpResult);
            setLoaded(true);
        } catch (error) {
            snackbar.enqueueSnackbar('We were unable to load Cloud Provider Integrations', { variant: 'error' });
            console.error(error);
            setLoaded(true);
        }
    };

    const onSave = () => {
        setWaiting(true);
        registerWebsocketHandlers();
        const request = new StartCloudProviderIntegrations();
        request.workloadId = appState.currentWorkload.workloadId;
        request.cloudProvider = appState.currentWorkload.cloudProvider;
        request.token = '';
        request.integrationDetails = integrationDetails;
        vegaApi
            .getApiClient()
            .post(request)
            .then((response) => console.log(response));
    };

    const handleUrlClick = () => {
        setWaiting(true);
        window.open(awsUrl);
    };

    const onAwsUrlReceived = (url: string) => {
        setAwsUrl(url);
        setAwsUrlReady(true);
        setWaiting(false);
    };

    const onComplete = () => {
        resourcesContext.discoverResources(appState.currentWorkload.workloadId);
        appState.setNewLoadId('');
        snackbar.enqueueSnackbar('Your workload is now configured with AWS', { variant: 'success' });
        websocketContext.removeListener(websocketId);
        goUpLevel();
    };

    const registerWebsocketHandlers = (): void => {
        setWebsocketId(
            websocketContext.addListener((msg: WebSocketMessage) => {
                if (msg.actionType === MessageAction.CLOUDPROVIDERAUTHORIZATIONURL) onAwsUrlReceived(msg.url);
                if (msg.actionType === MessageAction.CLOUDPROVIDERAUTHORIZATIONFINISHED) onComplete();
            })
        );
    };

    useEffect(() => {
        if (appState.currentWorkload) onAfterLoaded();
        // eslint-disable-next-line
    }, [appState.currentWorkload]);

    useEffect(() => {
        setIsNewWorkload(!!appState.newLoadId);
        appState.setNewLoadId('');

        onAfterLoaded();
        // eslint-disable-next-line
    }, []);

    const saveDisabled =
        !integrationDetails.length ||
        (integrationDetails.length && integrationDetails.findIndex((x) => x.cloudProviderIntegrationType === CloudProviderIntegrationType.Discovery) === -1);

    return (
        <>
            {!loaded && <ContentLoading />}
            {showConfirmSkip && (
                <Modal
                    header={<h6>Skip Configuration?</h6>}
                    content={<>Skipping configuration will create a workload shell, however you will not have cloud provider integration.</>}
                    footer={
                        <>
                            <button className='primary text' type='button' onClick={() => setShowConfirmSkip(false)}>
                                Cancel
                            </button>
                            <button className='danger accent medium' onClick={() => goUpLevel()}>
                                Skip Configuration
                            </button>
                        </>
                    }
                    onClose={() => setShowConfirmSkip(false)}
                />
            )}
            <div className='scrollContainer flexGrow'>
                <WorkloadHeader title='Configure Workload Policies' isMainPage={false} />
                {integrations.map((integration, index) => (
                    <VegaConnectAws key={index} integration={integration} onEnabled={setEnabled} onDisabled={setDisabled} enableOverride={isNewWorkload} />
                ))}
            </div>
            <PageFooter
                textArea={
                    awsUrlReady && (
                        <>
                            Pressing the button "Complete in AWS" will open your AWS Console in another window. Please make sure you are logged into account{' '}
                            {appState.currentWorkload.workloadConfigurationAws.accountId} to complete workload configuration in AWS.
                        </>
                    )
                }
                buttons={
                    <>
                        {isNewWorkload && (
                            <>
                                <button className='danger accent medium' onClick={skipConfig}>
                                    Skip Configuration
                                </button>
                                {!waiting && !awsUrlReady && (
                                    <button className='primary normal medium' disabled={saveDisabled} onClick={onSave}>
                                        Continue
                                    </button>
                                )}
                                {waiting && <CircularProgress />}
                                {awsUrlReady && !waiting && (
                                    <button className='success normal medium' onClick={handleUrlClick}>
                                        Complete in AWS
                                    </button>
                                )}
                            </>
                        )}
                        {!isNewWorkload && (
                            <>
                                <button className='primary accent medium' onClick={goUpLevel}>
                                    Cancel
                                </button>
                                {!waiting && !awsUrlReady && (
                                    <button className='primary normal medium' disabled={saveDisabled} onClick={onSave}>
                                        Continue
                                    </button>
                                )}
                                {waiting && <CircularProgress />}
                                {awsUrlReady && !waiting && (
                                    <button className='success normal medium' onClick={handleUrlClick}>
                                        Complete in AWS
                                    </button>
                                )}
                            </>
                        )}
                    </>
                }
            />
        </>
    );
};

export default WorkloadConfig;
