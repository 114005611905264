import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AdminNavigation from '../AdminNavigation/AdminNavigation';
import { Table, TableHead, TableRow, TableCell, TableBody, TextField, RadioGroup, FormControlLabel, Radio, Tooltip } from '@material-ui/core';
import CombinedTopbar from '../CombinedTopbar/CombinedTopbar';
import Icon from '../Icon/Icon';

export interface IAdminBudgetsLanding extends RouteComponentProps<any> {}

const AdminBudgetsLanding: React.FC<IAdminBudgetsLanding> = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <>
            <div className='flexRow flexGrow'>
                <AdminNavigation />
                <div className='flexCol flexGrow bg-white'>
                    <header className='pageHeaderContainer light bottomBorder'>
                        <CombinedTopbar />
                        <h4>Budgets</h4>
                    </header>
                    <div className='flexGrow marginNormal'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Monthly Spend Limits</TableCell>
                                    <TableCell>
                                        Actions&nbsp;
                                        <Tooltip
                                            arrow
                                            open={showTooltip}
                                            onClose={() => setShowTooltip(false)}
                                            title='You may set role restrictions if the role is going to or has exceeded their budget. Deny will not allow them to create new infrastructure. Notifications will allow them to create infrastructure and will email the org admin.'
                                            disableHoverListener
                                        >
                                            <button className='iconButton' onClick={() => setShowTooltip(!showTooltip)}>
                                                <Icon icon='info' />
                                            </button>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {/* <TableCell colSpan={3}>No budgets have been set</TableCell> */}
                                    <TableCell>Role Name</TableCell>
                                    <TableCell size='small'>
                                        <TextField variant='outlined' label='$' size='small' />
                                    </TableCell>
                                    <TableCell size='small'>
                                        <RadioGroup style={{ flexDirection: 'row' }}>
                                            <FormControlLabel value='Deny' control={<Radio />} label='Deny' />
                                            <FormControlLabel value='Notification' control={<Radio />} label='Notification Only' />
                                        </RadioGroup>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    {/* <TableCell colSpan={3}>No budgets have been set</TableCell> */}
                                    <TableCell>Role Name</TableCell>
                                    <TableCell size='small'>
                                        <TextField variant='outlined' label='$' size='small' />
                                    </TableCell>
                                    <TableCell size='small'>
                                        <RadioGroup style={{ flexDirection: 'row' }}>
                                            <FormControlLabel value='Deny' control={<Radio />} label='Deny' />
                                            <FormControlLabel value='Notification' control={<Radio />} label='Notification Only' />
                                        </RadioGroup>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    {/* <TableCell colSpan={3}>No budgets have been set</TableCell> */}
                                    <TableCell>Role Name</TableCell>
                                    <TableCell size='small'>
                                        <TextField variant='outlined' label='$' size='small' />
                                    </TableCell>
                                    <TableCell size='small'>
                                        <RadioGroup style={{ flexDirection: 'row' }}>
                                            <FormControlLabel value='Deny' control={<Radio />} label='Deny' />
                                            <FormControlLabel value='Notification' control={<Radio />} label='Notification Only' />
                                        </RadioGroup>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminBudgetsLanding;
