import React, { useContext, useState, useEffect } from 'react';
import { ReactComponent as AwsLogo } from '../../images/icons/logos/aws-logo.svg';
import scss from './WorkloadSatelliteAws.module.scss';
import WorkloadSatelliteAwsModel from '../WorkloadSatelliteAwsModel/WorkloadSatelliteAwsModel';
import { CircularProgress } from '@material-ui/core';
import { ResourcesContext } from '../../contexts/Resources/Resources';
import { RouteComponentProps } from 'react-router';
import { ListWorkloadResources, CloudResource, CreateSatelliteWorkload } from '../../ServiceStack/ServiceStack.dtos';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { AppStateContext } from '../../contexts/AppState/AppState';
import PageFooter from '../PageFooter/PageFooter';
import { urlUpOneLevel } from '../../utils';
import CombinedTopbar from '../CombinedTopbar/CombinedTopbar';

export interface WorkloadSatelliteAwsProps extends RouteComponentProps {}

const WorkloadSatelliteAws: React.FC<WorkloadSatelliteAwsProps> = (props) => {
    const resourcesContext = useContext<any>(ResourcesContext);
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);

    const [formState, setFormState] = useState({
        name: '',
        description: '',
        workloadTypeId: -1,
    });
    const [allowCreate, setAllowCreate] = useState(false);
    const [selectedResources, setSelectedResources] = useState<CloudResource[]>([]);
    const [loading, setLoading] = useState(false);
    const [resources, setResources] = useState<CloudResource[]>([]);

    const updateAllowCreate = () => {
        setAllowCreate(!!formState.name && !!formState.description && !!formState.workloadTypeId);
    };

    const handleChange = (event) => {
        setFormState({ ...formState, [event.target.name]: event.target.value });
    };

    const createWorkload = async () => {
        setLoading(true);
        const requestModel = new CreateSatelliteWorkload({ ...formState, workloadId: appState.currentWorkload.workloadId });
        const createSatResponse = await vegaApi.getApiClient().post(requestModel);
        await resourcesContext.tagSatellites('tag', appState.awsRegions, selectedResources, createSatResponse.result.satelliteId, appState.currentWorkload.workloadId);
        await appState.loadWorkloads();
        resourcesContext.discoverResources(appState.currentWorkload.workloadId); // parent
        resourcesContext.discoverResources(createSatResponse.result.workloadId); // satellite
        props.history.push(createSatResponse.result.workloadId);
    };

    const cancelClick = () => {
        const val = props.location.pathname.substring(0, props.location.pathname.lastIndexOf('/'));
        props.history.push(val);
    };

    useEffect(() => {
        updateAllowCreate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState, selectedResources]);

    useEffect(() => {
        if (!appState.currentWorkload.workloadId) {
            urlUpOneLevel(props);
            return;
        }

        const resourcesRequest = new ListWorkloadResources({ workloadId: appState.currentWorkload.workloadId });
        vegaApi
            .getApiClient()
            .get(resourcesRequest)
            .then((response) => {
                setResources(response.result.resources);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className='scrollContainer flexGrow flexCol'>
                <header className='pageHeaderContainer'>
                    <CombinedTopbar />
                    <div className='flexRow flexSeparate alignItemsCenter'>
                        <h4>Create Satellite Workload</h4>
                        <div className='flexRow'>
                            <div className={`c-slate-light ${scss.addSpaceType}`}>
                                <span className='icon icon-satellite' />
                                &nbsp; Satellite
                            </div>
                            <div className='svgIconNormal'>
                                <AwsLogo />
                            </div>
                        </div>
                    </div>
                </header>

                <div className='marginNormal'>
                    <p className='c-slate-light'>
                        Satellite workloads are connected to a parent workload account. When you add resources from the parent workload, they will not show in the parent resource
                        list and you may perform actions on these resources separately.
                    </p>
                </div>

                {appState.currentWorkload.workloadId && (
                    <WorkloadSatelliteAwsModel
                        mode='Add'
                        model={formState}
                        resources={resources}
                        handleChange={handleChange}
                        selectedResources={selectedResources}
                        selectedResourcesChange={(newVals) => setSelectedResources(newVals)}
                    />
                )}
            </div>
            <PageFooter
                buttons={
                    <>
                        <button className='accent medium primary' onClick={cancelClick}>
                            Cancel
                        </button>
                        {!loading && (
                            <button className='normal medium primary' disabled={!allowCreate} onClick={createWorkload}>
                                Create Workload
                            </button>
                        )}
                        {loading && <CircularProgress />}
                    </>
                }
            />
        </>
    );
};

export default WorkloadSatelliteAws;
