import React, { useContext } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, DialogTitle, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { ActionsContext } from '../../contexts/Actions/Actions';
import { SchedulesContext } from '../../contexts/Schedules/Schedules';
import { ResourcesContext } from '../../contexts/Resources/Resources';
import Modal from '../Modal/Modal';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import dateTimePipe from '../../utils/DateTimePipe';
import { Scheduler } from '../Scheduler/Scheduler';
import moment from 'moment';
import * as Globals from '../../globals';
import { AppStateContext } from '../../contexts/AppState/AppState';

export interface WorkloadActionsTableProps extends RouteComponentProps<any> {
    type: 'scheduled' | 'history';
    entityId: string;
}

const WorkloadActionsTable: React.FC<WorkloadActionsTableProps> = (props) => {
    const actionsContext = useContext<any>(ActionsContext);
    const schedulesContext = useContext<any>(SchedulesContext);
    const resourcesContext = useContext<any>(ResourcesContext);
    const appState = useContext(AppStateContext);

    const [actions, setActions] = React.useState([]);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showEdit, setShowEdit] = React.useState(false);
    const [schedule, setSchedule] = React.useState([]);

    const loadData = () => {
        if (props.type === 'scheduled') {
            actionsContext
                .loadActions(props.entityId)
                .then((response) => setActions(response.data.result))
                .catch((error) => {
                    // no actions to load
                });
        } else {
            actionsContext.loadHistoricalActions(props.entityId).then((response) => setActions(response.data.result));
        }
    };

    React.useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    const mapScheduleToModel = (schedule) => {
        return schedule.map((scheduledTime) => ({
            startMinuteUtc: scheduledTime.start - moment().utcOffset(),
            endMinuteUtc: scheduledTime.end - moment().utcOffset(),
        }));
    };

    const mapModelToSchedule = (model) => {
        return model.map((modelTime) => ({
            start: modelTime.startMinuteUtc + moment().utcOffset(),
            end: modelTime.endMinuteUtc + moment().utcOffset(),
        }));
    };

    const showEditSchedule = (actionType) => {
        schedulesContext
            .getSchedule(props.entityId)
            .then((response) => {
                const model = response.data.result;
                setSchedule(mapModelToSchedule(model.entriesUtc));
                setShowEdit(true);
            })
            .catch((error) => {
                // it's all good, no schedule exists already! :)
            });
    };

    const updateSchedule = () => {
        let model: any = {};
        model.entriesUtc = mapScheduleToModel(schedule);
        schedulesContext.postSchedule(props.entityId, model).then((response) => {
            setShowEdit(false);
            loadData();
        });
    };

    const cancelSchedule = () => {
        schedulesContext.deleteSchedule(props.entityId).then((_) => {
            // remove action tags
            resourcesContext.clearVegaActionTag(appState.awsRegions, appState.currentWorkload.workloadId);
            setActions([]);
            loadData();
            setShowConfirm(false);
        });
    };

    return (
        <>
            {showEdit && (
                <Modal
                    size='large'
                    onClose={() => setShowEdit(false)}
                    header={<h5>Edit Schedule</h5>}
                    content={<Scheduler initialSelections={schedule} onChangeSchedule={(selectedTimes) => setSchedule(selectedTimes)} />}
                    footer={
                        <>
                            <button onClick={() => setShowEdit(false)} className='primary accent medium'>
                                Cancel
                            </button>
                            <button onClick={updateSchedule} className='primary normal medium'>
                                Update Schedule
                            </button>
                        </>
                    }
                />
            )}
            <Dialog open={showConfirm} onClose={() => setShowConfirm(false)}>
                <DialogTitle>Confirm Dangerous Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>This will clear the entire workload's schedule!</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={() => setShowConfirm(false)} className='accent primary medium'>
                        Cancel
                    </button>
                    <button onClick={cancelSchedule} className='normal danger medium'>
                        Clear Schedule
                    </button>
                </DialogActions>
            </Dialog>
            {actions && actions.length > 0 && (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>{props.type === 'scheduled' ? 'Next Run' : 'Last Run'}</TableCell>
                            {props.type === 'scheduled' && <TableCell>Actions</TableCell>}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {actions.map((action) => (
                            <TableRow key={action.id}>
                                <TableCell component='th' scope='row'>
                                    {action.actionType === Globals.SPACE_ACTIONS.BACKUP && <span className='icon icon-backup' />}
                                    {(action.actionType === Globals.SPACE_ACTIONS.PARK || action.actionType === Globals.SPACE_ACTIONS.UNPARK) && (
                                        <span className='icon icon-park' />
                                    )}
                                    {action.actionType === 'Discover' && <span className='icon icon-update-schedule' />}
                                </TableCell>
                                <TableCell>{action.friendlyName}</TableCell>
                                <TableCell>{dateTimePipe(action.scheduledDateUtc)}</TableCell>
                                {props.type === 'scheduled' && (
                                    <TableCell>
                                        <button className='iconButton' onClick={() => showEditSchedule(action.actionType)}>
                                            <span className='icon icon-backup' />
                                        </button>
                                        <button className='iconButton' onClick={() => setShowConfirm(true)}>
                                            <span className='icon icon-close' />
                                        </button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
            {(!actions || actions.length === 0) && <div className='center'>There aren't any {props.type} records!</div>}
        </>
    );
};

export default withRouter(WorkloadActionsTable);
