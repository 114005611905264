import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';

export const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    let number: string = phoneNumber.toString();
    return `+${number.charAt(0)} (${number.substring(1, 4)}) ${number.substring(4, 7)}-${number.substring(7, 11)}`;
};

export const parseFormatMomentDate = (mmt) => formatMomentDate(moment(mmt));

export const parseFormatMomentTime = (mmt) => formatMomentTime(moment(mmt));

export const formatMoment = (mmt) => {
    if (!mmt) return '';
    return mmt.format('LLL');
};

export const formatMomentDate = (mmt) => {
    if (!mmt) return '';
    return mmt.format('LL');
};

export const formatMomentTime = (mmt) => {
    if (!mmt) return '';
    return mmt.format('LT');
};

export function dateToUtcSeconds(time) {
    return time.getUTCSeconds() + time.getUTCMinutes() * 60 + time.getUTCHours() * 60 * 60;
}

export function convertTo12HourTime(oldTime) {
    let cur = moment();
    let temp = oldTime.split(':').map((x) => parseInt(x));
    cur.hour(temp[0]);
    cur.minute(temp[1]);
    return cur.format('LT');
}

export const convertToSeconds = (time) => {
    let cur = moment();
    let temp = time.split(':').map((x) => parseInt(x));
    cur.minute(temp[1]);
    cur.hour(temp[0]);
    cur = cur.utc();
    return cur.minute() * 60 + cur.hour() * 3600;
};

export const convertSecToHourMin = (time) => {
    const intTime = parseInt(time);
    const hours = Math.floor(intTime / 3600);
    const mins = Math.floor((intTime % 3600) / 60);
    let cur = moment().utc();
    cur.minute(mins);
    cur.hour(hours);
    cur = cur.local();
    return `${cur.hour().toString().padStart(2, '0')}:${cur.minute().toString().padStart(2, '0')}`;
};

export const timeSince = (time) => {
    if (time !== undefined && time !== '') {
        if (time.charAt(time.length - 1) !== 'Z') time += 'Z';
        return moment(time).fromNow();
    }

    return time;
};

export const UtcDateAddMinutes = (utcDate, minutes) => {
    return new Date(utcDate.getTime() + minutes * 60000);
};

export const isGuid = (value: string) => {
    return value && value.indexOf('-') === 8;
};

export const urlUpOneLevel = (props: RouteComponentProps) => {
    const val = props.location.pathname.substring(0, props.location.pathname.lastIndexOf('/'));
    props.history.push(val);
};

function LeftPadWithZeros(number, length) {
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }

    return str;
}

function MakeMoney(myNum) {
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    if (!myNum) return formatter.format(0);

    return formatter.format(myNum);
}

function RandomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

function GetAmountTimePassedMinutes(start, end) {
    return Math.round((end - start) / 60000);
}

function DoProcessingInit(myThis) {
    myThis.setState({
        IsError: false,
        ErrorCode: '',
        ErrorMsg: '',
        IsProcessing: true,
        IsCompleted: false,
    });
}

function DoProcessingError(myThis, myResponse) {
    myThis.setState({
        IsError: true,
        ErrorCode: myResponse.errorCode,
        ErrorMsg: myResponse.errorMsg,
        IsValidOrgDomainName: false,
    });
}

function DoErrorStuff(e, myThis) {
    if (e.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Response Data', e.response.data);
        console.error('Response status', e.response.status);
        console.error('Response Headers', e.response.headers);
    } else if (e.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error('Request', e.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', e.message);
    }
    console.error('Config', e.config);

    var theStatus = '',
        theData: any = {};
    if (e.response !== undefined) {
        theStatus = e.response.status;
        if (e.response.data !== undefined) theData = e.response.data;
    }

    var theErrorMsg = '';
    if (theData !== null) {
        if (theData.responseStatus !== undefined) {
            theErrorMsg = theData.responseStatus.errorCode + ':' + theData.responseStatus.message;
        }
    }
    myThis.setState({
        IsError: true,
        ErrorCode: theStatus,
        ErrorMsg: theErrorMsg,
        IsProcessing: false,
        IsCompleted: false,
    });
}

export { LeftPadWithZeros, MakeMoney, RandomDate, GetAmountTimePassedMinutes, DoErrorStuff, DoProcessingInit, DoProcessingError };
