import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { AppStateContext } from '../../contexts/AppState/AppState';

export default function Logout() {
    const appState = useContext(AppStateContext);

    useEffect(() => {
        appState.logout();
        // eslint-disable-next-line
    }, []);

    // redirect to login after all clean
    return <Redirect to='/login' />;
}
