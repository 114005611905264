import React from 'react';
import scss from './PageFooter.module.scss';

export interface PageFooterProps {
    textArea?: JSX.Element[] | JSX.Element | string;
    buttons: JSX.Element[] | JSX.Element;
}

const PageFooter: React.FC<PageFooterProps> = (props) => {
    return (
        <>
            <div className={scss.pageFooter}>
                <div className={`body2 c-slate ${scss.text}`}>{!!props.textArea && props.textArea}</div>
                <div className={`flexRow alignItemsCenter ${scss.buttons}`}>{props.buttons}</div>
            </div>
        </>
    );
};

export default PageFooter;
