import React, { useState, useContext } from 'react';
import axios from 'axios';
import Qs from 'qs';
import { WebSocketContext } from '../WebSocket/WebSocket';
import { AppStateContext } from '../AppState/AppState';
import * as Globals from '../../globals';
import * as Utils from '../../utils';

const ResourcesContext = React.createContext();

const ResourcesProvider = ({ children }) => {
    const webSocketContext = useContext(WebSocketContext);
    const appState = useContext(AppStateContext);

    const [resources, setResources] = useState(Globals.EMPTY_ARRAY);
    const [servers, setServers] = useState(Globals.EMPTY_ARRAY);
    const [databases, setDatabases] = useState(Globals.EMPTY_ARRAY);
    const [totalResourcesCount, setTotalResourcesCount] = useState(0);
    const [totalResourcesCost, setTotalResourcesCost] = useState(0);
    const [currentResource, setCurrentResource] = useState({});
    const [selectedResources, setSelectedResources] = useState([]);
    const [currentAction, setCurrentAction] = useState('');
    const [workloadResourcesLastUpdate, setWorkloadResourcesLastUpdate] = useState('');
    const [runningDiscover, setRunningDiscover] = useState(undefined);

    const resourcesInit = () => {
        setResources(Globals.EMPTY_ARRAY);
        setServers(Globals.EMPTY_ARRAY);
        setDatabases(Globals.EMPTY_ARRAY);
        setTotalResourcesCount(0);
        setTotalResourcesCost(0);
        setCurrentResource({});
        setSelectedResources([]);
        setCurrentAction('');
        setWorkloadResourcesLastUpdate('');
        setRunningDiscover(false);
    };

    React.useEffect(() => {
        if (webSocketContext.ec2DiscoveryFinished && webSocketContext.rdsDiscoveryFinished) {
            if (appState.currentWorkload.workloadId) loadResources(appState.currentWorkload.workloadId);
            setRunningDiscover(undefined);
        }
        // eslint-disable-next-line
    }, [webSocketContext.ec2DiscoveryFinished, webSocketContext.rdsDiscoveryFinished]);

    React.useEffect(() => {
        if (webSocketContext.ec2ParkingEnded || webSocketContext.rdsParkingEnded || webSocketContext.rdsUnparkEnded || webSocketContext.ec2UnparkEnded) {
            if (appState.currentWorkload.workloadId) loadResources(appState.currentWorkload.workloadId);
            webSocketContext.resetParkingMessages();
        }
        // eslint-disable-next-line
    }, [webSocketContext.ec2ParkingEnded, webSocketContext.rdsParkingEnded, webSocketContext.rdsUnparkEnded, webSocketContext.ec2UnparkEnded]);

    const getAxiosConfig = () => {
        return {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('jwttoken') },
        };
    };

    const startDiscoverTimer = (workloadId) => {
        webSocketContext.startDiscovery();
        setRunningDiscover(workloadId);
    };

    const loadResources = async (workloadId) => {
        try {
            const encodedUrl = Globals.WORKLOADS_ENDPOINT + '/' + workloadId + '/resources';
            var response = await axios.get(encodedUrl, getAxiosConfig());
            // Check response and update state
            if (response.errorCode !== undefined && response.errorCode.length > 0) {
                setResources(Globals.EMPTY_ARRAY);

                console.log('Error Loading Resources:' + response.errorCode + response.errorMsg);
                return undefined;
            } else {
                setResources([]);
                setResources(response.data.result.resources);
                setServers(response.data.result.servers);
                setDatabases(response.data.result.databases);
                setTotalResourcesCount(response.data.result.resources.length);
                setTotalResourcesCost(0.0);
                setWorkloadResourcesLastUpdate(response.data.result.lastUpdate);
                return response.data.result.resources;
            }
        } catch (e) {
            console.log('Error Loading Resources:' + e);
            setResources(Globals.EMPTY_ARRAY);
            return undefined;
        }
    };

    const addResource = (rObj) => () => {
        if (rObj !== undefined) {
            var rObjs = resources;
            rObjs.push(rObj);
            setResources(rObjs);

            // calculate total number of resources
            setTotalResourcesCount(rObjs.length);
            // calculate total cost of resources
            setTotalResourcesCost(rObjs.sum('totalCost').toFixed(2));
        }
    };

    const updateResource = (rId, rObj) => () => {
        // do the work to update the JSON object
        var rObjs = resources;
        var rIdx = -1;
        rIdx = rObjs.findIndex((r) => r.resourceId === rId);
        if (rIdx !== undefined) {
            for (var k in rObjs[rIdx]) if (rObjs[rIdx][k] !== rObj[k]) rObjs[rIdx][k] = rObj[k];
            setResources(rObjs);
        }
    };

    const removeResource = (rId) => {
        var rObjs = resources;
        var rIdx = -1;
        rIdx = rObjs.findIndex((r) => r.resourceId === rId);
        if (rIdx !== undefined) {
            rObjs.splice(rIdx, 1);
            setResources(rObjs);
        }
        // make a call with axios to remove workload from our Vega API by VegaWorkloadId

        // calculate total number of resources
        setTotalResourcesCount(rObjs.length);
        // calculate total cost of resources
        setTotalResourcesCost(rObjs.sum('totalCost').toFixed(2));
    };

    const getResource = (rId) => {
        var rObj = undefined;

        rObj = resources.find((r) => Object.entries(r).find(([k, value]) => k === 'resourceId' && value === rId) !== undefined);

        setCurrentResource(rObj);

        return rObj;
    };

    const discoverResources = async (workloadId) => {
        // set state for processing indicator
        startDiscoverTimer(workloadId);
        try {
            var encodedUrl = `${Globals.BASE_URL}actions/schedules/${workloadId}`;
            var payload = {
                waitUntil: new Date().toISOString(),
                entityId: workloadId,
                actionType: 'Discover',
                isImmediate: true,
            };

            await axios.post(encodedUrl, payload, getAxiosConfig());
        } catch (e) {
            console.log('Error discovering resources', e);
        }
    };

    const addSelectedResource = (resource) => {
        console.log('added resource');
        const temp = selectedResources;
        temp.push(resource);
        setSelectedResources(temp);
    };

    const removeSelectedResource = (resource) => {
        const temp = selectedResources;
        const selectedResourceToRemove = temp.find((selectedResource) => selectedResource.resourceId === resource.resourceId && selectedResource.region === resource.region);
        const idx = temp.indexOf(selectedResourceToRemove);
        if (idx >= 0) temp.splice(idx, 1);
        setSelectedResources(temp);
    };

    const clearSelectedResources = () => {
        console.log('clearSelectedResources...');
        setSelectedResources([]);
    };

    const resetCurrentAction = () => {
        // clear selected resources and current action
        setSelectedResources(Globals.EMPTY_ARRAY);
        setCurrentAction(Globals.EMPTY_STR);
    };

    const regionMatches = (aRegion) => {
        return function (element) {
            return element.region.indexOf(aRegion) === 0;
        };
    };

    const typeMatches = (aType) => {
        return function (element) {
            return element.resourceKind.indexOf(aType) === 0;
        };
    };

    const parkSelectedResources = async (awsRegions, wId, scheduleMode) => {
        console.log('PARK Selected Resources...First we chunk them up by region and type and tag them for workload:' + wId);
        console.log('PARK - Regions:' + awsRegions);
        var aRegion = '';
        var aResource = {};
        var regionList = [];
        var serverList = [];
        var databaseList = [];
        var assetIds = [];

        // clear tags then retag as required if SCHEDULED
        if (scheduleMode === Globals.SCHEDULE_MODE.SCHEDULE) {
            await clearVegaActionTag(awsRegions, wId);
        }
        // for each region in the list tag them for parking
        for (aRegion of awsRegions) {
            regionList = selectedResources.filter(regionMatches(aRegion));
            // for each type - EC2 or RDS
            if (regionList.length > 0) {
                console.log('PARK - Have resources in region:' + aRegion);
                serverList = regionList.filter(typeMatches('EC2'));
                if (serverList.length > 0) {
                    for (aResource of serverList) {
                        assetIds.push(aResource.resourceId);
                    }
                    console.log('PARK - Num Servers:' + serverList.length + ':::' + assetIds);
                    console.log('PARK - scheduleMode:' + scheduleMode);
                    if (scheduleMode === Globals.SCHEDULE_MODE.NOW) {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'ParkImmediate', 'ServerEc2');
                    } else {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'Park', 'ServerEc2');
                    }
                    assetIds = [];
                }
                databaseList = regionList.filter(typeMatches('RDS'));
                if (databaseList.length > 0) {
                    for (aResource of databaseList) {
                        assetIds.push(aResource.hostName);
                    }
                    console.log('PARK - Num Databases:' + databaseList.length + ':::' + assetIds);
                    console.log('PARK - scheduleMode' + scheduleMode);
                    if (scheduleMode === Globals.SCHEDULE_MODE.NOW) {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'ParkImmediate', 'DatabaseRds');
                    } else {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'Park', 'DatabaseRds');
                    }
                    assetIds = [];
                }
            }
        }

        if (scheduleMode === Globals.SCHEDULE_MODE.NOW) {
            setCurrentAction('Park');
            // if NOW we have to create a scheduled action to park this specific workload in a minute
            var waitUntil = Utils.UtcDateAddMinutes(new Date(), 1).toISOString();
            startMachineExecution(wId, waitUntil, 'Park', scheduleMode);
            // disable UI functions and cleanup, this will have to be solved better as time goes on
            setTimeout(() => resetCurrentAction(), 120000);
        }
    };

    const parkAllResources = async (awsRegions, wId, scheduleMode) => {
        console.log('PARK Resources...First we chunk them up by region and type and tag them for workload:' + wId);
        console.log('PARK - Regions:' + awsRegions);
        var aRegion = '';
        var aResource = {};
        var regionList = [];
        var serverList = [];
        var databaseList = [];
        var assetIds = [];

        // clear tags then retag as required if SCHEDULED
        if (scheduleMode === Globals.SCHEDULE_MODE.SCHEDULE) {
            await clearVegaActionTag(awsRegions, wId);
        }
        // for each region in the list tag them for parking
        for (aRegion of awsRegions) {
            regionList = resources.filter(regionMatches(aRegion));
            // for each type - EC2 or RDS
            if (regionList.length > 0) {
                console.log('PARK - Have resources in region:' + aRegion);
                serverList = regionList.filter(typeMatches('EC2'));
                if (serverList.length > 0) {
                    for (aResource of serverList) {
                        assetIds.push(aResource.resourceId);
                    }
                    console.log('PARK - Num Servers:' + serverList.length + ':::' + assetIds);
                    console.log('PARK - scheduleMode:' + scheduleMode);
                    if (scheduleMode === Globals.SCHEDULE_MODE.NOW) {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'ParkImmediate', 'ServerEc2');
                    } else {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'Park', 'ServerEc2');
                    }
                    assetIds = [];
                }
                databaseList = regionList.filter(typeMatches('RDS'));
                if (databaseList.length > 0) {
                    for (aResource of databaseList) {
                        assetIds.push(aResource.hostName);
                    }
                    console.log('PARK - Num Databases:' + databaseList.length + ':::' + assetIds);
                    console.log('PARK - scheduleMode' + scheduleMode);
                    if (scheduleMode === Globals.SCHEDULE_MODE.NOW) {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'ParkImmediate', 'DatabaseRds');
                    } else {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'Park', 'DatabaseRds');
                    }
                    assetIds = [];
                }
            }
        }

        if (scheduleMode === Globals.SCHEDULE_MODE.NOW) {
            setCurrentAction('Park');
            // if NOW we have to create a scheduled action to park this specific workload in a minute
            var waitUntil = Utils.UtcDateAddMinutes(new Date(), 1).toISOString();
            startMachineExecution(wId, waitUntil, 'Park', scheduleMode);
            // disable UI functions and cleanup, this will have to be solved better as time goes on
            setTimeout(() => resetCurrentAction(), 120000);
        }
    };

    const unParkSelectedResources = async (awsRegions, wId, scheduleMode) => {
        console.log('UnPark Selected Resources...First we chunk them up by region and type and tag them for workload:' + wId);
        console.log('Regions:' + awsRegions);
        var aRegion = '';
        var aResource = {};
        var regionList = [];
        var serverList = [];
        var databaseList = [];
        var assetIds = [];

        // clear tags then retag as required if SCHEDULED
        if (scheduleMode === Globals.SCHEDULE_MODE.SCHEDULE) {
            await clearVegaActionTag(awsRegions, wId);
        }
        // for each region in the list tag them for parking
        for (aRegion of awsRegions) {
            regionList = selectedResources.filter(regionMatches(aRegion));
            // for each type - EC2 or RDS
            if (regionList.length > 0) {
                console.log('Have resources in region:' + aRegion);
                serverList = regionList.filter(typeMatches('EC2'));
                if (serverList.length > 0) {
                    for (aResource of serverList) {
                        assetIds.push(aResource.resourceId);
                    }
                    console.log('Num Servers:' + serverList.length + ':::' + assetIds);
                    console.log('scheduleMode:' + scheduleMode);
                    if (scheduleMode === Globals.SCHEDULE_MODE.NOW) {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'UnParkImmediate', 'ServerEc2');
                    } else {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'UnPark', 'ServerEc2');
                    }
                    assetIds = [];
                }
                databaseList = regionList.filter(typeMatches('RDS'));
                if (databaseList.length > 0) {
                    for (aResource of databaseList) {
                        assetIds.push(aResource.hostName);
                    }
                    console.log('Num Databases:' + databaseList.length + ':::' + assetIds);
                    console.log('scheduleMode:' + scheduleMode);
                    if (scheduleMode === Globals.SCHEDULE_MODE.NOW) {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'UnParkImmediate', 'DatabaseRds');
                    } else {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'UnPark', 'DatabaseRds');
                    }
                    assetIds = [];
                }
            }
        }
        setCurrentAction('UnPark');
        // once done we have to create a scheduled action to park this specific workload in a minute
        var waitUntil = Utils.UtcDateAddMinutes(new Date(), 1).toISOString();
        startMachineExecution(wId, waitUntil, 'UnPark', Globals.SCHEDULE_MODE.NOW);

        // disable UI functions and cleanup, this will have to be solved better as time goes on
        setTimeout(() => resetCurrentAction(), 120000);
    };

    const unParkAllResources = async (awsRegions, wId, scheduleMode) => {
        console.log('UnPark Resources...First we chunk them up by region and type and tag them for workload:' + wId);
        console.log('Regions:' + awsRegions);
        var aRegion = '';
        var aResource = {};
        var regionList = [];
        var serverList = [];
        var databaseList = [];
        var assetIds = [];

        // clear tags then retag as required if SCHEDULED
        if (scheduleMode === Globals.SCHEDULE_MODE.SCHEDULE) {
            await clearVegaActionTag(awsRegions, wId);
        }
        // for each region in the list tag them for parking
        for (aRegion of awsRegions) {
            regionList = resources.filter(regionMatches(aRegion));
            // for each type - EC2 or RDS
            if (regionList.length > 0) {
                console.log('Have resources in region:' + aRegion);
                serverList = regionList.filter(typeMatches('EC2'));
                if (serverList.length > 0) {
                    for (aResource of serverList) {
                        assetIds.push(aResource.resourceId);
                    }
                    console.log('Num Servers:' + serverList.length + ':::' + assetIds);
                    console.log('scheduleMode:' + scheduleMode);
                    if (scheduleMode === Globals.SCHEDULE_MODE.NOW) {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'UnParkImmediate', 'ServerEc2');
                    } else {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'UnPark', 'ServerEc2');
                    }
                    assetIds = [];
                }
                databaseList = regionList.filter(typeMatches('RDS'));
                if (databaseList.length > 0) {
                    for (aResource of databaseList) {
                        assetIds.push(aResource.hostName);
                    }
                    console.log('Num Databases:' + databaseList.length + ':::' + assetIds);
                    console.log('scheduleMode:' + scheduleMode);
                    if (scheduleMode === Globals.SCHEDULE_MODE.NOW) {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'UnParkImmediate', 'DatabaseRds');
                    } else {
                        tagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'UnPark', 'DatabaseRds');
                    }
                    assetIds = [];
                }
            }
        }
        setCurrentAction('UnPark');
        // once done we have to create a scheduled action to park this specific workload in a minute
        var waitUntil = Utils.UtcDateAddMinutes(new Date(), 1).toISOString();
        startMachineExecution(wId, waitUntil, 'UnPark', Globals.SCHEDULE_MODE.NOW);

        // disable UI functions and cleanup, this will have to be solved better as time goes on
        setTimeout(() => resetCurrentAction(), 120000);
    };

    const clearVegaActionTag = async (awsRegions, wId) => {
        console.log('Clear VegaAction Tag...First we chunk them up by region and type and tag them for workload:' + wId);
        console.log('Regions:' + awsRegions);
        var aRegion = '';
        var aResource = {};
        var regionList = [];
        var serverList = [];
        var databaseList = [];
        var assetIds = [];
        // for each region in the list tag them for parking
        for (aRegion of awsRegions) {
            regionList = resources.filter(regionMatches(aRegion));
            // for each type - EC2 or RDS
            if (regionList.length > 0) {
                console.log('Have resources in region:' + aRegion);
                serverList = regionList.filter(typeMatches('EC2'));
                if (serverList.length > 0) {
                    for (aResource of serverList) {
                        assetIds.push(aResource.resourceId);
                    }
                    console.log('Num Servers:' + serverList.length + ':::' + assetIds);
                    await unTagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'Park', 'ServerEc2');
                    assetIds = [];
                }
                databaseList = regionList.filter(typeMatches('RDS'));
                if (databaseList.length > 0) {
                    for (aResource of databaseList) {
                        assetIds.push(aResource.hostName);
                    }
                    console.log('Num Databases:' + databaseList.length + ':::' + assetIds);
                    await unTagResourcesByRegionByActionTypeByAssetType(wId, assetIds, aRegion, 'Park', 'DatabaseRds');
                    assetIds = [];
                }
            }
        }
    };

    const tagSatellites = async (action, awsRegions, resources, satelliteId, workloadId) => {
        for (const region of awsRegions) {
            const regionResources = resources.filter((x) => x.region === region);
            const ec2Resources = regionResources.filter((x) => x.resourceKind === 'EC2');
            if (ec2Resources.length > 0) {
                if (action === 'tag')
                    await postSatelliteTag(
                        ec2Resources.map((x) => x.resourceId),
                        satelliteId,
                        workloadId,
                        region,
                        'ServerEc2'
                    );
                if (action === 'untag')
                    await deleteSatelliteTag(
                        ec2Resources.map((x) => x.resourceId),
                        workloadId,
                        region,
                        'ServerEc2'
                    );
            }
            const rdsResources = regionResources.filter((x) => x.resourceKind === 'RDS');
            if (rdsResources.length > 0) {
                if (action === 'tag')
                    await postSatelliteTag(
                        rdsResources.map((x) => x.hostName),
                        satelliteId,
                        workloadId,
                        region,
                        'DatabaseRds'
                    );
                if (action === 'untag')
                    await deleteSatelliteTag(
                        rdsResources.map((x) => x.hostName),
                        workloadId,
                        region,
                        'DatabaseRds'
                    );
            }
        }
    };

    const postSatelliteTag = (assetIds, satelliteId, workloadId, region, resourceAssetType) => {
        const model = {
            resourceEcoType: 'Sat',
            assetIds,
            satelliteId,
            region,
            resourceAssetType,
        };
        return axios.post(`${Globals.BASE_URL}workloads/${workloadId}/resources/ecos`, model, getAxiosConfig());
    };

    const deleteSatelliteTag = (assetIds, workloadId, region, resourceAssetType) => {
        const config = getAxiosConfig();
        config.params = {
            resourceEcoType: 'Sat',
            assetIds,
            region,
            resourceAssetType,
        };
        config.paramsSerializer = function (params) {
            return Qs.stringify(params, { arrayFormat: 'repeat' });
        };
        return axios.delete(`${Globals.BASE_URL}workloads/${workloadId}/resources/ecos`, config);
    };

    const tagResourcesByRegionByActionTypeByAssetType = (wId, assets, regionCd, actionType, assetType) => {
        console.log('Tag Selected Resources By:' + wId + '~' + assets + '~' + regionCd + '~' + actionType + '~' + assetType);
        // set state for processing indicator
        var encodedUrl = Globals.WORKLOADS_ENDPOINT + '/' + wId + '/resources/actions';
        var payload = {
            resourceActionType: actionType,
            assetIds: assets,
            workloadId: wId,
            region: regionCd,
            resourceAssetType: assetType,
        };
        return axios.post(encodedUrl, payload, getAxiosConfig());
    };

    const unTagResourcesByRegionByActionTypeByAssetType = (wId, assets, regionCd, actionType, assetType) => {
        console.log('UnTag Selected Resources By:' + wId + '~' + assets + '~' + regionCd + '~' + actionType + '~' + assetType);
        // set state for processing indicator

        var encodedUrl = Globals.WORKLOADS_ENDPOINT + '/' + wId + '/resources/actions';

        var axiosConfig = {
            params: {
                resourceActionType: actionType,
                assetIds: assets,
                workloadId: wId,
                region: regionCd,
                resourceAssetType: assetType,
            },
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('jwttoken'),
            },
            paramsSerializer: function (params) {
                return Qs.stringify(params, { arrayFormat: 'repeat' });
            },
        };
        return axios.delete(encodedUrl, axiosConfig);
    };

    const startMachineExecution = async (wId, waitUntil, aType, scheduleMode) => {
        // set state for processing indicator
        try {
            var encodedUrl = `${Globals.BASE_URL}actions/schedules/${wId}`;
            var doNow = scheduleMode === Globals.SCHEDULE_MODE.NOW ? true : false;

            console.log('Machine Starting Action: ' + aType + ' for Workload: ' + wId + ' at: ' + waitUntil + ' doNow: ' + doNow);

            var payload = {
                waitUntil: waitUntil,
                entityId: wId,
                actionType: aType,
                isImmediate: doNow,
            };

            await axios.post(encodedUrl, payload, getAxiosConfig());
        } catch (e) {
            console.log('Error starting machine execution', e);
        }
    };

    return (
        <ResourcesContext.Provider
            value={{
                resources: resources,
                servers: servers,
                databases: databases,
                totalResourcesCount: totalResourcesCount,
                totalResourcesCost: totalResourcesCost,
                currentResource: currentResource,
                selectedResources: selectedResources,
                currentAction: currentAction,
                addResource: addResource,
                updateResource: updateResource,
                removeResource: removeResource,
                getResource: getResource,
                loadResources: loadResources,
                discoverResources: discoverResources,
                clearSelectedResources: clearSelectedResources,
                addSelectedResource: addSelectedResource,
                removeSelectedResource: removeSelectedResource,
                parkSelectedResources: parkSelectedResources,
                parkAllResources: parkAllResources,
                unParkSelectedResources: unParkSelectedResources,
                unParkAllResource: unParkAllResources,
                resetCurrentAction: resetCurrentAction,
                resourcesInit: resourcesInit,
                workloadResourcesLastUpdate: workloadResourcesLastUpdate,
                tagSatellites: tagSatellites,
                startDiscoverTimer: startDiscoverTimer,
                runningDiscover: runningDiscover,
                clearVegaActionTag: clearVegaActionTag,
            }}
        >
            {children}
        </ResourcesContext.Provider>
    );
};

const ResourcesConsumer = ResourcesContext.Consumer;
export { ResourcesProvider, ResourcesConsumer, ResourcesContext };
