import React from 'react';

export interface UiDemoButtonsTabProps {}

const UiDemoButtonsTab: React.FC<UiDemoButtonsTabProps> = (props) => {
    return (
        <>
            <p>Buttons use the standard html button tag. Three css classes are used to select button size, style, and color</p>
            <h5>Normal Buttons</h5>
            <button className='normal small primary'>Small</button>&nbsp;&nbsp;&nbsp;
            <button className='normal medium primary'>Medium</button>&nbsp;&nbsp;&nbsp;
            <button className='normal large primary'>Large</button>&nbsp;&nbsp;&nbsp;
            <button disabled className='normal large primary'>
                Disabled
            </button>
            <h5>Flat Buttons</h5>
            <button className='flat small primary'>Small</button>&nbsp;&nbsp;&nbsp;
            <button className='flat medium primary'>Medium</button>&nbsp;&nbsp;&nbsp;
            <button className='flat large primary'>Large</button>&nbsp;&nbsp;&nbsp;
            <button disabled className='flat large primary'>
                Disabled
            </button>
            &nbsp;&nbsp;&nbsp;
            <h5>Accent Buttons</h5>
            <button className='accent small primary'>Small</button>&nbsp;&nbsp;&nbsp;
            <button className='accent medium primary'>Medium</button>&nbsp;&nbsp;&nbsp;
            <button className='accent large primary'>Large</button>&nbsp;&nbsp;&nbsp;
            <button disabled className='accent large primary'>
                Disabled
            </button>
            <h5>Text Buttons</h5>
            <button className='text primary'>Text Button</button>&nbsp;&nbsp;
            <button disabled className='text primary'>
                Disabled
            </button>
            &nbsp;&nbsp;
            <h5>Button Colors</h5>
            <button className='normal medium primary'>Primary</button>&nbsp;&nbsp;&nbsp;
            <button className='flat medium primary'>Primary</button>&nbsp;&nbsp;&nbsp;
            <button className='accent medium primary'>Primary</button>
            <br />
            <br />
            <button className='normal medium success'>Success</button>&nbsp;&nbsp;&nbsp;
            <button className='flat medium success'>Success</button>&nbsp;&nbsp;&nbsp;
            <button className='accent medium success'>Success</button>
            <br />
            <br />
            <button className='normal medium danger'>Danger</button>&nbsp;&nbsp;&nbsp;
            <button className='flat medium danger'>Danger</button>&nbsp;&nbsp;&nbsp;
            <button className='accent medium danger'>Danger</button>
            <br />
            <br />
            <button className='normal medium slate-light'>slate-light</button>&nbsp;&nbsp;&nbsp;
            <button className='flat medium slate-light'>slate-light</button>&nbsp;&nbsp;&nbsp;
            <button className='accent medium slate-light'>slate-light</button>
            <br />
            <br />
            <button className='text primary'>Primary</button>&nbsp;&nbsp;
            <button className='text success'>Success</button>&nbsp;&nbsp;
            <button className='text danger'>Danger</button>&nbsp;&nbsp;
            <button className='text slate-light'>Slate Light</button>&nbsp;&nbsp;
            <button disabled className='text danger'>
                Disabled
            </button>
        </>
    );
};

export default UiDemoButtonsTab;
