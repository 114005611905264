import React, { useContext } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import './WorkloadUnPark.scss';
import ExpansionPanel from '../ExpansionPanel/ExpansionPanel';
import DotIndicator from '../DotIndicator/DotIndicator';
import { ResourcesContext } from '../../contexts/Resources/Resources';

export interface WorkloadUnParkProps {}

const WorkloadUnPark: React.FC<WorkloadUnParkProps> = (props) => {
    const resourcesContext = useContext<any>(ResourcesContext);

    return (
        <>
            <p className='c-slate-light'>Are you sure you want to immediately unpark this workload?</p>
        </>
    );
};

export default WorkloadUnPark;
