import React from 'react';
import scss from './BackButton.module.scss';
import Icon from '../Icon/Icon';

export interface BackButtonProps {
    onClick: () => void;
    lightMode?: boolean;
}

const BackButton = (props: BackButtonProps) => {
    return (
        <button className={`blank ${props.lightMode ? 'c-white' : 'c-secondary'} ${scss.button}`} onClick={props.onClick}>
            <Icon icon='back-arrow' />
        </button>
    );
};

export default BackButton;
