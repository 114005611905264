import React, { useState } from 'react';
import ReactGA from 'react-ga';
import Icon from '../Icon/Icon';
import { timeSince } from '../../utils';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { InlineBadge } from '../InlineBadge/InlineBadge';
import { IconButton } from '../IconButton/IconButton';
import scss from './NotificationList.module.scss';
import { ClickAwayListener, Badge } from '@material-ui/core';
import NotificationTile from './NotificationTile';

export interface NotificationListProps {
    isInSidebar?: boolean;
    workloadIdFilter?: string;
}

const NotificationList: React.FC<NotificationListProps> = (props) => {
    const appState = React.useContext(AppStateContext);

    const [show, setShow] = useState(false);
    const [showEasterEgg, setShowEasterEgg] = useState(0);

    const messageClick = (msgId: string) => () => {
        ReactGA.event({
            category: 'TopBar',
            action: 'Notifications - Clicked Message',
            label: 'MessageID:' + msgId,
        });
    };

    const dismissNotification = (id: string) => appState.dismissNotification(id);

    const notifications = props.workloadIdFilter ? appState.notifications.filter((x) => x.workloadId === props.workloadIdFilter) : appState.notifications;

    const notificationList = (
        <>
            {notifications.length > 0 && (
                <div className={`scrollContainer noScrollbar ${props.isInSidebar && 'flexGrow'}`}>
                    {notifications.map((notification) => (
                        <NotificationTile
                            key={notification.id}
                            title={notification.name}
                            description={notification.description}
                            timestamp={timeSince(notification.dateCreatedUtc)}
                            actionIcon={
                                <button className='iconButton' type='button' onClick={() => dismissNotification(notification.id)}>
                                    <Icon icon='close' />
                                </button>
                            }
                            onClick={messageClick(notification.id)}
                        />
                    ))}
                </div>
            )}
            {!(notifications.length > 0) && (
                <div className='c-slate-light flexGrow'>
                    <span onClick={() => setShowEasterEgg((old) => old + 1)}>No new notifications</span>&nbsp;
                    {showEasterEgg > 3 && (
                        <span role='img' aria-label='Thumbs Up'>
                            👍
                        </span>
                    )}
                </div>
            )}
        </>
    );

    return (
        <>
            {props.isInSidebar && (
                <div className='flexRow alignItemsCenter'>
                    <div className='flexRow alignItemsCenter'>
                        <Badge badgeContent={notifications.length} color='primary'>
                            <IconButton icon='notifications' className={props.isInSidebar && show && 'active'} onClick={() => setShow(!show)} />
                        </Badge>
                    </div>
                    {show && (
                        <ClickAwayListener onClickAway={() => setShow(false)}>
                            <div className={`simpleCard padded flexCol ${scss.notificationPanel}`}>
                                <h5 className='marginNormal-bottom'>Notifications</h5>
                                {notificationList}
                            </div>
                        </ClickAwayListener>
                    )}
                </div>
            )}
            {!props.isInSidebar && (
                <>
                    <h5 className='marginNormal-bottom'>
                        Notifications&nbsp;
                        {notifications.length > 0 && <InlineBadge value={notifications.length} className='bg-danger' />}
                    </h5>
                    {notificationList}
                </>
            )}
        </>
    );
};

export default NotificationList;
