import React, { useState, useContext, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import scss from './SideBar.module.scss';
import Modal from '../Modal/Modal';
import { TextField, CircularProgress, Tooltip } from '@material-ui/core';
import Icon from '../Icon/Icon';
import { ReactComponent as VLogo } from '../../images/v-logo.svg';
import InfoSidePanel from '../InfoSidePanel/InfoSidePanel';
import { InlineBadge } from '../InlineBadge/InlineBadge';
import Fab from '../Fab/Fab';
import { RecommendationsLanding } from '../Recommendations/RecommendationsLanding';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { ListRecommendations, RecommendationStatus, CreateUserFeedback } from '../../ServiceStack/ServiceStack.dtos';
import { AppStateContext } from '../../contexts/AppState/AppState';
import TasksLanding from '../Tasks/TasksLanding';
import FavoritesLanding from '../Favorites/FavoritesLanding';
import BusinessUnitsList from '../BusinessUnits/BusinessUnitsList';
import * as Globals from '../../globals';
import FeedbackForm from '../FeedbackForm/FeedbackForm';

enum InfoPanelViews {
    None,
    Tasks,
    Recommendations,
    Favorites,
    BusinessUnits,
}

export interface SidebarProps extends RouteComponentProps<any> {}

const SideBar: React.FC<SidebarProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);

    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [infoPanelView, setInfoPanelView] = useState(InfoPanelViews.None);
    const [recommendationsCount, setRecommendationsCount] = useState(0);

    var isLLC = false;
    var isQumuloSalesRep = false;
    var isTelusPOC = false;

    if(appState.currentUser.email !== undefined) {
        isLLC = appState.currentUser.email.endsWith('@libertylakecloud.com') || appState.currentUser.email.endsWith('@vegacloud.io');
        isQumuloSalesRep = appState.currentUser.email.endsWith('@qumulo.com') && appState.currentUser.email.startsWith('salesrep');
        isTelusPOC = appState.currentUser.email.endsWith('@telus.com') && appState.currentUser.email.startsWith('teluspoc');
    }
    
    const handleInfoPanel = (val: InfoPanelViews) => {
        if (val === infoPanelView) setInfoPanelView(InfoPanelViews.None);
        else setInfoPanelView(val);
    };

    const setRecommendationCount = () => {
        const request = new ListRecommendations();
        vegaApi
            .getApiClient()
            .get(request)
            .then((response) =>
                setRecommendationsCount(
                    response.results.filter(
                        (x) => x.status === RecommendationStatus.Scheduled || x.status === RecommendationStatus.WaitingForApproval || x.status === RecommendationStatus.Pending
                    ).length
                )
            );
    };

    //hack to get number to toggle when you delete a recommendation schendule
    const onDeleteSchedule = (recommendationId: string) => {
        const request = new ListRecommendations();
        vegaApi
            .getApiClient()
            .get(request)
            .then((response) =>
                setRecommendationsCount(
                    response.results.filter(
                        (x) =>
                            (x.status === RecommendationStatus.Scheduled || x.status === RecommendationStatus.WaitingForApproval || x.status === RecommendationStatus.Pending) &&
                            x.id !== recommendationId
                    ).length
                )
            );
    };

    const closeInfoPanel = () => setInfoPanelView(InfoPanelViews.None);

    useEffect(() => {
        setRecommendationCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const vegaNavLogo = (
        <NavLink className={scss.svg} to='/dashboard' id='app_dashboard_link'>
            <VLogo />
        </NavLink>
    );

    const vegaLogo = <VLogo className={scss.svg} />;

    function GetLogo() {
        if (isQumuloSalesRep || isTelusPOC) {
            return vegaLogo;
        } else {
            return vegaNavLogo;
        }
    }

    return (
        <>
            {showFeedbackForm && <FeedbackForm setShowFeedbackForm={setShowFeedbackForm} />}
            <div className={`flexCol flexSeparate scrollContainer noScrollbar noXOverflow ${scss.sideNavContainer}`}>
                <div className={scss.logo}>{GetLogo()}</div>
                <div className={`${scss.topButtons}`}>
                    {isLLC && (
                        <button
                            className={`${scss.navLink} ${scss.panelBtn} ${infoPanelView === InfoPanelViews.BusinessUnits && scss.panelOpen}`}
                            type='button'
                            onClick={() => handleInfoPanel(InfoPanelViews.BusinessUnits)}
                        >
                            <Tooltip title='Business Units' placement='right' arrow>
                                <div>
                                    <Icon icon='domain' className={scss.icon} />
                                </div>
                            </Tooltip>
                        </button>
                    )}

                    {!isLLC && !isQumuloSalesRep && !isTelusPOC && (
                        <NavLink to='/spaces' className={scss.navLink} activeClassName={scss.active}>
                            <Tooltip title='Spaces' placement='right' arrow>
                                <div>
                                    <Icon icon='spaces' className={scss.icon} />
                                </div>
                            </Tooltip>
                        </NavLink>
                    )}

                    {Globals.ENABLE_REPORTING && !isQumuloSalesRep && !isTelusPOC && (
                        <NavLink onClick={closeInfoPanel} to='/reports' className={scss.navLink} activeClassName={scss.active}>
                            <Tooltip title='Reports' placement='right' arrow>
                                <div>
                                    <Icon icon='reports' className={scss.icon} />
                                </div>
                            </Tooltip>
                        </NavLink>
                    )}

                    {isLLC && !isQumuloSalesRep && !isTelusPOC && (
                        <NavLink onClick={closeInfoPanel} to='/admin/budgets' className={scss.navLink} activeClassName={scss.active}>
                            <Tooltip title='Administration' placement='right' arrow>
                                <div>
                                    <Icon icon='admin' className={scss.icon} />
                                </div>
                            </Tooltip>
                        </NavLink>
                    )}
                    {!isQumuloSalesRep && !isTelusPOC && (
                        <>
                            <button
                                className={`${scss.navLink} ${scss.panelBtn} ${infoPanelView === InfoPanelViews.Tasks && scss.panelOpen}`}
                                type='button'
                                onClick={() => handleInfoPanel(InfoPanelViews.Tasks)}
                            >
                                <Tooltip title='Tasks' placement='right' arrow>
                                    <div>
                                        <Icon icon='tasks' className={scss.icon} />
                                    </div>
                                </Tooltip>
                            </button>

                            <button
                                className={`${scss.navLink} ${scss.panelBtn} ${infoPanelView === InfoPanelViews.Recommendations && scss.panelOpen}`}
                                type='button'
                                onClick={() => handleInfoPanel(InfoPanelViews.Recommendations)}
                            >
                                <Tooltip title='Recommendations' placement='right' arrow>
                                    <div>
                                        <Icon icon='recommendations' className={`${scss.icon} ${scss.recommendations}`} />
                                    </div>
                                </Tooltip>
                                <InlineBadge value={recommendationsCount} className={scss.badge} />
                            </button>

                            <button
                                className={`${scss.navLink} ${scss.panelBtn} ${infoPanelView === InfoPanelViews.Favorites && scss.panelOpen}`}
                                type='button'
                                onClick={() => handleInfoPanel(InfoPanelViews.Favorites)}
                            >
                                <Tooltip title='Favorites' placement='right' arrow>
                                    <div>
                                        <Icon icon='favorites' className={scss.icon} />
                                    </div>
                                </Tooltip>
                            </button>
                        </>
                    )}
                </div>
                <div className={`padded ${scss.fab}`}>
                    <Fab icon='activity' onClick={() => setShowFeedbackForm(true)} className='center' />
                </div>
            </div>
            {!!infoPanelView && (
                <InfoSidePanel>
                    {infoPanelView === InfoPanelViews.Recommendations && <RecommendationsLanding onDeleteSchedule={onDeleteSchedule} />}
                    {infoPanelView === InfoPanelViews.Favorites && <FavoritesLanding />}
                    {infoPanelView === InfoPanelViews.Tasks && <TasksLanding />}
                    {infoPanelView === InfoPanelViews.BusinessUnits && <BusinessUnitsList onHideSidebar={closeInfoPanel} />}
                </InfoSidePanel>
            )}
        </>
    );
};

export default withRouter(SideBar);
