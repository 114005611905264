import React, { useState, useContext } from 'react';
import { ResourcesContext } from '../../contexts/Resources/Resources';
import WorkloadParkWizard from '../WorkloadParkWizard/WorkloadParkWizard';
import * as Globals from '../../globals';
import scss from './WorkloadActions.module.scss';
import WorkloadUnparkWizard from '../WorkloadUnparkWizard/WorkloadUnparkWizard';
import TimedInstanceController from './TimedInstanceController';
import Icon from '../Icon/Icon';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { WorkloadContentType } from '../../ServiceStack/ServiceStack.dtos';
import { GrantSecurityAccessController } from './GrantSecurityAccessController';

const WorkloadActions = (props) => {
    const resourcesContext = useContext(ResourcesContext);
    const appState = useContext(AppStateContext);

    const [activeAction, setActiveAction] = useState();

    const actionClicked = (actionType) => () => setActiveAction(actionType);

    const onActionClose = () => setActiveAction(undefined);

    return (
        <>
            {activeAction === Globals.WORKLOAD_ACTIONS.PARK && <WorkloadParkWizard workload={appState.currentWorkload} onClose={onActionClose} />}
            {activeAction === Globals.WORKLOAD_ACTIONS.UNPARK && <WorkloadUnparkWizard workload={appState.currentWorkload} onClose={onActionClose} />}
            {activeAction === 'TimedInstance' && <TimedInstanceController cancelClick={onActionClose} workloadId={appState.currentWorkload.workloadId} />}
            {activeAction === 'GrantSecurityAccess' && <GrantSecurityAccessController cancelClick={onActionClose} workloadId={appState.currentWorkload.workloadId} />}
            <div className='marginNormal'>
                <div className='centeredCardContainer'>
                    <div className='cardWrapper'>
                        <button
                            className={`simpleCard selectable padded stdSize centerContent`}
                            onClick={actionClicked(Globals.WORKLOAD_ACTIONS.PARK)}
                            disabled={resourcesContext.currentAction === 'Park'}
                        >
                            <div>
                                <Icon icon='park' color='c-primary' />
                            </div>
                            {resourcesContext.currentAction !== 'Park' && <div className={`body1 ${scss.cardText}`}>Park</div>}
                            {resourcesContext.currentAction === 'Park' && <div className={`body1 c-slate-lightest ${scss.cardText}`}>Parking</div>}
                        </button>
                        <div className='belowCardText body1'>Optimize spend by scheduling when you would like your workloads to run/stop.</div>
                    </div>
                    <div className='cardWrapper'>
                        <button
                            className={`simpleCard selectable padded stdSize centerContent`}
                            onClick={actionClicked(Globals.WORKLOAD_ACTIONS.UNPARK)}
                            disabled={resourcesContext.currentAction === 'UnPark'}
                        >
                            <div>
                                <Icon icon='park' color='c-primary' />
                            </div>
                            {resourcesContext.currentAction !== 'UnPark' && <div className={`body1 ${scss.cardText}`}>UnPark</div>}
                            {resourcesContext.currentAction === 'UnPark' && <div className={`body1 c-slate-lightest ${scss.cardText}`}>UnParking</div>}
                        </button>
                        <div className='belowCardText body1'>UnPark Workload.</div>
                    </div>
                    <div className='cardWrapper'>
                        <button
                            className='simpleCard selectable padded stdSize centerContent'
                            disabled={true}
                            // onClick={actionClicked(Globals.WORKLOAD_ACTIONS.BACKUP)}
                        >
                            <div>
                                <Icon icon='backup' color='c-primary' />
                            </div>
                            <div className={`body1 ${scss.cardText}`}>Clone</div>
                        </button>
                        <div className='belowCardText body1'>Clone your selected resources.</div>
                    </div>
                    {appState.currentWorkload.workloadContentType === WorkloadContentType.Satellite && (
                        <>
                            <div className='cardWrapper'>
                                <button className='simpleCard selectable padded stdSize centerContent' onClick={actionClicked('TimedInstance')}>
                                    <div>
                                        <Icon icon='clock' color='c-primary' />
                                    </div>
                                    <div className={`body1 ${scss.cardText}`}>Timed Instance</div>
                                </button>
                                <div className='belowCardText body1'>Create an instance that will terminate based on your set schedule.</div>
                            </div>
                            <div className='cardWrapper'>
                                <button className='simpleCard selectable padded stdSize centerContent' onClick={actionClicked('GrantSecurityAccess')}>
                                    <div>
                                        <Icon icon='clock' color='c-primary' />
                                    </div>
                                    <div className={`body1 ${scss.cardText}`}>Grant Security Access</div>
                                </button>
                                <div className='belowCardText body1'>Grant temporary or permanent security access.</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default WorkloadActions;
