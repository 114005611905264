import React, { useState } from 'react';
import { InfoSidePanelHeader } from '../InfoSidePanel/InfoSidePanelHeader';

export interface ITasksLanding {}

const TasksLanding: React.FC<ITasksLanding> = (props) => {
    const [tasks] = useState([]);

    return (
        <>
            <div className='padded'>
                <InfoSidePanelHeader showBottomMargin={true}>
                    <h5 className='c-slate-light'>Tasks</h5>
                </InfoSidePanelHeader>
                <div className='scrollContainer noScrollbar'>{/* this is where to map tasks */}</div>
                {!(tasks.length > 0) && <div>You don't have any new tasks!</div>}
            </div>
        </>
    );
};

export default TasksLanding;
