import React, { useContext, useState } from 'react';
import { Grid, TextField, CircularProgress, FormControlLabel, Checkbox } from '@material-ui/core';
import WorkloadSatelliteAwsModel from '../WorkloadSatelliteAwsModel/WorkloadSatelliteAwsModel';
import { ResourcesContext } from '../../contexts/Resources/Resources';
import WorkloadHeader from '../WorkloadHeader/WorkloadHeader';
import { CloudResource, ListWorkloadResources, WorkloadContentType, UpdateWorkload, GetWorkload } from '../../ServiceStack/ServiceStack.dtos';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { RouteComponentProps } from 'react-router-dom';
import { urlUpOneLevel } from '../../utils';
import WorkloadInfoForm from '../WorkloadInfoForm/WorkloadInfoForm';
import PageFooter from '../PageFooter/PageFooter';

type UpdateWorkloadForm = Omit<UpdateWorkload, 'createResponse' | 'getTypeName' | 'organizationId' | 'masterWorkloadId' | 'isMasterWorkload' | 'awsRoleArn'>;

export interface WorkloadEditProps extends RouteComponentProps<any> {}

const WorkloadEdit: React.FC<WorkloadEditProps> = (props) => {
    const resourcesContext = useContext<any>(ResourcesContext);
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);

    const [editModel, setEditModel] = useState<UpdateWorkloadForm>(
        new UpdateWorkload({
            workloadId: '',
            name: '',
            description: '',
            workloadTypeId: 0,
            budgetAmount: 0,
            budgetPeriodType: '',
            isLinkedWorkload: false,
        })
    );
    const [selectedResources, setSelectedResources] = useState<CloudResource[]>([]);
    const [originalSelectedResources, setOriginalSelectedResources] = useState<CloudResource[]>([]);
    const [resources, setResources] = useState<CloudResource[]>([]);
    const [processing, setProcessing] = useState(false);

    const onAfterLoaded = () => {
        const { currentWorkload } = appState;

        if (currentWorkload.workloadContentType === WorkloadContentType.Satellite) {
            let tempResources: CloudResource[] = [];
            const resourcesRequest = new ListWorkloadResources({ workloadId: currentWorkload.workloadId });
            vegaApi
                .getApiClient()
                .get(resourcesRequest)
                .then((response) => {
                    tempResources.push(...response.result.resources);
                    setSelectedResources(response.result.resources);
                    setOriginalSelectedResources(response.result.resources);
                    const parentResourcesRequest = new ListWorkloadResources({ workloadId: currentWorkload.parentWorkloadId });
                    vegaApi
                        .getApiClient()
                        .get(parentResourcesRequest)
                        .then((parentResponse) => {
                            tempResources.push(...parentResponse.result.resources);
                            setResources(tempResources);
                        });
                });
        }

        let editModelTemp = {
            workloadId: currentWorkload.workloadId,
            name: currentWorkload.name,
            description: currentWorkload.description,
            workloadTypeId: currentWorkload.workloadType.value,
            budgetAmount: currentWorkload.budget.amount,
            budgetPeriodType: currentWorkload.budget.budgetPeriodType,
            isLinkedWorkload: currentWorkload.isLinkedWorkload,
        };

        setEditModel(editModelTemp);
    };

    const handleChange = (event) => {
        setEditModel({ ...editModel, [event.target.name]: event.target.value });
    };

    const goUpLevel = () => urlUpOneLevel(props);

    const saveWorkload = async () => {
        setProcessing(true);
        let model = {};
        if (appState.currentWorkload.workloadContentType === WorkloadContentType.Satellite) {
            model = {
                workloadId: editModel.workloadId,
                name: editModel.name,
                description: editModel.description,
                workloadTypeId: editModel.workloadTypeId,
            };

            const added: CloudResource[] = [];
            const removed: CloudResource[] = [];
            selectedResources.forEach((resource) => {
                if (!originalSelectedResources.map((x) => x.hostName).includes(resource.hostName)) added.push(resource);
            });
            originalSelectedResources.forEach((resource) => {
                if (!selectedResources.map((x) => x.hostName).includes(resource.hostName)) removed.push(resource);
            });
            if (removed.length > 0) {
                await resourcesContext.tagSatellites('untag', appState.awsRegions, removed, appState.currentWorkload.satelliteId, appState.currentWorkload.parentWorkloadId);
            }
            if (added.length > 0) {
                await resourcesContext.tagSatellites('tag', appState.awsRegions, added, appState.currentWorkload.satelliteId, appState.currentWorkload.parentWorkloadId);
            }

            resourcesContext.discoverResources(appState.currentWorkload.parentWorkloadId); // parent
            resourcesContext.discoverResources(appState.currentWorkload.workloadId); // satellite
        } else model = editModel;
        vegaApi
            .getApiClient()
            .put(new UpdateWorkload(model))
            .then(() => {
                // TODO get the api to return a model so we don't have to request new one
                vegaApi
                    .getApiClient()
                    .get(new GetWorkload({ workloadId: appState.currentWorkload.workloadId }))
                    .then((response) => {
                        appState.setCurrentWorkload(response.result);
                        appState.loadWorkloads();
                        setProcessing(false);
                        urlUpOneLevel(props);
                    });
            });
    };

    React.useEffect(() => {
        onAfterLoaded();
        // eslint-disable-next-line
    }, []);

    const satelliteForm = (
        <>
            <WorkloadSatelliteAwsModel
                mode='Edit'
                model={editModel}
                handleChange={handleChange}
                resources={resources}
                selectedResources={selectedResources}
                selectedResourcesChange={(newVals) => setSelectedResources(newVals)}
            />
        </>
    );

    const cloudProviderForm = (
        <>
            <div className='flexRow flexGrow'>
                <div className='marginNormal flex1 padded paper'>
                    <WorkloadInfoForm model={editModel} handleChange={handleChange} />
                </div>
                <div className='marginNormal flex1 paper padded'>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <h5>Workload AWS Details</h5>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={appState.currentWorkload.workloadConfigurationAws.accountId}
                                id='accountId'
                                name='accountId'
                                label='AWS Account'
                                variant='outlined'
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} className='flexCol'>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox disabled={true} name='isLinkedWorkload' checked={appState.currentWorkload.isLinkedWorkload} />}
                                    label='Part of an AWS Organization'
                                />
                            </div>
                            <p className={`body2 c-slate-lightest mui-checkbox-offset`} style={{ maxWidth: '20rem' }}>
                                Select this if you would like to add this workload to an AWS Organization Master Account.
                            </p>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );

    return (
        <>
            <div className='scrollContainer flexGrow flexCol'>
                <WorkloadHeader title={`Edit ${appState.currentWorkload.name}`} isMainPage={false} />
                {appState.currentWorkload.workloadContentType === WorkloadContentType.Satellite && satelliteForm}
                {appState.currentWorkload.workloadContentType === WorkloadContentType.CloudProvider && cloudProviderForm}
            </div>
            <PageFooter
                buttons={
                    <>
                        <button className='primary accent medium' onClick={goUpLevel}>
                            Cancel
                        </button>
                        {!processing && (
                            <button className='primary normal medium' onClick={saveWorkload}>
                                Save Workload
                            </button>
                        )}
                        {processing && <CircularProgress />}
                    </>
                }
            />
        </>
    );
};

export default WorkloadEdit;
