import React from 'react';
import scss from './InfoSidePanelHeader.module.scss';

export interface InfoSidePanelHeaderProps {
    showBottomMargin?: boolean;
}

export const InfoSidePanelHeader: React.FC<InfoSidePanelHeaderProps> = (props) => {
    return <div className={`flexRow alignItemsCenter ${props.showBottomMargin && scss.marginBottom} ${scss.isph}`}>{props.children}</div>;
};
