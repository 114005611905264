import React, { useState, useContext } from 'react';
import Modal from '../Modal/Modal';
import { RecommendationDto, DeclineRecommendation } from '../../ServiceStack/ServiceStack.dtos';
import { MakeMoney } from '../../utils';
import { RecommendationSchedule } from './RecommendationSchedule';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import scss from './Recommendation.module.scss';

export interface RecommendationAcceptProps {
    recommendation: RecommendationDto;
    cancelClick: () => void;
    onComplete: () => void;
}

export const RecommendationAccept: React.FC<RecommendationAcceptProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const [showSchedule, setShowSchedule] = useState(false);

    const cancelClick = () => {
        props.cancelClick();
    };

    const doItMyself = () => {
        const request = new DeclineRecommendation();
        request.id = props.recommendation.id;
        vegaApi
            .getApiClient()
            .delete(request)
            .then((_) => props.cancelClick());
    };

    const detailsModal = (
        <Modal
            size='fullscreen'
            disableAnimation={true}
            onClose={cancelClick}
            header={<h6>{props.recommendation.title}</h6>}
            content={
                <>
                    <p className={`body1 c-slate-lightest ${scss.marginBottom}`}>{props.recommendation.description}</p>
                    <ul className='body1'>
                        {props.recommendation.reviewTasks.map((task, index) => (
                            <li key={index}>
                                <div>{task.title}</div>
                                <span>{task.description}</span>
                            </li>
                        ))}
                    </ul>
                    <div className='paper dark padded'>
                        Work Cost: <span className='c-secondary'>{MakeMoney(props.recommendation.cost)}</span>
                    </div>
                </>
            }
            footer={
                <>
                    <button className='text danger' type='button' onClick={doItMyself}>
                        Do it Myself
                    </button>
                    <button className='flat medium primary' type='button' onClick={() => setShowSchedule(true)}>
                        Schedule Work
                    </button>
                </>
            }
        />
    );

    return (
        <>
            {!showSchedule && detailsModal}
            {showSchedule && <RecommendationSchedule recommendation={props.recommendation} cancelClick={cancelClick} onComplete={props.onComplete} />}
        </>
    );
};
