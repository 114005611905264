import React from 'react';
import scss from './Tabs.module.scss';

export interface TabsProps {
    align?: 'center' | undefined;
    labels: string[];
    activeTab: number;
    onChange: (index: number) => void;
}

const Tabs: React.FC<TabsProps> = (props) => {
    return (
        <div className={`${scss.tabHeaderContainer} ${props.align === 'center' ? scss.centered : ''}`}>
            {props.labels.map((tab, index) => (
                <button key={index} className={`blank ${scss.tab} ${props.activeTab === index ? scss.active : ''}`} onClick={() => props.onChange(index)}>
                    {tab}
                </button>
            ))}
        </div>
    );
};

export default Tabs;
