import React, { useContext, useState, useEffect } from 'react';
import CostHeader from '../CostHeader/CostHeader';
import { FinancialPeriodType, GetFinancialTotalsModel, GetFinancialTotals } from '../../ServiceStack/ServiceStack.dtos';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';

export interface ISpaceCostInfoProps {
    entityId: string;
}

const SpaceCostInfo: React.FC<ISpaceCostInfoProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const [costData, setCostData] = useState({
        entityId: props.entityId,
        financialPeriodType: FinancialPeriodType.MTD,
        totalCost: 0,
        budgetAmount: 0,
        savingsAmount: 0,
        productCategoryCosts: {
            Compute: 0,
            Database: 0,
            Storage: 0,
            Network: 0,
            Other: 0,
        },
    } as GetFinancialTotalsModel);

    useEffect(() => {
        const request = new GetFinancialTotals({ financialPeriodType: FinancialPeriodType.MTD, entityId: props.entityId });
        vegaApi
            .getApiClient()
            .get(request)
            .then((result) => {
                if (result.responseStatus) {
                    console.error('Error loading workload cost info!');
                    console.error(result.responseStatus.message);
                }
                setCostData(result.result);
            })
            .catch((error) => {
                console.error('Error loading workload cost info!');
                console.error(error);
            });
        // eslint-disable-next-line
    }, [props.entityId]);

    return (
        <>
            <CostHeader totalCost={costData.totalCost} budget={costData.budgetAmount} variance={costData.budgetAmount - costData.totalCost} />
        </>
    );
};

export { SpaceCostInfo };
