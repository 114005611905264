import React, { useEffect, useContext, useState } from 'react';
import ResourceTable from '../../ResourceTable/ResourceTable';
import { GetWorkloadModel, ListWorkloadResources, CloudResource } from '../../../ServiceStack/ServiceStack.dtos';
import { SchedulesContext } from '../../../contexts/Schedules/Schedules';
import { ResourcesContext } from '../../../contexts/Resources/Resources';
import { timeSince } from '../../../utils';
import { Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import { VegaApiContext } from '../../../contexts/VegaApi/VegaApi';
import { useSnackbar } from 'notistack';
import { IconButton } from '../../IconButton/IconButton';
import Icon from '../../Icon/Icon';
import { WebSocketContext } from '../../../contexts/WebSocket/WebSocket';
import { WebSocketMessage, MessageAction } from '../../../contexts/WebSocket/WebSocketTypes';

export interface ResourcesTabProps {
    workload: GetWorkloadModel;
}

interface IGroupedResources {
    server: CloudResource[];
    storage: CloudResource[];
    database: CloudResource[];
}

const ResourcesTab: React.FC<ResourcesTabProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const schedulesContext = useContext<any>(SchedulesContext);
    const resourcesContext = useContext<any>(ResourcesContext);
    const websocketContext = useContext(WebSocketContext);
    const snackbar = useSnackbar();

    const [currentAction, setCurrentAction] = useState<string>();
    const [loaded, setLoaded] = useState(false);
    const [groupedResources, setGroupedResources] = useState<IGroupedResources>({
        server: [],
        storage: [],
        database: [],
    });
    const [lastUpdated, setLastUpdated] = useState<string>();
    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    const doDiscovery = () => resourcesContext.discoverResources(props.workload.workloadId);

    const toggleExpandRow = (rowName: string) => {
        if (expandedRows.includes(rowName)) {
            let temp = [...expandedRows];
            temp.splice(temp.indexOf(rowName), 1);
            setExpandedRows(temp);
        } else setExpandedRows([...expandedRows, rowName]);
    };

    const runningDiscover = props.workload && props.workload.workloadId === resourcesContext.runningDiscover;

    const setTheGroups = (toFilter: CloudResource[]) => {
        const temp: IGroupedResources = { ...groupedResources };
        temp.database = toFilter.filter((x) => x.resourceKind === 'RDS');
        temp.server = toFilter.filter((x) => x.resourceKind === 'EC2');
        setGroupedResources(temp);
    };

    const reloadResources = () => {
        setLoaded(false);
        vegaApi
            .getApiClient()
            .get(new ListWorkloadResources({ workloadId: props.workload.workloadId }))
            .then((response) => {
                setTheGroups(response.result.resources);
                setLastUpdated(response.result.lastUpdate);
                schedulesContext
                    .getSchedule(props.workload.workloadId)
                    .then((response) => {
                        setCurrentAction(response.data.result.actionOnStart);
                        setLoaded(true);
                    })
                    .catch((error) => {
                        // TODO this should trigger a snackbar alert or something
                        if (error.response && error.response.status !== 404) console.error(error.response);
                        setLoaded(true);
                    });
            })
            .catch((error) => {
                setLoaded(true);
                snackbar.enqueueSnackbar('Error loading resources for this workload!', { variant: 'error' });
            });
    };

    useEffect(() => {
        setLoaded(false);

        const id = websocketContext.addListener((msg: WebSocketMessage) => {
            const actionsToListenFor = [
                MessageAction.EC2_DISCOVERY_ENDED,
                MessageAction.EC2_PARKING_ENDED,
                MessageAction.EC2_UNPARK_ENDED,
                MessageAction.RDS_DISCOVERY_ENDED,
                MessageAction.RDS_PARK_ENDED,
                MessageAction.RDS_UNPARK_ENDED,
            ];
            if (actionsToListenFor.includes(msg.actionType)) reloadResources();
        });

        reloadResources();

        return () => websocketContext.removeListener(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log('useEffect called');
        setTheGroups(resourcesContext.resources);
    }, [resourcesContext.resources]);

    return (
        <>
            <div className='paper noBorderRadius flexCol flexGrow noXOverflow padded'>
                <div className='flexRow alignItemsCenter flexSeparate'>
                    <div className='flexRow c-slate-lightest'>
                        {runningDiscover && 'Now Updating...'}
                        {!runningDiscover && (
                            <>
                                {lastUpdated !== '0001-01-01T00:00:00.0000000' && <>Updated {timeSince(lastUpdated)}</>}
                                {lastUpdated === '0001-01-01T00:00:00.0000000' && <>Resources have never been updated</>}
                            </>
                        )}
                    </div>
                    <div>
                        <button type='button' disabled={runningDiscover} className='accent primary small' onClick={doDiscovery}>
                            {runningDiscover ? 'Refreshing' : 'Refresh'}
                        </button>
                    </div>
                </div>
                <div className='scrollContainer'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Resources</TableCell>
                                <TableCell>New</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(groupedResources).map(
                                (groupName) =>
                                    groupedResources[groupName].length > 0 && (
                                        <React.Fragment key={groupName}>
                                            <TableRow>
                                                <TableCell>
                                                    {groupName === 'server' && (
                                                        <>
                                                            <Icon icon='compute' />
                                                            &nbsp;&nbsp;&nbsp;
                                                        </>
                                                    )}
                                                    {groupName === 'database' && (
                                                        <>
                                                            <Icon icon='database' />
                                                            &nbsp;&nbsp;&nbsp;
                                                        </>
                                                    )}
                                                    {groupName}
                                                </TableCell>
                                                <TableCell>{groupedResources[groupName].length}</TableCell>
                                                <TableCell>0</TableCell>
                                                <TableCell>
                                                    <IconButton icon='chevron-down' onClick={() => toggleExpandRow(groupName)} />
                                                </TableCell>
                                            </TableRow>
                                            {expandedRows.includes(groupName) && (
                                                <TableRow>
                                                    <TableCell colSpan={4}>
                                                        <ResourceTable resources={groupedResources[groupName]} />
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    )
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default ResourcesTab;
