import React, { useEffect, useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { GetDashboardUrl } from '../../ServiceStack/ServiceStack.dtos';
import ContentLoading from '../ContentLoading/ContentLoading';
import { Link, Typography } from '@material-ui/core';
import scss from './ReportsLanding.module.scss';
import FeedbackForm from '../FeedbackForm/FeedbackForm';

export interface IReportsLandingProps extends RouteComponentProps<any> {}

export const ReportsLanding: React.FC<IReportsLandingProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);

    const onDashboardLoad = (payload) => {
        console.log('Do something when the dashboard is fully loaded.');
    };
    const onError = (payload) => {
        console.log('Do something when the dashboard fails loading');
    };

    const qsDashboard = (dUrl) => {
        var qsContainer = document.getElementById('qsContainer');
        var options = {
            url: dUrl,
            container: qsContainer,
            scrolling: 'no',
            height: '100%',
            width: '100%',
            locale: 'en-US',
            footerPaddingEnabled: true,
        };

        const qsDash = embedDashboard(options);
        qsDash.on('error', onError);
        qsDash.on('load', onDashboardLoad);
    };

    useEffect(() => {
        vegaApi
            .getApiClient()
            .get(new GetDashboardUrl())
            .then((response) => {
                setIsLoading(false);
                qsDashboard(response.embeddedUrl);
            })
            .catch((error) => {
                console.error(error);

                setIsLoading(false);
                setIsError(true);
            });
    }, []);

    return (
        <>
            {showFeedbackForm && <FeedbackForm setShowFeedbackForm={setShowFeedbackForm} />}
            <div className='flexRow flexGrow'>
                <div style={{ position: 'relative' }} className='flexCol flexGrow bg-white'>
                    <header className='pageHeaderContainer light bottomBorder'>
                        <h4>Reports</h4>
                    </header>
                    {isLoading && <ContentLoading />}
                    <div className='flexGrow marginNormal'>
                        {isError ? (
                            <div className={scss.reportErrorContainer}>
                                <Typography variant={'h4'} align={'center'}>
                                    You do not have access to the Vega Reporting Engine.
                                </Typography>
                                <Link onClick={() => setShowFeedbackForm(true)} variant={'h4'} align={'center'}>
                                    Please contact Vega Support.
                                </Link>
                            </div>
                        ) : (
                            <div className={`${scss.reportContainer}`} id='qsContainer'></div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
