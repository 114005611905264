import React, { useContext } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import Modal from '../Modal/Modal';
import Stepper from '../Stepper/Stepper';
import WorkloadPark from '../WorkloadPark/WorkloadPark';
import { ResourcesContext } from '../../contexts/Resources/Resources';
import AdvanceButton from '../AdvanceButton/AdvanceButton';
import WorkloadUnPark from '../WorkloadUnPark/WorkloadUnPark';
import dateTimePipe from '../../utils/DateTimePipe';
import { withRouter } from 'react-router-dom';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { useSnackbar } from 'notistack';

const WorkloadUnparkWizard = withRouter((props) => {
    const [currentStep, setCurrentStep] = React.useState(0);
    const [scheduleMode] = React.useState('now');
    const [isProcessing] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const appState = useContext(AppStateContext);
    const snackbar = useSnackbar();
    const resourcesContext = React.useContext(ResourcesContext);

    const backClicked = () => setCurrentStep(currentStep - 1);

    const nextClicked = () => setCurrentStep(currentStep + 1);

    const confirmUnPark = async (wId) => {
        setSaving(true);
        try {
            await resourcesContext.unParkAllResource(appState.awsRegions, wId, scheduleMode);
            snackbar.enqueueSnackbar('The selected workload is now queued for unparking', { variant: 'info' });
            props.onClose();
        } catch (error) {
            setSaving(false);
            console.error(error);
            snackbar.enqueueSnackbar('There was an error unparking your workload', { variant: 'error' });
        }
    };

    React.useEffect(() => {
        resourcesContext.loadResources(props.workload.workloadId);
    }, []);

    const componentSteps = [<WorkloadUnPark />];

    const currentComponentStep = componentSteps[currentStep];

    const header = (
        <>
            <h5>
                <span className='icon icon-park' />
                &nbsp;&nbsp;Unpark&nbsp;{props.workload.name}
            </h5>
        </>
    );

    const footer = (
        <>
            <button className={`accent primary medium ${currentStep > 0 ? '' : 'invisible'}`} onClick={backClicked}>
                <span className='icon icon-back-arrow' /> Previous
            </button>
            {currentStep < componentSteps.length - 1 && <AdvanceButton onClick={nextClicked} />}
            {currentStep === componentSteps.length - 1 && (
                <div className='flexRow alignItemsCenter'>
                    {!saving && (
                        <div>
                            <button style={{ marginRight: '25px' }} className='normal slate-light medium' onClick={props.onClose}>
                                Cancel
                            </button>
                            <button className='normal primary medium' onClick={() => confirmUnPark(props.workload.workloadId)}>
                                Unpark
                            </button>
                        </div>
                    )}
                    {saving && (
                        <div className='center'>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            )}
        </>
    );

    const content = (
        <>
            <Grid container direction='row'>
                {/*<Grid item xs={4}>*/}
                {/*    <Stepper numSteps={componentSteps.length} currentStep={currentStep + 1} />*/}
                {/*</Grid>*/}
                <Grid item xs={4}></Grid>
            </Grid>
            {currentComponentStep}
        </>
    );

    return <Modal size='small' onClose={props.onClose} header={header} content={content} footer={footer} />;
});

export default WorkloadUnparkWizard;
