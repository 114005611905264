import React from 'react';
import { Grid as MuiGrid, GridProps as MuiGridProps } from '@material-ui/core';

export interface GridProps extends MuiGridProps {}

const Grid: React.FC<GridProps> = (props) => {
    return props.container ? (
        <div className='noOverflow'>
            <MuiGrid {...props} />
        </div>
    ) : (
        <MuiGrid {...props} />
    );
};

export default Grid;
