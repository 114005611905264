import React, { useState, useContext } from 'react';
import Tabs from '../../Tabs/Tabs';
import ResourcesTab from './ResourcesTab';
import OverviewTab from './OverviewTab';
import WorkloadActions from '../../WorkloadActions/WorkloadActions';
import HistoryTab from './HistoryTab';
import LinkedWorkloadsTab from './LinkedWorkloadsTab';
import WorkloadHeader from '../../WorkloadHeader/WorkloadHeader';
import { AppStateContext } from '../../../contexts/AppState/AppState';

export interface WorkloadDetailImportLandingProps {}

const WorkloadDetailImportLanding: React.FC<WorkloadDetailImportLandingProps> = (props) => {
    const appState = useContext(AppStateContext);

    const [currentTab, setCurrentTab] = useState(0);

    return (
        <>
            <WorkloadHeader title={appState.currentWorkload.name} isMainPage={true} />
            {appState.currentWorkload.isMasterWorkload && (
                <Tabs labels={['Overview', 'Resources', 'History', 'Workloads']} activeTab={currentTab} onChange={(i) => setCurrentTab(i)} align='center' />
            )}
            {!appState.currentWorkload.isMasterWorkload && appState.currentWorkload.satelliteId < 1 && (
                <Tabs labels={['Overview', 'Resources', 'History']} activeTab={currentTab} onChange={(i) => setCurrentTab(i)} align='center' />
            )}
            {appState.currentWorkload.satelliteId > 0 && (
                <Tabs labels={['Overview', 'Actions', 'Resources', 'History']} activeTab={currentTab} onChange={(i) => setCurrentTab(i)} align='center' />
            )}

            {currentTab === 0 && <OverviewTab workload={appState.currentWorkload} />}
            {(currentTab === 1 && !appState.currentWorkload.isMasterWorkload && appState.currentWorkload.satelliteId < 1) ||
            (currentTab === 1 && appState.currentWorkload.isMasterWorkload) ? (
                <ResourcesTab workload={appState.currentWorkload} />
            ) : (
                currentTab === 1 && <WorkloadActions />
            )}
            {(currentTab === 2 && !appState.currentWorkload.isMasterWorkload && appState.currentWorkload.satelliteId < 1) ||
            (currentTab === 2 && appState.currentWorkload.isMasterWorkload) ? (
                <HistoryTab workload={appState.currentWorkload} />
            ) : (
                currentTab === 2 && <ResourcesTab workload={appState.currentWorkload} />
            )}
            {currentTab === 3 && appState.currentWorkload.isMasterWorkload ? (
                <LinkedWorkloadsTab workload={appState.currentWorkload} />
            ) : (
                currentTab === 3 && <HistoryTab workload={appState.currentWorkload} />
            )}
            {currentTab === 4 && <LinkedWorkloadsTab workload={appState.currentWorkload} />}
        </>
    );
};

export default WorkloadDetailImportLanding;
