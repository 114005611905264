import React, { useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BackButton from '../BackButton/BackButton';
import WorkloadActionMenu from '../WorkloadActionMenu/WorkloadActionMenu';
import scss from './WorkloadHeader.module.scss';
import WorkloadTypeIndicator from '../WorkloadTypeIndicator/WorkloadTypeIndicator';
import { urlUpOneLevel } from '../../utils';
import { AppStateContext } from '../../contexts/AppState/AppState';
import VegaBreadcrumbs from '../VegaBreadcrumbs/VegaBreadcrumbs';
import TopBar from '../TopBar/TopBar';
import DotIndicator from '../DotIndicator/DotIndicator';

export interface WorkloadHeaderProps extends RouteComponentProps<any> {
    title: string;
    isMainPage: boolean;
    showBorderBottom?: boolean;
}

const WorkloadHeader: React.FC<WorkloadHeaderProps> = (props) => {
    const appState = useContext(AppStateContext);

    const goBack = () => urlUpOneLevel(props);

    let view = <React.Fragment />;

    const isQumuloSalesRep = appState.currentUser.email.endsWith('@qumulo.com') && appState.currentUser.email.startsWith('salesrep');
    const isTelusPOC = appState.currentUser.email.endsWith('@telus.com') && appState.currentUser.email.startsWith('teluspoc');

    if (appState.currentWorkload)
        view = (
            <header
                className={`pageHeaderContainer flexRow flexSeparate ${props.isMainPage ? 'light' : 'dark'}  ${(!props.isMainPage || props.showBorderBottom) && scss.container}`}
            >
                <div className='flexGrow'>
                    <VegaBreadcrumbs />
                    <div className='flexRow flexSeparate'>
                        <div className='flexRow'>
                            <BackButton onClick={goBack} />
                            <h4>{props.title}</h4>
                        </div>
                        {props.isMainPage && !isQumuloSalesRep && !isTelusPOC && <WorkloadActionMenu />}
                        {props.isMainPage && (isQumuloSalesRep || isTelusPOC) && (
                            <div>
                                <DotIndicator color='running' /> Running...
                            </div>
                        )}
                    </div>
                </div>
                {!props.isMainPage && (
                    <div className='flexCol'>
                        <TopBar />
                        <WorkloadTypeIndicator workload={appState.currentWorkload} />
                    </div>
                )}
            </header>
        );

    return view;
};

export default withRouter(WorkloadHeader);
