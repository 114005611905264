import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemIcon, ListItemText } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import * as Globals from '../../globals';
import Icon from '../Icon/Icon';

export interface SpaceActionMenuProps extends RouteComponentProps<any> {}

const SpaceActionMenu = withRouter((props: SpaceActionMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        doAction(event.currentTarget.id);
    };

    const doOpen = (event) => {
        setOpen(true);
    };

    const doDelete = (event) => {
        setOpen(false);
        setAnchorEl(null);
    };

    const doCancel = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const doFavorite = () => {
        setOpen(false);
        setAnchorEl(null);
        // TODO actually write some code here
    };

    const doAction = (actionId) => {
        let pStr = props.history.location.pathname;

        switch (actionId) {
            case Globals.ACTION_MENU_ACTIONS.MAKE_FAVORITE:
                doFavorite();
                break;
            case Globals.ACTION_MENU_ACTIONS.EDIT:
                props.history.push(pStr + '/edit');
                break;
            default:
                console.log('Unknown ActionId');
        }
    };

    return (
        <>
            <button className='accent primary small' onClick={handleClick}>
                <Icon icon='settings' />
            </button>
            <Menu id='action-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem id={Globals.ACTION_MENU_ACTIONS.EDIT} onClick={handleClose}>
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText primary={Globals.ACTION_MENU_ACTIONS.EDIT} />
                </MenuItem>
                <MenuItem id={Globals.ACTION_MENU_ACTIONS.DELETE} onClick={doOpen} disabled={true}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText primary={Globals.ACTION_MENU_ACTIONS.DELETE} />
                </MenuItem>
            </Menu>
            <Dialog open={open} onClose={doCancel} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{Globals.DELETE_SPACE}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>{Globals.DELETE_SPACE_DESC}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={doCancel} color='primary'>
                        {Globals.CANCEL}
                    </Button>
                    <Button onClick={doDelete} color='primary' autoFocus>
                        {Globals.DELETE_SPACE}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default SpaceActionMenu;
