import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AdminNavigation from '../AdminNavigation/AdminNavigation';
import CombinedTopbar from '../CombinedTopbar/CombinedTopbar';
import { AppStateContext } from '../../contexts/AppState/AppState';

export interface IAdminLandingProps extends RouteComponentProps<any> {}

export const AdminLanding: React.FC<IAdminLandingProps> = (props) => {
    return (
        <>
            <div className='flexRow flexGrow'>
                <AdminNavigation />
                <div className='flexCol flexGrow bg-white'>
                    <header className='pageHeaderContainer light bottomBorder'>
                        <CombinedTopbar />
                        <h4>Administration Console</h4>
                    </header>
                    <div className='flexGrow marginNormal'>
                        <p>Welcome to the administration portal, where you can administer all the things.</p>
                    </div>
                </div>
            </div>
        </>
    );
};
