import React from 'react';
import scss from './Fab.module.scss';
import Icon from '../Icon/Icon';

export interface FabProps {
    icon: string;
    onClick: () => void;
    className?: string;
}

const Fab = (props: FabProps) => {
    return (
        <button className={`blank ${scss.fab} ${!!props.className && props.className}`} onClick={props.onClick}>
            <Icon icon={props.icon} className='big' />
        </button>
    );
};

export default Fab;
