import React, { useState } from 'react';
import Tabs from '../Tabs/Tabs';
import UiDemoTypographyTab from './UiDemoTypographyTab';
import UiDemoButtonsTab from './UiDemoButtonsTab';
import UiDemoLayoutCompsTab from './UiDemoLayoutCompsTab';
import { ReactComponent as VegaLogo } from '../../images/vega-logo.svg';

export interface UiDemoLandingProps {}

const UiDemoLanding: React.FC<UiDemoLandingProps> = (props) => {
    const [currentTab, setCurrentTab] = useState(0);

    return (
        <div className='flexCol'>
            <div className='flexRow center padded'>
                <div className='svgIconNormal'>
                    <VegaLogo />
                </div>
                <h4>&nbsp;&nbsp;UI Kit Demo</h4>
            </div>
            <Tabs labels={['Typography', 'Buttons', 'Layout Components']} activeTab={currentTab} onChange={(i) => setCurrentTab(i)} align='center' />
            <div className='flexGrow padded'>
                {currentTab === 0 && <UiDemoTypographyTab />}
                {currentTab === 1 && <UiDemoButtonsTab />}
                {currentTab === 2 && <UiDemoLayoutCompsTab />}
            </div>
        </div>
    );
};

export default UiDemoLanding;
