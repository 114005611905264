import React, { useContext, useState } from 'react';
import Modal from '../Modal/Modal';
import { CircularProgress, TextField } from '@material-ui/core';
import { CreateUserFeedback } from '../../ServiceStack/ServiceStack.dtos';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { RouteComponentProps, withRouter } from 'react-router';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';

export interface IFeedbackFormProps extends RouteComponentProps<any> {
    setShowFeedbackForm: (value: boolean) => void;
}

const FeedbackForm: React.FC<IFeedbackFormProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);

    const [userFeedback, setUserFeedback] = useState('');
    const [sending, setSending] = useState(false);

    const sendFeedback = () => {
        setSending(true);
        const request = new CreateUserFeedback({
            email: appState.currentUser.email,
            feedback: userFeedback,
            subject: props.location.pathname,
        });
        vegaApi
            .getApiClient()
            .post(request)
            .then(() => {
                setUserFeedback('');
                setSending(false);
                props.setShowFeedbackForm(false);
            });
    };

    return (
        <Modal
            size='auto'
            header={<h6>Vega Support</h6>}
            content={
                <>
                    <p>Please share your feedback or issue with us! We'll get in touch!</p>
                    <TextField multiline rows={3} variant='outlined' onChange={(e) => setUserFeedback(e.target.value)} placeholder='Details here...' />
                </>
            }
            footer={
                <>
                    {!sending && (
                        <button className='primary normal medium' onClick={sendFeedback}>
                            Send
                        </button>
                    )}
                    {sending && <CircularProgress />}
                </>
            }
            onClose={() => props.setShowFeedbackForm(false)}
        />
    );
};

export default withRouter(FeedbackForm);
