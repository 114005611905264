import React from 'react';
import scss from './ContentLoading.module.scss';
import { CircularProgress } from '@material-ui/core';

export interface IContentLoadingProps {
    loadingMessage?: string;
    className?: string;
    style?: any;
}

const ContentLoading: React.FC<IContentLoadingProps> = (props) => {
    return (
        <div className={`${scss.spinnerContainer} ${!!props.className && props.className}`} style={!!props.style ? props.style : {}}>
            <div className={scss.loading}>
                <CircularProgress />
                <span className='c-slate-lightest padded'>{props.loadingMessage ? props.loadingMessage : 'Loading Data'}</span>
            </div>
        </div>
    );
};

export default ContentLoading;
