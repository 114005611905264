import React, { useContext, useState } from 'react';
import { Grid, Checkbox, CircularProgress } from '@material-ui/core';
import scss from './Onboard.module.scss';
import { ReactComponent as NoneCompliance } from '../../images/icons/logos/none-compliance.svg';
import { ReactComponent as PciIcon } from '../../images/icons/logos/pci.svg';
import { ReactComponent as HipaaIcon } from '../../images/icons/logos/hipaa.svg';
import { ReactComponent as Soc2Icon } from '../../images/icons/logos/soc2.svg';
import { ReactComponent as FedRampIcon } from '../../images/icons/logos/fedramp.svg';
import Stepper from '../Stepper/Stepper';
import PreviousButton from '../PreviousButton/PreviousButton';
import AdvanceButton from '../AdvanceButton/AdvanceButton';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { ListComplianceTypes, ComplianceTypeModel, CreateOnBoardOrganization } from '../../ServiceStack/ServiceStack.dtos';
import { useSnackbar } from 'notistack';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export interface OnboardStep3Props extends RouteComponentProps<any> {
    model: any;
    modelChange: (newValues: any) => void;
    back: () => void;
    next: () => void;
}

const OnboardStep3: React.FC<OnboardStep3Props> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const snackbar = useSnackbar();
    const appState = useContext(AppStateContext);

    const [saving, setSaving] = useState(false);
    const [allowNext, setAllowNext] = useState(false);
    const [complianceTypes, setComplianceTypes] = useState<ComplianceTypeModel[]>([]);

    const onCompleteOnboard = () => {
        setSaving(true);
        vegaApi
            .getApiClient()
            .post(new CreateOnBoardOrganization({ ...props.model }))
            .then(() => {
                // if user refreshes the page while onboarding, we won't have their PW stored anymore
                if (!appState.password) appState.logout();
                else {
                    // wait for org to actually be created
                    setTimeout(() => {
                        // need a new token, so login again
                        appState.login(appState.currentUser.email, appState.password).then(() => {
                            props.history.push('/dashboard');
                        });
                    }, 3500);
                }
            })
            .catch((error) => {
                setSaving(false);
                const errorMsg = error.responseStatus.message;
                switch (errorMsg) {
                    // case "The card number is not a valid credit card number.":
                    //     setErrors({ ...errors, CreditCardNumber: errorMsg });
                    //     break;
                    // case "Your card's expiration year is invalid.":
                    //     setErrors({ ...errors, CreditCardExpYear: errorMsg });
                    //     break;
                    // case "Your card's expiration month is invalid.":
                    //     setErrors({ ...errors, CreditCardExpMonth: errorMsg });
                    //     break;
                    // case "Your card's security code is invalid.":
                    //     setErrors({ ...errors, CreditCardCvc: errorMsg });
                    //     break;
                    default: {
                        if (errorMsg) snackbar.enqueueSnackbar(`Error onboarding: ${errorMsg}`, { variant: 'error' });
                        else snackbar.enqueueSnackbar('Sorry, but we are having trouble completing your onboarding. Please contact support', { variant: 'error' });
                        console.error('Error submitting onboarding!');
                    }
                }
            });
    };

    React.useEffect(() => {
        setAllowNext(props.model.complianceTypes && props.model.complianceTypes.length > 0);
    }, [props.model.complianceTypes]);

    React.useEffect(() => {
        vegaApi
            .getApiClient()
            .get(new ListComplianceTypes())
            .then((response) => setComplianceTypes(response.result.complianceTypes))
            .catch(() => {
                snackbar.enqueueSnackbar('Error loading Compliance types', { variant: 'error' });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const check = (isChecked, value) => {
        if (isChecked) {
            let temp = [...props.model.complianceTypes];
            if (value === '1') temp = [];
            else {
                const idx = temp.indexOf('1');
                if (idx >= 0) temp.splice(idx, 1);
            }
            temp.push(value);
            props.modelChange({ complianceTypes: temp });
        } else {
            const temp = [...props.model.complianceTypes];
            temp.splice(props.model.complianceTypes.indexOf(value), 1);
            props.modelChange({ complianceTypes: temp });
        }
    };

    const cardClicked = (val) => {
        const checked = !props.model.complianceTypes.includes(val);
        check(checked, val);
    };

    const checkChange = (event) => check(event.target.checked, event.target.value);

    // const nextClicked = () => props.next();

    const complianceItemChecked = (complianceTypeId) => {
        return props.model.complianceTypes.includes(complianceTypeId.toString());
    };

    return (
        <div className='marginNormal paper flexCol flexGrow'>
            <div className={`flexGrow ${scss.contentContainer}`}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Stepper numSteps={4} currentStep={3} />
                    </Grid>

                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <h6 className='center'>Let's take a few moments and set up your new organization and Vega dashboard.</h6>
                    </Grid>
                    <Grid item xs={4} />

                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={4}>
                                <p className='body1 c-slate-light'>
                                    Please choose one or more compliance settings. This determines your default security settings, which may be changed at any time.
                                </p>
                            </Grid>
                            <Grid item xs={4}></Grid>
                        </Grid>
                        <div className={`flexRow`}>
                            <div className='centeredCardContainer'>
                                {complianceTypes.map((cOption) => (
                                    <div className='cardWrapper' key={cOption.complianceTypeId}>
                                        <div className='simpleCard selectable padded centerContent' onClick={() => cardClicked(cOption.complianceTypeId.toString())}>
                                            <div className='cardSelectControl'>
                                                <Checkbox checked={complianceItemChecked(cOption.complianceTypeId)} onChange={checkChange} value={cOption.complianceTypeId} />
                                            </div>
                                            <div className='svgIconNormal'>
                                                {cOption.complianceTypeId === 1 && <NoneCompliance />}
                                                {cOption.complianceTypeId === 2 && <PciIcon />}
                                                {cOption.complianceTypeId === 3 && <HipaaIcon />}
                                                {cOption.complianceTypeId === 4 && <Soc2Icon />}
                                                {cOption.complianceTypeId === 5 && <FedRampIcon />}
                                            </div>
                                            <p className='body1 c-slate'>{cOption.complianceTypeName}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <footer className={`padded flexRow flexSeparate ${scss.footer}`}>
                <div>
                    <PreviousButton onClick={props.back} />
                </div>
                <div>
                    {!saving && <AdvanceButton onClick={onCompleteOnboard} disabled={!allowNext} label='Complete Onboarding' />}
                    {saving && <CircularProgress />}
                </div>
            </footer>
        </div>
    );
};

export default withRouter(OnboardStep3);
