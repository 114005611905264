import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import * as Globals from '../../globals';

const styles = {
    root: {},
};

class FullName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: Globals.EMPTY_STR,
            errorMsg: Globals.EMPTY_STR,
        };
        this.helperTextProps = {
            error: true,
        };
    }
    // React Component Lifecycle Methods
    componentDidMount() {}
    componentDidUpdate() {}
    componentWillUnmount() {}

    updateFullName = () => {
        if (this.props.setFN instanceof Function) {
            this.props.setFN(this.state.fullName);
        }
        if (this.props.onBlur instanceof Function) {
            this.props.onBlur(this.state.fullName);
        }
    };

    checkFullName = (evt) => {
        this.setState({
            fullName: evt.target.value,
        });
        this.validateFullName(evt.target.value);
    };

    validateFullName = (theFullName) => {
        // Check to see that you have at least one word, and no more than 5 words
        let msg = Globals.EMPTY_STR;
        let regEx = /^[a-z ,.'-]+$/i;

        if (theFullName != null) {
            if (regEx.test(theFullName)) {
                msg = Globals.EMPTY_STR;
            } else {
                msg = Globals.INVALID_FULLNAME_MSG;
            }
            if (theFullName.indexOf(Globals.SPACE) === -1) {
                msg = Globals.EMPTY_STR;
            } else {
                // Split on SPACE
                var tokens = theFullName.split(Globals.SPACE);
                if (tokens.length > 5) msg = Globals.INVALID_FULLNAME_MSG;
            }
        }
        this.setState({
            errorMsg: msg,
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <FormControl>
                    <TextField
                        error={this.state.errorMsg.length > 0 ? true : false}
                        id={classes.id}
                        label='Full name'
                        className={classes.className}
                        helperText={this.state.errorMsg}
                        FormHelperTextProps={this.helperTextProps}
                        type='fullName'
                        name='fullName'
                        autoComplete='name'
                        variant='outlined'
                        width={classes.width}
                        onChange={this.checkFullName}
                        onBlur={this.updateFullName}
                    />
                </FormControl>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(FullName);
