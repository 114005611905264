import React from 'react';
import scss from './UiDemoTypographyTab.module.scss';

export interface UiDemoTypographyTabProps {}

const UiDemoTypographyTab: React.FC<UiDemoTypographyTabProps> = (props) => {
    return (
        <>
            <h1>Colors</h1>
            <hr />
            <div className={scss.coloursContainer}>
                <div className='bg-slate-lightest'>slate-lightest</div>
                <div className='bg-slate-light'>slate-light</div>
                <div className='bg-slate c-white'>slate</div>
                <div className='bg-slate-dark c-white'>slate-dark</div>
                <div className='bg-primary-light'>primary-light</div>
                <div className='bg-primary c-white'>primary</div>
                <div className='bg-primary-dark c-white'>primary-dark</div>
                <div className='bg-success-light'>success-light</div>
                <div className='bg-success'>success</div>
                <div className='bg-success-dark'>success-dark</div>
                <div className='bg-warning-light'>warning-light</div>
                <div className='bg-warning'>warning</div>
                <div className='bg-warning-dark'>warning-dark</div>
                <div className='bg-danger-light'>danger-light</div>
                <div className='bg-danger'>danger</div>
                <div className='bg-danger-dark c-white'>danger-dark</div>
                <div className='bg-mono-lightest'>mono-lightest</div>
                <div className='bg-mono-light'>mono-light</div>
                <div className='bg-mono'>mono</div>
                <div className='bg-mono-dark'>mono-dark</div>
                <div className='bg-secondary c-white'>secondary</div>
                <div className='bg-secondary-dark c-white'>secondary-dark</div>
                <div className='bg-secondary-accent c-white'>secondary-accent</div>
                <div className='bg-secondary-shadow c-white'>secondary-shadow</div>

                <p>
                    These colors are available for use in the styles of this app using the names shown above. Here are the ways they are available:
                    <ul>
                        <li>
                            For <b>backgrounds</b>, use the className "bg-nameOfColor"
                        </li>
                        <li>
                            For <b>text</b>, use the className "c-nameOfColor"
                        </li>
                        <li>
                            Sass variables -- reference them by using <code>$name-of-color</code>
                        </li>
                    </ul>
                </p>
            </div>

            <h1>Typography</h1>
            <hr />
            <h1>Heading 1</h1>
            <h2>Heading 2</h2>
            <h3>Heading 3</h3>
            <div className='flexRow'>
                <h4>Heading 4</h4>
                <p className='subtitle2 c-slate-lightest'>Used for page headings</p>
            </div>
            <div className='flexRow'>
                <h5>Heading 5</h5>
                <p className='subtitle2 c-slate-lightest'>Used for section headings</p>
            </div>
            <h6>Heading 6</h6>

            <p>This is body1 text (line height 1.5rem)</p>
            <p>This is body2 text (line height 1.5rem)</p>
            <p>This is subtitle1 text (line height 2rem)</p>
            <p>This is subtitle2 text (line height 1.5rem)</p>
            <p>This is caption text (line height 1rem)</p>

            <div className='paper dark padded'>Text can be left or right aligned with the className's "ta-right" or "ta-left". To center text, use the className "center"</div>
        </>
    );
};

export default UiDemoTypographyTab;
