import React from 'react';
import { CircularProgress, Grid, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@material-ui/core';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { CreateLoginPasswordAction, PasswordAction, CreateLoginPasswordActionResponse } from '../../ServiceStack/ServiceStack.dtos';

export interface ForgotPasswordProps {
    next: (response: CreateLoginPasswordActionResponse, email: string) => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
    const vegaApi = React.useContext(VegaApiContext);
    const [theEmail, setTheEmail] = React.useState('');
    const [processing, setProcessing] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);

    const setEmailAddr = (event) => setTheEmail(event.target.value);

    const submitForm = (event) => {
        event.preventDefault();
        setProcessing(true);
        const request = new CreateLoginPasswordAction({ email: theEmail, passwordAction: PasswordAction.Forgot });
        vegaApi
            .getApiClient()
            .post(request)
            .then((response) => {
                props.next(response, theEmail);
            })
            .catch(() => {
                setProcessing(false);
                setEmailError(true);
            });
    };

    const cantAccessAccountMailto = 'mailto:support@vegacloud.io?subject=VCC::Cannot Access My Account&body=User:' + theEmail;

    return (
        <>
            <form onSubmit={submitForm}>
                <Grid container spacing={4}>
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <h5>Forgot Password?</h5>
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <FormControl variant='outlined' error={emailError}>
                            <InputLabel htmlFor='theEmail'>Email</InputLabel>
                            <OutlinedInput id='theEmail' name='theEmail' onChange={setEmailAddr} labelWidth={40} />
                            {emailError && <FormHelperText>We could not find that email.</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} />

                    <Grid item xs={12}>
                        {!processing && (
                            <button type='submit' className='normal medium primary center' disabled={!(theEmail.length > 0)}>
                                Next
                            </button>
                        )}
                        {processing && (
                            <div className='center'>
                                <CircularProgress />
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <a className='center' href={cantAccessAccountMailto}>
                            Cannot access your account?
                        </a>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default ForgotPassword;
