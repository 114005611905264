import React, { useContext, useState } from 'react';
import { Grid, RadioGroup, Radio, TextField } from '@material-ui/core';
import scss from './Onboard.module.scss';
import { ReactComponent as CentralItIcon } from '../../images/icons/logos/central-it.svg';
import { ReactComponent as BusinessUnitIcon } from '../../images/icons/logos/bu-it.svg';
import { ReactComponent as OtherIcon } from '../../images/icons/logos/other.svg';
import Stepper from '../Stepper/Stepper';
import PreviousButton from '../PreviousButton/PreviousButton';
import AdvanceButton from '../AdvanceButton/AdvanceButton';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { ListItOrganizationTypes, ItOrganizationTypeModel } from '../../ServiceStack/ServiceStack.dtos';
import { useSnackbar } from 'notistack';

export interface OnboardStep2Props {
    model: any;
    modelChange: (newValues: any) => void;
    back: () => void;
    next: () => void;
}

const OnboardStep2: React.FC<OnboardStep2Props> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const snackbar = useSnackbar();

    const [allowNext, setAllowNext] = useState(false);
    const [itOrgTypes, setItOrgTypes] = useState<ItOrganizationTypeModel[]>([]);

    const handleChange = (event) => props.modelChange({ [event.target.name]: event.target.value });

    const cardClicked = (val) => props.modelChange({ ItOrganizationTypeId: val });

    const nextStep = () => props.next();

    React.useEffect(() => {
        setAllowNext(!!props.model.ItOrganizationTypeId);
    }, [props.model.ItOrganizationTypeId]);

    React.useEffect(() => {
        vegaApi
            .getApiClient()
            .get(new ListItOrganizationTypes())
            .then((response) => setItOrgTypes(response.result.itOrganizationTypes))
            .catch(() => {
                snackbar.enqueueSnackbar('Error loading IT Organization types', { variant: 'error' });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className='marginNormal paper flexCol flexGrow'>
                <div className={`flexGrow ${scss.contentContainer}`}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Stepper numSteps={4} currentStep={2} />
                        </Grid>

                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <h6 className='center'>Let's take a few moments and set up your new organization and Vega dashboard.</h6>
                        </Grid>
                        <Grid item xs={4} />

                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={4} />
                                <Grid item xs={4}>
                                    <h5>IT Set Up</h5>
                                    <p className='body1 c-slate-light'>How is your IT structured?</p>
                                </Grid>
                                <Grid item xs={4} />

                                <Grid item xs={12}>
                                    <RadioGroup name='ItOrganizationTypeId' value={props.model.ItOrganizationTypeId} onChange={handleChange}>
                                        <div className='centeredCardContainer'>
                                            {itOrgTypes.map((itOrgType) => (
                                                <div className='cardWrapper' key={itOrgType.itOrganizationTypeId}>
                                                    <div
                                                        className='simpleCard selectable padded centerContent'
                                                        onClick={() => cardClicked(itOrgType.itOrganizationTypeId.toString())}
                                                    >
                                                        <div className='cardSelectControl'>
                                                            <Radio value={itOrgType.itOrganizationTypeId.toString()} />
                                                        </div>
                                                        <div className={`${scss.cardTop} flexCol `}>
                                                            <div className='svgIconNormal center'>
                                                                {itOrgType.itOrganizationTypeId.toString() === '1' && <CentralItIcon />}
                                                                {itOrgType.itOrganizationTypeId.toString() === '2' && <BusinessUnitIcon />}
                                                                {itOrgType.itOrganizationTypeId.toString() === '3' && <OtherIcon />}
                                                            </div>
                                                            <p className='body1 c-slate'>{itOrgType.itOrganizationTypeName}</p>
                                                        </div>
                                                        {itOrgType.itOrganizationTypeId.toString() === '3' && props.model.ItOrganizationTypeId === '3' && (
                                                            <TextField
                                                                variant='outlined'
                                                                name='itOrganizationTypeOther'
                                                                value={props.model.itOrganizationTypeOther}
                                                                onChange={handleChange}
                                                                className={scss.cardBottom}
                                                                placeholder='IT Set Up'
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <footer className={`padded flexRow flexSeparate ${scss.footer}`}>
                    <div>
                        <PreviousButton onClick={props.back} />
                    </div>
                    <div>
                        <AdvanceButton onClick={nextStep} disabled={!allowNext} />
                    </div>
                </footer>
            </div>
        </>
    );
};

export default OnboardStep2;
