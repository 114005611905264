import React, { useEffect, useContext, useState } from 'react';
import { InfoSidePanelHeader } from '../InfoSidePanel/InfoSidePanelHeader';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { ListRecommendations, RecommendationDto, DeclineRecommendation, RecommendationStatus } from '../../ServiceStack/ServiceStack.dtos';
import { SidebarMessage } from '../SidebarMessage/SidebarMessage';
import { IconButton } from '../IconButton/IconButton';
import { RecommendDetails } from './RecommendDetails';
import { RecommendationAccept } from './RecommendationAccept';
import { CircularProgress } from '@material-ui/core';
import Chip from '../Chip/Chip';
import Icon from '../Icon/Icon';
import * as Globals from '../../globals';

enum ViewMode {
    ListAll,
    Details,
    EditSchedule,
    RecommendationAccept,
}

export interface RecommendationsLandingProps {
    onDeleteSchedule: (recommendationId: string) => void;
}

export const RecommendationsLanding: React.FC<RecommendationsLandingProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const [recommendations, setRecommendations] = useState<RecommendationDto[]>([]);
    const [viewMode, setViewMode] = useState(ViewMode.ListAll);
    const [currentRecommendation, setCurrentRecommendation] = useState<RecommendationDto>();
    const [loading, setLoading] = useState(false);
    const [loadComplete, setLoadComplete] = useState(false);

    const loadRecommendations = () => {
        setLoading(true);
        const request = new ListRecommendations();
        vegaApi
            .getApiClient()
            .get(request)
            .then((response) => {
                setRecommendations(
                    response.results
                        .filter(
                            (x) => x.status === RecommendationStatus.Scheduled || x.status === RecommendationStatus.WaitingForApproval || x.status === RecommendationStatus.Pending
                        )
                        .sort((a, b) => +!!(a.status === RecommendationStatus.Scheduled) - +!!(b.status === RecommendationStatus.Scheduled))
                );
                setLoading(false);
                setLoadComplete(true);
            });
    };

    useEffect(() => {
        loadRecommendations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const doItMyself = (recommendationId: string, event: any) => {
        event.target.disabled = true;
        const request = new DeclineRecommendation();
        request.id = recommendationId;
        vegaApi
            .getApiClient()
            .delete(request)
            .then((_) => setRecommendations(recommendations.filter((x) => x.id !== recommendationId)));
    };

    const cancelClick = () => {
        setViewMode(ViewMode.ListAll);
        setCurrentRecommendation(undefined);
    };

    const viewDetails = (rec: RecommendationDto) => () => {
        setCurrentRecommendation(rec);
        setViewMode(ViewMode.Details);
    };

    const acceptRecommendation = (rec: RecommendationDto) => () => {
        setCurrentRecommendation(rec);
        setViewMode(ViewMode.RecommendationAccept);
    };

    const onScheduleComplete = () => {
        setViewMode(ViewMode.ListAll);
        setCurrentRecommendation(undefined);
        loadRecommendations();
    };

    const onDeleteSchedule = (recommendationId: string) => {
        props.onDeleteSchedule(recommendationId);
        setViewMode(ViewMode.ListAll);
        setCurrentRecommendation(undefined);
        setRecommendations(recommendations.filter((recommendation) => recommendation.id !== recommendationId));
    };

    const landingView = (
        <>
            <div className='padded'>
                <InfoSidePanelHeader showBottomMargin={true}>
                    <h5 className='c-slate-light'>Recommendations</h5>
                </InfoSidePanelHeader>
                <div className='scrollContainer noScrollbar'>
                    {recommendations.map((recommendation) => (
                        <SidebarMessage
                            key={recommendation.id}
                            title={recommendation.title}
                            actionButtons={
                                recommendation.status !== RecommendationStatus.Scheduled && (
                                    <>
                                        <button className='text danger' type='button' onClick={(event) => doItMyself(recommendation.id, event)}>
                                            Do it Myself
                                        </button>
                                        <button className='flat small primary' type='button' onClick={acceptRecommendation(recommendation)}>
                                            Continue
                                        </button>
                                    </>
                                )
                            }
                            actionIcon={<IconButton icon='chevron-right' onClick={viewDetails(recommendation)} />}
                            statusIndicator={
                                (recommendation.status === RecommendationStatus.Scheduled || recommendation.status === RecommendationStatus.Pending) && (
                                    <Chip
                                        label={recommendation.status}
                                        icon={recommendation.status === RecommendationStatus.Scheduled && <Icon icon='clock' />}
                                        className='bg-success'
                                    />
                                )
                            }
                        />
                    ))}
                </div>
                {!(recommendations.length > 0) && loadComplete && <div>No new recommendations</div>}
                {loading && (
                    <div className='center'>
                        <CircularProgress />
                        <br />
                        Loading...
                    </div>
                )}
            </div>
        </>
    );

    const comingsoonLandingView = (
        <>
            <InfoSidePanelHeader showBottomMargin={true}>
                <h6 className='c-slate-light'>Recommendations</h6>
            </InfoSidePanelHeader>
            <div className='c-white'>Coming Soon, to a town near You!</div>
        </>
    );

    return (
        <>
            {viewMode === ViewMode.ListAll && Globals.ENABLE_RECOMMENDATIONS && landingView}
            {viewMode === ViewMode.ListAll && !Globals.ENABLE_RECOMMENDATIONS && comingsoonLandingView}
            {viewMode === ViewMode.Details && (
                <RecommendDetails
                    recommendation={currentRecommendation}
                    cancelClick={cancelClick}
                    onComplete={onScheduleComplete}
                    refreshRecommendation={loadRecommendations}
                    onClickContinue={acceptRecommendation(currentRecommendation)}
                    onDeleteSchedule={onDeleteSchedule}
                    onClickDoItMyself={(event) => {
                        doItMyself(currentRecommendation.id, event);
                        cancelClick();
                    }}
                />
            )}
            {viewMode === ViewMode.RecommendationAccept && (
                <RecommendationAccept recommendation={currentRecommendation} cancelClick={cancelClick} onComplete={onScheduleComplete} />
            )}
        </>
    );
};
