import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Typography, Paper, Grid } from '@material-ui/core';

import './WorkloadCreateGcp.css';

const styles = (theme) => ({
    root: {
        left: '4rem',
        right: 0,
        top: '4rem',
        bottom: 0,
        zIndex: -2,
        display: 'block',
        position: 'absolute',
    },
    grid: {
        zIndex: -2,
    },
    griditem: {
        zIndex: -2,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        zIndex: -2,
    },
});
class WorkloadCreateGcp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    // React Component Lifecycle Methods
    componentDidMount() {}
    componentDidUpdate() {}
    componentWillUnmount() {}

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <div className={classes.root}>
                    <Grid className={classes.grid}>
                        <Grid className={classes.griditem} item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant='h1'>Create a new Vega GCP Workload</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(WorkloadCreateGcp);
