import React, { useState, useEffect, useContext } from 'react';
import Modal from '../Modal/Modal';
import { RecommendationDto, ScheduleType, UpdateRecommendationSchedule, RecommendationStatus, StartRecommendationAcceptWithSchedule } from '../../ServiceStack/ServiceStack.dtos';
import { RadioGroup, Radio, CircularProgress, TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider , TimePicker } from '@material-ui/pickers';
import scss from './Recommendation.module.scss';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { formatMoment, formatMomentDate, formatMomentTime, MakeMoney } from '../../utils';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import business from 'moment-business';
import './RecommendationSchedule.scss';

export interface RecommendationScheduleProps {
    cancelClick: () => void;
    onComplete: () => void;
    recommendation: RecommendationDto;
    isEditSchedule?: boolean;
}

export const RecommendationSchedule: React.FC<RecommendationScheduleProps> = (props) => {
    const calculateMinDate = (): Date => {
        var result = business.addWeekDays(moment(), 5);
        return result;
    };

    const vegaApi = useContext(VegaApiContext);
    const [showSummary, setShowSummary] = useState(false);
    const [scheduleMode, setScheduleMode] = useState<ScheduleType | ''>(props.recommendation.status === RecommendationStatus.Scheduled ? props.recommendation.scheduledType : '');
    const [startDate, setStartDate] = useState<Date>(
        props.recommendation.status === RecommendationStatus.Scheduled ? new Date(props.recommendation.scheduledStartDateUtc) : calculateMinDate()
    );
    const [startDateMoment, setStartDateMoment] = useState<moment.Moment>();
    const [endDate, setEndDate] = useState<Date>(
        props.recommendation.status === RecommendationStatus.Scheduled ? new Date(props.recommendation.scheduledEndDateUtc) : calculateMinDate()
    );
    const [endDateMoment, setEndDateMoment] = useState<moment.Moment>();
    const [valid, setValid] = useState(false);
    const [saving, setSaving] = useState(false);

    const handleChange = (event) => setScheduleMode(event.target.value);

    const handleStartDateChange = (date: Date | null) => setStartDate(date);

    const handleEndDateChange = (date: Date | null) => setEndDate(date);

    const validate = () => {
        let temp = true;

        if (scheduleMode === ScheduleType.Window && (!startDateMoment || !endDateMoment)) temp = false;
        if (scheduleMode === ScheduleType.Schedule && !startDateMoment) temp = false;
        // check if it's at least 4 business days in the future

        if (!startDateMoment.isSameOrAfter(business.addWeekDays(moment(), 4))) temp = false;
        // check if window is big enough

        if (startDateMoment && endDateMoment && scheduleMode === ScheduleType.Window) {
            const isInWindow = startDateMoment.clone().add(moment.duration(props.recommendation.estimatedTimeRequired, 'hour')).isBefore(endDateMoment);
            if (!isInWindow) temp = false;
        }

        setValid(temp);
    };

    const submit = () => {
        setSaving(true);
        let TheInitVal = {
            id: props.recommendation.id,
            scheduledType: scheduleMode as ScheduleType,
            scheduledStartDateUtc: startDateMoment.utc().format(),
            scheduledEndDateUtc: undefined,
        };

        if (TheInitVal.scheduledType === ScheduleType.Window) TheInitVal.scheduledEndDateUtc = endDateMoment.utc().format();

        if (props.recommendation.status === RecommendationStatus.WaitingForApproval) {
            const request = new StartRecommendationAcceptWithSchedule(TheInitVal);
            vegaApi
                .getApiClient()
                .post(request)
                .then(() => props.onComplete());
        } else {
            const request = new UpdateRecommendationSchedule(TheInitVal);
            vegaApi
                .getApiClient()
                .put(request)
                .then(() => props.onComplete());
        }
    };

    const disableWeekends = (date) => {
        return date.getDay() === 0 || date.getDay() === 6;
    };

    useEffect(() => setStartDateMoment(moment(startDate)), [startDate]);

    useEffect(() => setEndDateMoment(moment(endDate)), [endDate]);

    useEffect(() => {
        if (startDateMoment) validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDateMoment, endDateMoment, scheduleMode]);

    const scheduleStep = (
        <Modal
            size='fullscreen'
            disableAnimation={true}
            onClose={props.cancelClick}
            header={<h6>{props.recommendation.title}</h6>}
            content={
                <>
                    <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                        <p className={`body1 c-slate-lightest ${scss.marginBottom}`}>
                            {props.recommendation.description}
                            <br />
                            <br />
                            This work is estimated to take {props.recommendation.estimatedTimeRequired} hours to complete.
                        </p>
                        <RadioGroup value={scheduleMode} onChange={handleChange}>
                            <div className={`paper dark padded ${scss.marginBottom}`}>
                                <Radio value={ScheduleType.Schedule} /> Schedule
                                {scheduleMode === ScheduleType.Schedule && (
                                    <div className='flexRow flexSeparate'>
                                        <DatePicker
                                            PopoverProps={{ className: 'dayPicker' }}
                                            inputVariant="outlined"
                                            format='MM/dd/yyyy'
                                            label='Date'
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            minDate={calculateMinDate()}
                                            shouldDisableDate={disableWeekends}
                                        />
                                        <TimePicker inputVariant="outlined" label='Time' value={startDate} onChange={handleStartDateChange} />
                                    </div>
                                )}
                            </div>
                            <div className='paper dark padded'>
                                <Radio value={ScheduleType.Window} /> Schedule Maintenance Window
                                {scheduleMode === ScheduleType.Window && (
                                    <>
                                        <h6>Start</h6>
                                        <div className='flexRow flexSeparate'>
                                            <DatePicker
                                                PopoverProps={{ className: 'dayPicker' }}
                                                inputVariant="outlined"
                                                format='MM/dd/yyyy'
                                                label='Date'
                                                value={startDate}
                                                onChange={handleStartDateChange}
                                                minDate={calculateMinDate()}
                                                shouldDisableDate={disableWeekends}
                                            />
                                            <TimePicker inputVariant="outlined" label='Time' value={startDate} onChange={handleStartDateChange} />
                                        </div>
                                        <h6>End</h6>
                                        <div className='flexRow flexSeparate'>
                                            <DatePicker
                                                PopoverProps={{ className: 'dayPicker' }}
                                                inputVariant="outlined"
                                                format='MM/dd/yyyy'
                                                label='Date'
                                                value={endDate}
                                                onChange={handleEndDateChange}
                                                minDate={calculateMinDate()}
                                                shouldDisableDate={disableWeekends}
                                            />
                                            <TimePicker inputVariant="outlined" label='Time' value={endDate} onChange={handleEndDateChange} />
                                        </div>
                                    </>
                                )}
                            </div>
                        </RadioGroup>
                    </MuiPickersUtilsProvider>
                </>
            }
            footer={
                <>
                    <button className='text danger' type='button' onClick={props.cancelClick}>
                        Cancel
                    </button>
                    <button className='flat medium primary' type='button' disabled={!valid} onClick={props.isEditSchedule ? submit : () => setShowSummary(true)}>
                        {props.isEditSchedule ? 'Update Schedule' : 'Review and Pay'}
                    </button>
                </>
            }
        />
    );

    const summaryStep = (
        <Modal
            size='fullscreen'
            disableAnimation={true}
            onClose={props.cancelClick}
            header={<h6>{props.recommendation.title}</h6>}
            content={
                <>
                    <div className={`body1 c-slate-lightest ${scss.marginBottom}`}>{props.recommendation.description}</div>
                    <h6>Details</h6>
                    <div className={`paper dark padded ${scss.marginBottom}`}>
                        <h6 className='c-slate-light'>Schedule Work:</h6>
                        {scheduleMode === ScheduleType.Schedule && <div className='c-secondary'>{formatMoment(startDateMoment)}</div>}
                        {scheduleMode === ScheduleType.Window && (
                            <div className={`c-secondary flexRow ${scss.summarySchedule}`}>
                                <div>
                                    <div className='c-slate-lightest'>Start</div>
                                    {formatMomentDate(startDateMoment)}
                                    <br />
                                    {formatMomentTime(startDateMoment)}
                                </div>
                                <div>
                                    <div className='c-slate-lightest'>End</div>
                                    {formatMomentDate(endDateMoment)}
                                    <br />
                                    {formatMomentTime(endDateMoment)}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='paper dark padded'>
                        <h6 className='c-slate-light'>
                            Work Cost: <span className='c-secondary'>{MakeMoney(props.recommendation.cost)}</span>
                        </h6>
                    </div>
                </>
            }
            footer={
                <>
                    <button className='accent small primary' onClick={() => setShowSummary(false)} type='button'>
                        Edit Schedule
                    </button>
                    {!saving && (
                        <button className='normal medium primary' type='button' onClick={submit}>
                            Complete
                        </button>
                    )}
                    {saving && <CircularProgress />}
                </>
            }
        />
    );

    return (
        <>
            {!showSummary && scheduleStep}
            {showSummary && summaryStep}
        </>
    );
};
