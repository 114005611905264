import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, IconButton, Tabs, Tab } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import * as Globals from '../../globals';

import './WorkloadCreateAwsConfigDetails.css';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
    },
    tabsIndicator: {
        backgroundColor: '#1890ff',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing(3),
    },
});

class WorkloadCreateAwsConfigDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetails: false,
            value: 0,
        };
    }
    // React Component Lifecycle Methods
    componentDidMount() {}

    componentDidUpdate() {}

    componentWillUnmount() {}

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleClose = () => {
        this.setState({ showDetails: false });
    };

    viewDetails = (event) => {
        this.setState({ showDetails: true });
    };

    render() {
        const { classes } = this.props;
        const { value } = this.state;
        return (
            <React.Fragment>
                <Button onClick={this.viewDetails}>{Globals.DETAILS}</Button>
                <Dialog fullScreen open={this.state.showDetails} onClose={this.handleClose}>
                    <div className='detailsTop'>
                        <div className='detailsHeader'>
                            <span className='detailsTitle'>
                                {Globals.WORKLOAD_CONFIG_TYPE}: {this.props.name}
                            </span>
                        </div>
                        <div className='detailsClose'>
                            <IconButton onClick={this.handleClose}>
                                <Clear />
                            </IconButton>
                        </div>
                    </div>
                    <div className='detailsHeader'>
                        <Tabs value={value} onChange={this.handleChange} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                            <Tab disableRipple classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label={Globals.DETAILS} />
                            <Tab disableRipple classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label={Globals.AVAILABILITY} />
                            <Tab disableRipple classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label={Globals.NETWORK} />
                            <Tab disableRipple classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label={Globals.INSTANCES} />
                        </Tabs>
                        {value === 0 && (
                            <div className='detailTab'>
                                {Globals.WORKLOAD_CONFIG_DETAILS}
                                <div className='detailImg' />
                            </div>
                        )}
                        {value === 1 && <div className='detailTab'>{Globals.AVAILABILITY}</div>}
                        {value === 2 && <div className='detailTab'>{Globals.NETWORK}</div>}
                        {value === 3 && <div className='detailTab'>{Globals.INSTANCES}</div>}
                    </div>
                    <div className='selectWorkload'>
                        <Button variant='contained' color='primary'>
                            {Globals.SELECT_WORKLOAD}
                        </Button>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(WorkloadCreateAwsConfigDetails);
