import React, { Component } from 'react';
import { ReactComponent as MySpaceIcon } from '../../images/icons/myspace-ico.svg';
import { ReactComponent as SpaceIcon } from '../../images/icons/space-ico.svg';
import * as Globals from '../../globals';
import BackButton from '../BackButton/BackButton';
import CombinedTopbar from '../CombinedTopbar/CombinedTopbar';

class SpaceAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    doCancel = () => {
        this.props.history.push('/spaces');
    };

    createSpace = (spaceType) => () => {
        switch (spaceType) {
            case Globals.SPACE_TYPES.Standard:
                this.props.history.push('/spaces/add/vega');
                break;
            case Globals.SPACE_TYPES.MySpace:
                this.props.history.push('/spaces/add/my');
                break;
            default:
                console.error('Do not support creating this type of space:' + spaceType);
        }
    };

    render() {
        return (
            <>
                <header className='pageHeaderContainer'>
                    <CombinedTopbar />
                    <div className='flexRow'>
                        <BackButton onClick={this.doCancel} />
                        <h4>Create Space</h4>
                    </div>
                </header>
                <div className='pageCenterContainer'>
                    <div className='centeredCardContainer'>
                        <div className='cardWrapper'>
                            <button className={`simpleCard selectable flexCol flexCenter stdSize`} onClick={this.createSpace(Globals.SPACE_TYPES.Standard)}>
                                <div className={'svgIconNormal'}>
                                    <SpaceIcon />
                                </div>
                                <div>Space</div>
                            </button>
                            <div className='belowCardText'>Spaces are used to create or import workloads.</div>
                        </div>
                        <div className='cardWrapper'>
                            <button className={`simpleCard selectable flexCol flexCenter stdSize`} onClick={this.createSpace(Globals.SPACE_TYPES.MySpace)} disabled={true}>
                                <div className={'svgIconNormal'}>
                                    <MySpaceIcon />
                                </div>
                                <div>My Space</div>
                            </button>
                            <div className='belowCardText'>Used to organize workloads for reporting.</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default SpaceAdd;
