import React, { useContext, useState } from 'react';
import scss from './Profile.module.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { EditProfile } from './EditProfile';
import { ChangePassword } from './ChangePassword';
import Icon from '../Icon/Icon';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { ClickAwayListener } from '@material-ui/core';
import { formatPhoneNumber } from '../../utils';

enum ViewMode {
    Default,
    EditProfile,
    ChangePassword,
    NotificationSettings,
    InviteToVega,
}

interface IMenuButtonProps {
    onClick: () => void;
    disabled?: boolean;
}

const MenuButton: React.FC<IMenuButtonProps> = (props) => (
    <button className={`blank ${scss.menuButton}`} type='button' onClick={props.onClick} disabled={props.disabled}>
        <div>{props.children}</div>
        <div>
            <Icon icon='chevron-right' />
        </div>
    </button>
);

export interface IProfileProps extends RouteComponentProps<any> {}

const Profile: React.FC<IProfileProps> = (props) => {
    const appState = useContext(AppStateContext);

    const [viewMode, setViewMode] = useState(ViewMode.Default);
    const [show, setShow] = useState(false);

    const logoutClicked = () => props.history.push('/logout');

    const cancelClick = () => setViewMode(ViewMode.Default);

    const defaultView = (
        <>
            <div className='c-slate-light'>
                <div className='center'>
                    <Icon icon='account' className={scss.avatarSize} />
                </div>
                <div className='center'>
                    {appState.currentUser.firstName} {appState.currentUser.lastName}
                </div>
                <div className='center body1'>
                    {appState.currentUser.isOnBoarded && <div>{appState.orgData.organizationName}</div>}
                    <div>{appState.currentUser.email}</div>
                    <div>{formatPhoneNumber(appState.currentUser.mobilePhone)}</div>
                </div>
            </div>
            <div className={`flexGrow ${scss.menuContainer}`}>
                <MenuButton onClick={() => setViewMode(ViewMode.EditProfile)}>Edit Profile</MenuButton>
                <MenuButton onClick={() => setViewMode(ViewMode.ChangePassword)}>Change Password</MenuButton>
                <MenuButton onClick={() => {}} disabled={true}>
                    Notification Settings
                </MenuButton>
                <button className={`blank ${scss.menuButton}`} type='button' onClick={logoutClicked} id='app_profile_logout'>
                    <div>Log Out</div>
                    <div>
                        <Icon icon='log-out-icon' />
                    </div>
                </button>
            </div>
            {/* <div className={scss.inviteContainer}>
            <button className='primary small flat center block' onClick={() => setViewMode(ViewMode.InviteToVega)}>Invite Colleagues</button>
        </div> */}
        </>
    );

    return (
        <>
            <div className='flexRow alignItemsCenter'>
                <button className={`iconButton c-slate-lightest iconButtonSize chipContainer ${show && 'active'}`} onClick={() => setShow(!show)} id='app_profile_showhide'>
                    <Icon icon='account' className={show && 'active'} />
                    <span>
                        {appState.currentUser.firstName}&nbsp;{appState.currentUser.lastName}
                    </span>
                    <Icon icon='menu-down' />
                </button>
                {show && (
                    <ClickAwayListener onClickAway={() => setShow(false)}>
                        <div className={`simpleCard padded flexCol ${scss.profilePanel}`}>
                            {viewMode === ViewMode.Default && defaultView}
                            {viewMode === ViewMode.EditProfile && <EditProfile cancelClick={cancelClick} />}
                            {/* {viewMode === ViewMode.InviteToVega && <InviteToVega cancelClick={cancelClick} />} */}
                            {viewMode === ViewMode.ChangePassword && <ChangePassword cancelClick={cancelClick} />}
                        </div>
                    </ClickAwayListener>
                )}
            </div>
        </>
    );
};

export default withRouter(Profile);
