import React, { useState, useEffect } from 'react';
import scss from './Dashboard.module.scss';
import { SpaceCostInfo } from '../SpaceDetail/SpaceCostInfo';
import { ReactComponent as AwsLogo } from '../../images/icons/logos/aws-logo.svg';
import { ReactComponent as AzureLogo } from '../../images/icons/logos/azure-logo.svg';
import { ReactComponent as GcpLogo } from '../../images/icons/logos/google-logo.svg';
import { Grid } from '@material-ui/core';
import StatusList from '../StatusList/StatusList';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { RouteComponentProps, Redirect } from 'react-router';
import { GetSpace, CloudProvider } from '../../ServiceStack/ServiceStack.dtos';
import { ListSpacesSummary, ListSpacesSummaryModel } from '../../ServiceStack/ServiceStack.dtos';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import CombinedTopbar from '../CombinedTopbar/CombinedTopbar';

export interface DashboardProps extends RouteComponentProps<any> {}

const Dashboard: React.FC<DashboardProps> = (props) => {
    const appState = React.useContext(AppStateContext);
    const vegaApi = React.useContext(VegaApiContext);

    const isQumuloSalesRep = appState.currentUser.email.endsWith('@qumulo.com') && appState.currentUser.email.startsWith('salesrep');
    const isTelusPOC = appState.currentUser.email.endsWith('@telus.com') && appState.currentUser.email.startsWith('teluspoc');

    const createWorkload = () => {
        vegaApi
            .getApiClient()
            .get(new GetSpace({ spaceId: appState.orgData.defaultSpaceId }))
            .then((response) => {
                appState.setCurrentSpace(response.result);
                props.history.push('/spaces/' + appState.orgData.defaultSpaceId + '/addpcp', 'FirstTime');
            });
    };

    useEffect(() => {
        // Get Spaces for Qumulo
        if (isQumuloSalesRep || isTelusPOC) {
            vegaApi
                .getApiClient()
                .get(new ListSpacesSummary())
                .then((response) => {
                    // setSpaceSummaries(response.result);
                    var salesRepSpace = response.result.filter((space) => space.name.includes(appState.currentUser.email.split('@')[0]));
                    if (salesRepSpace != null) {
                        props.history.push('/spaces/' + salesRepSpace[0].spaceId, 'FirstTime');
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`flexCol fullHeight noXOverflow`}>
                <div className='pageHeaderContainer light'>
                    <CombinedTopbar hideBreadcrumbs={true} />
                    <SpaceCostInfo entityId='' />
                </div>
                <div className='marginNormal'>
                    <div className={`paper flexRow posRelative ${scss.workloadStuff} ${scss.tallMargin}`}>
                        {appState.workloads.length > 0 && (
                            <>
                                <Grid container spacing={4}>
                                    <Grid item xs={4} className='flexCol'>
                                        <div className={`center flexRow alignItemsCenter ${scss.cloudCard}`}>
                                            <div className='svgIconSmall marginNormal'>
                                                <AwsLogo />
                                            </div>
                                            <div className='flexCol ta-left'>
                                                <h6>{appState.workloads.filter((w) => w.cloudProvider === CloudProvider.Aws).length}</h6>
                                                <div className='c-slate-lightest'>AWS Workloads</div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} className='flexCol'>
                                        <div className={`center flexRow alignItemsCenter ${scss.cloudCard}`}>
                                            <div className='svgIconSmall marginNormal'>
                                                <AzureLogo />
                                            </div>
                                            <div className='flexCol ta-left'>
                                                <h6>{appState.workloads.filter((w) => w.cloudProvider === CloudProvider.Azure).length}</h6>
                                                <div className='c-slate-lightest'>Azure Workloads</div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} className='flexCol'>
                                        <div className={`center flexRow alignItemsCenter ${scss.cloudCard} ${scss.noBorder}`}>
                                            <div className='svgIconSmall marginNormal'>
                                                <GcpLogo />
                                            </div>
                                            <div className='flexCol ta-left'>
                                                <h6>{appState.workloads.filter((w) => w.cloudProvider === CloudProvider.Gcp).length}</h6>
                                                <div className='c-slate-lightest'>GCP Workloads</div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {appState.workloads.length === 0 && (
                            <div className='flexCol center'>
                                <div className='padded'>Import an existing workload, build your own, or let Vega create one for you.</div>
                                <div className='padded'>
                                    <button onClick={createWorkload} className='primary normal medium'>
                                        Create Workload
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={`flexCol flexGrow`}>
                        <h5>Status</h5>
                        <StatusList />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
