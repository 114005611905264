import React from 'react';
import scss from './RoundCloseButton.module.scss';

export interface RoundCloseButtonProps {
    onClick: () => void;
}

const RoundCloseButton: React.FC<RoundCloseButtonProps> = (props) => (
    <button className={scss.roundCloseButton} onClick={props.onClick}>
        <span className={`icon icon-close ${scss.icon}`}></span>
    </button>
);

export default RoundCloseButton;
