import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import * as Globals from '../../globals';

export interface AccessCodeProps {
    setAccessCode: (accessCode: string) => void;
}

export interface AccessCodeState {
    accessCode: string;
    errorMessage: string;
}

class AccessCodeInput extends React.Component<AccessCodeProps, AccessCodeState> {
    constructor(props: AccessCodeProps) {
        super(props);
        this.state = {
            accessCode: Globals.EMPTY_STR,
            errorMessage: Globals.EMPTY_STR,
        };
    }

    helperTextProps = {
        error: true,
    };

    updateAccessCode = () => {
        if (this.props.setAccessCode instanceof Function) {
            this.props.setAccessCode(this.state.accessCode);
        }
    };

    onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const errorMessage = this.validateAccessCode(event.target.value);

        this.setState(
            {
                errorMessage: errorMessage,
                accessCode: event.target.value,
            },
            () => {
                this.updateAccessCode();
            }
        );
    };

    validateAccessCode = (accessCode: string): string => {
        //toDo what should the actual validation look like for this?
        let errorMessage = Globals.EMPTY_STR;

        if (accessCode.length < 1) {
            errorMessage = 'Access Code is Required';
        }

        return errorMessage;
    };

    render() {
        return (
            <React.Fragment>
                <FormControl>
                    <TextField
                        value={this.state.accessCode}
                        error={this.state.errorMessage.length > 1}
                        label='Early Access Code'
                        helperText={this.state.errorMessage}
                        FormHelperTextProps={this.helperTextProps}
                        type='text'
                        name='accessCode'
                        variant='outlined'
                        onChange={this.onValueChange}
                    />
                </FormControl>
            </React.Fragment>
        );
    }
}

export { AccessCodeInput };
