import React, { useEffect, useContext, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, LinearProgress, CircularProgress } from '@material-ui/core';
import scss from './StatusList.module.scss';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { ListExecutionMessages, ListExecutionMessagesModel, CloudProvider, EntityFilterType } from '../../ServiceStack/ServiceStack.dtos';
import { parseFormatMomentDate } from '../../utils';
import { AppStateContext } from '../../contexts/AppState/AppState';

export interface StatusListProps extends RouteComponentProps {}

const StatusList: React.FC<StatusListProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);
    const [loading, setLoading] = useState(false);
    const [actions, setActions] = useState<ListExecutionMessagesModel[]>([]);

    useEffect(() => {
        setLoading(true);
        vegaApi
            .getApiClient()
            .get(new ListExecutionMessages({ cloudProvider: CloudProvider.None, entityFilterType: EntityFilterType.None, entityId: undefined }))
            .then((response) => {
                let data = response.result.filter((executionMessage) => executionMessage.action === 'Recommendation');
                // if recommendation, replace GUID in "title" with workload name
                data.forEach((x) => {
                    if (x.action === 'Recommendation') {
                        const TheName = appState.workloads.find((wl) => wl.workloadId === x.name);
                        if (TheName) x.name = TheName.name;
                    }
                });
                setActions(data);
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {actions.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Action</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Complete</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {actions.map((action) => (
                            <TableRow key={action.id}>
                                <TableCell>
                                    <Typography variant='body2'>{action.action}</Typography>
                                    <Typography variant='caption' color='textSecondary'>
                                        {parseFormatMomentDate(action.dateStartUtc)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='body2'>{action.actionType}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='body2'>{action.name}</Typography>
                                </TableCell>
                                <TableCell>
                                    <LinearProgress variant='determinate' value={action.percentageComplete} />
                                    <Typography variant='body2' display='inline'>
                                        {action.percentageComplete}%
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    {action.actionType === 'Recommendation' && (
                                        <button className='text primary' type='button'>
                                            Details
                                        </button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <div className={scss.noStatusPaper}>
                    {!loading && (
                        <div className={scss.noStatusMiddle}>
                            <div className={scss.noStatusTitle}>No status to Show</div>
                            <div className={scss.noStatusBody}>Status allows you to see the progress of builds, maintenance, or scheduled workloads.</div>
                        </div>
                    )}
                    {loading && (
                        <div className='center'>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default withRouter(StatusList);
