import React, { useContext, useState, useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';
import VarianceText from '../VarianceText/VarianceText';
import { MakeMoney } from '../../utils';
import Icon from '../Icon/Icon';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { GetFinancialTotalsModel, FinancialPeriodType, GetFinancialTotals, GetWorkloadModel } from '../../ServiceStack/ServiceStack.dtos';
import CostInfoTile from './CostInfoTile';

export interface IWorkloadCostInfoProps {
    workload: GetWorkloadModel;
}

const WorkloadCostInfo: React.FC<IWorkloadCostInfoProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);

    const [periodName, setPeriodName] = useState('');
    const [costData, setCostData] = useState<GetFinancialTotalsModel>({
        entityId: props.workload.workloadId,
        financialPeriodType: FinancialPeriodType.MTD,
        totalCost: 0,
        budgetAmount: 0,
        savingsAmount: 0,
        productCategoryCosts: {
            Compute: 0,
            Database: 0,
            Storage: 0,
            Network: 0,
            Other: 0,
        },
    });

    useEffect(() => {
        const request = new GetFinancialTotals({ financialPeriodType: FinancialPeriodType.MTD, entityId: props.workload.workloadId });
        vegaApi
            .getApiClient()
            .get(request)
            .then((response) => {
                setCostData(response.result);
                setPeriodName(response.result.financialPeriodType === 'MTD' ? 'Monthly' : 'Yearly');
            })
            .catch((error) => {
                console.error('[WorkloadCostInfo] Error loading workload cost info!');
                console.error(error);
            });
        // eslint-disable-next-line
    }, []);

    const computePercent = Math.floor((costData.productCategoryCosts.Compute / costData.totalCost) * 100) || 0;
    const databasePercent = Math.floor((costData.productCategoryCosts.Database / costData.totalCost) * 100) || 0;
    const storagePercent = Math.floor((costData.productCategoryCosts.Storage / costData.totalCost) * 100) || 0;
    const networkPercent = Math.floor((costData.productCategoryCosts.Network / costData.totalCost) * 100) || 0;
    const otherPercent = Math.floor((costData.productCategoryCosts['Other Services'] / costData.totalCost) * 100) || 0;

    return (
        <>
            <CostInfoTile title={MakeMoney(costData.totalCost)} subtitle={`${periodName} Total Cost`} />
            <CostInfoTile
                title={MakeMoney(costData.budgetAmount)}
                subtitle={
                    <>
                        {periodName} Budget &nbsp;&nbsp;&nbsp;&nbsp;
                        <VarianceText variance={costData.budgetAmount - costData.totalCost} />
                    </>
                }
            />
            <CostInfoTile
                icon={<Icon icon='compute' color='c-slate-lightest' />}
                title={`Compute: ${MakeMoney(costData.productCategoryCosts.Compute)}`}
                graph={<LinearProgress variant='determinate' value={computePercent} />}
                subtitle={`${computePercent}% of total cost`}
            />
            <CostInfoTile
                icon={<Icon icon='database' color='c-slate-lightest' />}
                title={`Database: ${MakeMoney(costData.productCategoryCosts.Database)}`}
                graph={<LinearProgress variant='determinate' value={databasePercent} />}
                subtitle={`${databasePercent}% of total cost`}
            />
            <CostInfoTile
                icon={<Icon icon='box' color='c-slate-lightest' />}
                title={`Storage: ${MakeMoney(costData.productCategoryCosts.Storage)}`}
                graph={<LinearProgress variant='determinate' value={storagePercent} />}
                subtitle={`${storagePercent}% of total cost`}
            />
            <CostInfoTile
                icon={<Icon icon='network' color='c-slate-lightest' />}
                title={`Network: ${MakeMoney(costData.productCategoryCosts.Network)}`}
                graph={<LinearProgress variant='determinate' value={networkPercent} />}
                subtitle={`${networkPercent}% of total cost`}
            />
            <CostInfoTile
                icon={<Icon icon='cloud' color='c-slate-lightest' />}
                title={`Other Services: ${MakeMoney(costData.productCategoryCosts['Other Services'])}`}
                graph={<LinearProgress variant='determinate' value={otherPercent} />}
                subtitle={`${otherPercent}% of total cost`}
            />
        </>
    );
};

export default WorkloadCostInfo;
