import React from 'react';
import { ReactComponent as SpaceIcon } from '../../images/icons/space-ico.svg';
import SpaceEditModel from '../SpaceEditModel/SpaceEditModel';
import AdmiralSnackbar from '../AdmiralSnackbar/AdmiralSnackbar';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { RouteComponentProps } from 'react-router-dom';
import { CreateSpace, SpaceContainerType, BudgetPeriodType } from '../../ServiceStack/ServiceStack.dtos';
import { CircularProgress } from '@material-ui/core';
import CombinedTopbar from '../CombinedTopbar/CombinedTopbar';

export interface SpaceCreateVegaProps extends RouteComponentProps<any> {}

type CreateSpaceForm = Omit<CreateSpace, 'createResponse' | 'getTypeName' | 'organizationId'>;

const SpaceCreateVega: React.FC<SpaceCreateVegaProps> = (props) => {
    const vegaApi = React.useContext(VegaApiContext);

    const [model, setModel] = React.useState<CreateSpaceForm>({
        name: '',
        description: '',
        spaceTypeId: 0,
        spaceContainerType: SpaceContainerType.Standard,
        budgetAmount: 0,
        budgetPeriodType: BudgetPeriodType.Monthly,
        isDefaultSpace: false,
    });
    const [errors, setErrors] = React.useState({});
    const [allowNext, setAllowNext] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);

    const validate = (event) => {
        setErrors({
            ...errors,
            [event.target.name]: event.target.value ? '' : 'This field is required',
        });
    };

    const handleChange = (event) => {
        setModel({ ...model, [event.target.name]: event.target.value });
        validate(event);
    };

    const cancelClick = () => {
        props.history.push('/spaces');
    };

    const createSpace = () => {
        setProcessing(true);
        vegaApi
            .getApiClient()
            .post(new CreateSpace(model))
            .then((response) => {
                props.history.push(`/spaces/${response.result.spaceId}`);
            })
            .finally(() => setProcessing(false));
    };

    React.useEffect(() => {
        setAllowNext(!!model.name && !!model.description && !!model.spaceTypeId);
    }, [model]);

    return (
        <div className='flexCol flexGrow snackbarMargin'>
            <header className='pageHeaderContainer'>
                <CombinedTopbar />
                <div className='flexRow alignItemsCenter'>
                    <div className='cardIcon'>
                        <SpaceIcon />
                    </div>
                    <h4>Create Space</h4>
                </div>
            </header>
            <div className='marginNormal paper padded flexGrow'>
                <h5>Space Information</h5>
                <SpaceEditModel errors={errors} model={model} handleChange={handleChange} />
            </div>
            <AdmiralSnackbar>
                <button className='accent medium primary' onClick={cancelClick}>
                    Cancel
                </button>
                {!processing && (
                    <button className='normal medium primary' disabled={!allowNext} onClick={createSpace}>
                        Create Space
                    </button>
                )}
                {processing && <CircularProgress />}
            </AdmiralSnackbar>
        </div>
    );
};

export default SpaceCreateVega;
