import React from 'react';
import scss from './DotIndicator.module.scss';

export interface DotIndicatorProps {
    color: string;
}

const DotIndicator = (props: DotIndicatorProps) => {
    const success = ['success', 'running', 'available'];
    const warning = ['warning', 'stopping', 'shutting-down', 'pending', 'starting', 'rebooting', 'modifying'];
    const danger = ['danger', 'stopped', 'terminated'];

    let color = '';

    if (success.includes(props.color)) color = 'bg-success';
    if (warning.includes(props.color)) color = 'bg-warning';
    if (danger.includes(props.color)) color = 'bg-danger';

    return <div className={`${scss.dotIndicator} ${color}`} />;
};

export default DotIndicator;
