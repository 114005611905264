import React from 'react';
import scss from './OverviewTab.module.scss';
import { GetWorkloadModel } from '../../../ServiceStack/ServiceStack.dtos';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import NotificationList from '../../NotificationList/NotificationList';
import RuntimeGraph from '../RuntimeGraph';
import WorkloadRecommendations from '../WorkloadRecommendations';

export interface OverviewTabProps extends RouteComponentProps<any> {
    workload: GetWorkloadModel;
}

const OverviewTab: React.FC<OverviewTabProps> = (props) => {
    const createSatellite = () => props.history.push(props.location.pathname.substring(0, props.location.pathname.lastIndexOf('/')) + '/satelliteaws');

    return (
        <>
            <div>
                <RuntimeGraph workloadId={props.workload.workloadId} />
            </div>
            <div className='flexRow'>
                <div className={`marginNormal ${scss.kpi}`}>
                    <h5 className='marginNormal-bottom'>Information</h5>
                    <p className='c-slate-light'>
                        <span className='c-slate-lightest'>Type:</span> {props.workload.workloadType.name}
                    </p>
                    <p className='c-slate-light'>
                        <span className='c-slate-lightest'>Compliance:</span>&nbsp;
                        {props.workload.complianceTypes.map((c) => (
                            <React.Fragment key={c}>{c} </React.Fragment>
                        ))}
                    </p>
                    <p className='c-slate-light'>
                        <span className='c-slate-lightest'>Description:</span> {props.workload.description}
                    </p>
                </div>
                <div className={`marginNormal flexCol ${scss.kpi}`}>
                    <WorkloadRecommendations workloadId={props.workload.workloadId} />
                </div>
                <div className={`marginNormal flexCol ${scss.kpi}`}>
                    <NotificationList workloadIdFilter={props.workload.workloadId} />
                </div>
                <div className={`marginNormal ${scss.kpi} flexCol`}>
                    <div className='flexGrow'>
                        <h5 className='marginNormal-bottom'>Satellite Workload</h5>
                        <p className='body1 c-slate-light'>Satellites allow you to isolate resources and help keep accounts well organized</p>
                    </div>
                    <button className='accent primary medium block center' type='button' onClick={createSatellite}>
                        Create Satellite
                    </button>
                </div>
            </div>
        </>
    );
};

export default withRouter(OverviewTab);
