import React, { useState, useContext } from 'react';
import SpaceEditModel from '../SpaceEditModel/SpaceEditModel';
import BackButton from '../BackButton/BackButton';
import AdmiralSnackbar from '../AdmiralSnackbar/AdmiralSnackbar';
import { CircularProgress } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { UpdateSpace } from '../../ServiceStack/ServiceStack.dtos';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { AppStateContext } from '../../contexts/AppState/AppState';
import CombinedTopbar from '../CombinedTopbar/CombinedTopbar';

export interface SpaceEditProps extends RouteComponentProps<any> {}

type UpdateSpaceForm = Omit<UpdateSpace, 'createResponse' | 'getTypeName'>;

const SpaceEdit: React.FC<SpaceEditProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);

    const [model, setModel] = useState<UpdateSpaceForm>({
        spaceId: appState.currentSpace.spaceId,
        name: appState.currentSpace.name,
        description: appState.currentSpace.description,
        spaceTypeId: appState.currentSpace.spaceType.value,
        budgetAmount: appState.currentSpace.budget.amount,
        budgetPeriodType: appState.currentSpace.budget.budgetPeriodType,
    });
    const [errors, setErrors] = useState({});
    const [processing, setProcessing] = useState(false);
    const [allowNext, setAllowNext] = useState(false);

    const validate = (event) => {
        let tempErrors = { ...errors };
        if (event.target.required) {
            tempErrors[event.target.name] = event.target.value ? '' : 'This field is required';
        }
        setErrors(tempErrors);
    };

    const updateAllowNext = () => {
        let allowNextTemp = true;
        Object.keys(errors).forEach((key) => {
            if (errors[key]) allowNextTemp = false;
        });

        if (!model.name || !model.description || !model.spaceTypeId) allowNextTemp = false;
        setAllowNext(allowNextTemp);
    };

    const handleChange = (event) => {
        setModel({ ...model, [event.target.name]: event.target.value });
        validate(event);
    };

    const cancelClick = () => {
        const val = props.location.pathname.substring(0, props.location.pathname.lastIndexOf('/'));
        props.history.push(val);
    };

    const saveSpace = () => {
        setProcessing(true);
        const request = new UpdateSpace(model);
        vegaApi
            .getApiClient()
            .put(request)
            .then((response) => {
                appState.setCurrentSpace(response.result);
                props.history.push(`/spaces/${appState.currentSpace.spaceId}`);
            });
    };

    React.useEffect(() => {
        updateAllowNext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    return (
        <>
            <header className='pageHeaderContainer'>
                <CombinedTopbar />
                <div className='flexRow alignItemsCenter'>
                    <BackButton onClick={cancelClick} />
                    <h4>Edit {appState.currentSpace.name}</h4>
                </div>
            </header>
            <div className='marginNormal paper padded flexGrow snackbarMargin'>
                <h5>Space Information</h5>
                <br />
                <SpaceEditModel errors={errors} model={model} handleChange={handleChange} />
                <AdmiralSnackbar>
                    <button className='accent medium primary' onClick={cancelClick}>
                        Cancel
                    </button>
                    {!processing && (
                        <button className='normal medium primary' disabled={!allowNext} onClick={saveSpace}>
                            Save Space
                        </button>
                    )}
                    {processing && <CircularProgress />}
                </AdmiralSnackbar>
            </div>
        </>
    );
};

export default SpaceEdit;
