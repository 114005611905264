import React, { useContext, useState } from 'react';
import { TextField, Table, TableHead, TableRow, TableCell, TableBody, Radio, InputAdornment, TableSortLabel } from '@material-ui/core';
import Modal from '../Modal/Modal';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { GetWorkload, ListWorkloadSummariesModel } from '../../ServiceStack/ServiceStack.dtos';

export interface WorkloadSatelliteAwsSelectModalProps extends RouteComponentProps<any> {
    onClose: () => void;
}

const WorkloadSatelliteAwsSelectModal: React.FC<WorkloadSatelliteAwsSelectModalProps> = (props) => {
    const appState = useContext(AppStateContext);
    const vegaApi = useContext(VegaApiContext);

    const [selectedWorkloadId, setSelectedWorkloadId] = useState();
    const [orderBy, setOrderBy] = useState('');
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState(true); // false = asc, true = desc
    const [displayData, setDisplayData] = useState<ListWorkloadSummariesModel[]>([]);
    const [loaded, setLoaded] = useState(false);

    const handleSelectWL = (event) => setSelectedWorkloadId(event.target.value);

    const handleSearch = (event) => setSearch(event.target.value.toLowerCase());

    const clickedHeader = (colName) => {
        setOrder(!order);
        setOrderBy(colName);
    };

    const searchNsort = (data: ListWorkloadSummariesModel[]) => {
        if (data !== undefined) {
            let temp = data.filter((x) => x.workloadContentType !== 'Satellite');

            if (search) {
                temp = temp.filter((resource) => {
                    let retval = false;
                    Object.keys(resource).forEach((x) => {
                        if (resource[x].toString().toLowerCase().indexOf(search) !== -1) retval = true;
                    });
                    return retval;
                });
            }
            if (orderBy) {
                temp.sort((a, b) => {
                    return order ? (a[orderBy] > b[orderBy] ? 1 : -1) : a[orderBy] < b[orderBy] ? -1 : 1;
                });
            }
            setDisplayData(temp);
        }
    };

    const selectWorkloadClicked = () => {
        vegaApi
            .getApiClient()
            .get(new GetWorkload({ workloadId: selectedWorkloadId }))
            .then((response) => {
                appState.setCurrentWorkload(response.result);
                props.history.push('satelliteaws');
            });
    };

    React.useEffect(() => {
        searchNsort(appState.workloads);
        // eslint-disable-next-line
    }, [orderBy, search, order]);

    React.useEffect(() => {
        searchNsort(appState.workloads);
        setLoaded(true);
        // eslint-disable-next-line
    }, []);

    const workloadSelectionContent = !loaded ? (
        <React.Fragment />
    ) : (
        <div>
            <div className='flexRow flexSeparate'>
                <div>
                    <h6>Available AWS Imported Workloads</h6>
                    <p>Please select the workload you want to move resources from.</p>
                </div>
                <div>
                    <TextField
                        id='search'
                        name='search'
                        label='Search'
                        variant='outlined'
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <span className='icon icon-search' />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === 'name'} direction={order ? 'asc' : 'desc'} onClick={() => clickedHeader('name')}>
                                Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === 'accountNumber'} direction={order ? 'asc' : 'desc'} onClick={() => clickedHeader('accountNumber')}>
                                Account
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel active={orderBy === 'workloadCreationType'} direction={order ? 'asc' : 'desc'} onClick={() => clickedHeader('workloadCreationType')}>
                                Type
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayData.map((row) => (
                        <TableRow key={row.workloadId}>
                            <TableCell>
                                <Radio checked={selectedWorkloadId === row.workloadId} onChange={handleSelectWL} value={row.workloadId} name='workloadSelection' />
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.accountId}</TableCell>
                            <TableCell>{row.workloadCreationType}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );

    const workloadSelectionFooter = (
        <>
            <div>
                <button className='accent primary medium' onClick={props.onClose}>
                    Cancel
                </button>
            </div>
            <div>
                <button className='medium normal primary' disabled={!selectedWorkloadId} onClick={selectWorkloadClicked}>
                    Select Workload
                </button>
            </div>
        </>
    );

    return <Modal size='large' content={workloadSelectionContent} footer={workloadSelectionFooter} onClose={props.onClose} header={<h5>Create Workload Type</h5>} />;
};

export default withRouter(WorkloadSatelliteAwsSelectModal);
