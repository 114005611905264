import React from 'react';

export interface IconProps {
    icon: string;
    className?: string;
    color?: string;
}

const Icon = (props: IconProps) => {
    return (
        <span className={props.color ? props.color : ''}>
            <span className={`icon icon-${props.icon} ${props.className}`} />
        </span>
    );
};

export default Icon;
