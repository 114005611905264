import React, { ChangeEvent } from 'react';
import Modal from '../Modal/Modal';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { DescribeAmi, DescribeInstanceType, DescribeVpc, DescribeSecurityGroup, DescribeRegion } from '../../ServiceStack/ServiceStack.dtos';
import PasswordInput from '../PasswordInput/PasswordInput';

export interface ITimedInstanceProps {
    cancelClick: () => void;
    onChangeRegion: (event) => void;
    onChangeOperatingSystem: (event) => void;
    onChangeVpc: (event) => void;
    onChangeAmi: (event) => void;
    onChangeSecurityGroup: (event) => void;
    onChangeInstanceType: (event) => void;
    onChangeInstanceName: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeSnapshotDescription: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangePublicKey: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeTimeInstanceRuns: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeUserName: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangePassword: (password: string) => void;
    onChangeConfirmPassword: (confirmPassword: string) => void;
    isValidTimedInstanceInput: () => boolean;
    onClickCreateInstance: () => void;
    regions: DescribeRegion[];
    operatingSystems: string[];
    amiIds: DescribeAmi[];
    instanceTypes: DescribeInstanceType[];
    vpcs: DescribeVpc[];
    securityGroups: DescribeSecurityGroup[];
    region: string;
    operatingSystem: string;
    vpc: string;
    amiId: string;
    securityGroup: string;
    instanceType: string;
    instanceName: string;
    publicKey: string;
    snapshotDescription: string;
    timeInstanceRuns: string;
    userName: string;
    password: string;
    confirmPassword: string;
    isInvalidConfirmPassword: boolean;
    isInvalidTimeInstanceRuns: boolean;
}

const TimedInstance: React.FC<ITimedInstanceProps> = (props) => {
    return (
        <>
            <Modal
                size='fullscreen'
                header={<h6>Create Timed Instance</h6>}
                content={
                    <>
                        <Grid container spacing={4}>
                            <Grid item xs={12} className='c-slate-light'>
                                Create a new instance in your satellite which will run for a specified duration. At the time of termination, Vega will take a snapshot of your
                                instance.
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name='' label='Instance Name' variant='outlined' value={props.instanceName} onChange={props.onChangeInstanceName} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name='' label='Snapshot Description' variant='outlined' value={props.snapshotDescription} onChange={props.onChangeSnapshotDescription} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant='outlined'>
                                    <InputLabel>Select Region</InputLabel>
                                    <Select value={props.region} onChange={props.onChangeRegion}>
                                        {props.regions.map((region) => (
                                            <MenuItem key={region.regionName} value={region.regionName}>
                                                {region.regionName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant='outlined'>
                                    <InputLabel>Select OS</InputLabel>
                                    <Select value={props.operatingSystem} onChange={props.onChangeOperatingSystem}>
                                        {props.operatingSystems.map((os) => (
                                            <MenuItem key={os} value={os.toLowerCase()}>
                                                {os}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {props.operatingSystem === 'linux/unix' && (
                                <Grid item xs={12}>
                                    <TextField name='' label='Public Key (Base64)' variant='outlined' value={props.publicKey} onChange={props.onChangePublicKey} />
                                </Grid>
                            )}
                            {props.operatingSystem === 'windows' && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField name='' label='Username' variant='outlined' value={props.userName} onChange={props.onChangeUserName} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PasswordInput value={props.password} setPwd={props.onChangePassword} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PasswordInput
                                            error={props.isInvalidConfirmPassword}
                                            errorMessage={'Must match Password.'}
                                            placeholder='Confirm Password'
                                            value={props.confirmPassword}
                                            setPwd={props.onChangeConfirmPassword}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <FormControl variant='outlined'>
                                    <InputLabel>Select AMI ID</InputLabel>
                                    <Select value={props.amiId} onChange={props.onChangeAmi}>
                                        {props.amiIds.map((ami, index) => (
                                            <MenuItem key={index} value={ami.imageId}>
                                                {ami.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant='outlined'>
                                    <InputLabel>Select Instance Type</InputLabel>
                                    <Select value={props.instanceType} onChange={props.onChangeInstanceType}>
                                        {props.instanceTypes.map((instanceType) => (
                                            <MenuItem key={instanceType.instanceType} value={instanceType.instanceType}>
                                                {instanceType.instanceType}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant='outlined'>
                                    <InputLabel>Select VPC</InputLabel>
                                    <Select value={props.vpc} onChange={props.onChangeVpc}>
                                        {props.vpcs.map((vpc) => (
                                            <MenuItem key={vpc.vpcId} value={vpc.vpcId}>
                                                {vpc.vpcId}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant='outlined'>
                                    <InputLabel>Select Security Group</InputLabel>
                                    <Select value={props.securityGroup} onChange={props.onChangeSecurityGroup}>
                                        {props.securityGroups.map((securityGroup) => (
                                            <MenuItem key={securityGroup.groupName} value={securityGroup.groupName}>
                                                {securityGroup.groupName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    type='Number'
                                    name=''
                                    label='Time Instance Runs'
                                    variant='outlined'
                                    value={props.timeInstanceRuns}
                                    onChange={props.onChangeTimeInstanceRuns}
                                    error={props.isInvalidTimeInstanceRuns}
                                    helperText={props.isInvalidTimeInstanceRuns && 'Must be whole number between 1 and 8760.'}
                                />
                            </Grid>
                            <Grid item xs={5} className='c-slate-light flexCol justifyCenter'>
                                Hours
                            </Grid>
                        </Grid>
                    </>
                }
                footer={
                    <>
                        <button className='text c-slate-light medium' onClick={props.cancelClick} type='button'>
                            Cancel
                        </button>
                        <button className='primary normal medium' onClick={props.onClickCreateInstance} disabled={!props.isValidTimedInstanceInput()} type='button'>
                            Create Instance
                        </button>
                    </>
                }
                onClose={props.cancelClick}
            />
        </>
    );
};

export default TimedInstance;
