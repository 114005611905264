import React, { useContext, useState, useEffect } from 'react';
import scss from './WorkloadImportAzure.module.scss';
import { ReactComponent as AzureLogo } from '../../images/icons/logos/azure-logo.svg';
import { Grid, TextField, CircularProgress } from '@material-ui/core';
import { AppStateContext } from '../../contexts/AppState/AppState';
import PageFooter from '../PageFooter/PageFooter';
import { urlUpOneLevel } from '../../utils';
import { RouteComponentProps } from 'react-router-dom';
import WorkloadInfoForm from '../WorkloadInfoForm/WorkloadInfoForm';
import { CreateWorkload, GetWorkload, CloudProvider, WorkloadCreationType } from '../../ServiceStack/ServiceStack.dtos';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import CombinedTopbar from '../CombinedTopbar/CombinedTopbar';

export type CreateWorkloadForm = Omit<CreateWorkload, 'createResponse' | 'getTypeName'>;

export interface WorkloadImportAzureProps extends RouteComponentProps<any> {}

const WorkloadImportAzure: React.FC<WorkloadImportAzureProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const appState = useContext(AppStateContext);

    const [saving, setSaving] = useState(false);
    const [allowAddWorkload, setAllowAddWorkload] = useState(false);
    const [formState, setFormState] = useState<CreateWorkloadForm>(
        new CreateWorkload({
            spaceId: appState.currentSpace.spaceId,
            masterWorkloadId: '',
            isMasterWorkload: false,
            name: '',
            description: '',
            workloadTypeId: 0,
            cloudProvider: CloudProvider.Azure,
            workloadCreationType: WorkloadCreationType.Import,
            budgetAmount: 0,
            budgetPeriodType: '',
            isLinkedWorkload: false,
        })
    );

    const addWorkload = async () => {
        try {
            setSaving(true);
            const responseNewWl = await vegaApi.getApiClient().post(new CreateWorkload(formState));
            appState.setNewLoadId(responseNewWl.result.workloadId);
            appState.setCurrentWorkload((await vegaApi.getApiClient().get(new GetWorkload({ workloadId: responseNewWl.result.workloadId }))).result);
            await appState.loadWorkloads();
            props.history.push(`/spaces/${responseNewWl.result.spaceId}/${responseNewWl.result.workloadId}/configure`);
        } catch (error) {
            console.error(error);
            setSaving(false);
        }
    };

    const cancelClick = () => urlUpOneLevel(props);

    const handleChange = (event) => {
        setFormState({ ...formState, [event.target.name]: event.target.value });
    };

    useEffect(() => {
        // Check required fields are entered
        let allowNext = !!(formState.name && formState.workloadTypeId && formState.description);
        setAllowAddWorkload(allowNext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    return (
        <>
            <div className='scrollContainer flexGrow flexCol'>
                <header className='pageHeaderContainer'>
                    <CombinedTopbar />
                    <div className='flexRow flexSeparate alignItemsCenter'>
                        <h4>Create Vega Cloud Provider Workload</h4>
                        <div className='flexRow'>
                            <div className={scss.addSpaceType}></div>
                            <div className='svgIconNormal'>
                                <AzureLogo />
                            </div>
                        </div>
                    </div>
                </header>
                <div className='viewContainer flexRow flexGrow'>
                    <div className='marginNormal flex1 paper padded'>
                        <WorkloadInfoForm model={formState} handleChange={handleChange} />
                    </div>
                    <div className='marginNormal flex1 paper padded'>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <h5>Workload Azure Details</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name='subscriptionId' label='Subscription ID' variant='outlined' />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name='tenantId' label='Tenant ID' variant='outlined' />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name='appId' label='App ID / Client ID' variant='outlined' />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name='appAccessKey' label='App Access Key / Client Secret Key' variant='outlined' />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <PageFooter
                buttons={
                    <>
                        <button className='accent medium primary' disabled={saving} onClick={cancelClick}>
                            Cancel
                        </button>
                        {!saving && (
                            <button className='normal medium primary' disabled={!allowAddWorkload} onClick={addWorkload}>
                                Add Workload
                            </button>
                        )}
                        {saving && <CircularProgress />}
                    </>
                }
            />
        </>
    );
};

export default WorkloadImportAzure;
