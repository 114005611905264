import React, { useContext } from 'react';
import { Grid, TextField, Select, MenuItem, OutlinedInput, InputLabel, FormControl, InputAdornment } from '@material-ui/core';
import { AppStateContext } from '../../contexts/AppState/AppState';

export interface WorkloadInfoFormProps {
    model: { name: string; description: string; workloadTypeId: number; budgetAmount: number; budgetPeriodType: string };
    handleChange: (event: any) => void;
}

const WorkloadInfoForm: React.FC<WorkloadInfoFormProps> = (props) => {
    const appState = useContext(AppStateContext);

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <h5>Workload Information</h5>
                </Grid>
                <Grid item xs={12}>
                    <TextField id='name' name='name' label='Workload name' variant='outlined' value={props.model.name} onChange={props.handleChange} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='description'
                        name='description'
                        label='Workload Description'
                        variant='outlined'
                        multiline
                        rows='4'
                        value={props.model.description}
                        onChange={props.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined'>
                        <InputLabel htmlFor='workloadTypeId'>Workload Type</InputLabel>
                        <Select
                            value={props.model.workloadTypeId}
                            onChange={props.handleChange}
                            input={<OutlinedInput labelWidth={110} name='workloadTypeId' id='workloadTypeId' />}
                        >
                            {appState.workloadTypes.map((workloadType) => (
                                <MenuItem key={workloadType.id} value={workloadType.id}>
                                    {workloadType.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <h5>Workload Budget</h5>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id='budgetAmount'
                        name='budgetAmount'
                        label='Budget (Optional)'
                        variant='outlined'
                        type='number'
                        InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                        }}
                        value={props.model.budgetAmount}
                        onChange={props.handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant='outlined'>
                        <InputLabel htmlFor='budgetPeriodType'>Period</InputLabel>
                        <Select
                            value={props.model.budgetPeriodType}
                            onChange={props.handleChange}
                            input={<OutlinedInput labelWidth={50} name='budgetPeriodType' id='budgetPeriodType' />}
                        >
                            <MenuItem value='None'>None</MenuItem>
                            <MenuItem value='monthly'>Monthly</MenuItem>
                            <MenuItem value='yearly'>Yearly</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export default WorkloadInfoForm;
