import React from 'react';
import scss from './SidebarMessage.module.scss';

export interface SidebarMessageProps {
    title: string;
    description?: string;
    timestamp?: string;
    actionButtons?: JSX.Element[] | JSX.Element;
    actionIcon: JSX.Element;
    onClick?: () => void;
    statusIndicator?: JSX.Element;
    darkBackground?: boolean;
}

export const SidebarMessage: React.FC<SidebarMessageProps> = (props) => {
    const handleOnClick = () => {
        if (props.onClick) props.onClick();
    };

    return (
        <>
            <div className={scss.message} onClick={handleOnClick}>
                {props.statusIndicator && <div className={scss.statusIndicator}>{props.statusIndicator}</div>}
                <div className={`flexRow ${props.darkBackground ? 'c-white' : 'c-secondary'}`}>
                    <h6 className={`flexGrow ${props.darkBackground ? 'white' : 'c-secondary'}`}>{props.title}</h6>
                    <div>{props.actionIcon}</div>
                </div>
                {!!props.description && <p className={`body2 ${props.darkBackground ? 'c-slate-light' : 'c-slate'}`}>{props.description}</p>}
                <div className={`body2 ta-right ${props.darkBackground ? 'c-slate-lightest' : 'c-slate-light'}`}>{props.timestamp}</div>
                {!!props.actionButtons && <div className={`flexRow flexSeparate alignItemsCenter ${scss.buttons}`}>{props.actionButtons}</div>}
            </div>
        </>
    );
};
