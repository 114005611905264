import React, { useContext, useState, useEffect, ChangeEvent } from 'react';
import TimedInstance from './TimedInstance';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import {
    ListAwsDescribeAmis,
    DescribeAmi,
    ListAwsDescribeInstanceTypes,
    DescribeInstanceType,
    ListAwsDescribeVpcs,
    DescribeVpc,
    ListAwsDescribeSecurityGroups,
    DescribeSecurityGroup,
    ListAwsDescribeRegions,
    DescribeRegion,
    StartActionExecution,
    TimedInstanceParameters,
    ActionType,
} from '../../ServiceStack/ServiceStack.dtos';
import { useSnackbar } from 'notistack';

export interface ITimedInstanceControllerProps {
    cancelClick: () => void;
    workloadId: string;
}

const MAX_TIME_FOR_INSTANCE_RUN = 8760;

const TimedInstanceController: React.FC<ITimedInstanceControllerProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const snackbar = useSnackbar();

    const [regions, setRegions] = useState<DescribeRegion[]>([]);
    const [operatingSystems, setOperatingSystems] = useState<string[]>(['Linux/UNIX', 'Windows']);
    const [amiIds, setAmiIds] = useState<DescribeAmi[]>([]);
    const [instanceTypes, setInstanceTypes] = useState<DescribeInstanceType[]>([]);
    const [vpcs, setVpcs] = useState<DescribeVpc[]>([]);
    const [securityGroups, setSecurityGroups] = useState<DescribeSecurityGroup[]>([]);
    const [isWindows, setIsWindows] = React.useState(false);
    const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = React.useState(false);
    const [isInvalidTimeInstanceRuns, setIsInvalidTimeInstanceRuns] = React.useState(false);

    //user enter data handlers
    const [operatingSystem, setOperatingSystem] = React.useState('');
    const [region, setRegion] = React.useState('');
    const [amiId, setAmiId] = React.useState('');
    const [instanceType, setInstanceType] = useState('');
    const [vpc, setVpc] = React.useState('');
    const [securityGroup, setSecurityGroup] = React.useState('');
    const [instanceName, setInstanceName] = React.useState('');
    const [snapshotDescription, setSnapshotDescription] = React.useState('');
    const [publicKey, setPublicKey] = React.useState('');
    const [timeInstanceRuns, setTimeInstanceRuns] = React.useState('');
    const [userName, setUserName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');

    useEffect(() => {
        loadRegions();
        loadOperatingSystems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadAmis();
        loadInstanceTypes();
        loadVpcs();
        loadSecurityGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region, operatingSystem, vpc]);

    const loadOperatingSystems = () => {
        /*
        const request = new ListRecommendations();

        vegaApi.getApiClient().get(request)
        .then(response => {
            setRecommendations(response.results.filter(x => x.status === RecommendationStatus.Scheduled || x.status === RecommendationStatus.WaitingForApproval || x.status === RecommendationStatus.Pending).sort( (a,b) => +!!(a.status === RecommendationStatus.Scheduled) - +!!(b.status === RecommendationStatus.Scheduled)));
            setLoading(false);
            setLoadComplete(true);
        });
        */
    };

    const loadRegions = () => {
        const request = new ListAwsDescribeRegions({
            workloadId: props.workloadId,
        });
        vegaApi
            .getApiClient()
            .get(request)
            .then((response) => {
                setRegions(response.result);
            });
    };

    const loadAmis = () => {
        if (region !== '' && operatingSystem !== '') {
            const request = new ListAwsDescribeAmis({
                workloadId: props.workloadId,
                region: region,
                operatingSystem: operatingSystem,
            });

            vegaApi
                .getApiClient()
                .get(request)
                .then((response) => {
                    setAmiIds(response.result);
                });
        }
    };

    const loadInstanceTypes = () => {
        if (region !== '') {
            const request = new ListAwsDescribeInstanceTypes({
                workloadId: props.workloadId,
                region: region,
            });

            vegaApi
                .getApiClient()
                .get(request)
                .then((response) => {
                    setInstanceTypes(response.result);
                });
        }
    };

    const loadVpcs = () => {
        if (region !== '') {
            const request = new ListAwsDescribeVpcs({
                workloadId: props.workloadId,
                region: region,
            });

            vegaApi
                .getApiClient()
                .get(request)
                .then((response) => {
                    setVpcs(response.result);
                });
        }
    };

    const loadSecurityGroups = () => {
        if (region !== '' && vpc !== '') {
            const request = new ListAwsDescribeSecurityGroups({
                workloadId: props.workloadId,
                region: region,
                vpc: vpc,
            });

            vegaApi
                .getApiClient()
                .get(request)
                .then((response) => {
                    setSecurityGroups(response.result);
                });
        }
    };

    const onChangeRegion = (event) => {
        setOperatingSystem('');
        setAmiId('');
        setInstanceType('');
        setSecurityGroup('');
        setVpc('');
        setRegion(event.target.value);
    };

    const onChangeOperatingSystem = (event) => {
        setAmiId('');
        if (event.target.value === 'windows') {
            setIsWindows(true);
        } else {
            setIsWindows(false);
        }
        setOperatingSystem(event.target.value);
    };

    const onChangeAmi = (event) => {
        setAmiId(event.target.value);
    };

    const onChangeInstanceType = (event) => {
        setInstanceType(event.target.value);
    };

    const onChangeSecurityGroup = (event) => {
        setSecurityGroup(event.target.value);
    };

    const onChangeVpc = (event) => {
        setSecurityGroup('');
        setVpc(event.target.value);
    };

    const onChangeInstanceName = (event: ChangeEvent<HTMLInputElement>) => {
        setInstanceName(event.target.value);
    };

    const onChangeSnapshotDescription = (event: ChangeEvent<HTMLInputElement>) => {
        setSnapshotDescription(event.target.value);
    };

    const onChangePublicKey = (event: ChangeEvent<HTMLInputElement>) => {
        setPublicKey(event.target.value);
    };

    const isWholeNumber = (value: number) => {
        if (value % 1 === 0) {
            return true;
        } else {
            return false;
        }
    };

    const onChangeTimeInstanceRuns = (event: ChangeEvent<HTMLInputElement>) => {
        const timeInstanceRuns = Number(event.target.value);
        if (!isNaN(timeInstanceRuns)) {
            if (timeInstanceRuns < 1 || timeInstanceRuns > MAX_TIME_FOR_INSTANCE_RUN || !isWholeNumber(timeInstanceRuns)) {
                setIsInvalidTimeInstanceRuns(true);
            } else {
                setIsInvalidTimeInstanceRuns(false);
            }
        } else {
            setIsInvalidTimeInstanceRuns(true);
        }
        setTimeInstanceRuns(event.target.value);
    };

    const onChangeUserName = (event: ChangeEvent<HTMLInputElement>) => {
        setUserName(event.target.value);
    };

    const onChangePassword = (password: string) => {
        setPassword(password);
    };

    const onChangeConfirmPassword = (confirmPassword: string) => {
        if (confirmPassword !== password) {
            setIsInvalidConfirmPassword(true);
        } else {
            setIsInvalidConfirmPassword(false);
        }
        setConfirmPassword(confirmPassword);
    };

    const isValidTimedInstanceInput = (): boolean => {
        const isValidFormData =
            region !== '' &&
            operatingSystem !== '' &&
            vpc !== '' &&
            amiId !== '' &&
            securityGroup !== '' &&
            instanceType !== '' &&
            instanceName !== '' &&
            snapshotDescription !== '' &&
            timeInstanceRuns !== '';

        let isValidWindowsFormInput = true;

        if (isWindows) {
            isValidWindowsFormInput = userName !== '' && password !== '' && confirmPassword !== '' && password === confirmPassword;
        }

        if (!isWindows) {
            isValidWindowsFormInput = !!publicKey;
        }

        return isValidFormData && isValidWindowsFormInput && !isInvalidTimeInstanceRuns;
    };

    const onClickCreateInstance = () => {
        const request = new StartActionExecution({
            entityId: props.workloadId,
            actionType: ActionType.TimedInstance,
            timedInstanceParameters: new TimedInstanceParameters({
                publicKey: publicKey,
                userName: userName,
                password: password,
                region: region,
                satelliteId: props.workloadId,
                securityGroupId: securityGroup,
                amiImageId: amiId,
                instanceName: instanceName,
                instanceType: instanceType,
                snapshotDescription: snapshotDescription,
                isWindows: isWindows,
                waitInHours: Number(timeInstanceRuns),
            }),
        });

        vegaApi
            .getApiClient()
            .post(request)
            .then((response) => {
                console.log(response);
                snackbar.enqueueSnackbar('Timed Instances can take up to 5 minutes to build. Vega will notify you when your instance is ready.', { variant: 'info' });
                props.cancelClick();
            });
    };

    return (
        <>
            <TimedInstance
                cancelClick={props.cancelClick}
                onChangeRegion={onChangeRegion}
                onChangeOperatingSystem={onChangeOperatingSystem}
                onChangeVpc={onChangeVpc}
                onChangeAmi={onChangeAmi}
                onChangeInstanceType={onChangeInstanceType}
                onChangeSecurityGroup={onChangeSecurityGroup}
                onChangeInstanceName={onChangeInstanceName}
                onChangeSnapshotDescription={onChangeSnapshotDescription}
                onChangePublicKey={onChangePublicKey}
                onChangeTimeInstanceRuns={onChangeTimeInstanceRuns}
                onChangeUserName={onChangeUserName}
                onChangePassword={onChangePassword}
                onChangeConfirmPassword={onChangeConfirmPassword}
                isValidTimedInstanceInput={isValidTimedInstanceInput}
                onClickCreateInstance={onClickCreateInstance}
                regions={regions}
                operatingSystems={operatingSystems}
                amiIds={amiIds}
                instanceTypes={instanceTypes}
                vpcs={vpcs}
                securityGroups={securityGroups}
                region={region}
                operatingSystem={operatingSystem}
                vpc={vpc}
                amiId={amiId}
                securityGroup={securityGroup}
                instanceType={instanceType}
                instanceName={instanceName}
                snapshotDescription={snapshotDescription}
                publicKey={publicKey}
                timeInstanceRuns={timeInstanceRuns}
                userName={userName}
                password={password}
                confirmPassword={confirmPassword}
                isInvalidConfirmPassword={isInvalidConfirmPassword}
                isInvalidTimeInstanceRuns={isInvalidTimeInstanceRuns}
            />
        </>
    );
};

export default TimedInstanceController;
