import React, { ChangeEvent } from 'react';
import { Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Scheduler, Times } from '../Scheduler/Scheduler';

export interface IActionScheduleProps {
    initialSelections?: Array<Times>;
    onChangeSchedule: (selectedTimes: Array<Times>) => void;
    setSchedule: (selectedTimes: Array<Times>) => void;
    scheduleMode: string;
    onSetScheduleMode?: (event: ChangeEvent<HTMLInputElement>, scheduleMode: string) => void;
}

const ActionSchedule: React.FC<IActionScheduleProps> = (props: IActionScheduleProps) => {
    return (
        <>
            <Grid container direction='column' alignItems='center'>
                <Grid item xs={6}>
                    <RadioGroup aria-label='ActionStart' name='actionStart' value={props.scheduleMode} onChange={props.onSetScheduleMode} row>
                        <FormControlLabel value='now' control={<Radio />} label='Immediately' />
                        <FormControlLabel value='schedule' control={<Radio />} label='Schedule' />
                    </RadioGroup>
                </Grid>
                {props.scheduleMode === 'now' && <p className='body1 c-slate-light'>Your selected workloads will be immediately parked.</p>}
            </Grid>
            {props.scheduleMode === 'schedule' && <Scheduler onChangeSchedule={props.onChangeSchedule} initialSelections={props.initialSelections} />}
        </>
    );
};

export { ActionSchedule };
