import React, { useContext, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import * as Globals from '../../globals';
import scss from './SignUpAuth.module.scss';
import SignupLayout from '../SignupLayout/SignupLayout';
import emailMaskPipe from '../../utils/EmailMaskPipe';
import phoneMaskPipe from '../../utils/PhoneMaskPipe';
import { CreateOnBoardUserForm } from './SignUpLanding';
import { PassCodeDeliveryMedium, CreateOnBoardUser } from '../../ServiceStack/ServiceStack.dtos';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { useSnackbar } from 'notistack';

export interface SignUpAuthProps {
    model: CreateOnBoardUserForm;
    modelChange: (newVal: CreateOnBoardUserForm) => void;
    onNext: () => void;
}

const SignUpAuth: React.FC<SignUpAuthProps> = (props) => {
    const vegaApi = useContext(VegaApiContext);
    const snackbar = useSnackbar();

    const [isProcessing, setIsProcessing] = useState(false);

    const submitSignup = (passCodeDeliveryMedium: PassCodeDeliveryMedium) => {
        setIsProcessing(true);
        const temp = new CreateOnBoardUser(props.model);
        temp.passCodeDeliveryMedium = passCodeDeliveryMedium;
        props.modelChange(temp);
        vegaApi
            .getApiClient()
            .post(temp)
            .then(props.onNext)
            .catch((error) => {
                snackbar.enqueueSnackbar(`Authentication Error: ${error.responseStatus.message}`, { variant: 'error' });
                setIsProcessing(false);
            });
    };

    return (
        <SignupLayout>
            <Grid container spacing={4}>
                <Grid item xs={3} />
                <Grid item xs={6}>
                    <h5>Authentication</h5>
                </Grid>
                <Grid item xs={3} />

                <Grid item xs={3} />
                <Grid item xs={6}>
                    <span className='body1 c-slate-light'>How would you like us to send your authentication code?</span>
                </Grid>
                <Grid item xs={3} />

                <Grid item xs={3} />
                <Grid item xs={6}>
                    <div className={scss.spinnerCtr}>
                        <button className={`simpleCard selectable padded flexRow ${scss.cardButton}`} onClick={() => submitSignup(PassCodeDeliveryMedium.Email)}>
                            <div className='cardIcon'>
                                {!(isProcessing && props.model.passCodeDeliveryMedium === PassCodeDeliveryMedium.Email) && <span className='icon icon-email active large' />}
                                {isProcessing && props.model.passCodeDeliveryMedium === PassCodeDeliveryMedium.Email && <CircularProgress />}
                            </div>
                            <div className='flexCol flexGrow'>
                                <div className='body1 c-slate-light'>{Globals.BY_EMAIL}</div>
                                <div className='body1'>{emailMaskPipe(props.model.email)}</div>
                            </div>
                        </button>
                    </div>
                </Grid>
                <Grid item xs={3} />

                <Grid item xs={3} />
                <Grid item xs={6}>
                    <button className={`simpleCard selectable padded flexRow ${scss.cardButton}`} onClick={() => submitSignup(PassCodeDeliveryMedium.MobilePhone)}>
                        <div className='cardIcon'>
                            {!(isProcessing && props.model.passCodeDeliveryMedium === PassCodeDeliveryMedium.MobilePhone) && <span className='icon icon-mobile active large' />}
                            {isProcessing && props.model.passCodeDeliveryMedium === PassCodeDeliveryMedium.MobilePhone && <CircularProgress />}
                        </div>
                        <div className='flexCol flexGrow'>
                            <div className='body1 c-slate-light'>{Globals.BY_PHONE}</div>
                            <div className='body1'>{phoneMaskPipe(props.model.mobilePhone)}</div>
                        </div>
                    </button>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </SignupLayout>
    );
};

export default SignUpAuth;
