import React, { ChangeEvent, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';

export interface IHoursInputProps {
    onChangeHours: (event: { hours: string; isValid: boolean }) => void;
    maxHours: number;
    label: string;
    hours: string;
}

const HoursInput: React.FC<IHoursInputProps> = (props) => {
    const [isValidTime, setIsValidTime] = useState(true);

    const isWholeNumber = (value: number) => {
        if (value % 1 === 0) {
            return true;
        } else {
            return false;
        }
    };

    const onChangeTimeInstanceRuns = (event: ChangeEvent<HTMLInputElement>) => {
        const time = Number(event.target.value);
        let isValid = false;
        if (!isNaN(time)) {
            if (time < 1 || time > props.maxHours || !isWholeNumber(time)) {
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        setIsValidTime(isValid);
        props.onChangeHours({ hours: event.target.value, isValid: isValid });
    };

    return (
        <>
            <Grid item xs={7}>
                <TextField
                    type='Number'
                    name=''
                    label={props.label}
                    variant='outlined'
                    value={props.hours}
                    onChange={onChangeTimeInstanceRuns}
                    error={!isValidTime}
                    helperText={!isValidTime && `Must be whole number between 1 and ${props.maxHours}.`}
                />
            </Grid>
            <Grid item xs={5} className='c-slate-light flexCol justifyCenter'>
                Hours
            </Grid>
        </>
    );
};

export { HoursInput };
