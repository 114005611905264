import React, { ChangeEvent, useState } from 'react';
import { GrantSecurityAccess } from './GrantSecurityAccess';
const publicIp = require('public-ip');
const IPCIDR = require('ip-cidr');

export interface IGrantSecurityAccessControllerProps {
    cancelClick: () => void;
    workloadId: string;
}

export type GrantSecurityAccessType = {
    name: string;
    ipProtocol: string;
    fromPort: string;
    toPort: string;
};

const GrantSecurityAccessTypes: GrantSecurityAccessType[] = [
    {
        name: 'RDP',
        ipProtocol: 'TCP',
        fromPort: '3389',
        toPort: '3389',
    },
    {
        name: 'SSH',
        ipProtocol: 'TCP',
        fromPort: '22',
        toPort: '22',
    },
];

const GrantSecurityAccessController: React.FC<IGrantSecurityAccessControllerProps> = (props) => {
    const ipProtocols = ['TCP', 'UDP', 'ICMP', 'ICMPV6'];
    const [selectedIpProtocol, setSelectedIpProtocol] = useState('');
    const [selectedSecurityAccessType, setSelectedSecurityAccessType] = useState<number>('' as any);
    const [ipAddress, setIpAddress] = useState('');
    const [fromPort, setFromPort] = useState('');
    const [toPort, setToPort] = useState('');
    const [timeForAccess, setTimeForAccess] = useState('');
    const [isTimedAccess, setIsTimedAccess] = useState(false);
    const [isValidTimeForAccess, setIsValidTimeForAccess] = useState(true);
    const [isValidIpAddress, setIsValidIpAddress] = useState(true);

    const onClickGetMyIp = async () => {
        const ip = await publicIp.v4();
        validateIp(ip);
        setIpAddress(`${ip}/32`);
    };

    const onChangeFromPort = (event: ChangeEvent<HTMLInputElement>) => {
        setFromPort(event.target.value);
    };

    const onChangeToPort = (event: ChangeEvent<HTMLInputElement>) => {
        setToPort(event.target.value);
    };

    const onChangeIpAddress = (event: ChangeEvent<HTMLInputElement>) => {
        validateIp(event.target.value);
        setIpAddress(event.target.value);
    };

    const validateIp = (ip: string) => {
        const cidr = new IPCIDR(ip);
        setIsValidIpAddress(cidr.isValid());
    };

    const onChangeSecurityAccessType = (event) => {
        const securityAccessType: GrantSecurityAccessType = GrantSecurityAccessTypes[event.target.value];
        setSelectedSecurityAccessType(event.target.value);
        setSelectedIpProtocol(securityAccessType.ipProtocol);
        setFromPort(securityAccessType.fromPort);
        setToPort(securityAccessType.toPort);
    };

    const onChangeIpProtocol = (event) => {
        setSelectedIpProtocol(event.target.value);
    };

    const onChangeIsTimedAccess = (event: ChangeEvent<HTMLInputElement>) => {
        if (isTimedAccess) {
            setTimeForAccess('');
            setIsTimedAccess(false);
        } else {
            setIsTimedAccess(true);
        }
    };

    const onChangeTimeForAccess = (event: { hours: string; isValid: boolean }) => {
        setIsValidTimeForAccess(event.isValid);
        setTimeForAccess(event.hours);
    };

    const isValidGrantSecurityInput = (): boolean => {
        const isValidFormData = selectedIpProtocol !== '' && fromPort !== '' && toPort !== '' && Number(toPort) >= Number(fromPort) && ipAddress !== '';

        let isValidTimedFormInput = true;

        if (isTimedAccess) {
            isValidTimedFormInput = timeForAccess !== '' && isValidTimeForAccess;
        }

        return isValidFormData && isValidTimedFormInput && isValidIpAddress;
    };

    const onClickGrantSecurityAccess = () => {
        const fakeRequest = {
            ipProtocol: selectedIpProtocol,
            fromPort: fromPort,
            toPort: toPort,
            ipAddress: ipAddress,
            timeForAccess: isTimedAccess ? timeForAccess : -1,
        };

        console.log(fakeRequest);
    };

    return (
        <GrantSecurityAccess
            onCancelClick={props.cancelClick}
            onClickGrantSecurityAccess={onClickGrantSecurityAccess}
            onClickGetMyIp={onClickGetMyIp}
            onChangeFromPort={onChangeFromPort}
            onChangeToPort={onChangeToPort}
            onChangeIpAddress={onChangeIpAddress}
            onChangeSecurityAccessType={onChangeSecurityAccessType}
            onChangeIpProtocol={onChangeIpProtocol}
            onChangeIsTimedAccess={onChangeIsTimedAccess}
            onChangeTimeForAccess={onChangeTimeForAccess}
            fromPort={fromPort}
            toPort={toPort}
            ipAddress={ipAddress}
            grantSecurityAccessTypes={GrantSecurityAccessTypes}
            ipProtocols={ipProtocols}
            selectedIpProtocol={selectedIpProtocol}
            selectedSecurityAccessType={selectedSecurityAccessType}
            isTimedAccess={isTimedAccess}
            timeForAccess={timeForAccess}
            isValidGrantSecurityInput={isValidGrantSecurityInput()}
            isValidIpAddress={isValidIpAddress}
        />
    );
};

export { GrantSecurityAccessController };
