import React, { useContext, useState, useEffect } from 'react';
import { VegaApiContext } from '../VegaApi/VegaApi';
import {
    ListAwsRegions,
    GetUser,
    GetUserModel,
    Authenticate,
    ListNotifications,
    CloudProvider,
    EntityFilterType,
    ListNotificationsModel,
    DeleteNotification,
    GetOrganization,
    GetOrganizationModel,
    GetSpaceModel,
    GetSpace,
    ListSpaceTypes,
    SpaceTypeModel,
    GetWorkload,
    GetWorkloadModel,
    ListWorkloadTypes,
    ListWorkloadTypesModel,
    ListWorkloadSummaries,
    ListWorkloadSummariesModel,
} from '../../ServiceStack/ServiceStack.dtos';
import { ActionsContext } from '../../contexts/Actions/Actions';
import { VegaContext } from '../../contexts/Vega/Vega';
import { WebSocketContext } from '../WebSocket/WebSocket';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { urlUpOneLevel, isGuid } from '../../utils';
import { useSnackbar } from 'notistack';
import { WebSocketMessage } from '../WebSocket/WebSocketTypes';
import VegaAppLoading from '../../components/VegaAppLoading/VegaAppLoading';

export interface IAppStateContextMethods {
    awsRegions: string[];
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    inviteCodeVerified: boolean;
    setInviteCodeVerified: React.Dispatch<React.SetStateAction<boolean>>;
    currentUser: GetUserModel;
    login: (userName: string, password: string) => Promise<void>;
    isAuthenticated: boolean;
    clearRedirectUrl: () => void;
    logout: () => void;
    redirectUrl: string;
    loadUserData: () => Promise<GetUserModel>;
    notifications: ListNotificationsModel[];
    dismissNotification: (id: string) => void;
    orgData: GetOrganizationModel;
    currentSpace: GetSpaceModel;
    setCurrentSpace: React.Dispatch<React.SetStateAction<GetSpaceModel>>;
    currentWorkload: GetWorkloadModel;
    setCurrentWorkload: React.Dispatch<React.SetStateAction<GetWorkloadModel>>;
    spaceTypes: SpaceTypeModel[];
    workloadTypes: ListWorkloadTypesModel[];
    loadWorkloads: (userIn?: GetUserModel) => Promise<ListWorkloadSummariesModel[]>;
    workloads: ListWorkloadSummariesModel[];
    setWorkloads: React.Dispatch<React.SetStateAction<ListWorkloadSummariesModel[]>>;
    newLoadId: string;
    setNewLoadId: React.Dispatch<React.SetStateAction<string>>;
}

export interface IAppStateContextProps extends RouteComponentProps<any> {}

const AppStateContext = React.createContext({} as IAppStateContextMethods);

const AppStateProviderNoExport: React.FC<IAppStateContextProps> = (props) => {
    const actionsContext = useContext<any>(ActionsContext);
    const vegaContext = useContext<any>(VegaContext);
    const websocketContext = useContext(WebSocketContext);
    const vegaApi = useContext(VegaApiContext);
    const snackbar = useSnackbar();

    // global data
    const [loaded, setLoaded] = useState(false);
    const [awsRegions, setAwsRegions] = useState<string[]>([]);
    const [spaceTypes, setSpaceTypes] = useState<SpaceTypeModel[]>();
    const [workloadTypes, setWorkloadTypes] = useState<ListWorkloadTypesModel[]>([]);
    const [orgData, setOrgData] = useState<GetOrganizationModel>(new GetOrganizationModel());
    // state
    const [currentSpace, setCurrentSpace] = useState<GetSpaceModel>(new GetSpaceModel());
    const [currentWorkload, setCurrentWorkload] = useState<GetWorkloadModel>(new GetWorkloadModel());
    const [workloads, setWorkloads] = useState<ListWorkloadSummariesModel[]>([]);
    const [notifications, setNotifications] = useState<ListNotificationsModel[]>([]);
    // authentication
    const [currentUser, setCurrentUser] = useState<GetUserModel>(new GetUserModel());
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('jwttoken'));
    const [redirectUrl, setRedirectUrl] = useState('');
    // temporarily used by onboarding
    const [password, setPassword] = useState('');
    const [inviteCodeVerified, setInviteCodeVerified] = useState(false);
    // temporarily used by workloads
    const [newLoadId, setNewLoadId] = useState<string>();
    // internal use only
    const [websocketId, setWebsocketId] = useState(-1);

    const login = async (userName: string, password: string) => {
        const client = vegaApi.getApiClient();
        const request = new Authenticate({ userName, password, provider: 'cognito' });
        const response = await client.post(request);
        client.bearerToken = response.bearerToken;
        localStorage.setItem('jwttoken', response.bearerToken);
        localStorage.setItem('email', userName);
        await afterLoginTasks();
        setIsAuthenticated(true);
    };

    const logout = () => {
        console.info('[VegaApiContext] Logout triggered');
        setRedirectUrl(document.location.pathname);
        setIsAuthenticated(false);
        websocketContext.removeListener(websocketId);
        websocketContext.disconnectWebSocket();
        vegaApi.getApiClient().bearerToken = undefined;
        localStorage.clear();

        // clean up all the contexts
        actionsContext.actionsInit();
        vegaContext.vegaInit();
        websocketContext.resetParkingMessages();
    };

    const loadAwsRegions = async () => {
        await vegaApi
            .getApiClient()
            .get(new ListAwsRegions())
            .then((response) => setAwsRegions(response.result.regions));
    };

    const clearRedirectUrl = () => setRedirectUrl('');

    const loadUserData = async () => {
        try {
            const response = await vegaApi.getApiClient().get(new GetUser({ email: localStorage.getItem('email') }));
            setCurrentUser(response.result);
            return response.result;
        } catch (error) {
            console.error(error);
            logout();
            throw error;
        }
    };

    const initWebsocket = async (user: GetUserModel) => {
        if (user.isOnBoarded) websocketContext.initializeWebSocket();
    };

    const loadNotifications = async (user: GetUserModel) => {
        if (user.isOnBoarded) {
            const request = new ListNotifications({
                cloudProvider: CloudProvider.None,
                entityFilterType: EntityFilterType.None,
            });
            const response = await vegaApi.getApiClient().get(request);
            setNotifications(response.result);
        } else setNotifications([]);
    };

    const dismissNotification = (id: string) => {
        vegaApi
            .getApiClient()
            .delete(new DeleteNotification({ id }))
            .then((response) => {
                if (response.result.isSuccess) {
                    setNotifications(notifications.filter((x) => x.id !== id));
                }
            });
    };

    const loadOrgInfo = async () => {
        const response = await vegaApi.getApiClient().get(new GetOrganization());
        setOrgData(response.result);
    };

    const loadSpaceTypes = async () => {
        const response = await vegaApi.getApiClient().get(new ListSpaceTypes());
        setSpaceTypes(response.result.spaceTypes);
    };

    const loadWorkloadTypes = async () => {
        const response = await vegaApi.getApiClient().get(new ListWorkloadTypes());
        setWorkloadTypes(response.result);
    };

    const loadStuffFromUrl = async (user: GetUserModel) => {
        // get rid of trailing slashes -- they screw up react router dom
        if (props.location.pathname.charAt(props.location.pathname.length - 1) === '/')
            props.history.replace(props.location.pathname.substring(0, props.location.pathname.length - 1));

        if (user.isOnBoarded) {
            let keepGoing = true;
            const parts = props.location.pathname.split('/');

            // load current space by GUID if present
            if (parts[1] && parts[1] === 'spaces' && parts[2] && isGuid(parts[2])) {
                try {
                    const response = await vegaApi.getApiClient().get(new GetSpace({ spaceId: parts[2] }));
                    setCurrentSpace(response.result);
                } catch (e) {
                    console.error(`Unable to find spaceId ${parts[2]}`);
                    snackbar.enqueueSnackbar(`We couldn't find a space of ID ${parts[2]}`, { variant: 'warning' });
                    keepGoing = false;
                    urlUpOneLevel(props);
                }
            }

            // load current workload by GUID if present
            if (keepGoing && parts[3] && isGuid(parts[3])) {
                try {
                    const response = await vegaApi.getApiClient().get(new GetWorkload({ workloadId: parts[3] }));
                    setCurrentWorkload(response.result);
                } catch (e) {
                    console.error(`Unable to find workloadId ${parts[3]}`);
                    snackbar.enqueueSnackbar(`We couldn't find a workload of ID ${parts[3]}`, { variant: 'warning' });
                    keepGoing = false;
                    urlUpOneLevel(props);
                }
            }
        }
    };

    const loadWorkloads = async (userIn?: GetUserModel) => {
        const user = userIn ? userIn : currentUser;
        if (user.isOnBoarded) {
            const response = await vegaApi.getApiClient().get(new ListWorkloadSummaries());
            setWorkloads(response.result);
            return response.result;
        }
        return [];
    };

    const initWebsocketListeners = async (user: GetUserModel) => {
        const id = websocketContext.addListener((message: WebSocketMessage) => {
            loadNotifications(user);
        });
        setWebsocketId(id);
    };

    const afterLoginTasks = async () => {
        console.info('[AppState] User authenticated -- loading stuff');
        const user = await loadUserData();
        if (user) {
            await initWebsocket(user);
            await loadNotifications(user);
            await loadOrgInfo();
            await loadStuffFromUrl(user);
            await loadSpaceTypes();
            await loadWorkloadTypes();
            await loadWorkloads(user);
            await initWebsocketListeners(user);
        }
    };

    // load stuff at app launch
    useEffect(() => {
        // configure 401 unauthorized logout interceptors
        vegaApi.getApiClient().onAuthenticationRequired = async () => {
            snackbar.enqueueSnackbar('Your session has expired. Please sign in again', { variant: 'warning' });
            logout();
            setLoaded(true);
            return;
        };

        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response.status === 401) {
                    snackbar.enqueueSnackbar('Your session has expired. Please sign in again', { variant: 'warning' });
                    logout();
                }
                throw error;
            }
        );

        loadAwsRegions().then(() => {
            const afterDone = () => setLoaded(true);
            if (isAuthenticated) afterLoginTasks().then(afterDone);
            else {
                setRedirectUrl(props.location.pathname);
                props.history.push('/login');
                afterDone();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppStateContext.Provider
            value={{
                awsRegions,
                password,
                setPassword,
                inviteCodeVerified,
                setInviteCodeVerified,
                currentUser,
                login,
                isAuthenticated,
                logout,
                redirectUrl,
                clearRedirectUrl,
                loadUserData,
                notifications,
                dismissNotification,
                orgData,
                currentSpace,
                setCurrentSpace,
                spaceTypes,
                currentWorkload,
                setCurrentWorkload,
                loadWorkloads,
                workloadTypes,
                newLoadId,
                setNewLoadId,
                workloads,
                setWorkloads,
            }}
        >
            {loaded && props.children}
            {!loaded && <VegaAppLoading />}
        </AppStateContext.Provider>
    );
};

const AppStateConsumer = AppStateContext.Consumer;
const AppStateProvider = withRouter(AppStateProviderNoExport);

export { AppStateProvider, AppStateConsumer, AppStateContext };
