import React from 'react';
import scss from './LoginLayout.module.scss';
import { ReactComponent as VegaLogo } from '../../images/vega-logo.svg';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import RoundCloseButton from '../RoundCloseButton/RoundCloseButton';
import { ReactComponent as SignInImage } from '../../images/sign-in-image.svg';
import Modal from '../Modal/Modal';
import { Grid } from '@material-ui/core';
import EmailInput from '../EmailInput/EmailInput';
import { AccessCodeInput } from '../AccessCodeInput/AccessCodeInput';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { VegaApiContext } from '../../contexts/VegaApi/VegaApi';
import { AcceptInvite } from '../../ServiceStack/ServiceStack.dtos';

export interface LoginLayoutProps extends RouteComponentProps<any> {
    onClose?: () => void;
}

const LoginLayout: React.FC<LoginLayoutProps> = (props) => {
    const [showInviteModal, setShowInviteModal] = React.useState(false);
    const [inviteCode, setInviteCode] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [error, setError] = React.useState();
    const vegaApi = React.useContext(VegaApiContext);
    const appState = React.useContext(AppStateContext);

    const createAnAccount = () => props.history.push('/signup');

    const validateCode = (event) => {
        event.preventDefault();
        vegaApi
            .getApiClient()
            .put(new AcceptInvite({ email, inviteCode }))
            .then(() => {
                appState.setInviteCodeVerified(true);
                createAnAccount();
            })
            .catch((error) => setError(error.responseStatus.message));
    };

    const inviteModal = (
        <Modal
            size='auto'
            header={<h6>Welcome to Vega!</h6>}
            content={
                <form onSubmit={validateCode}>
                    <Grid container spacing={4} alignItems='center' className={`${scss.modalContentWidth} noOverflow`}>
                        <Grid item xs={12}>
                            <span>Enter your early access code to create your new Vega Cloud Management Portal account.</span>
                        </Grid>
                        <Grid item xs={8} container spacing={4}>
                            <Grid item xs={12}>
                                <EmailInput id='yourEmail' setUN={setEmail} />
                            </Grid>
                            <Grid item xs={12}>
                                <AccessCodeInput setAccessCode={setInviteCode} />
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <button type='submit' disabled={!email || !inviteCode} className='primary normal medium'>
                                Enter
                            </button>
                        </Grid>
                        {error && (
                            <Grid item xs={12} className='c-danger'>
                                Error: {error} <a href='mailto:support@libertylakecloud.com'>Contact Us</a>
                            </Grid>
                        )}
                    </Grid>
                </form>
            }
            onClose={() => setShowInviteModal(false)}
        />
    );

    return (
        <div className={scss.SignInContainer}>
            {showInviteModal && inviteModal}
            <div className={`${scss.leftSide} flexCol`}>
                <div className={scss.graphicContainer}>
                    <SignInImage className={scss.graphic} />
                </div>
                <div className='padded'>
                    <h2 className='bold'>Welcome to Vega!</h2>
                    <h6>Tools to help you understand and manage your cloud environments.</h6>
                    <button onClick={() => setShowInviteModal(true)} className={`success medium normal center ${scss.createBtn}`}>
                        Create an Account
                    </button>
                </div>
            </div>
            <div className={scss.rightSide}>
                <div className={`noOverflow ${scss.rightContent}`}>
                    {props.onClose && <RoundCloseButton onClick={props.onClose} />}
                    <VegaLogo className={`vegaLogoLarge center ${scss.logo}`} />
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default withRouter(LoginLayout);
