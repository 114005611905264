const emailMaskPipe = (text: string) => {
    if (!text) return undefined;
    let emailParts = text.split('@');
    if (emailParts[0].length > 4) {
        let temp = emailParts[0].substring(emailParts[0].length - 4);
        emailParts[0] = '\u2022'.repeat(emailParts[0].length - temp.length) + temp;
    }

    return emailParts.join('@');
};

export default emailMaskPipe;
