import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Link } from '@material-ui/core';
import scss from './VegaBreadcrumbs.module.scss';
import { AppStateContext } from '../../contexts/AppState/AppState';
import { isGuid } from '../../utils';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export interface VegaBreadcrumbsProps extends RouteComponentProps<any> {
    color?: string;
}

const RouterBreadcrumbs: React.FC<VegaBreadcrumbsProps> = (props) => {
    const appState = React.useContext(AppStateContext);

    const crumbText = (value) => {
        var rtnVal = value;

        // check to see if GUID, if so look up name of entity
        if (isGuid(value)) {
            // check current space
            if (appState.currentSpace && appState.currentSpace.spaceId === value) rtnVal = appState.currentSpace.name;

            // check current workload
            if (appState.currentWorkload && appState.currentWorkload.workloadId === value) rtnVal = appState.currentWorkload.name;
        }

        return rtnVal;
    };

    const linkPieces = props.location.pathname.split('/').filter((x) => x);
    const isQumuloSalesRep = appState.currentUser.email.endsWith('@qumulo.com') && appState.currentUser.email.startsWith('salesrep');
    const isTelusPOC = appState.currentUser.email.endsWith('@telus.com') && appState.currentUser.email.startsWith('teluspoc');

    return (
        <div className={scss.vegaBreadcrumbsContainer}>
            {!isQumuloSalesRep && !isTelusPOC && (
                <Breadcrumbs className={`${scss.vegaBreadcrumbs} ${scss.vegaBreadcrumbs}${props.color}`} aria-label='Breadcrumb'>
                    <LinkRouter color='inherit' to='/dashboard'>
                        <span className={scss.linkText}>Home</span>
                    </LinkRouter>
                    {linkPieces.map((value, index) => {
                        const isLast = index === linkPieces.length - 1;
                        const toLink = `/${linkPieces.slice(0, index + 1)}`.toString().replace(/,/gi, '/');
                        return !isLast ? (
                            <LinkRouter color='inherit' to={toLink} key={toLink}>
                                <span className={scss.linkText}>{crumbText(value)}</span>
                            </LinkRouter>
                        ) : (
                            ''
                        );
                    })}
                </Breadcrumbs>
            )}
        </div>
    );
};

export default withRouter(RouterBreadcrumbs);
