import React, { useContext, useEffect, useMemo } from 'react';
import { Table, TableRow, TableCell, TableBody, TableHead, TableSortLabel } from '@material-ui/core';
import DotIndicator from '../DotIndicator/DotIndicator';
import ellipsePipe from '../../utils/EllipsePipe';
import { useTable, useSortBy } from 'react-table';
import { ResourcesContext } from '../../contexts/Resources/Resources';

export interface ResourceTableProps {
    resources?: any;
    workloadId?: string;
}

const ResourceTable: React.FC<ResourceTableProps> = (props) => {
    const resourcesContext = useContext<any>(ResourcesContext);

    useEffect(() => {
        if (props.workloadId) resourcesContext.loadResources(props.workloadId);
        // eslint-disable-next-line
    }, [props.workloadId]);

    const resources = props.resources ? props.resources : resourcesContext.resources;

    const columns = useMemo(
        () => [
            { Header: 'Type', accessor: 'resourceKind' },
            { Header: 'Name', accessor: 'resourceId' },
            { Header: 'Size', accessor: 'resourceType' },
            { Header: 'Region', accessor: 'region' },
            { Header: 'State', accessor: 'operatingState' },
        ],
        []
    );

    const table = useTable(
        {
            columns,
            data: resources,
        },
        useSortBy
    );

    return (
        <Table {...table.getTableProps()}>
            <TableHead>
                {table.headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'}>
                                    {column.render('Header')}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody>
                {table.rows.map(
                    (row) =>
                        table.prepareRow(row) || (
                            <TableRow {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <TableCell {...cell.getCellProps()}>
                                        {(() => {
                                            switch (cell.column.Header) {
                                                case 'State':
                                                    return (
                                                        <>
                                                            <DotIndicator color={cell.row.values.operatingState} />
                                                            {cell.render('Cell')}
                                                        </>
                                                    );
                                                case 'Name':
                                                    return ellipsePipe(cell.render('Cell'));
                                                default:
                                                    return cell.render('Cell');
                                            }
                                        })()}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                )}
                {!table.rows.length && (
                    <TableRow>
                        {!resourcesContext.runningDiscover && <TableCell colSpan={5}>No resources found!</TableCell>}
                        {resourcesContext.runningDiscover && <TableCell colSpan={5}>Resource discovery in progress...</TableCell>}
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

export default ResourceTable;
