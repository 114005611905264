import React from 'react';

export interface AdvanceButtonProps {
    onClick: () => void;
    disabled: boolean;
    label?: string;
    last?: boolean;
}

const AdvanceButton: React.FC<AdvanceButtonProps> = (props) => {
    let label = props.label || 'Next';

    return (
        <button className={`medium primary ${props.last ? 'normal' : 'flat'}`} onClick={props.onClick} disabled={props.disabled}>
            {label}&nbsp;&nbsp;
            <span className='icon icon-next-arrow' />
        </button>
    );
};

export default AdvanceButton;
